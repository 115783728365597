import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";

type GoBackArrowProps = {
  absolute?: boolean;
  stylesAdded?: string;
  isOffer?: boolean;
};

const GoBackArrow = ({
  absolute,
  stylesAdded,
  isOffer = false,
}: GoBackArrowProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMaterialOffer = location.pathname.includes("/material/offer");
  return (
    <div className={`${absolute ? "absolute top-2" : "mb-4"} ${stylesAdded}`}>
      <button
        onClick={() => {
          isMaterialOffer
            ? navigate("/material/offers")
            : isOffer
              ? navigate("/biddings")
              : navigate(-1);
        }}
      >
        <ArrowLeftCircleIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
};

export default GoBackArrow;
