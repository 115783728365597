import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

async function loadAsyncScript(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) {
      resolve(); // Script already loaded
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = src;
    script.onload = () => resolve();
    script.onerror = () =>
      reject(new Error("Failed to load Google Maps script"));
    document.head.appendChild(script);
  });
}

export default function SiteAutocomplete({
  setAddress,
  address,
}: {
  setAddress: (place: any) => void;
  address?: string;
}) {
  const { t } = useTranslation();
  const searchInput = useRef<HTMLInputElement | null>(null);

  const initMapScript = async (): Promise<void> => {
    // @ts-expect-error window.google is diplays an error
    if (window.google) {
      return Promise.resolve(); // Google API is already loaded
    }
    const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE}&libraries=places`;
    return loadAsyncScript(src);
  };

  // @ts-expect-error window.google is diplays an error
  const onChangeAddress = (autocomplete: google.maps.places.Autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(place);
  };

  const initAutocomplete = () => {
    // @ts-expect-error window.google is diplays an error
    if (!searchInput.current || !window.google) return;

    // @ts-expect-error window.google is diplays an error
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current as HTMLInputElement
    );
    autocomplete.setFields(["place_id", "formatted_address"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  useEffect(() => {
    initMapScript()
      .then(() => initAutocomplete())
      .catch(console.error);
  }, []);

  return (
    <div>
      <label className="uppercase block text-xs font-medium text-gray-700 pb-1">
        {t("sites.address")}
        <span className="text-sm text-red-600"> *</span>
      </label>
      <input
        ref={searchInput}
        placeholder={address ? address : t("sites.insert")}
        className="mt-1 focus:ring-spectum-light border p-2 focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
      />
    </div>
  );
}
