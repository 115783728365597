import { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { MaterialOfferTable } from "../../components";
import { Loader } from "../../components";
import { mapStateToProps } from "../../utils";
import {
  useAllCompanyMaterialOffers,
  useAllMaterialOffers,
} from "../../hooks/useMaterialsOffers";

const MaterialOffers = ({ isAnalyst }: { isAnalyst: boolean }) => {
  const { t } = useTranslation();
  const [pageNumberMaterialOffer, setPageNumberMaterialOffer] = useState(0);
  const [pageNumberCompanyMaterialOffer, setPageNumberCompanyMaterialOffer] =
    useState(0);

  const { data: allMaterialsOffers, isLoading: isLoadingAllMaterialOffers } =
    useAllMaterialOffers(pageNumberMaterialOffer);

  const {
    data: allCompanyMaterialOffers,
    isLoading: isLoadingAllMaterialCompanyOffers,
  } = useAllCompanyMaterialOffers(pageNumberCompanyMaterialOffer, isAnalyst);

  return (
    <div className="px-4 mt-6">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {t("navigation.materialOffers")}
      </h2>
      {isLoadingAllMaterialOffers || isLoadingAllMaterialCompanyOffers ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <>
          {allMaterialsOffers?.content?.length > 0 ? (
            <MaterialOfferTable
              materialOffers={allMaterialsOffers}
              pageNumber={pageNumberMaterialOffer}
              setPageNumber={setPageNumberMaterialOffer}
            />
          ) : (
            <h4 className="text-xs leading-7 text-gray-900 sm:truncate pt-10">
              {t("opportunities.materialOffers")}
            </h4>
          )}
          {!isAnalyst && (
            <>
              {allCompanyMaterialOffers?.content?.length > 0 && (
                <MaterialOfferTable
                  isCompany
                  materialOffers={allCompanyMaterialOffers}
                  pageNumber={pageNumberCompanyMaterialOffer}
                  setPageNumber={setPageNumberCompanyMaterialOffer}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(MaterialOffers);
