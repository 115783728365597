import { useState } from "react";
import { Loader, Messages } from "../../../components";
import { useLanguage } from "../../../hooks";
import i18next from "../../../i18n";
import { formatDate } from "../Chat";
import { useChat, useCreateMessage } from "../../../hooks/useChat";
import { queryKeys } from "../../../hooks/queryKeys";

type ActiveChatProps = {
  isBuy: boolean;
  userId: string;
  materialId: string;
  userIdParams: string | null;
  materialIdParams: string | null;
};

const ActiveChat = ({
  isBuy,
  userId,
  materialId,
  userIdParams,
  materialIdParams,
}: ActiveChatProps) => {
  const langEs = useLanguage();
  const [message, setMessage] = useState("");

  const { data: chat, isLoading: isLoadingChat } = useChat(
    userIdParams ? userIdParams : userId,
    materialIdParams ? materialIdParams : materialId
  );

  const { mutate: mutateMessage, isPending: isLoadingMessage } =
    useCreateMessage([queryKeys.CHAT]);

  const handleCreateMessage = () => {
    if (!message || !chat) return;
    mutateMessage({
      chat: { id: chat.id },
      text: message,
    });
    setMessage("");
  };

  return (
    <div className="col-span-3 flex-1 pb-6 shadow justify-between flex flex-col max-h-eightyFive rounded-tr rounded-br border">
      {isLoadingChat || isLoadingMessage ? (
        <Loader isFullScreen />
      ) : (
        <>
          {chat ? (
            <>
              <div className="flex sm:items-center justify-between border-b border-gray-200">
                <div className="border-b-2 bg-blue-100 w-full">
                  <div className="text-xs px-2 py-4">
                    <p className="uppercase font-bold text-spectum">
                      {chat.material.globalMaterialName}
                    </p>
                    <p className="uppercase font-bold">
                      {isBuy ? (
                        <>
                          {chat.supplier.fullName} -{" "}
                          {chat.supplier.company.name}
                        </>
                      ) : (
                        <>
                          {chat.material.owner.fullName} -{" "}
                          {chat.material.owner.company.name}
                        </>
                      )}
                    </p>
                    {chat.messages.length > 0 && (
                      <span className="text-gray-600">
                        {formatDate(
                          chat.messages[chat.messages.length - 1].updatedAt,
                          langEs
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                id="messages"
                className="flex flex-col space-y-2 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
              >
                {chat.messages.map((message: any) => (
                  <Messages key={message.id} conv={message} />
                ))}

                <div className="border-t-2 border-gray-200 px-4 pb-2 pt-4 mb-1 relative">
                  <div className="flex relative">
                    <input
                      type="text"
                      placeholder={i18next.t("chat.write")}
                      max={255}
                      value={message}
                      onChange={(e) => {
                        if (e.target.value.length === 255) return;
                        setMessage(e.target.value);
                      }}
                      className="shadow text-xs w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 py-1 rounded-tl rounded-bl"
                    />
                    <span className="absolute -bottom-4 text-xs left-0">
                      {message.length} / 255
                    </span>
                    <button
                      onClick={handleCreateMessage}
                      type="button"
                      className="text-xs inline-flex items-center justify-center rounded-tr rounded-br px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none"
                    >
                      <span className="font-bold">{i18next.t("cta.send")}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-6 w-6 ml-2 transform rotate-90"
                      >
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="h-full w-full flex justify-center items-center">
              <p className="font-bold text-spectum text-md uppercase">
                {i18next.t("chat.select")}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ActiveChat;
