import { useMutation } from "@tanstack/react-query";
import axios from "../axios-orders";

const handleValidateUser = async ({
  token,
  email,
}: {
  token: string;
  email: string;
}) => {
  const { data } = await axios.get(`/auth/validate-email/${token}/${email}`);
  return data;
};

export const useUserValidate = () =>
  useMutation({
    mutationFn: handleValidateUser,
  });
