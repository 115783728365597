import i18next from "../../../i18n";
import { PN_TYPES } from "../../../types";

type PnTypeSelectProps = {
  pi_npi: { id: PN_TYPES; title: string; value: PN_TYPES }[];
  handlePNChange: (value: PN_TYPES) => void;
};

export default function PnTypeSelect({
  pi_npi,
  handlePNChange,
}: PnTypeSelectProps) {
  return (
    <select
      className="border-gray-300 border flex items-center cursor-pointer uppercase font-semibold text-xs rounded w-full"
      onChange={(e) => handlePNChange(e.target.value as PN_TYPES)}
    >
      <option value="">{i18next.t("material.head")}</option>
      {pi_npi.map((el) => (
        <option key={el.id} value={el.value}>
          {el.title}
        </option>
      ))}
    </select>
  );
}
