import i18Next from "../../i18n";

export const materialsPITh = [
  { id: 1, name: i18Next.t("tables.internalCode") },
  { id: 2, name: i18Next.t("tables.name") },
  { id: 3, name: i18Next.t("tables.annualVolume") },
  { id: 4, name: i18Next.t("tables.deliveryFormat") },
  { id: 5, name: i18Next.t("tables.site") },
  { id: 6, name: i18Next.t("tables.validatedSuppliers") },
  { id: 7, name: i18Next.t("tables.suppliers") },
  { id: 8, name: i18Next.t("tables.orders") },
  { id: 9, name: "" },
];

export const materialsNPITh = [
  { id: 1, name: i18Next.t("tables.internalCode") },
  { id: 2, name: i18Next.t("tables.name") },
  { id: 3, name: i18Next.t("tables.annualVolume") },
  { id: 4, name: i18Next.t("tables.site") },
  { id: 5, name: i18Next.t("tables.validatedSuppliers") },
  { id: 6, name: i18Next.t("tables.suppliers") },
  { id: 7, name: i18Next.t("tables.orders") },
  { id: 8, name: "" },
];

export const materialsSerTh = [
  { id: 1, name: i18Next.t("tables.internalCode") },
  { id: 2, name: i18Next.t("tables.name") },
  { id: 3, name: i18Next.t("tables.validatedSuppliers") },
  { id: 4, name: i18Next.t("tables.suppliers") },
  { id: 5, name: i18Next.t("tables.orders") },
  { id: 6, name: "" },
];

export const foundMaterialsTh = [
  { id: 1, name: i18Next.t("tables.internalCode") },
  { id: 2, name: i18Next.t("tables.name") },
  { id: 3, name: i18Next.t("tables.deliveryFormat") },
  { id: 4, name: i18Next.t("tables.site") },
  { id: 5, name: "" },
];

export const materialsByOwnerTh = [
  { id: 1, name: i18Next.t("tables.internalCode") },
  { id: 2, name: i18Next.t("tables.name") },
  { id: 3, name: i18Next.t("tables.annualVolume") },
  { id: 4, name: i18Next.t("tables.deliveryFormat") },
  { id: 5, name: i18Next.t("tables.site") },
  { id: 6, name: i18Next.t("tables.owner") },
  { id: 7, name: i18Next.t("tables.validatedSuppliers") },
  { id: 8, name: i18Next.t("tables.suppliers") },
  { id: 9, name: i18Next.t("tables.orders") },
  { id: 10, name: "" },
];

export const allPICompanyMaterialsTh = [
  { id: 1, name: i18Next.t("tables.internalCode") },
  { id: 2, name: i18Next.t("tables.name") },
  { id: 3, name: i18Next.t("tables.annualVolume") },
  { id: 4, name: i18Next.t("tables.deliveryFormat") },
  { id: 5, name: i18Next.t("tables.owner") },
  { id: 6, name: i18Next.t("tables.validatedSuppliers") },
  { id: 7, name: i18Next.t("tables.suppliers") },
  { id: 8, name: i18Next.t("tables.orders") },
  { id: 9, name: "" },
];

export const allNPICompanyMaterialsTh = [
  { id: 1, name: i18Next.t("tables.internalCode") },
  { id: 2, name: i18Next.t("tables.name") },
  { id: 3, name: i18Next.t("tables.annualVolume") },
  { id: 5, name: i18Next.t("tables.owner") },
  { id: 6, name: i18Next.t("tables.validatedSuppliers") },
  { id: 7, name: i18Next.t("tables.suppliers") },
  { id: 8, name: i18Next.t("tables.orders") },
  { id: 9, name: "" },
];

export const userMaterialsTh = [
  { id: 1, name: i18Next.t("tables.internalCode") },
  { id: 2, name: i18Next.t("tables.name") },
  { id: 3, name: i18Next.t("tables.annualVolume") },
  { id: 4, name: i18Next.t("tables.site") },
  { id: 6, name: "" },
];
