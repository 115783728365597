import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios-orders";
import { MaterialOfferResponse, MaterialResponse } from "../types";
import { queryKeys } from "./queryKeys";

const onGetAllMaterialOffers = async (page = 0) => {
  const { data } = await axios.get(`/materialOffer/list/${page}`);
  return data;
};

export const useAllMaterialOffers = (page: number) =>
  useQuery({
    queryKey: [queryKeys.ALL_MATERIALS_OFFERS, page],
    queryFn: () => onGetAllMaterialOffers(page),
  });

const onGetMaterialOffer = async (materialOfferId: string) => {
  const { data } = await axios.get(`/materialOffer/${materialOfferId}`);
  return data;
};

export const useMaterialOffer = (materialOfferId: string) =>
  useQuery<MaterialOfferResponse>({
    queryKey: [queryKeys.MATERIAL_OFFER, materialOfferId],
    queryFn: () => onGetMaterialOffer(materialOfferId),
  });

const onGetAllCompanyMaterialOffers = async (page = 0) => {
  const { data } = await axios.get(`/materialOffer/list/company/${page}`);
  return data;
};

export const useAllCompanyMaterialOffers = (page: number, isAnalyst: boolean) =>
  useQuery({
    queryKey: [queryKeys.ALL_COMPANY_MATERIAL_OFFERS, page],
    queryFn: () => onGetAllCompanyMaterialOffers(page),
    enabled: !isAnalyst,
  });

const onCreateMaterialOffer = async (body: any) => {
  const { data } = await axios.post(`/materialOffer`, body);
  return data;
};

export const useCreateMaterialOffer = (
  onSuccess: (res: any) => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onCreateMaterialOffer,
    onSuccess,
    onError,
  });

const onCreateMaterialOfferList = async (body: any) => {
  const { data } = await axios.post(`/materialOffer/list`, body);
  return data;
};

export const useCreateMaterialOfferList = (
  onSuccess: () => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onCreateMaterialOfferList,
    onSuccess,
    onError,
  });

const onDelegateMaterialOffer = async ({
  materialOfferId,
  body,
}: {
  materialOfferId: string;
  body: any;
}) => {
  const { data } = await axios.put(`/materialOffer/${materialOfferId}`, body);
  return data;
};

export const useDelegateMaterialOffer = (
  onSuccess: () => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onDelegateMaterialOffer,
    onSuccess,
    onError,
  });

const onRequestSample = async (materialOfferId: string) => {
  const { data } = await axios.get(`/materialOffer/sample/${materialOfferId}`);
  return data;
};

export const useRequestSample = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onRequestSample,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.MATERIAL_OFFERS] });
      onSuccess();
    },
    onError,
  });
};

const onQualifySample = async ({
  materialOfferId,
  body,
}: {
  materialOfferId: string;
  body: any;
}) => {
  const { data } = await axios.put(`/materialOffer/${materialOfferId}`, body);
  return data;
};

export const useQualifySample = (onSuccess: (res: any) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onQualifySample,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.MATERIAL_OFFERS] });
      onSuccess(res);
    },
  });
};

const onCreateMaterialOfferInvitation = async (body: any) => {
  const { data } = await axios.post(`/materialInvitation`, body);
  return data;
};

export const useCreateMaterialOfferInvitation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onCreateMaterialOfferInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.MATERIAL_OFFERS] });
      onSuccess();
    },
    onError,
  });
};

const onCheckMaterialOffer = async ({
  materialId,
  buyerId,
}: {
  materialId: string;
  buyerId: string;
}) => {
  const { data } = await axios.get(
    `/buyerSeller/seller/material/${materialId}/buyer/${buyerId}`
  );
  return data;
};

export const useCheckMaterialOffer = (
  materialId: string,
  userId: string,
  buyerId: string,
  material?: MaterialResponse
) =>
  useQuery({
    queryKey: [queryKeys.CHECK_MATERIAL_OFFER, materialId, userId],
    queryFn: () =>
      onCheckMaterialOffer({
        materialId: materialId,
        buyerId: buyerId,
      }),
    enabled: !!materialId && !!material,
  });

const onGetMaterialOffers = async (materialId: string, page = 0) => {
  const { data } = await axios.get(
    `/materialOffer/material/${materialId}/${page}`
  );
  return data;
};

export const useMaterialOffers = (
  materialId: string,
  userId?: string,
  page?: number
) =>
  useQuery({
    queryKey: [queryKeys.MATERIAL_OFFERS, materialId, userId],
    queryFn: () => onGetMaterialOffers(materialId, page),
    enabled: !!materialId,
  });
