import { format, parseISO } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { ORDER_TYPES } from "../types/enums";
import { BIDDING_TYPES, BIDDING_TYPES_LABELS } from "./enums";

export const currencyFormatter = (number?: number, coin?: string) => {
  if (!number) return;
  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: coin ? coin : "ARS",
  }).format(number);

  return currency;
};

export const cutIdOnFirstHyphen = (id?: string) => {
  if (!id) return "";
  return id.split("-")[0];
};

export const taxIdFormatter = (taxId: string) => {
  if (!taxId) return;
  const trimmedText = taxId.trim();
  const cleanedText = trimmedText.replace(/[^a-zA-Z0-9]/g, "");
  return cleanedText;
};

export const addressFormatter = (address: string) => {
  if (!address) return address;
  const result = address?.split(",").slice(-2).join(",");
  return result;
};

export const numberFormatter = new Intl.NumberFormat("es-AR", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  roundingIncrement: 5,
});

export const getNumberOfDays = (end: string) => {
  const date1 = new Date();
  const date2 = new Date(end);
  date2.setDate(date2.getDate() + 1);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
};

export const updateObject = (oldObject: any, updatedProperties: any) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const calculateMinimumDecrement = (
  price?: number,
  percentage?: number
) => {
  if (!price || !percentage) return;
  const res = (price * percentage) / 100;
  return (price - res).toFixed(2);
};

export const capitalize = (sentence: string, first?: boolean) => {
  if (!sentence) return;
  const words = sentence.split(" ");
  if (first) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(" ");
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const getMonth = (date: string) => {
  const period = new Intl.DateTimeFormat("es-AR", {
    month: "long",
  }).format(new Date(date));
  return capitalize(period);
};

export const labelType = (type: ORDER_TYPES) => {
  if (type === BIDDING_TYPES.INVERSE) return BIDDING_TYPES_LABELS.INVERSE;
  if (type === BIDDING_TYPES.NORMAL) return BIDDING_TYPES_LABELS.NORMAL;
  if (type === BIDDING_TYPES.JOINT) return BIDDING_TYPES_LABELS.JOINT;
  if (type === BIDDING_TYPES.INVERSE_JOINT)
    return BIDDING_TYPES_LABELS.INVERSE_JOINT;
};

export const formatDate = (date: string) => {
  const language = localStorage.getItem("i18nextLng");
  const isEnglish =
    language === "en" ||
    language === "en-US" ||
    language === "en-GB" ||
    language === "en-AU" ||
    language === "en-CA" ||
    language === "en-IN" ||
    language === "en-NZ" ||
    language === "en-ZA";
  const langEs = !isEnglish;

  try {
    const parsedDate = parseISO(date);
    const formattedDate = format(parsedDate, "dd/MM/yyyy", {
      locale: langEs ? es : enUS,
    });
    return formattedDate.replace(
      /(\s[a-z])/g,
      (match) => match.toUpperCase() // Capitalizes the month
    );
  } catch (err) {
    console.error(err);
    return "";
  }
};
