import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import {
  mapStateToProps,
  mapDispatchToProps,
  TO_BE_AGREED,
  BIDDING_TYPES_LABELS,
  BIDDING_TYPES,
  NO_SPEC,
  IT_DOESNT_HAVE_SPEC,
  FileType,
  PN_TYPE,
  formatDate,
} from "../../utils";
import {
  ExclamationTriangleIcon,
  ArrowDownTrayIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import {
  Award,
  GoBackArrow,
  Loader,
  Modal,
  Offer,
  Slide,
  TenderSucceeded,
  InfoItem,
  Chat,
} from "../../components";
import { useID } from "../../hooks";
import OffersOnOrders from "./OffersOnOrders";
import SuppliersSelected from "./SuppliersSelected";
import BidHeader from "./BidHeader";
import {
  OfferResponse,
  OrderResponse,
  OrderState,
  UserResponse,
} from "../../types";
import {
  onGetOrderComparison,
  useDeleteOrder,
  useOrder,
  useSelectedOrder,
} from "../../hooks/useOrders";
import {
  useCreateChat,
  useCreateMessage,
  useOrderChat,
} from "../../hooks/useChat";
import { useAcceptOffer, useOffersOnOrders } from "../../hooks/useOffers";
import { useInvitedOrderSuppliers } from "../../hooks/useSuppliers";
import { queryKeys } from "../../hooks/queryKeys";

type IsBuyerBidProps = {
  user: UserResponse;
  isCompliant: boolean;
  onOpenNotification: (message: string) => void;
};

const IsBuyerBid = ({
  user,
  isCompliant,
  onOpenNotification,
}: IsBuyerBidProps) => {
  const { t } = useTranslation();
  const id = useID();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [openSlide, setOpenSlide] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");
  const [itemToSlide, setItemToSlide] = useState<OfferResponse | null>(null);
  const [isAward, setIsAward] = useState(false);

  const queryClient = useQueryClient();

  const { data: order, isLoading: isLoadingOrders } = useOrder(id);

  const { data: offersOnOrders, isLoading: isLoadingOffers } =
    useOffersOnOrders(id);

  const isTendersSucceded = offersOnOrders?.content?.find(
    (el: OfferResponse) => el.selected
  );

  const { data: tender } = useSelectedOrder(id);

  const { data: chat, isLoading: isLoadingChat } = useOrderChat(id);

  const { mutate: mutateChat, isPending: isCreatingChat } = useCreateChat([
    queryKeys.CHAT,
  ]);

  const { mutate: mutateMessage, isPending: isSendingMessage } =
    useCreateMessage([queryKeys.CHAT]);

  const { data: invitedOrderSuppliers } = useInvitedOrderSuppliers(id);

  const { mutate: acceptOffer, isPending: isAcceptingOffer } = useAcceptOffer(
    () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.OFFERS_ON_ORDERS, id],
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.TENDER, id] });
      return onOpenNotification(t("tenders.data.acceptOffer"));
    },
    () => onOpenNotification(t("newMaterialForm.error"))
  );

  const { mutate: deleteOrder, isPending: isDeleting } = useDeleteOrder(
    () => {
      onOpenNotification(t("tenders.data.cancelMessage"));
      return navigate("/orders");
    },
    () => onOpenNotification(t("newMaterialForm.error"))
  );

  const isSpec = NO_SPEC === order?.material?.fileURL;
  const isOwner = user?.id === order?.material?.owner?.id;

  const handleCancel = () => {
    if (!cancelMessage) return;
    if (!order) return;
    deleteOrder({
      orderId: order?.id,
      order: {
        ...order,
        comment: cancelMessage,
        state: OrderState.CANCELLED,
      },
    });
    setOpen(false);
  };

  const handleItemClick = (e: any, item: OfferResponse) => {
    if (order?.state === OrderState.OPEN) return;
    setItemToSlide(item);
    setOpenSlide(true);
  };

  const onChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCancelMessage(e.target.value);

  return (
    <Fragment>
      <Modal
        open={open}
        setOpen={setOpen}
        hasMessage
        disabled={!cancelMessage}
        onChangeMessage={onChangeMessage}
        icon={
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        }
        title={t("tenders.data.cancel")}
        message={t("tenders.data.cancelQuestion")}
        isCancel
        onClickSuccess={handleCancel}
        onClickCancel={() => setOpen(false)}
      />
      {isLoadingOrders || isLoadingOffers || isDeleting || isAcceptingOffer ? (
        <Loader isFullScreen />
      ) : (
        <div className="min-h-full flex">
          <div className="flex flex-col w-0 flex-1">
            <div className="py-8 xl:py-10 relative">
              <div className="w-full px-8 flex justify-between items-center gap-4">
                <GoBackArrow />
                {!isAward ? (
                  <div className="flex gap-4 items-center w-full justify-end">
                    {order?.state !== OrderState.CANCELLED &&
                      order?.state !== OrderState.SUCCEED && (
                        <button
                          className="uppercase px-4 py-2 shadow-sm text-xs font-medium rounded text-red-500 border border-red-500 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                          onClick={() => setOpen(true)}
                        >
                          <span>{t("tenders.data.cancel")}</span>
                        </button>
                      )}
                    <button
                      onClick={() => setOpenChat(true)}
                      className="uppercase flex items-center gap-2 px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                      <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                      {t("chat.title")}
                      {chat?.messages?.length > 0 && (
                        <span className="text-white border border-blue-600 bg-blue-600 px-1 rounded-full">
                          {chat.messages.length}
                        </span>
                      )}
                    </button>
                    {!isTendersSucceded &&
                    order?.state === OrderState.CLOSED &&
                    isOwner &&
                    offersOnOrders &&
                    offersOnOrders?.content?.length > 0 ? (
                      <>
                        <button
                          onClick={() => onGetOrderComparison(id)}
                          className="uppercase flex items-center px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                          <ArrowDownTrayIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          {t("cta.downloadComparison")}
                        </button>
                        <button
                          onClick={() => setIsAward(true)}
                          className="uppercase px-4 py-2 shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                        >
                          {t("tenders.data.accept")}
                        </button>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <button
                    type="button"
                    className="uppercase inline-flex justify-center px-4 py-2 shadow-sm text-xs font-medium rounded text-black bg-white border border-black"
                    onClick={() => setIsAward(false)}
                  >
                    {t("cta.cancel")}
                  </button>
                )}
              </div>
              <div className="mx-auto px-4 lg:px-8 xl:grid xl:grid-cols-2 gap-4">
                <div className={`col-span-2 ${isAward ? "" : "xl:col-span-1"}`}>
                  <BidHeader
                    order={order}
                    orderName={order?.name}
                    ean={order?.material?.ean}
                    selected={isTendersSucceded?.selected}
                    setOpen={setOpen}
                  />
                  {!isAward ? (
                    <>
                      {order?.internalCode && (
                        <InfoItem
                          name={t("tenders.form.internalCode")}
                          description={order?.internalCode}
                        />
                      )}
                      <InfoItem
                        name={t("tenders.form.material")}
                        description={order?.material?.globalMaterialName}
                      />
                      <InfoItem
                        name={t("tenders.form.tenderType")}
                        description={
                          order?.type === BIDDING_TYPES.INVERSE
                            ? BIDDING_TYPES_LABELS.INVERSE
                            : order?.type
                        }
                      />
                      {order && (
                        <InfoItem
                          name={t("tenders.form.end")}
                          description={formatDate(order?.finishDate)}
                        />
                      )}
                      {order?.material?.type === PN_TYPE.SERVICE && (
                        <>
                          <InfoItem
                            name={t("tenders.form.purpose")}
                            description={order?.purpose}
                          />
                          <InfoItem
                            name={t("newMaterialForm.brand")}
                            description={order?.application}
                          />
                        </>
                      )}
                      {order?.type === BIDDING_TYPES.INVERSE && (
                        <Fragment>
                          <InfoItem
                            name={t("tenders.form.maximumInitialPrice")}
                            description={`${order?.initialPrice} ${order?.currency}`}
                          />
                          <InfoItem
                            name={t("tenders.form.minimumDecrement")}
                            description={`${order?.minimumDecrement} %`}
                          />
                        </Fragment>
                      )}
                      <InfoItem
                        name={
                          order?.material?.type === PN_TYPE.SERVICE
                            ? t("newMaterialForm.measurementUnit")
                            : t("tenders.form.volume")
                        }
                        description={`${order?.quantity ?? ""} ${
                          order?.measurementUnit
                        }`}
                      />
                      {order?.material?.incoTerm && (
                        <InfoItem
                          name={t("tenders.form.incoTerm")}
                          description={order?.material?.incoTerm}
                        />
                      )}
                      {order?.material?.deliveryFormat && (
                        <InfoItem
                          name={t("tenders.form.deliveryFormat")}
                          description={order?.material?.deliveryFormat}
                        />
                      )}
                      {order?.material?.type !== PN_TYPE.SERVICE && (
                        <InfoItem
                          name={t("tenders.form.deliveryPlace")}
                          description={
                            order?.deliveryPlace?.name ?? TO_BE_AGREED
                          }
                        />
                      )}
                      {order && (
                        <>
                          {order?.startSupplyDate === order?.endSupplyDate ? (
                            <InfoItem
                              name={t("tenders.form.deliveryDate")}
                              description={formatDate(order.startSupplyDate)}
                            />
                          ) : (
                            <InfoItem
                              name={t("tenders.form.tenderPeriod")}
                              description={`${formatDate(
                                order.startSupplyDate
                              )} ${t("tenders.to")} ${formatDate(
                                order.endSupplyDate
                              )}`}
                            />
                          )}
                        </>
                      )}
                      <InfoItem
                        name={t("tenders.form.paymentMethod")}
                        description={order?.paymentMethod}
                      />
                      <InfoItem
                        name={t("tenders.form.currency")}
                        description={order?.currency}
                      />
                      <InfoItem
                        name={t("tenders.form.paymentTerm")}
                        description={`${order?.paymentTerm} ${
                          order?.paymentTerm === 1
                            ? ` ${t("opportunities.table.day")}`
                            : ` ${t("opportunities.table.days")}`
                        }`}
                      />
                      {order?.comment && (
                        <InfoItem
                          name={t("tenders.form.comments")}
                          description={order?.comment}
                        />
                      )}
                      <div className="bg-white overflow-hidden">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
                            <dt className="uppercase text-xs font-medium text-gray-500">
                              {t("newMaterialForm.specification")}
                            </dt>
                            <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                              {isSpec ? (
                                <p>{IT_DOESNT_HAVE_SPEC}</p>
                              ) : (
                                <div className="prose max-w-none">
                                  <a
                                    href={order?.material?.fileURL}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                                  >
                                    <ArrowDownTrayIcon
                                      className="-ml-0.5 mr-2 h-4 w-4"
                                      aria-hidden="true"
                                    />
                                    {t("cta.download")}
                                  </a>
                                </div>
                              )}
                            </dd>
                          </div>
                        </dl>
                      </div>

                      {order && order?.files?.length > 0 && (
                        <div className="bg-white overflow-hidden">
                          {order?.files?.map((file) => (
                            <dl
                              key={file.fileURL}
                              className="sm:divide-y sm:divide-gray-200"
                            >
                              <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
                                <dt className="uppercase text-xs font-medium text-gray-500">
                                  {file.type === FileType.ATTACHMENT &&
                                    t("tenders.form.additionalDocument")}
                                  {file.type === FileType.SHEET &&
                                    t("tenders.form.plication")}
                                  {file.type === FileType.CERTIFICATION &&
                                    t("tenders.form.certificate")}
                                  {file.type === FileType.BRIEF &&
                                    t("tenders.form.brief")}
                                  {file.type === FileType.SERVICE_PLACE &&
                                    t("tenders.form.servicePlace")}
                                </dt>
                                <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                                  <div className="prose max-w-none">
                                    <a
                                      href={file.fileURL}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="w-200 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                                    >
                                      <ArrowDownTrayIcon
                                        className="-ml-0.5 mr-2 h-4 w-4"
                                        aria-hidden="true"
                                      />
                                      {file.type === FileType.ATTACHMENT &&
                                        t("tenders.form.additionalDocument")}
                                      {file.type === FileType.SHEET &&
                                        t("tenders.form.plication")}
                                      {file.type === FileType.CERTIFICATION &&
                                        t("tenders.form.certificate")}
                                      {file.type === FileType.BRIEF &&
                                        t("tenders.form.brief")}
                                      {file.type === FileType.SERVICE_PLACE &&
                                        t("tenders.form.servicePlace")}
                                    </a>
                                  </div>
                                </dd>
                              </div>
                            </dl>
                          ))}
                        </div>
                      )}

                      {order?.plicationUrl &&
                        order?.plicationUrl !== NO_SPEC && (
                          <InfoItem
                            name={t("newMaterialForm.plication")}
                            description={
                              <a
                                href={order?.plicationUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="inline-flex items-center px-2 py-1 border border-transparent shadow-xs text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                              >
                                <ArrowDownTrayIcon
                                  className="-ml-0.5 mr-2 h-4 w-4"
                                  aria-hidden="true"
                                />
                                {t("cta.plication")}
                              </a>
                            }
                          />
                        )}

                      <SuppliersSelected
                        suppliers={order?.suppliers}
                        invitedSuppliers={invitedOrderSuppliers}
                      />
                    </>
                  ) : (
                    <>
                      {order && offersOnOrders?.content && (
                        <Award
                          order={order}
                          offers={offersOnOrders?.content}
                          onAcceptOffer={acceptOffer}
                          onCancel={() => setIsAward(false)}
                          setIsAward={() => setIsAward(false)}
                        />
                      )}
                    </>
                  )}
                </div>
                {!isAward ? (
                  <div className="col-span-2 md:col-span-1">
                    <Fragment>
                      <h2
                        id="activity-title"
                        className="text-lg font-medium text-gray-900 border-b pb-6 pt-8"
                      >
                        {t("tenders.data.receivedOffers")}
                      </h2>
                      {order?.state === OrderState.CLOSED &&
                        offersOnOrders?.content?.length === 0 && (
                          <p className="uppercase text-sm font-bold text-red-800 rounded py-2 mb-4">
                            {t("tenders.data.noOffersMessage")}
                          </p>
                        )}

                      <OffersOnOrders
                        offOnOrd={offersOnOrders?.content}
                        handleItemClick={handleItemClick}
                        state={order?.state as OrderState}
                        isCompliant={isCompliant}
                      />

                      {tender && isTendersSucceded && (
                        <>
                          {tender.map((t: OrderResponse) => (
                            <TenderSucceeded key={t.id} tender={t} />
                          ))}
                        </>
                      )}
                    </Fragment>

                    {order?.state === OrderState.CANCELLED && (
                      <p className="text-sm font-bold text-red-800 uppercase py-2">
                        {t("tenders.data.cancelledMessage")} {order?.comment}
                      </p>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      <Slide open={openSlide} setOpen={setOpenSlide}>
        {itemToSlide && <Offer offer={itemToSlide} />}
      </Slide>
      <Slide open={openChat} setOpen={setOpenChat}>
        {order && (
          <Chat
            isOrder
            chat={chat}
            order={order}
            onCreateChat={mutateChat}
            isLoadingChat={isLoadingChat || isSendingMessage || isCreatingChat}
            onCreateMessage={mutateMessage}
          />
        )}
      </Slide>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IsBuyerBid);
