import { useState, useEffect } from "react";
import i18next from "../i18n";

export default function useErrors(error: any) {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (error?.status === 401) setErrorMessage(i18next.t("global.password"));

    if (error?.status === 500) setErrorMessage(i18next.t("global.connection"));
  }, [error]);

  return { errorMessage };
}
