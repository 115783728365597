import { useRef, useState, useMemo, useReducer } from "react";
import { connect } from "react-redux";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import i18next from "../../i18n";
import { Button, CompanyAwardItem, Modal } from "..";
import { currencyFormatter, mapStateToProps } from "../../utils";
import { OffersToAccept, OrderResponse } from "../../types";

const actions = {
  CLEAR_OFFERS: "CLEAR_OFFERS",
  UPDATE_VOLUME: "UPDATE_VOLUME",
  UPDATE_PRICE: "UPDATE_PRICE",
  DELETE_OFFER: "DELETE_OFFER",
  UPDATE_ON_CHECKBOX_CHANGE: "UPDATE_ON_CHECKBOX_CHANGE",
  SELECT_BEST_OFFERS: "SELECT_BEST_OFFERS",
  SELECT_ONE_SUPPLIER: "SELECT_ONE_SUPPLIER",
};

type AwardJointProps = {
  offers: [OffersToAccept[]];
  onAcceptOffer: (arg0: any) => void;
  onCancel: () => void;
  setIsAward: (arg0: boolean) => void;
  order: OrderResponse[];
};

type EventState = {
  offersToAccept: OffersToAccept[];
  errors: any[];
  invalid: any[];
};

const getRequestedVolumes = (orders: OrderResponse[]) => {
  const requestedVolumes: any = {};
  orders.forEach((order) => {
    requestedVolumes[order?.id] = order?.quantity;
  });
  return requestedVolumes;
};

const getOldQuantity = (orders: OrderResponse[]) => {
  const requestedVolumes: any = {};
  orders.forEach((order) => {
    requestedVolumes[order?.id] = order?.quantity;
  });
  return requestedVolumes;
};

const getBestOffersTotal = (formattedOffers: any) => {
  return formattedOffers.reduce(
    (result: { total: number; coin: string | null }, offerGroup: any) => {
      const bestOffers = offerGroup.filter((offer: any) => offer.isBestOffer);

      bestOffers.forEach((bestOffer: any) => {
        result.total +=
          bestOffer?.offer?.price * bestOffer?.offer?.order?.quantity;

        // Set the coin if it hasn't been set yet
        if (!result.coin) {
          result.coin = bestOffer?.offer?.order?.currency || null;
        }
      });

      return result;
    },
    { total: 0, coin: null } // Initial values
  );
};

const getPriceByVolume = (offersToAccept: OffersToAccept[]) => {
  let totalPrice = 0;
  let totalVolume = 0;

  offersToAccept.forEach((offer) => {
    if (offer.volume && offer.price) {
      totalPrice += offer.price * offer.volume;
      totalVolume += offer.volume;
    }
  });

  const priceByVolume = totalVolume > 0 ? totalPrice / totalVolume : 0;
  return { totalPrice, totalVolume, priceByVolume };
};

const AwardJoint = ({
  offers,
  onAcceptOffer,
  onCancel,
  setIsAward,
  order,
}: AwardJointProps) => {
  const [isBestSelected, setIsBestSelected] = useState(false);
  const cancelButtonRef = useRef(null);
  const [openAccept, setOpenAccept] = useState(false);

  const sortedOrder = order?.sort((a, b) =>
    a?.material?.globalMaterialName?.localeCompare(
      b?.material?.globalMaterialName
    )
  );

  const formattedOffers = useMemo(() => {
    const orderMinPriceMap: any = {};
    const orderAccumulateMap: any = {};
    const arrayAccumulatePrices: any = [];

    Object.values(offers).forEach((offerGroup: OffersToAccept[]) => {
      let groupTotal = 0;

      offerGroup.forEach((offer: any) => {
        const totalPrice = offer?.offer?.price * offer?.offer?.order?.quantity;
        const orderId = offer?.offer?.order?.id;

        if (
          !orderMinPriceMap[orderId] ||
          totalPrice < orderMinPriceMap[orderId]
        ) {
          orderMinPriceMap[orderId] = totalPrice;
        }

        groupTotal += totalPrice;

        if (!orderAccumulateMap[orderId]) {
          orderAccumulateMap[orderId] = 0;
        }
        orderAccumulateMap[orderId] += totalPrice;
      });

      arrayAccumulatePrices.push(groupTotal);
    });

    const minAccumulatedPrice = Math.min(...arrayAccumulatePrices);

    return Object.values(offers).map((offerGroup, index: number) =>
      offerGroup.map((offer) => {
        const totalPrice = offer?.offer?.price * offer?.offer?.order?.quantity;
        const orderId = offer?.offer?.order?.id;
        return {
          ...offer,
          isBestOffer: totalPrice === orderMinPriceMap[orderId],
          isBestAccumulate:
            arrayAccumulatePrices[index] === minAccumulatedPrice,
        };
      })
    );
  }, [offers]);

  const bestOffers = getBestOffersTotal(formattedOffers);

  const [event, updateEvent] = useReducer(
    (state: EventState, action: any) => {
      let newState = { ...state };
      switch (action.type) {
        case actions.UPDATE_ON_CHECKBOX_CHANGE:
          {
            const offerIndex = newState.offersToAccept.findIndex(
              (offer: OffersToAccept) => offer?.offer.id === action.id
            );
            if (offerIndex >= 0) {
              newState.offersToAccept[offerIndex] = {
                ...newState.offersToAccept[offerIndex],
                volume: action.volume,
                price: action.price,
              };
            } else {
              newState.offersToAccept.push({
                id: action.id,
                volume: action.volume,
                price: action.price,
                orderId: action.orderId,
                material: action.material,
                offer: action.offer,
              });
            }
          }
          break;
        case actions.UPDATE_VOLUME: {
          const offerIndex = newState.offersToAccept.findIndex(
            (offer) => offer?.offer.id === action.id
          );
          if (offerIndex >= 0) {
            newState.offersToAccept[offerIndex] = {
              ...newState.offersToAccept[offerIndex],
              volume: action.volume,
            };
          } else {
            newState.offersToAccept.push({
              id: action.id,
              volume: action.volume,
              orderId: action.orderId,
            } as OffersToAccept);
          }
          break;
        }
        case actions.UPDATE_PRICE: {
          const offerIndex = newState.offersToAccept.findIndex(
            (offer) => offer?.offer.id === action.id
          );
          if (offerIndex >= 0) {
            newState.offersToAccept[offerIndex] = {
              ...newState.offersToAccept[offerIndex],
              price: action.price,
            };
          } else {
            newState.offersToAccept.push({
              id: action.id,
              price: action.price,
              orderId: action.orderId,
            } as OffersToAccept);
          }
          break;
        }
        case actions.SELECT_BEST_OFFERS:
          {
            const bestOffers: any = [];
            newState.offersToAccept = [];
            formattedOffers.forEach((offerGroup) => {
              offerGroup.forEach((offer) => {
                if (offer.isBestOffer) {
                  bestOffers.push({
                    id: offer.offer.id,
                    volume: offer.offer.order.quantity,
                    price: offer.offer.price,
                    orderId: offer.offer.order.id,
                    material: offer.material,
                    offer: offer.offer,
                  });
                }
              });
            });
            newState.offersToAccept = bestOffers;
          }
          break;
        case actions.SELECT_ONE_SUPPLIER:
          {
            newState.offersToAccept = [];
            newState.offersToAccept = action.payload;
          }
          break;
        case actions.DELETE_OFFER:
          {
            newState = {
              ...newState,
              offersToAccept: state.offersToAccept.filter(
                (offer) => offer?.offer.id !== action.id
              ),
            };
          }
          break;
        case actions.CLEAR_OFFERS:
          {
            newState = {
              ...newState,
              offersToAccept: [],
            };
          }
          break;
      }

      const requestedVolumes = getRequestedVolumes(order);

      const volumeSums: any = {};
      newState.offersToAccept.forEach((offer) => {
        const isInvalidVolume =
          offer?.offer.volume <= 0 || offer?.offer.volume === null;
        const isInvalidPrice =
          offer?.offer.price <= 0 || offer?.offer.price === null;
        const errorIndex = newState.invalid.findIndex(
          (error) => error.id === offer?.offer.id
        );

        if (isInvalidVolume || isInvalidPrice) {
          const errorMessage = i18next.t("tenders.errors.invalid");

          if (errorIndex === -1) {
            newState.invalid.push({ id: offer.id, message: errorMessage });
          } else {
            newState.invalid[errorIndex] = {
              id: offer.id,
              message: errorMessage,
            };
          }
        } else if (errorIndex !== -1) {
          // If previously had an error and now corrected, remove the error
          newState.invalid.splice(errorIndex, 1);
        }

        volumeSums[offer.orderId] =
          (volumeSums[offer.orderId] || 0) +
          parseFloat(String(offer.volume || 0));
      });

      Object.keys(volumeSums).forEach((orderId) => {
        const requestedVolume = requestedVolumes[orderId];
        const errorIndex = newState.errors.findIndex(
          (error) => error.orderId === orderId
        );

        const material = newState.offersToAccept.find(
          (o) => o.orderId === orderId
        )?.material;

        if (volumeSums[orderId] > requestedVolume) {
          const errorMessage = `${i18next.t("tenders.errors.message")} ${
            volumeSums[orderId]
          } > ${requestedVolume}`;

          if (errorIndex === -1) {
            newState.errors.push({ orderId, message: errorMessage, material });
          } else {
            newState.errors[errorIndex] = {
              orderId,
              material,
              message: errorMessage,
            };
          }
        } else if (errorIndex !== -1) {
          newState.errors.splice(errorIndex, 1);
        }
      });

      return newState;
    },
    { offersToAccept: [], errors: [], invalid: [] }
  );

  const handleOfferConfirmation = () => setOpenAccept(true);

  const onAwardTender = () => {
    const requestedQuantity = getOldQuantity(order);
    const formattedOffers = event.offersToAccept.map((offer) => ({
      offer: {
        ...offer.offer,
        price: parseFloat(String(offer.price)),
        quantity: parseFloat(String(offer.volume)),
        oldQuantity: requestedQuantity[offer.orderId],
        oldPrice: offer.offer.price,
        selected: true,
      },
      replace: false,
    }));
    onAcceptOffer(formattedOffers);
    setOpenAccept(false);
    setIsAward(false);
  };

  const bestOffersSelected = event.offersToAccept.map((o) => o.offer.id);

  const totalSelection = getPriceByVolume(event.offersToAccept);

  return (
    <>
      <Modal
        open={openAccept}
        setOpen={setOpenAccept}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("tenders.data.accept")}
        message={i18next.t("tenders.data.acceptQuestion")}
        onClickSuccess={onAwardTender}
        onClickCancel={() => setOpenAccept(false)}
      />
      <div className="bg-white mt-4">
        <h2 className="uppercase text-2xl font-bold pb-2">
          {i18next.t("award.awardTo")}
        </h2>
        <div className="w-full flex gap-2 my-2">
          <div className="border rounded shadow p-4">
            <p className="uppercase text-xs font-bold text-spectum-dark pb-2">
              <label className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  checked={isBestSelected}
                  onChange={() => {
                    setIsBestSelected(!isBestSelected);
                    isBestSelected
                      ? updateEvent({ type: actions.CLEAR_OFFERS })
                      : updateEvent({ type: actions.SELECT_BEST_OFFERS });
                  }}
                  className="rounded shadow text-xs"
                />
                {i18next.t("award.selectBest")}
              </label>
            </p>
            <p className="text-xs font-bold">
              {currencyFormatter(bestOffers.total, bestOffers.coin)}
            </p>
          </div>
          <div className="border rounded shadow p-4">
            <p className="uppercase text-xs font-bold text-spectum-dark mb-2">
              {i18next.t("award.selectionPrice")}
            </p>
            <p className="text-xs font-bold">
              {currencyFormatter(totalSelection.totalPrice, bestOffers.coin) ??
                0}
            </p>
          </div>
        </div>
        <div className="flex">
          <div
            className="rounded border-t border-l"
            style={{ height: "fit-content" }}
          >
            <div className="text-center flex w-full p-4 border-b border-white"></div>
            <div className="text-center flex w-full p-4 border-b"></div>
            {sortedOrder?.map((ord) => (
              <div key={ord.id} className="text-left flex w-full border-b">
                <p className="text-xs w-200 pl-1 py-4 uppercase font-bold text-spectum max-w-9 truncate">
                  {ord?.material?.ean} - {ord?.material?.globalMaterialName}
                </p>
              </div>
            ))}
          </div>
          <div
            className="border-t border-l border-r"
            style={{ height: "fit-content" }}
          >
            <div className="text-center flex w-full p-4 border-white"></div>
            <div className="text-center flex w-full">
              <p className="text-xs w-full border-t border-b p-2 uppercase font-bold text-gray-600">
                {i18next.t("tenders.form.requested")}
              </p>
            </div>
            {sortedOrder?.map((ord) => (
              <div key={ord.id} className="flex w-full border-b text-center">
                <p className="text-xs w-full pl-1 py-4 uppercase font-bold max-w-9 truncate">
                  {ord?.quantity} {ord?.measurementUnit}
                </p>
              </div>
            ))}
          </div>
          <div className="flex overflow-x-scroll">
            {formattedOffers?.map((offers) => (
              <CompanyAwardItem
                key={offers[0]?.id}
                offers={offers}
                actions={actions}
                updateEvent={updateEvent}
                event={event}
                hasSelection={bestOffersSelected}
                setIsBestSelected={setIsBestSelected}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full pt-4">
        {event.errors.map((error) => (
          <div
            key={error.orderId}
            className="rounded bg-yellow-50 p-3 text-xs mb-4 border-yellow-600 border"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="font-medium text-yellow-800">
                  {i18next.t("award.warning")}
                </h3>
                <div className="mt-2 text-xs text-yellow-700">
                  <ul className="list-disc space-y-1 pl-5">
                    <li key={error.orderId}>{error.message}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
        {event.invalid.map((error) => (
          <div
            key={error.orderId}
            className="rounded bg-yellow-50 p-3 text-xs mb-4 border-yellow-600 border"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="font-medium text-yellow-800">
                  {i18next.t("tenders.errors.title")}
                </h3>
                <div className="mt-2 text-xs text-yellow-700">
                  <ul className="list-disc space-y-1 pl-5">
                    <li key={error.orderId}>{error.message}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-4 mt-4">
        <Button
          variant={
            event.offersToAccept.length > 0 && event.invalid.length === 0
              ? "success"
              : "disabled"
          }
          onClick={handleOfferConfirmation}
          disabled={
            event.offersToAccept.length === 0 || event.invalid.length > 0
          }
        >
          {i18next.t("tenders.data.accept")}
        </Button>
        <Button
          variant="cancel"
          onClick={() => {
            updateEvent({ type: actions.CLEAR_OFFERS, offersToAccept: [] });
            onCancel();
          }}
          ref={cancelButtonRef}
        >
          {i18next.t("cta.cancel")}
        </Button>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(AwardJoint);
