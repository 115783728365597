import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils";

const initialState = {
  items: [],
};

const updateSupplierPriceVolume = (state: any, action: any) => {
  const newState = [...state.items];
  const index = newState.findIndex((item) => {
    return item.id === action.item.id;
  });
  if (index === -1) {
    newState.push(action.item);
  } else {
    newState[index] = action.item;
  }
  return updateObject(state, {
    items: newState,
  });
};

const onCleanSupplierPriceVolume = (state: any) => {
  return updateObject(state, {
    items: [],
  });
};

const volumePriceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_SUPPLIER_PRICE_VOLUME:
      return updateSupplierPriceVolume(state, action);
    case actionTypes.CLEAN_SUPPLIER_PRICE_VOLUME:
      return onCleanSupplierPriceVolume(state);
    default:
      return state;
  }
};

export default volumePriceReducer;
