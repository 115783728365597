import { useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { queryKeys } from "./queryKeys";

const onGetPosition = async (orderId: string) => {
  const { data } = await axios.get(`/offer/order/position/${orderId}`);
  return data;
};

export const usePosition = (orderId: string) =>
  useQuery({
    queryKey: [queryKeys.POSITION, orderId],
    queryFn: () => onGetPosition(orderId),
    enabled: !!orderId,
  });
