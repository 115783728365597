import { useTranslation } from "react-i18next";
import { EditSiteForm, GoBackArrow, Loader } from "../../../components";
import { useID } from "../../../hooks";
import { useSite } from "../../../hooks/useSites";

const EditSite = () => {
  const { t } = useTranslation();
  const id = useID();
  const site = useSite(id);

  return (
    <div className="px-4 mt-6">
      <GoBackArrow />
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {t("sites.edit")}
      </h2>

      {site.isLoading ? <Loader /> : <EditSiteForm site={site.data} />}
    </div>
  );
};

export default EditSite;
