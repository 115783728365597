import { useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { MaterialType } from "../types";
import { queryKeys } from "./queryKeys";

const onGetMaterialMarketInfo = async (materialId: string) => {
  const { data } = await axios.get(`/utils/ai/material/${materialId}`);
  return data;
};

export const useMaterialMarketInfo = (id: string, type: MaterialType) =>
  useQuery({
    queryKey: [queryKeys.MATERIAL_MARKET_INFO, id],
    queryFn: () => onGetMaterialMarketInfo(id),
    enabled: type === MaterialType.PI,
  });

const onGetGlobalMarketInfo = async (userId: string) => {
  const { data } = await axios.get(`/utils/ai/global/${userId}`);
  return data;
};

export const useGlobalMarketInfo = (userId: string) =>
  useQuery({
    queryKey: [queryKeys.GLOBAL_MARKET_INFO, userId],
    queryFn: () => onGetGlobalMarketInfo(userId),
    retry: false,
  });
