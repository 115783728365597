import Select from "react-select";
import {
  CombinedBuyerSellerMaterialOfferResponse,
  UserResponse,
} from "../../../types";

const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    borderRadius: "0.375rem",
    fontSize: "14px",
  }),
  option: (styles: any) => {
    return {
      ...styles,
      fontSize: "14px",
    };
  },
  placeholder: (styles: any) => ({ ...styles, fontSize: "14px" }),
};

type PickListProps = {
  title?: string;
  selected: any;
  onChange: any;
  list: any;
  placeholder?: string;
  isSuppliers?: boolean;
};

export default function PickList({
  title,
  selected,
  onChange,
  list,
  placeholder,
  isSuppliers,
}: PickListProps) {
  const options = isSuppliers
    ? list?.map((item: CombinedBuyerSellerMaterialOfferResponse) => ({
        value: item,
        label: item.buyerSeller.seller.company.name,
      }))
    : list?.map((item: UserResponse) => ({
        value: item,
        label: item.fullName,
      }));
  return (
    <>
      {title && (
        <p className="block text-sm font-medium leading-6 text-gray-900">
          {title}
        </p>
      )}
      <Select
        options={options}
        onChange={onChange}
        value={selected}
        placeholder={placeholder}
        styles={colourStyles}
      />
    </>
  );
}
