export default function useLanguage() {
  const language = localStorage.getItem("i18nextLng");
  const isEnglish =
    language === "en" ||
    language === "en-US" ||
    language === "en-GB" ||
    language === "en-AU" ||
    language === "en-CA" ||
    language === "en-IN" ||
    language === "en-NZ" ||
    language === "en-ZA";
  const langEs = !isEnglish;
  return langEs;
}
