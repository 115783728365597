import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils";

const initialState = {
  isOpen: false,
  message: null,
};

const openBanner = (state: any, action: any) => {
  return updateObject(state, {
    isOpen: true,
    message: action.message,
  });
};

const closeBanner = (state: any) => {
  return updateObject(state, {
    isOpen: false,
    message: null,
  });
};

const bannerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.OPEN_BANNER:
      return openBanner(state, action);
    case actionTypes.CLOSE_BANNER:
      return closeBanner(state);
    default:
      return state;
  }
};

export default bannerReducer;
