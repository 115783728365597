import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { queryKeys } from "./queryKeys";

const onGetMaterialOpportunitiesByFamily = async ({
  page = 0,
  countryIds,
  categoryIds,
}: {
  page: number;
  countryIds: string[];
  categoryIds: string[];
}) => {
  const { data } = await axios.post(
    `/user/material/opportunities/family/${page}?countryIds=${countryIds.join(
      ","
    )}`,
    categoryIds
  );
  return data;
};

export const useMaterialOpportunitiesByFamily = (
  onSuccess: (res: any) => void
) =>
  useMutation({
    mutationFn: onGetMaterialOpportunitiesByFamily,
    onSuccess,
  });

const onGetNewMaterialCompanyOpportunitiesByCountry = async (
  materialId: string,
  countryId: string,
  page = 0
) => {
  const { data } = await axios.get(
    `/user/material/id/opportunities/${materialId}/country/${countryId}/${page}`
  );
  return data;
};

export const useNewMaterialCompanyOpportunitiesByCountry = (
  materialId: string,
  selectedCountry: string,
  page: number
) =>
  useQuery({
    queryKey: [
      queryKeys.MATERIAL_COMPANIES_OPPORTUNITIES_BY_COUNTRY,
      materialId,
      selectedCountry,
      page,
    ],
    queryFn: () =>
      onGetNewMaterialCompanyOpportunitiesByCountry(
        materialId,
        selectedCountry,
        page
      ),
    enabled: !!materialId && !!selectedCountry,
  });

const onGetUserMaterialOpportunitiesInvitations = async (page = 0) => {
  const { data } = await axios.get(`/materialInvitation/user/${page}`);
  return data;
};

export const useUserMaterialOpportunitiesInvitations = (page: number) =>
  useQuery({
    queryKey: [queryKeys.MATERIAL_INVITATIONS_OPPORTUNITIES, page],
    queryFn: () => onGetUserMaterialOpportunitiesInvitations(page),
  });

const onGetInvitationsByEmail = async (page = 0) => {
  const { data } = await axios.get(`/materialInvitation/userEmail/${page}`);
  return data;
};

export const useInvitationsByEmail = (page: number) =>
  useQuery({
    queryKey: [queryKeys.INVITATIONS_BY_EMAIL, page],
    queryFn: () => onGetInvitationsByEmail(page),
  });
