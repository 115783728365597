import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import {
  Banner,
  MainArea,
  MobileMenu,
  MobileMenuButton,
  Navbar,
  Notification,
  UserMobileMenu,
} from "../..";
import {
  buyerNavigation,
  mapStateToProps,
  mapDispatchToProps,
  secondaryNavigation,
  sellerNavigation,
  managerNavigation,
  analystNavigation,
  visualizerNavigation,
  adminNavigation,
  pendingApprovalNavigation,
  requesterNavigation,
  classNames,
} from "../../../utils";
import { UserResponse } from "../../../types";

type LayoutProps = {
  user: UserResponse;
  isBuy: boolean;
  isNew: boolean;
  isOpen: boolean;
  isAdmin: boolean;
  message: string;
  isBuyer: boolean;
  isSeller: boolean;
  isManager: boolean;
  isLoading: boolean;
  isAnalyst: boolean;
  isPending: boolean;
  isRequester: boolean;
  isOpenBanner: boolean;
  isVisualizer: boolean;
  bannerMessage: string;
  isResetPassword: boolean;
  onCloseNotification: () => void;
  isPendingAdminApproval: boolean;
};

const Layout = ({
  user,
  isBuy,
  isNew,
  isOpen,
  isAdmin,
  message,
  isBuyer,
  isSeller,
  isManager,
  isLoading,
  isAnalyst,
  isPending,
  isRequester,
  isOpenBanner,
  isVisualizer,
  bannerMessage,
  isResetPassword,
  onCloseNotification,
  isPendingAdminApproval,
}: LayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  let navigationBuild;

  if (isOpen) {
    setTimeout(() => {
      onCloseNotification();
    }, 4000);
  }

  let isTheNavigation;
  if (isBuy) {
    if (isAdmin) {
      isTheNavigation = adminNavigation;
    }
    if (isManager) {
      isTheNavigation = managerNavigation;
    }
    if (isBuyer) {
      isTheNavigation = buyerNavigation;
    }
    if (isAnalyst) {
      isTheNavigation = analystNavigation;
    }
    if (isVisualizer) {
      isTheNavigation = visualizerNavigation;
    }
    if (isRequester) {
      isTheNavigation = requesterNavigation;
    }
    if (
      !isManager &&
      !isBuyer &&
      !isAnalyst &&
      !isVisualizer &&
      !isAdmin &&
      !isRequester
    ) {
      isTheNavigation = analystNavigation;
    }
  } else if (isSeller) {
    if (isPendingAdminApproval) {
      isTheNavigation = pendingApprovalNavigation;
    } else {
      isTheNavigation = sellerNavigation;
    }
  }

  if (!isLoading && user) {
    navigationBuild = (
      <Fragment>
        {isTheNavigation && (
          <MobileMenu
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            secondaryNavigation={secondaryNavigation}
            navigation={isTheNavigation}
          />
        )}

        {isTheNavigation && (
          <Navbar
            navigation={isTheNavigation}
            classNames={classNames}
            secondaryNavigation={secondaryNavigation}
          />
        )}
      </Fragment>
    );
  }

  return (
    <div className="min-h-full">
      {navigationBuild}
      {isOpen ? (
        <Notification
          show={isOpen}
          handleShow={() => onCloseNotification()}
          message={message}
        />
      ) : null}
      <MainArea>
        {(isOpenBanner || isNew || isPending) && isSeller ? (
          <Banner
            isNew={isNew}
            isPending={isPending}
            bannerMessage={bannerMessage}
            isResetPassword={isResetPassword}
          />
        ) : null}
        {isResetPassword && isBuy ? (
          <Banner
            bannerMessage={bannerMessage}
            isResetPassword={isResetPassword}
          />
        ) : null}
        <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200">
          <MobileMenuButton setSidebarOpen={setSidebarOpen} />
          <div className="flex-1 flex justify-between">
            <UserMobileMenu classNames={classNames} />
          </div>
        </div>
        <Outlet />
      </MainArea>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
