import { Bars3Icon } from "@heroicons/react/24/outline";

const MobileMenuButton = ({
  setSidebarOpen,
}: {
  setSidebarOpen: (b: boolean) => void;
}) => {
  return (
    <button
      type="button"
      className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
      onClick={() => setSidebarOpen(true)}
    >
      <span className="sr-only">Open sidebar</span>
      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
};

export default MobileMenuButton;
