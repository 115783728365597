import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditMaterialForm, GoBackArrow, Loader } from "../../../components";
import { useID } from "../../../hooks";
import { mapStateToProps } from "../../../utils";
import { UserResponse } from "../../../types";
import { useSites } from "../../../hooks/useSites";
import { useMaterial } from "../../../hooks/useMaterials";

const EditMaterial = ({ user }: { user: UserResponse }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useID();

  const { data: material, isError } = useMaterial(id);

  const { data: sites } = useSites(user?.company?.id);

  if (isError) navigate("/not-found");

  return (
    <div className="px-4 mt-6">
      <GoBackArrow />
      <h2 className="uppercase text-xl font-bold leading-7 text-gray-900 sm:truncate">
        {t("newMaterialForm.edit")}
      </h2>
      {material ? (
        <EditMaterialForm
          pnType={material.category.parent.parent.parent.parent.name}
          material={material}
          deliveryPlace={material?.deliveryPlace?.id}
          sites={sites}
          confidentSpec={material?.confidentSpec}
        />
      ) : (
        <Loader isFullScreen />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(EditMaterial);
