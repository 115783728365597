import { connect } from "react-redux";
import {
  SellerManagerRoutes,
  BuyerManagerRoutes,
  BuyerRoutes,
  VisualizerRoutes,
  BuyerAdminRoutes,
  BlockedRoutes,
  CommercialRoutes,
  BuyerAnalystRoutes,
  SellerAnalystRoutes,
  SellerAdminRoutes,
  AuthRoutes,
  RequesterRoutes,
} from "..";
import { mapStateToProps } from "../../utils";
import ApprovalRoutes from "../Sellers/ApprovalRoutes/ApprovalRoutes";

type RoutesHandlerProps = {
  isAdmin: boolean;
  isBlocked: boolean;
  isBuyer: boolean;
  isAnalyst: boolean;
  isManager: boolean;
  isSeller: boolean;
  isVisualizer: boolean;
  isRequester: boolean;
  isCommercial: boolean;
  isBuy: boolean;
  isAuthenticated: boolean;
  isPendingAdminApproval: boolean;
};

const RoutesHandler = ({
  isBuy,
  isBlocked,
  isAdmin,
  isBuyer,
  isSeller,
  isManager,
  isAnalyst,
  isVisualizer,
  isRequester,
  isCommercial,
  isAuthenticated,
  isPendingAdminApproval,
}: RoutesHandlerProps) => {
  if (isBlocked && isAuthenticated) {
    return <BlockedRoutes />;
  }

  if (isBuy && isAuthenticated) {
    if (isAdmin) return <BuyerAdminRoutes />;
    if (isBuyer) return <BuyerRoutes />;
    if (isManager) return <BuyerManagerRoutes />;
    if (isAnalyst) return <BuyerAnalystRoutes />;
    if (isVisualizer) return <VisualizerRoutes />;
    if (isRequester) return <RequesterRoutes />;
  }

  if (isSeller && isAuthenticated) {
    if (isPendingAdminApproval) return <ApprovalRoutes />;
    if (isAdmin) return <SellerAdminRoutes />;
    if (isManager) return <SellerManagerRoutes />;
    if (isCommercial) return <CommercialRoutes />;
    if (isAnalyst) return <SellerAnalystRoutes />;
  }

  return <AuthRoutes />;
};

export default connect(mapStateToProps)(RoutesHandler);
