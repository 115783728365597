import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import { ErrorBoundary } from "react-error-boundary";

import "./index.css";
import App from "./App";
import rootReducer from "./store/reducers/rootReducer";
import { Error } from "./containers";
import "./i18n";

// Set up Redux DevTools extension for development mode
const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const queryClient = new QueryClient();

const container = document.getElementById("app");

if (container) {
  const root = createRoot(container as HTMLElement); // Ensure TypeScript knows container is HTMLElement

  const app = (
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary FallbackComponent={Error}>
              <App />
            </ErrorBoundary>
          </QueryClientProvider>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  );

  root.render(app);
} else {
  console.error("No root container found");
}
