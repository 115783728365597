import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { USER_COMPANY_TYPES, USER_TYPES } from "../../../utils";
import { UserResponse } from "../../../types";

type UsersTableRowProps = {
  user: UserResponse;
};

const UsersTableRow = ({ user }: UsersTableRowProps) => {
  const { t } = useTranslation();
  return (
    <tr key={user.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xxs">
        <div className="flex items-center uppercase font-semibold">
          <Link to={`/user/${user.id}`}>{user.fullName}</Link>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        {user.email}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        {user.phone}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        {user.parent?.fullName || "-"}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500 uppercase">
        {user.role === USER_TYPES.BLOCKED
          ? t("roles.blocked")
          : user.userCompanyType &&
            t(`userTypes.${user.userCompanyType?.toLowerCase()}`)}

        {user.role === USER_COMPANY_TYPES.ADMIN_APPROVAL && (
          <span className="px-2 inline-flex text-xxs leading-5 font-semibold rounded bg-yellow-100 text-yellow-800">
            {t("userTypes.pendingApproval")}
          </span>
        )}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-right text-xxs font-medium">
        <Link
          to={`/user/${user.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default UsersTableRow;
