import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import i18next from "../../i18n";
import {
  Loader,
  MaterialsTable,
  MobileCard,
  Search,
  Tabs,
} from "../../components";
import { useDebounce } from "../../hooks";
import { MaterialWithSuppliersResponse } from "../../types";
import {
  useAllCompanyMaterials,
  useSearchAllMaterials,
} from "../../hooks/useMaterials";

const tabs = [
  {
    name: i18next.t("titles.productive"),
    current: true,
  },
  {
    name: i18next.t("titles.nonProductive"),
    current: false,
  },
  {
    name: i18next.t("titles.services"),
    current: false,
  },
];

const CompanyMaterials = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabNumber = searchParams.get("tab");
  const [selectedTab, setSelectedTab] = useState(
    tabNumber ? parseInt(tabNumber) : 0
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { debouncedSearchTerm, isSearching } = useDebounce(searchTerm, 500);

  const allCompanyMaterials = useAllCompanyMaterials(selectedTab, pageNumber);

  const foundMaterials = useSearchAllMaterials(
    selectedTab,
    debouncedSearchTerm,
    pageNumber
  );

  useEffect(() => {
    if (selectedTab === 0) setSearchParams({ tab: "0" });
  }, [selectedTab, setSearchParams]);

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setSearchParams({ tab: `${index}` });
  };

  return (
    <div className="px-4 mt-8">
      <div className="md:flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {i18next.t("navigation.companyMaterials")}
        </h2>
      </div>

      <Tabs
        tabs={tabs}
        handleTabClick={handleTabClick}
        selectedTab={selectedTab}
      />

      {selectedTab === 0 && (
        <div className="hidden sm:block">
          <Search
            value={searchTerm}
            handleSearch={(e) => setSearchTerm(e.target.value)}
            border
          />
          {isSearching || allCompanyMaterials.isLoading ? (
            <div className="py-6">
              <Loader />
            </div>
          ) : (
            <>
              {searchTerm === "" ? (
                allCompanyMaterials?.data?.content?.length > 0 && (
                  <MaterialsTable
                    allPICompanyMaterials={allCompanyMaterials?.data}
                    pagePINumber={pageNumber}
                    setPagePINumber={setPageNumber}
                  />
                )
              ) : (
                <MaterialsTable foundMaterials={foundMaterials?.data} />
              )}
            </>
          )}
        </div>
      )}

      {selectedTab === 1 && (
        <div className="hidden sm:block">
          <Search
            value={searchTerm}
            handleSearch={(e) => setSearchTerm(e.target.value)}
            border
          />
          {isSearching || allCompanyMaterials.isLoading ? (
            <div className="py-6">
              <Loader />
            </div>
          ) : (
            <>
              {searchTerm === "" ? (
                allCompanyMaterials?.data?.content?.length > 0 && (
                  <MaterialsTable
                    allNPICompanyMaterials={allCompanyMaterials?.data}
                    pagePINumber={pageNumber}
                    setPagePINumber={setPageNumber}
                  />
                )
              ) : (
                <MaterialsTable foundMaterials={foundMaterials?.data} />
              )}
            </>
          )}
        </div>
      )}

      {selectedTab === 2 && (
        <div className="hidden sm:block">
          <Search
            value={searchTerm}
            handleSearch={(e) => setSearchTerm(e.target.value)}
            border
          />
          {isSearching || allCompanyMaterials.isLoading ? (
            <div className="py-6">
              <Loader />
            </div>
          ) : (
            <>
              {searchTerm === "" ? (
                allCompanyMaterials?.data?.content?.length > 0 && (
                  <MaterialsTable
                    services={allCompanyMaterials?.data}
                    pagePINumber={pageNumber}
                    setPagePINumber={setPageNumber}
                  />
                )
              ) : (
                <MaterialsTable foundMaterials={foundMaterials?.data} />
              )}
            </>
          )}
        </div>
      )}

      <div className="mt-10 sm:hidden">
        <ul className="mt-3 border border-gray-200 divide-y divide-gray-100 rounded">
          {selectedTab === 0 && (
            <>
              {allCompanyMaterials.isLoading ? (
                <Loader />
              ) : (
                allCompanyMaterials?.data?.content?.map(
                  (mat: MaterialWithSuppliersResponse) => (
                    <MobileCard
                      key={mat.material.id}
                      item={mat}
                      route="material"
                    />
                  )
                )
              )}
            </>
          )}

          {selectedTab === 1 && (
            <>
              {allCompanyMaterials.isLoading ? (
                <Loader />
              ) : (
                allCompanyMaterials?.data?.content?.map(
                  (mat: MaterialWithSuppliersResponse) => (
                    <MobileCard
                      key={mat.material.id}
                      item={mat}
                      route="material"
                    />
                  )
                )
              )}
            </>
          )}

          {selectedTab === 2 && (
            <>
              {allCompanyMaterials.isLoading ? (
                <Loader />
              ) : (
                allCompanyMaterials?.data?.content?.map(
                  (mat: MaterialWithSuppliersResponse) => (
                    <MobileCard
                      key={mat.material.id}
                      item={mat}
                      route="material"
                    />
                  )
                )
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default CompanyMaterials;
