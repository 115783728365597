import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrdersTable, Loader, UsersTable } from "../../../components";
import { ORDER_TYPES, OrderState, UserResponse } from "../../../types";
import { useUsersByCompany } from "../../../hooks/useUsers";
import { useOrdersByTypeAndState } from "../../../hooks/useOrders";

type BuyerProps = {
  isAnalyst?: boolean;
  user: UserResponse;
};

export default function Buyer({ isAnalyst = false, user }: BuyerProps) {
  const { t } = useTranslation();
  const [closedPageNumber, setClosedPageNumber] = useState(0);

  const { data: orders, isLoading } = useOrdersByTypeAndState(
    OrderState.CLOSED,
    ORDER_TYPES.NORMAL,
    ORDER_TYPES.INVERSE,
    closedPageNumber
  );

  const usersByCompany = useUsersByCompany(user?.id);

  const filteredUsers =
    usersByCompany?.data?.filter((u: UserResponse) => u.id !== user?.id) || [];

  return (
    <Fragment>
      {isLoading || usersByCompany.isLoading ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {orders && orders?.content?.length > 0 ? (
            <OrdersTable
              title={t("order.closed")}
              orders={orders}
              pageNumber={closedPageNumber}
              setPageNumber={setClosedPageNumber}
            />
          ) : null}

          {!isAnalyst ? (
            filteredUsers?.length > 0 ? (
              <UsersTable
                usersByCompany={filteredUsers}
                title={t("users.title")}
              />
            ) : null
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
}
