import { Fragment } from "react";
import { Menu, Transition, MenuButton, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useLanguage } from "../../../hooks";
import { CategoryResponse, CountryResponse } from "../../../types";

type MultiPickListProps = {
  label: string;
  items: CategoryResponse[];
  action: string;
  updateEvent: (event: { type: string; item: any }) => void;
  categories: CategoryResponse[] | CountryResponse[];
  setDisabled: (value: boolean) => void;
};

const MultiPickList = ({
  label,
  items,
  action,
  updateEvent,
  categories,
  setDisabled,
}: MultiPickListProps) => {
  const langEs = useLanguage();
  return (
    <Menu
      as="div"
      className="relative inline-block text-left w-full col-span-6 md:col-span-2 lg:col-span-1"
    >
      <div>
        <MenuButton
          className={`uppercase flex items-center w-full justify-between gap-x-1.5 rounded bg-white px-3 py-2 text-xs font-semibold text-gray-900 shadow-sm ring-inset hover:bg-gray-50 ${
            categories?.length === 0
              ? "ring-red-500 border-red-500 ring-2"
              : "ring-gray-300 ring-1"
          }`}
        >
          {label}{" "}
          <div className="flex">
            <span className="bg-spectum-light text-white rounded-full h-5 w-5 text-xs text-center flex items-center justify-center">
              {categories?.length}
            </span>
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-900"
              aria-hidden="true"
            />
          </div>
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute left-0 z-10 mt-2 w-full max-h-400 overflow-y-scroll origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <div className="flex flex-col py-2">
              {items?.map((item) => (
                <div key={item.id} className="w-full p-2 text-xs">
                  <label htmlFor={item.id} className="flex items-center">
                    <input
                      className="rounded shadow border border-gray-400 mr-2"
                      type="checkbox"
                      id={item.id}
                      name={langEs ? item.name : item.nameEn}
                      checked={!!categories?.find((cat) => cat.id === item.id)}
                      onChange={() => {
                        updateEvent({
                          type: action,
                          item,
                        });
                        setDisabled(false);
                      }}
                    />
                    {langEs ? item.name : item.nameEn}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default MultiPickList;
