export enum PN_TYPES {
  PI = "PI",
  NPI = "NPI",
  SERVICE = "SERVICE",
}

export enum ORDER_TYPES {
  NORMAL = "NORMAL",
  INVERSE = "INVERSE",
  JOINT = "JOINT",
  INVERSE_JOINT = "INVERSE_JOINT",
}

export enum DELIVERY_TYPE {
  SPOT = "SPOT",
  PERIOD = "PERIOD",
}
