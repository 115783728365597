type PriceVolumeActualProps = {
  volActual: number | string;
  priceActual: number | string;
  event: any;
};

export default function PriceVolumeActual({
  volActual,
  priceActual,
  event,
}: PriceVolumeActualProps) {
  return (
    <>
      <div
        className={`w-80 border-b p-1 text-center ${
          event.actual.vol !== volActual ? "bg-yellow-200" : "bg-gray-100"
        }`}
      >
        <span className="text-xs w-full border-none">{event.actual.vol}</span>
      </div>
      <div
        className={`w-80 border-b p-1 text-center ${
          event.actual.price !== priceActual ? "bg-yellow-200" : "bg-gray-100"
        }`}
      >
        <span className="text-xs w-full border-none">{event.actual.price}</span>
      </div>
    </>
  );
}
