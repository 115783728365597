import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { UsersIcon } from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import { mapDispatchToProps, mapStateToProps } from "../../utils";
import { useDebounce } from "../../hooks";
import {
  Loader,
  MaterialsTable,
  MobileCard,
  Search,
  Tabs,
} from "../../components";
import { MaterialType, UserResponse } from "../../types";
import { useSites } from "../../hooks/useSites";
import {
  useMaterials,
  useMaterialsByOwner,
  useRetrieveAllMaterials,
  useSearchMaterials,
} from "../../hooks/useMaterials";
import { queryKeys } from "../../hooks/queryKeys";

const tabs = [
  {
    name: i18next.t("titles.productive"),
    current: true,
  },
  {
    name: i18next.t("titles.nonProductive"),
    current: false,
  },
  {
    name: i18next.t("titles.services"),
    current: false,
  },
  {
    name: i18next.t("titles.delegated"),
    current: false,
  },
];

const Materials = ({
  user,
  onOpenNotification,
}: {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabNumber = searchParams.get("tab");
  const [selectedTab, setSelectedTab] = useState(
    tabNumber ? parseInt(tabNumber) : 0
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPage, setSearchPage] = useState(0);
  const [pagePINumber, setPagePINumber] = useState(0);
  const [pageNPINumber, setPageNPINumber] = useState(0);
  const [pageDelegated, setPageDelegated] = useState(0);
  const [servicesPage, setServicesPage] = useState(0);
  const { debouncedSearchTerm, isSearching } = useDebounce(searchTerm, 500);

  const { data: piMaterials } = useMaterials(
    queryKeys.PI_MATERIALS,
    MaterialType.PI,
    pagePINumber,
    selectedTab
  );

  const { data: npiMaterials } = useMaterials(
    queryKeys.NPI_MATERIALS,
    MaterialType.NPI,
    pageNPINumber,
    selectedTab
  );

  const { data: services } = useMaterials(
    queryKeys.SERVICES,
    MaterialType.SERVICE,
    servicesPage,
    selectedTab
  );

  const { data: sites } = useSites(user?.company?.id);

  const { data: foundMaterials } = useSearchMaterials(
    debouncedSearchTerm,
    searchPage
  );

  const { data: materialsByOwner, isLoading: isLoadingMaterials } =
    useMaterialsByOwner(pageDelegated);

  const { mutate: getBackAllMaterials } = useRetrieveAllMaterials(
    () => onOpenNotification(i18next.t("materials.success")),
    () => onOpenNotification(i18next.t("newMaterialForm.error"))
  );

  useEffect(() => {
    if (selectedTab === 0) setSearchParams({ tab: "0" });
  }, [selectedTab]);

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setSearchParams({ tab: `${index}` });
  };

  if (sites?.length === 0) {
    return (
      <div className="px-4 mt-6">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {i18next.t("materials.title")}
        </h2>
        <p className="text-xs leading-7 text-gray-900 sm:truncate pt-10">
          {i18next.t("materials.description")}
        </p>
        <Link
          to="/new-site"
          type="button"
          className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum sm:order-1 uppercase mt-4"
        >
          {i18next.t("materials.addSite")}
        </Link>
      </div>
    );
  }

  const handleServicePage = (page: number) => setServicesPage(page);

  return (
    <div className="px-4 mt-6">
      <div className="md:flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {i18next.t("materials.title")}
        </h2>
        <Link
          to="/new-material"
          type="button"
          className="px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum sm:order-1 uppercase mt-4"
        >
          {i18next.t("materials.add")}
        </Link>
      </div>

      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabClick={handleTabClick}
      />

      {selectedTab === 0 && (
        <div className="hidden sm:block">
          <Search
            value={searchTerm}
            handleSearch={(e) => setSearchTerm(e.target.value)}
            border
          />
          {isSearching || isLoadingMaterials ? (
            <div className="py-6">
              <Loader />
            </div>
          ) : (
            <>
              {searchTerm === "" ? (
                <>
                  {piMaterials && piMaterials?.content?.length > 0 ? (
                    <MaterialsTable
                      piMaterials={piMaterials}
                      setPagePINumber={setPagePINumber}
                      pagePINumber={pagePINumber}
                    />
                  ) : (
                    <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                      {i18next.t("materials.noUploaded")}
                    </h4>
                  )}
                </>
              ) : (
                <MaterialsTable
                  foundMaterials={foundMaterials}
                  searchPage={searchPage}
                  setSearchPage={setSearchPage}
                />
              )}
            </>
          )}
        </div>
      )}

      {selectedTab === 1 && (
        <div className="hidden sm:block">
          <Search
            value={searchTerm}
            handleSearch={(e) => setSearchTerm(e.target.value)}
            border
          />
          {isSearching || isLoadingMaterials ? (
            <div className="py-6">
              <Loader />
            </div>
          ) : (
            <>
              {searchTerm === "" ? (
                <>
                  {npiMaterials && npiMaterials?.content?.length > 0 ? (
                    <MaterialsTable
                      npiMaterials={npiMaterials}
                      setPageNPINumber={setPageNPINumber}
                      pageNPINumber={pageNPINumber}
                    />
                  ) : (
                    <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                      {i18next.t("materials.noUploaded")}
                    </h4>
                  )}
                </>
              ) : (
                <MaterialsTable
                  foundMaterials={foundMaterials}
                  searchPage={searchPage}
                  setSearchPage={setSearchPage}
                />
              )}
            </>
          )}
        </div>
      )}

      {selectedTab === 2 && (
        <div className="hidden sm:block">
          <Search
            value={searchTerm}
            handleSearch={(e) => setSearchTerm(e.target.value)}
            border
          />
          {isSearching || isLoadingMaterials ? (
            <div className="py-6">
              <Loader />
            </div>
          ) : (
            <>
              {searchTerm === "" ? (
                <>
                  {services && services?.content?.length > 0 ? (
                    <MaterialsTable
                      services={services}
                      setPageNPINumber={setServicesPage}
                      pageNPINumber={servicesPage}
                    />
                  ) : (
                    <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                      {i18next.t("materials.noUploadedServices")}
                    </h4>
                  )}
                </>
              ) : (
                <MaterialsTable
                  foundMaterials={foundMaterials}
                  searchPage={searchPage}
                  setSearchPage={setSearchPage}
                />
              )}
            </>
          )}
        </div>
      )}

      {selectedTab === 3 && (
        <div className="hidden sm:block">
          {isSearching || isLoadingMaterials ? (
            <div className="py-6">
              <Loader />
            </div>
          ) : (
            <>
              {materialsByOwner && materialsByOwner?.content?.length > 0 ? (
                <button
                  type="button"
                  onClick={() => getBackAllMaterials()}
                  className="uppercase flex justify-center items-center gap-4 px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light mt-4"
                >
                  <UsersIcon className="h-4 w-4" />
                  <span>{i18next.t("materials.retrieve")}</span>
                </button>
              ) : (
                <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                  {i18next.t("materials.notDelegated")}
                </h4>
              )}
              {!(materialsByOwner?.content?.length === 0) && (
                <MaterialsTable
                  materialsByOwner={materialsByOwner}
                  pageDelegated={pageDelegated}
                  setPageDelegated={setPageDelegated}
                />
              )}
            </>
          )}
        </div>
      )}

      {isSearching || isLoadingMaterials ? (
        <div className="py-6 sm:hidden">
          <Loader />
        </div>
      ) : (
        <div className="mt-10 sm:hidden">
          <ul className="mt-3 border-gray-200 divide-y divide-gray-100 rounded border">
            {selectedTab === 0 && (
              <>
                {piMaterials?.content?.map((mat) => (
                  <MobileCard
                    key={mat.material.id}
                    item={mat}
                    route="material"
                  />
                ))}
              </>
            )}
            {selectedTab === 1 && (
              <>
                {npiMaterials?.content?.map((mat) => (
                  <MobileCard
                    key={mat.material.id}
                    item={mat}
                    route="material"
                  />
                ))}
              </>
            )}
            {selectedTab === 2 && (
              <>
                {materialsByOwner && materialsByOwner?.content?.length > 0 ? (
                  <button
                    type="button"
                    onClick={() => getBackAllMaterials()}
                    className="uppercase flex justify-center items-center gap-4 px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light mt-4"
                  >
                    <UsersIcon className="h-4 w-4" />
                    <span>{i18next.t("materials.retrieve")}</span>
                  </button>
                ) : (
                  <h4 className="text-sm leading-7 text-gray-900 sm:truncate pt-10">
                    {i18next.t("materials.notDelegated")}
                  </h4>
                )}
                {materialsByOwner?.content?.map((mat) => (
                  <MobileCard
                    key={mat.material.id}
                    item={mat}
                    route="material"
                  />
                ))}
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Materials);
