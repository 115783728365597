import { Fragment } from "react";
import { connect } from "react-redux";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import {
  IT_DOESNT_HAVE_SPEC,
  mapStateToProps,
  NO_SPEC,
  numberFormatter,
  PN_TYPE,
  TO_BE_AGREED,
} from "../../../utils";
import i18next from "../../../i18n";
import { InfoItem } from "../..";
import { useLanguage } from "../../../hooks";
import { MaterialResponse } from "../../../types";

type DetailProps = {
  material: MaterialResponse;
  pnType: string;
  pn_type: {
    PI: string;
    NPI: string;
    SERVICE: string;
  };
};

const Detail = ({ material, pnType, pn_type }: DetailProps) => {
  const langEs = useLanguage();
  const isSpec = NO_SPEC === material?.fileURL;
  return (
    <Fragment>
      <div className="bg-white overflow-hidden">
        <div className="py-2 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="uppercase text-xxs font-medium text-black">
                {pnType === pn_type.NPI && i18next.t("material.nonProductive")}
                {pnType === pn_type.PI && i18next.t("material.productive")}
                {pnType === pn_type.SERVICE && i18next.t("material.service")}
              </dt>
            </div>
          </dl>
        </div>
      </div>
      <InfoItem
        name={i18next.t("material.merchancyCode")}
        description={
          langEs ? material?.category?.name : material?.category?.nameEn
        }
      />
      {material?.annualVolume && (
        <InfoItem
          name={i18next.t("material.annualVolume")}
          description={
            pnType === PN_TYPE.PI
              ? `${numberFormatter.format(material?.annualVolume)} ${
                  material?.measurementUnit
                }`
              : `${material?.annualVolume} ${material?.measurementUnit}`
          }
        />
      )}
      <InfoItem
        name={i18next.t("material.currency")}
        description={material?.coin}
      />
      {pnType === PN_TYPE.PI && (
        <Fragment>
          <InfoItem
            name={i18next.t("material.incoTerm")}
            description={material?.incoTerm}
          />
          <InfoItem
            name={i18next.t("material.deliveryFormat")}
            description={material?.deliveryFormat}
          />
        </Fragment>
      )}
      <InfoItem
        name={i18next.t("material.deliveryPlace")}
        description={material?.deliveryPlace?.name ?? TO_BE_AGREED}
      />
      {material?.certifications?.length > 0 && (
        <InfoItem
          name={i18next.t("material.certifications")}
          description={material?.certifications
            ?.map((cert) => cert.name)
            .join(", ")}
        />
      )}
      {material?.application && (
        <InfoItem
          name={
            pnType === PN_TYPE.PI
              ? i18next.t("material.application")
              : "Marca / Producto"
          }
          description={material?.application}
        />
      )}
      {material?.comments && (
        <InfoItem
          name={i18next.t("material.comments")}
          description={material?.comments}
        />
      )}
      <InfoItem
        name={
          pnType === PN_TYPE.SERVICE
            ? i18next.t("material.confidentService")
            : i18next.t("material.confidentMaterial")
        }
        description={
          material?.confident ? i18next.t("cta.yes") : i18next.t("cta.no")
        }
      />
      <InfoItem
        name={i18next.t("material.confidential")}
        description={
          material?.confidentSpec ? i18next.t("cta.yes") : i18next.t("cta.no")
        }
      />
      <div className="bg-white overflow-hidden">
        <div className="py-2 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="uppercase text-xxs font-medium text-gray-500">
                {i18next.t("material.specification")}
              </dt>
              <dd className="mt-1 text-xxs text-gray-900 sm:mt-0 sm:col-span-1">
                {isSpec ? (
                  <p>{IT_DOESNT_HAVE_SPEC}</p>
                ) : (
                  <a
                    href={material?.fileURL}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex items-center p-1 border border-transparent shadow-sm text-xxs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                  >
                    <ArrowDownTrayIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {i18next.t("cta.download")}
                  </a>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <InfoItem
        name={i18next.t("material.responsible")}
        description={material?.owner?.fullName}
      />
      <InfoItem
        name={i18next.t("material.owner")}
        description={material?.user?.fullName}
      />
    </Fragment>
  );
};

export default connect(mapStateToProps)(Detail);
