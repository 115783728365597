import { useReducer, useState } from "react";
import { connect } from "react-redux";
import "./styles.css";
import { MONTHS, mapDispatchToProps } from "../../../../utils";
import PriceVolumeItem from "./PriceVolumeItem/PriceVolumeItem";
import PriceVolumeSuppliers from "./PriceVolumeSuppliers/PriceVolumeSuppliers";

const actions = {
  UPDATE_FORECAST_VOLUME: "UPDATE_FORECAST_VOLUME",
  UPDATE_FORECAST_PRICE: "UPDATE_FORECAST_PRICE",
};

type PriceVolumeColumnProps = {
  id: string;
  isPreviousYear?: boolean;
  isNextYear?: boolean;
  month: string;
  volActual: string;
  priceActual: string;
  volForecast: string;
  priceForecast: string;
  actualId: string;
  forecastId: string;
  isActual?: boolean;
  suppliers: any[];
  onUpdateSupplierPriceVolume: (arg: any) => void;
  isMaterialView?: boolean;
  hasTableHead?: boolean;
  isFirst?: boolean;
};

const PriceVolumeColumn = ({
  id,
  isPreviousYear,
  isNextYear,
  month,
  volForecast,
  priceForecast,
  volActual,
  priceActual,
  actualId,
  forecastId,
  isActual,
  suppliers,
  onUpdateSupplierPriceVolume,
  isMaterialView,
  hasTableHead,
  isFirst,
}: PriceVolumeColumnProps) => {
  const [prevForecastVol, setPrevForecastVol] = useState<string | number>("");
  const [prevForecastPrice, setPrevForecastPrice] = useState<string | number>(
    ""
  );
  const [event, updateEvent] = useReducer(
    (state: any, action: any) => {
      let newEvent = { ...state, id };
      switch (action.type) {
        case actions.UPDATE_FORECAST_VOLUME:
          newEvent = {
            ...newEvent,
            forecast: {
              ...newEvent.forecast,
              vol: parseFloat(action.vol),
            },
          };
          break;
        case actions.UPDATE_FORECAST_PRICE:
          newEvent = {
            ...newEvent,
            forecast: {
              ...newEvent.forecast,
              price: parseFloat(action.price),
            },
          };
          break;
      }
      return newEvent;
    },
    {
      actual: { vol: volActual, price: priceActual, id: actualId },
      forecast: { vol: volForecast, price: priceForecast, id: forecastId },
      suppliers,
    }
  );

  const isForecastDisabled = () => {
    const currentMonth = new Date().getMonth();
    const whichMonth = MONTHS.find(
      (item) => item.es.slice(0, 3) === month || item.en.slice(0, 3) === month
    );
    return (
      (currentMonth > (whichMonth?.id ?? 0) - 1 && !isNextYear) ||
      isPreviousYear
    );
  };

  const volumeVariation =
    parseFloat(event.actual.price) > 0
      ? parseFloat(
          String((event.actual.vol - event.forecast.vol) * event.forecast.price)
        ).toFixed(1)
      : 0;
  const priceVariation =
    parseFloat(event.actual.price) > 0 && parseFloat(event.forecast.price) > 0
      ? parseFloat(
          String((event.actual.price - event.forecast.price) * event.actual.vol)
        ).toFixed(1)
      : 0;

  return (
    <div className="flex flex-col items-center text-xs w-80">
      {isFirst || hasTableHead ? (
        <div
          className={`font-bold text-white text-center ${
            isActual ? "bg-spectum" : "bg-spectum-light"
          } w-full p-1`}
        >
          {month}
        </div>
      ) : null}

      <PriceVolumeItem
        id={id}
        event={event}
        actions={actions}
        volActual={volActual}
        priceActual={priceActual}
        updateEvent={updateEvent}
        prevForecastVol={prevForecastVol}
        prevForecastPrice={prevForecastPrice}
        setPrevForecastVol={setPrevForecastVol}
        isForecastDisabled={isForecastDisabled}
        setPrevForecastPrice={setPrevForecastPrice}
        onUpdateSupplierPriceVolume={onUpdateSupplierPriceVolume}
      />

      <span className="font-bold text-white pt-0.5 bg-gray-200 w-full"></span>

      {isMaterialView ? (
        <>
          <div
            className={`w-80 border-b p-1 text-center ${
              Number(volumeVariation) < 0
                ? "bg-spectum-green"
                : Number(volumeVariation) > 0
                  ? "bg-red-200"
                  : "bg-white"
            }`}
          >
            <span className="text-xs w-full border-none">
              {volumeVariation}
            </span>
          </div>
          <div
            className={`w-80 border-b p-1 text-center ${
              Number(priceVariation) < 0
                ? "bg-spectum-green"
                : Number(priceVariation) > 0
                  ? "bg-red-200"
                  : "bg-white"
            }`}
          >
            <span className="text-xs w-full border-none">{priceVariation}</span>
          </div>
        </>
      ) : null}
      {isMaterialView ? (
        <PriceVolumeSuppliers event={event} month={month} isActual={isActual} />
      ) : null}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(PriceVolumeColumn);
