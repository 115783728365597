import { Fragment, useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { BIDDING_TYPES, FileType, PAYMENT_METHOD_LIST } from "../../../utils";
import i18next from "../../../i18n";
import { XMarkIcon } from "@heroicons/react/24/outline";
import PeriodDate from "./PeriodDate/PeriodDate";

const todayDateFormatter = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  return new Date(tomorrow.setDate(tomorrow.getDate()))
    .toISOString()
    .split("T")[0];
};

const addFilesOptions = [
  { id: 1, name: i18next.t("tenders.form.plication"), type: FileType.SHEET },
  {
    id: 2,
    name: i18next.t("tenders.form.certificate"),
    type: FileType.CERTIFICATION,
  },
  {
    id: 3,
    name: i18next.t("tenders.form.additionalDocument"),
    type: FileType.ATTACHMENT,
  },
  {
    id: 4,
    name: i18next.t("tenders.form.brief"),
    type: FileType.BRIEF,
  },
  {
    id: 5,
    name: i18next.t("tenders.form.servicePlace"),
    type: FileType.SERVICE_PLACE,
  },
];

type MultipleMaterialsBiddingFormProps = {
  type: string;
  actions: any;
  generalBidData: any;
  materialsToBid: any;
  setGeneralBidData: any;
  isLoadingUpload: boolean;
  uploadedFileUrl: string | null;
  specificationName: string;
  handleSpecification: any;
  setSpecificationName: any;
  handleSupplyPeriod: any;
};

const MultipleMaterialsBiddingForm = ({
  type,
  actions,
  generalBidData,
  materialsToBid,
  setGeneralBidData,
  isLoadingUpload,
  uploadedFileUrl,
  specificationName,
  handleSupplyPeriod,
  handleSpecification,
  setSpecificationName,
}: MultipleMaterialsBiddingFormProps) => {
  const [selectedFileType, setSelectedFileType] = useState<any>({
    name: "",
  });
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const handleFileUpload = (event: any) => {
    handleSpecification(event);
    setFileInputKey(Date.now());
  };

  useEffect(() => {
    if (uploadedFileUrl) {
      setGeneralBidData({
        type: actions.ADD_FILE,
        files: [
          {
            name: selectedFileType?.name,
            fileURL: uploadedFileUrl,
            type: selectedFileType?.type,
          },
        ],
      });
      setSpecificationName("");
      setSelectedFileType({
        name: "",
      });
    }
    uploadedFileUrl = null;
  }, [uploadedFileUrl]);

  const handleRemoveFile = (index: number) => {
    setGeneralBidData({
      type: actions.REMOVE_FILE,
      index,
    });
  };

  return (
    <Fragment>
      <form
        className="space-y-8 divide-y divide-gray-200 mt-8"
        autoComplete="off"
      >
        <div className="mt-10 sm:mt-0">
          <div className="mt-5 md:mt-0">
            <div className="shadow border overflow-hidden rounded">
              <div className="grid grid-cols-2 gap-6 bg-white p-6">
                {(type === BIDDING_TYPES.JOINT ||
                  type === BIDDING_TYPES.INVERSE_JOINT) && (
                  <>
                    <div className="col-span-2 md:col-span-1">
                      <label
                        htmlFor="name"
                        className="uppercase block text-xs font-medium text-gray-700"
                      >
                        {i18next.t("tenders.form.name")}
                        <span className="text-sm text-red-600"> *</span>
                      </label>
                      <input
                        className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                        id="name"
                        name="name"
                        type="text"
                        value={generalBidData.name}
                        max={255}
                        onChange={(e) => {
                          if (e.target.value.length === 255) return;
                          setGeneralBidData({
                            type: actions.UPDATE_NAME,
                            name: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <label
                        htmlFor="internalCode"
                        className="uppercase block text-xs font-medium text-gray-700"
                      >
                        {i18next.t("tenders.form.internalCode")}
                        <span className="text-sm"></span>
                      </label>
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="internalCode"
                          id="internalCode"
                          value={generalBidData.internalCode}
                          onChange={(e) =>
                            setGeneralBidData({
                              type: actions.UPDATE_INTERNAL_CODE,
                              internalCode: e.target.value,
                            })
                          }
                          className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                        />
                      </div>
                    </div>

                    <PeriodDate
                      matToBid={materialsToBid[0]}
                      handleSupplyPeriod={handleSupplyPeriod}
                    />
                  </>
                )}

                <div className="col-span-2 md:col-span-1">
                  <label
                    htmlFor="startDate"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("tenders.form.initialDate")}
                    <span className="text-sm text-red-600"> *</span>
                  </label>
                  <input
                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                    id="startDate"
                    name="startDate"
                    type="date"
                    min={todayDateFormatter()}
                    value={generalBidData.startDateValue}
                    onChange={(e) =>
                      setGeneralBidData({
                        type: actions.UPDATE_START_DATE,
                        startDateValue: e.target.value,
                      })
                    }
                    required
                  />
                </div>

                <div className="col-span-2 md:col-span-1">
                  <label
                    htmlFor="finishDate"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("tenders.form.closeDate")}
                    <span className="text-sm text-red-600"> *</span>
                  </label>
                  <input
                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-600 rounded"
                    id="finishDate"
                    name="finishDate"
                    type="date"
                    min={generalBidData.startDateValue}
                    disabled={!generalBidData.startDateValue}
                    value={generalBidData.endDateValue}
                    onChange={(e) =>
                      setGeneralBidData({
                        type: actions.UPDATE_FINISH_DATE,
                        endDateValue: e.target.value,
                      })
                    }
                    required
                  />
                </div>

                <div className="col-span-2 md:col-span-1">
                  <label
                    htmlFor="paymentMethod"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("tenders.form.paymentMethod")}
                    <span className="text-sm text-red-600"> *</span>
                  </label>
                  <select
                    id="paymentMethod"
                    name="paymentMethod"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                    value={generalBidData.paymentMethod}
                    onChange={(e) =>
                      setGeneralBidData({
                        type: actions.UPDATE_PAYMENT_METHOD,
                        paymentMethod: e.target.value,
                      })
                    }
                  >
                    <option value="">
                      {i18next.t("validations.selectPaymentMethod")}
                    </option>
                    {PAYMENT_METHOD_LIST?.map((pay) => (
                      <option key={pay.id} value={pay.name}>
                        {pay.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-span-2 md:col-span-1">
                  <label
                    htmlFor="paymentTerm"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("tenders.paymentTerm")}
                    <span className="text-sm text-red-600"> *</span>
                  </label>
                  <input
                    type="number"
                    name="paymentTerm"
                    min={0}
                    id="paymentTerm"
                    value={generalBidData.paymentTerm}
                    onChange={(e) =>
                      setGeneralBidData({
                        type: actions.UPDATE_PAYMENT_TERM,
                        paymentTerm: e.target.value,
                      })
                    }
                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block shadow-sm sm:text-xs border-gray-300 rounded w-full"
                  />
                </div>

                <div className="col-span-2">
                  <p className="uppercase block text-xs font-medium text-gray-700">
                    {i18next.t("cta.addFiles")}
                  </p>
                </div>

                <div className="col-span-1">
                  <div className="flex gap-4 items-center">
                    <select
                      value={selectedFileType?.name}
                      onChange={(e) => {
                        setSelectedFileType({
                          name: e.target.value,
                          type: addFilesOptions.find(
                            (opt) => opt.name === e.target.value
                          )?.type,
                        });
                      }}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                    >
                      <option value="">
                        {i18next.t("tenders.form.selectFileType")}
                      </option>
                      {addFilesOptions.map((type) => (
                        <option key={type.id} value={type.name}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {specificationName && <span>{specificationName}</span>}

                      <div className="text-xs text-gray-600">
                        <label
                          htmlFor="specificationFileUrl"
                          className="uppercase relative cursor-pointer bg-white rounded font-medium text-spectum hover:text-spectum-light focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-spectum-light"
                        >
                          {isLoadingUpload ? (
                            <div className="flex w-full items-center justify-center">
                              <ClipLoader loading size={30} />
                            </div>
                          ) : (
                            <span>
                              {i18next.t("cta.attach")} {selectedFileType?.name}
                            </span>
                          )}
                          <input
                            id="specificationFileUrl"
                            name="specificationFileUrl"
                            type="file"
                            className="sr-only"
                            disabled={
                              isLoadingUpload || selectedFileType?.name === ""
                            }
                            key={fileInputKey}
                            onChange={handleFileUpload}
                          />
                        </label>
                      </div>

                      <p className="text-xs text-gray-500">PDF 10MB</p>
                    </div>
                  </div>
                </div>

                <div className="col-span-2 md:col-span-1 mt-1">
                  {generalBidData.files.map((file: any, index: number) => (
                    <div
                      key={file.file}
                      className="flex justify-between mb-1 text-xs p-2 border rounded relative"
                    >
                      <div className="flex items-center gap-2">
                        <p>{file.name}</p>
                      </div>
                      <XMarkIcon
                        className="h-4 w-4 text-red-500 cursor-pointer border rounded-full border-red-500"
                        onClick={() => handleRemoveFile(index)}
                      />
                    </div>
                  ))}
                </div>

                <div className="col-span-2">
                  <label
                    htmlFor="comment"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("tenders.form.comments")}{" "}
                    <span className="text-gray-400">
                      ({i18next.t("newMaterialForm.optional")})
                    </span>
                  </label>
                  <input
                    className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                    id="comment"
                    name="comment"
                    type="text"
                    placeholder={i18next.t("tenders.form.comments")}
                    value={generalBidData.comment}
                    max={255}
                    onChange={(e) => {
                      if (e.target.value.length === 255) return;
                      setGeneralBidData({
                        type: actions.UPDATE_COMMENT,
                        comment: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default MultipleMaterialsBiddingForm;
