import { useTranslation } from "react-i18next";
import { UserResponse } from "../../types";

type SuppliersSelectedProps = {
  suppliers?: UserResponse[];
  invitedSuppliers?: UserResponse[];
};

export default function SuppliersSelected({
  suppliers,
  invitedSuppliers,
}: SuppliersSelectedProps) {
  const { t } = useTranslation();
  return (
    <div className="bg-white w-full">
      {suppliers && suppliers?.length > 0 && (
        <>
          <h2
            id="activity-title"
            className="text-lg font-medium text-gray-900 border-b pb-4"
          >
            {t("tenders.data.suppliers")}
          </h2>

          <div className="bg-white shadow sm:rounded p-2 mt-2">
            <ul className="list-disc pl-4">
              {suppliers?.map((sup) => (
                <li className="text-xs mb-1" key={sup.id}>
                  {sup.company.name}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      {invitedSuppliers && invitedSuppliers?.length > 0 && (
        <>
          <h2
            id="activity-title"
            className="text-xs font-medium text-gray-900 border-b py-2"
          >
            {t("tenders.data.invited")}
          </h2>
          <div className="bg-white shadow sm:rounded p-2 mt-2">
            <ul className="list-disc pl-4">
              {invitedSuppliers?.map((sup) => (
                <li className="text-xs mb-1" key={sup.id}>
                  {sup.email}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
