import { Fragment, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
  CheckBadgeIcon,
  XCircleIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import {
  currencyFormatter,
  mapStateToProps,
  mapDispatchToProps,
  newOfferValidationSchema,
  calculateMinimumDecrement,
  NO_SPEC,
  IT_DOESNT_HAVE_SPEC,
  BIDDING_TYPES,
  SAMPLE_STATE,
} from "../../utils";
import { EditOfferForm, Modal, Loader, TenderSucceeded, Slide } from "..";
import Delegate from "../Material/Delegate/Delegate";
import {
  BuyerSellerResponse,
  MaterialOfferResponse,
  ORDER_TYPES,
  OrderState,
} from "../../types";
import { usePosition } from "../../hooks/usePosition";
import { useOffer, useUpdateInverseOffer } from "../../hooks/useOffers";
import { useSeller } from "../../hooks/useValidateSeller";

type IsSellerOfferProps = {
  offerId?: string;
  materialOffer?: MaterialOfferResponse;
  onOpenNotification: (message: string) => void;
  valid?: boolean;
};

type FormValues = {
  price: number;
};

const IsSellerOffer = ({
  offerId,
  materialOffer,
  onOpenNotification,
  valid = false,
}: IsSellerOfferProps) => {
  const [open, setOpen] = useState(false);
  const [openDelegate, setOpenDelegate] = useState(false);

  const { data: offer } = useOffer(offerId || "");

  const { data: seller } = useSeller();

  const { data: position } = usePosition(offer?.order?.id || "");

  const { mutate: updateInverseOffer, isPending: isUpdatingInverseOffer } =
    useUpdateInverseOffer(
      () => onOpenNotification(i18next.t("tenders.seller.modifiedOffer")),
      () => onOpenNotification(i18next.t("newMaterialForm.error"))
    );

  const currency = currencyFormatter(offer?.price, offer?.order?.currency);
  const minDecrement = calculateMinimumDecrement(
    offer?.price,
    offer?.order?.minimumDecrement
  );

  const formValidationNeeded = newOfferValidationSchema(
    offer?.order?.type as ORDER_TYPES,
    Number(minDecrement)
  );

  const formOptions = {
    resolver: yupResolver(formValidationNeeded),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>(formOptions);

  const onSubmit = (data: FormValues) => {
    if (!offer) return;
    updateInverseOffer({
      offerId: offer?.id,
      price: parseFloat(String(data?.price)),
    });
    reset();
    setOpen(false);
  };
  const onError = () => setOpen(false);

  const isRejected = seller?.filter(
    (item: BuyerSellerResponse) =>
      item?.material?.id === materialOffer?.material?.id
  )[0]?.rejected;

  const handleDelegate = () => setOpenDelegate(true);
  const handleCloseDelegate = () => setOpenDelegate(false);

  if (isUpdatingInverseOffer) return <Loader isFullScreen />;

  return (
    <Fragment>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("tenders.seller.modify")}
        message={i18next.t("tenders.seller.modifyQuestion")}
        onClickSuccess={handleSubmit(onSubmit, onError)}
        onClickCancel={() => setOpen(false)}
      />
      <div className="relative col-span-2 lg:col-span-1">
        {materialOffer && (
          <div className="mb-4 flex w-full justify-end">
            <button
              type="button"
              onClick={handleDelegate}
              className="uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
            >
              <ArrowPathIcon className="-ml-1 mr-2 h-5 w-5 text-white" />
              <span>{i18next.t("delegate.delegate")}</span>
            </button>
          </div>
        )}
        <div className="shadow p-4 rounded border border-spectum relative">
          {offer?.order?.type === "INVERSE" && (
            <span
              className={`uppercase text-xs text-white font-bold absolute px-4 py-2 ${
                position.data === 0 ? "bg-green-700" : "bg-red-700"
              } rounded right-4`}
            >
              {i18next.t("tenders.seller.position")} {position.data + 1}
            </span>
          )}
          {materialOffer && isRejected && (
            <span className="mb-5 px-4 py-2 inline-flex rounded text-xs leading-5 font-semibold bg-red-700 text-white uppercase">
              {i18next.t("tenders.seller.rejected")}
            </span>
          )}
          <h4 className="text-xs font-bold text-gray-600 uppercase pt-4">
            {offer
              ? i18next.t("tenders.seller.myOffer")
              : i18next.t("tenders.seller.myApplication")}
          </h4>
          {offer ? (
            <h1 className="text-2xl font-bold text-gray-900">
              {offer?.order?.material?.globalMaterialName}{" "}
              <span className="text-xs text-gray-500">
                {offer?.order?.material?.ean}
              </span>
            </h1>
          ) : (
            <h1 className="text-2xl font-bold text-gray-900">
              {materialOffer?.material?.globalMaterialName}{" "}
              <span className="text-xs text-gray-500">
                {materialOffer?.material?.ean}
              </span>
            </h1>
          )}
          {offer?.selected ? (
            <span className="px-4 py-2 inline-flex rounded text-xs leading-5 font-semibold bg-green-700 text-white uppercase absolute right-4 top-4">
              {i18next.t("tenders.seller.accepted")}
            </span>
          ) : null}
          {!offer?.selected && offer?.order?.state === OrderState.SUCCEED ? (
            <span className="px-4 py-2 inline-flex rounded text-xs leading-5 font-semibold bg-red-700 text-white uppercase absolute right-4 top-4">
              {i18next.t("tenders.seller.notSelected")}
            </span>
          ) : null}
          {offer ? (
            <Fragment>
              <div className="bg-white overflow-hidden">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-3">
                    <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                      {i18next.t("tenders.seller.price")} (
                      {offer?.order?.currency}){" "}
                      {i18next.t("tenders.seller.noTax")}
                    </dt>
                    <dd className="text-xs text-gray-900">
                      {currency} / {offer?.order?.measurementUnit}
                    </dd>
                  </div>
                </dl>
                {offer?.selected && (
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-3">
                      <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                        {i18next.t("tenders.seller.volumeAwarded")}
                      </dt>
                      <dd className="text-xs text-gray-900">
                        {offer?.quantity} {offer?.order?.measurementUnit} -{" "}
                        {currency} / {offer?.order?.measurementUnit}
                      </dd>
                    </div>
                  </dl>
                )}
                {offer?.fileURL !== NO_SPEC && (
                  <div className="bg-white overflow-hidden">
                    <dl className="sm:divide-y sm:divide-gray-200">
                      <div className="py-3">
                        <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                          {i18next.t("material.file")}
                        </dt>
                        <dd className="text-xs text-gray-900">
                          <a
                            href={offer?.fileURL}
                            target="_blank"
                            rel="noreferrer"
                            className="inline-flex items-center p-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                          >
                            <ArrowDownTrayIcon
                              className="-ml-0.5 mr-2 h-4 w-4"
                              aria-hidden="true"
                            />
                            {i18next.t("cta.downloadFile")}
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>
                )}
                {offer?.comment && (
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-3">
                      <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                        {i18next.t("offer.comments")}
                      </dt>
                      <dd className="text-xs text-gray-900">
                        {offer?.comment}
                      </dd>
                    </div>
                  </dl>
                )}
              </div>
              {offer?.order?.type === BIDDING_TYPES.INVERSE &&
                offer?.order?.state !== OrderState.CLOSED &&
                offer?.order?.state !== OrderState.SUCCEED && (
                  <EditOfferForm
                    currency={offer?.order?.currency}
                    errors={errors}
                    state={offer?.order?.state}
                    setOpen={setOpen}
                    register={register}
                    orderType={offer?.order?.type}
                    decrement={offer?.order?.minimumDecrement}
                    actualOffer={offer?.price}
                    measurementUnit={offer?.order?.measurementUnit}
                  />
                )}
            </Fragment>
          ) : null}
          {materialOffer ? (
            <Fragment>
              {valid ? (
                <div className="px-4 py-2 flex items-center gap-2 rounded text-xs leading-5 font-semibold bg-green-700 text-white uppercase absolute right-4 top-4">
                  <CheckCircleIcon className="h-4 w-4 text-white" />{" "}
                  {i18next.t("offer.validatedFlag")}
                </div>
              ) : null}
              <div className="bg-white overflow-hidden">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-3">
                    <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                      {i18next.t("offer.origin")}
                    </dt>
                    <dd className="text-xs text-gray-900">
                      {materialOffer?.origin?.name}
                    </dd>
                  </div>
                </dl>
                {materialOffer?.certifications?.length > 0 && (
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-3">
                      <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                        {i18next.t("certifications.certifications")}
                      </dt>
                      <dd className="text-xs text-gray-900">
                        {materialOffer?.certifications
                          ?.map((cert) => cert.materialCertification.name)
                          .join(", ")}
                      </dd>
                    </div>
                  </dl>
                )}
                {materialOffer?.comment && (
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-3">
                      <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                        {i18next.t("offer.comments")}
                      </dt>
                      <dd className="text-xs text-gray-900">
                        {materialOffer.comment}
                      </dd>
                    </div>
                  </dl>
                )}
              </div>
              {materialOffer?.specificationFileUrl && (
                <div className="bg-white overflow-hidden">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-3">
                      <dt className="uppercase text-xs font-medium text-gray-500 pb-1">
                        {i18next.t("newMaterialForm.specification")}
                      </dt>
                      <dd className="text-xs text-gray-900">
                        {materialOffer?.specificationFileUrl === NO_SPEC ? (
                          IT_DOESNT_HAVE_SPEC
                        ) : (
                          <a
                            href={materialOffer?.specificationFileUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                          >
                            <ArrowDownTrayIcon
                              className="-ml-0.5 mr-2 h-4 w-4"
                              aria-hidden="true"
                            />
                            {i18next.t("cta.download")}
                          </a>
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
              )}
            </Fragment>
          ) : null}
          {materialOffer?.state === SAMPLE_STATE.REQUESTED &&
            seller &&
            seller?.length > 0 && <TenderSucceeded seller={materialOffer} />}
          {materialOffer?.state === SAMPLE_STATE.VERIFIED && (
            <div className="p-2 flex items-center gap-3 shadow border rounded text-xs mt-10">
              <CheckBadgeIcon
                className="h-7 w-7 text-blue-500"
                aria-hidden="true"
              />
              <p>{i18next.t("offer.sampleVerified")}</p>
            </div>
          )}
          {materialOffer?.state === SAMPLE_STATE.REJECTED && (
            <div className="p-2 flex items-center gap-3 shadow border rounded text-xs mt-10">
              <XCircleIcon
                className="h-7 w-7 text-red-500"
                aria-hidden="true"
              />
              <p>{i18next.t("offer.sampleRejected")}</p>
            </div>
          )}
        </div>
        {offer?.order?.state === OrderState.CANCELLED && (
          <p className="text-sm font-bold text-red-800 uppercase py-2">
            {i18next.t("tenders.data.cancelledMessage")} {offer?.order?.comment}
          </p>
        )}
      </div>
      <Slide open={openDelegate} setOpen={setOpenDelegate}>
        <Delegate
          onCloseDelegate={handleCloseDelegate}
          isDelegateMaterialOffer
          materialOffer={materialOffer}
        />
      </Slide>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IsSellerOffer);
