import { Link } from "react-router-dom";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { SiteResponse } from "../../../types";

const SitesTableRow = ({ site }: { site: SiteResponse }) => {
  return (
    <tr key={site.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        {site.internalCode}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs font-semibold uppercase">
        <div className="flex items-center">
          <Link to={`/site/${site.id}`}>{site.name}</Link>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        {site.address}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        {site.comment ?? " - "}
      </td>
      <td className="flex-shrink-0 pr-2">
        <Link
          to={`/site/${site.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default SitesTableRow;
