import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios-orders";
import { queryKeys } from "./queryKeys";

const onCreateChat = async (body: any) => {
  const response = await axios.post(`/chat`, body);
  return response.data;
};

export const useCreateChat = (invalidateKeys: string[]) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onCreateChat,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: invalidateKeys }),
  });
};

const onCreateMessage = async (body: any) => {
  const response = await axios.post(`/chat/message`, body);
  return response.data;
};

export const useCreateMessage = (invalidateKeys: string[]) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onCreateMessage,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: invalidateKeys }),
  });
};

const onGetChats = async (type: string) => {
  const response = await axios.get(`/chat/${type}`);
  return response.data;
};

export const useChats = (isBuy: boolean) =>
  useQuery({
    queryKey: [queryKeys.CHATS],
    queryFn: () => {
      let type = "buyer";
      if (!isBuy) type = "seller";
      return onGetChats(type);
    },
  });

const onGetOrderChat = async (orderId: string) => {
  const response = await axios.get(`/chat/order/${orderId}`);
  return response.data;
};

export const useOrderChat = (orderId: string) =>
  useQuery({
    queryKey: [queryKeys.CHAT, orderId],
    queryFn: () => onGetOrderChat(orderId),
    enabled: !!orderId,
    retry: false,
  });

const onGetMultipleOrderChat = async (orderId: string) => {
  const response = await axios.get(`/chat/multipleOrder/${orderId}`);
  return response.data;
};

export const useMultipleOrderChat = (multipleOrderId: string) =>
  useQuery({
    queryKey: [queryKeys.MULTIPLE_ORDER_CHAT, multipleOrderId],
    queryFn: () => onGetMultipleOrderChat(multipleOrderId),
    enabled: !!multipleOrderId,
    retry: false,
  });

const onGetChat = async (userId: string, materialId: string) => {
  const response = await axios.get(`/chat/${userId}/${materialId}`);
  return response.data;
};

export const useChat = (userId: string, materialId: string, isOpen?: boolean) =>
  useQuery({
    queryKey: [queryKeys.CHAT, isOpen, userId, materialId],
    queryFn: () => onGetChat(userId, materialId),
    enabled: !!userId && !!materialId && isOpen,
    retry: false,
  });
