import { WHATSAPP } from "../../utils";
import { useTranslation } from "react-i18next";

export default function CategorySearch({ isError }: { isError: boolean }) {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <p className="pb-2 text-sm font-semibold">
        {t("newMaterialForm.help")}{" "}
        <a
          href={WHATSAPP}
          target="_blank"
          rel="noreferrer"
          className="font-bold text-spectum-light"
        >
          {t("newMaterialForm.support")}
        </a>
        .
      </p>
      {isError && (
        <p className="pt-2 text-xs text-red-500">
          {t("newMaterialForm.notFound")}
        </p>
      )}
    </div>
  );
}
