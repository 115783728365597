import { useTranslation } from "react-i18next";

export default function Error() {
  const { t } = useTranslation();
  return (
    <div className="bg-white rounded p-3 text-red-600 mt-2">
      <p>{t("register.form.error")}</p>
    </div>
  );
}
