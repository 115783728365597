import { Fragment, useState } from "react";
import { CheckCircleIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
  newOfferValidationSchema,
  NO_SPEC,
  TO_BE_AGREED,
  IT_DOESNT_HAVE_SPEC,
  BIDDING_TYPES_LABELS,
  BIDDING_TYPES,
  FileType,
  PN_TYPE,
  formatDate,
} from "../../utils";
import {
  GoBackArrow,
  Loader,
  Modal,
  NewOfferForm,
  InfoItem,
  Slide,
  Chat,
} from "../../components";
import { useID, useFileInputUploader } from "../../hooks";
import { FileResponse, UserResponse } from "../../types";
import { useOrder } from "../../hooks/useOrders";
import {
  useCreateChat,
  useCreateMessage,
  useOrderChat,
} from "../../hooks/useChat";
import { useCreateOffer, useUserOffer } from "../../hooks/useOffers";
import { queryKeys } from "../../hooks/queryKeys";

type IsSellerBidProps = {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
};

const IsSellerBid = ({ user, onOpenNotification }: IsSellerBidProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useID();
  const {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  } = useFileInputUploader();
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [openChat, setOpenChat] = useState(false);

  const { data: order, isLoading: isLoadingOrders } = useOrder(id);

  const { data: offer, isLoading: isLoadingOffers } = useUserOffer(
    id,
    user?.id
  );

  const { data: chat, isLoading: isLoadingChat } = useOrderChat(id);

  const { mutate: mutateChat, isPending: isLoadingCreateChat } = useCreateChat([
    queryKeys.CHAT,
  ]);

  const { mutate: mutateMessage, isPending: isLoadingMessage } =
    useCreateMessage([queryKeys.CHAT]);

  const { mutate: mutateOffer, isPending: isSendingOffer } = useCreateOffer(
    (res: any) => {
      onOpenNotification(t("tenders.seller.offerSent"));
      return navigate(`/offer/${res.id}`);
    },
    () => onOpenNotification(t("newMaterialForm.error"))
  );

  const formValidationNeeded = newOfferValidationSchema(
    order?.type,
    order?.initialPrice
  );

  const formOptions = {
    resolver: yupResolver(formValidationNeeded),
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  const handleSpecification = (e: any) => {
    const file = e?.target?.files;
    if (file) {
      handleSpecificationUpload(file);
      setFileName(file[0]?.name);
    }
  };

  const onSubmit = (data: any) => {
    mutateOffer({
      ...data,
      order: { id: order?.id },
      user: { id: user?.id },
      oldPrice: parseFloat(data?.price),
      price: parseFloat(data?.price),
      fileURL: uploadedSpecificationFileUrl
        ? uploadedSpecificationFileUrl
        : NO_SPEC,
    });
    reset();
    setOpen(false);
  };
  const onError = () => setOpen(false);

  const isSpec = NO_SPEC === order?.material?.fileURL;

  if (offer && offer.length > 0) navigate(`/offer/${offer[0].id}`);

  return (
    <Fragment>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={t("tenders.seller.offer")}
        message={t("tenders.seller.question")}
        onClickSuccess={handleSubmit(onSubmit, onError)}
        onClickCancel={() => setOpen(false)}
      />
      {isLoadingOrders || isLoadingOffers || isSendingOffer ? (
        <Loader isFullScreen />
      ) : (
        <div className="min-h-full flex">
          <div className="flex flex-col w-0 flex-1">
            <div className="py-8 xl:py-10 relative">
              <div className="flex justify-between items-center pr-4 lg:pr-8">
                <GoBackArrow stylesAdded={"px-8"} />
                <button
                  onClick={() => setOpenChat(true)}
                  className="uppercase flex items-center gap-2 px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                  {t("chat.title")}
                  {chat?.messages?.length > 0 && (
                    <span className="text-white border border-blue-600 bg-blue-600 px-1 rounded-full">
                      {chat?.messages?.length}
                    </span>
                  )}
                </button>
              </div>
              <div className="mx-auto px-4 lg:px-8 lg:grid lg:grid-cols-2 gap-4">
                <div className="col-span-2 lg:col-span-1">
                  <div className="md:flex md:items-center md:justify-between md:space-x-4 border-b pb-4">
                    <div className="w-full">
                      <div className="flex items-center justify-between">
                        <h1 className="text-2xl font-bold text-gray-900 uppercase">
                          {order?.material?.globalMaterialName}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <InfoItem
                    name={t("tenders.form.company")}
                    description={
                      <span className="text-spectum font-bold uppercase">
                        {order?.user?.company?.name}
                      </span>
                    }
                  />
                  <InfoItem
                    name={t("tenders.form.fullName")}
                    description={order?.user?.fullName}
                  />
                  <InfoItem
                    name={t("tenders.form.email")}
                    description={order?.user?.email}
                  />
                  <InfoItem
                    name={t("tenders.form.phone")}
                    description={order?.user?.phone}
                  />
                  <InfoItem
                    name={t("tenders.form.tenderType")}
                    description={
                      order?.type === BIDDING_TYPES.INVERSE
                        ? BIDDING_TYPES_LABELS.INVERSE
                        : order?.type
                    }
                  />
                  {order?.finishDate && (
                    <InfoItem
                      name={t("tenders.form.end")}
                      description={formatDate(order.finishDate)}
                    />
                  )}
                  {order?.material?.type === PN_TYPE.SERVICE && (
                    <>
                      <InfoItem
                        name={t("tenders.form.purpose")}
                        description={order?.purpose}
                      />
                      {order?.application && (
                        <InfoItem
                          name={t("newMaterialForm.brand")}
                          description={order?.application}
                        />
                      )}
                    </>
                  )}
                  {order?.comment && (
                    <InfoItem
                      name={t("newMaterialForm.comments")}
                      description={order?.comment}
                    />
                  )}
                  {order?.type === BIDDING_TYPES.INVERSE && (
                    <Fragment>
                      <InfoItem
                        name={t("tenders.form.maximumInitialPrice")}
                        description={`${order?.initialPrice} ${order?.currency}`}
                      />
                      <InfoItem
                        name={t("tenders.form.minimumDecrement")}
                        description={`${order?.minimumDecrement} %`}
                      />
                    </Fragment>
                  )}
                  <InfoItem
                    name={
                      order?.material?.type === PN_TYPE.SERVICE
                        ? t("newMaterialForm.measurementUnit")
                        : t("tenders.form.volume")
                    }
                    description={`${order?.quantity ?? ""} ${
                      order?.measurementUnit
                    }`}
                  />
                  {order?.material?.incoTerm && (
                    <InfoItem
                      name={t("tenders.form.incoTerm")}
                      description={order?.material?.incoTerm}
                    />
                  )}
                  {order?.material?.deliveryFormat && (
                    <InfoItem
                      name={t("tenders.form.deliveryFormat")}
                      description={order?.material?.deliveryFormat}
                    />
                  )}
                  <InfoItem
                    name={t("tenders.form.deliveryPlace")}
                    description={
                      order?.deliveryPlace
                        ? order?.deliveryPlace?.address
                        : TO_BE_AGREED
                    }
                  />
                  {order && (
                    <>
                      {order?.startSupplyDate === order?.endSupplyDate ? (
                        <InfoItem
                          name={t("tenders.form.deliveryDate")}
                          description={formatDate(order?.startSupplyDate)}
                        />
                      ) : (
                        <InfoItem
                          name={t("tenders.form.tenderPeriod")}
                          description={`${formatDate(order?.startSupplyDate)} ${t(
                            "tenders.to"
                          )} ${formatDate(order?.endSupplyDate)}`}
                        />
                      )}
                    </>
                  )}
                  <InfoItem
                    name={t("tenders.form.paymentMethod")}
                    description={order?.paymentMethod}
                  />
                  <InfoItem
                    name={t("tenders.form.paymentTerm")}
                    description={`${order?.paymentTerm} ${
                      order?.paymentTerm === 1
                        ? ` ${t("opportunities.table.day")}`
                        : ` ${t("opportunities.table.days")}`
                    }`}
                  />
                  {isSpec ? (
                    <InfoItem
                      name={t("newMaterialForm.specification")}
                      description={IT_DOESNT_HAVE_SPEC}
                    />
                  ) : (
                    <InfoItem
                      name={t("newMaterialForm.specification")}
                      description={
                        <Fragment>
                          {isSpec ? (
                            IT_DOESNT_HAVE_SPEC
                          ) : (
                            <a
                              href={order?.material?.fileURL}
                              target="_blank"
                              rel="noreferrer"
                              className="inline-flex items-center px-2 py-1 border border-transparent shadow-xs text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                            >
                              <ArrowDownTrayIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              {t("cta.download")}
                            </a>
                          )}
                        </Fragment>
                      }
                    />
                  )}
                  {order && order?.files?.length > 0 && (
                    <div className="bg-white overflow-hidden">
                      {order.files.map((file: FileResponse) => (
                        <div key={file.fileURL} className="py-2 sm:p-0">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
                              <dt className="uppercase text-xs font-medium text-gray-500">
                                {file.type === FileType.ATTACHMENT &&
                                  t("tenders.form.additionalDocument")}
                                {file.type === FileType.SHEET &&
                                  t("tenders.form.plication")}
                                {file.type === FileType.CERTIFICATION &&
                                  t("tenders.form.certificate")}
                                {file.type === FileType.BRIEF &&
                                  t("tenders.form.brief")}
                                {file.type === FileType.SERVICE_PLACE &&
                                  t("tenders.form.servicePlace")}
                              </dt>
                              <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                                <a
                                  href={file.fileURL}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="w-200 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                                >
                                  <ArrowDownTrayIcon
                                    className="-ml-0.5 mr-2 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  {file.type === FileType.ATTACHMENT &&
                                    t("tenders.form.additionalDocument")}
                                  {file.type === FileType.SHEET &&
                                    t("tenders.form.plication")}
                                  {file.type === FileType.CERTIFICATION &&
                                    t("tenders.form.certificate")}
                                  {file.type === FileType.BRIEF &&
                                    t("tenders.form.brief")}
                                  {file.type === FileType.SERVICE_PLACE &&
                                    t("tenders.form.servicePlace")}
                                </a>
                              </dd>
                            </div>
                          </dl>
                        </div>
                      ))}
                    </div>
                  )}
                  {order?.plicationUrl && order?.plicationUrl !== NO_SPEC && (
                    <InfoItem
                      name={t("newMaterialForm.plication")}
                      description={
                        <a
                          href={order?.plicationUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="inline-flex items-center px-2 py-1 border border-transparent shadow-xs text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                        >
                          <ArrowDownTrayIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          {t("cta.plication")}
                        </a>
                      }
                    />
                  )}
                </div>
                {!isLoadingOffers && order && (
                  <div className="col-span-2 lg:col-span-1">
                    <NewOfferForm
                      quantity={order?.quantity}
                      control={control}
                      currency={order?.currency}
                      errors={errors}
                      state={order?.state}
                      setOpen={setOpen}
                      register={register}
                      orderType={order?.type}
                      initialPrice={order?.initialPrice}
                      decrement={order?.minimumDecrement}
                      measurementUnit={order?.measurementUnit}
                      isLoadingUpload={isLoadingUpload}
                      handleSpecification={handleSpecification}
                      fileName={fileName}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Slide open={openChat} setOpen={setOpenChat}>
        {order && (
          <Chat
            isOrder
            chat={chat}
            order={order}
            isLoadingChat={
              isLoadingChat || isLoadingCreateChat || isLoadingMessage
            }
            onCreateChat={mutateChat}
            onCreateMessage={mutateMessage}
          />
        )}
      </Slide>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IsSellerBid);
