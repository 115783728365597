import {
  ChatBubbleOvalLeftEllipsisIcon,
  InformationCircleIcon,
  // TagIcon,
} from "@heroicons/react/24/outline";
import i18next from "../../i18n";
import { TooltipSP } from "..";
import {
  MaterialOfferResponse,
  MaterialCompaniesOpportunitiesResponse,
} from "../../types";

type MaterialOfferItemProps = {
  materialOffer?: MaterialOfferResponse;
  potencialSupplier?: MaterialCompaniesOpportunitiesResponse;
  handleItemClick: () => void;
  handleChatClick?: () => void;
};

const MaterialOfferItem = ({
  materialOffer,
  handleItemClick,
  handleChatClick,
  potencialSupplier,
}: MaterialOfferItemProps) => {
  return (
    <li>
      <div>
        <div className="flex items-center px-2 py-3">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div className="flex justify-between items-center">
                <p className="flex items-center text-xxs">
                  <span className="truncate uppercase text-spectum-dark font-bold">
                    {potencialSupplier
                      ? potencialSupplier?.user?.company?.name
                      : materialOffer?.user?.company?.name}
                  </span>
                </p>
              </div>
            </div>
            {potencialSupplier?.invited && (
              <span className="px-2 inline-flex text-xxs leading-5 font-semibold rounded bg-green-100 text-green-800 mr-10 uppercase">
                {i18next.t("offer.invited")}
              </span>
            )}
          </div>
          <div className="flex items-center gap-6">
            <div
              onClick={handleItemClick}
              className="p-1 rounded-full border font-bold border-gray-700 text-white bg-spectum cursor-pointer"
              id={`${materialOffer?.user?.company?.name}-info`}
            >
              <InformationCircleIcon className="h-5 w-5 " />
              <TooltipSP
                id={`${materialOffer?.user?.company?.name}-info`}
                message={i18next.t("offer.information")}
              />
            </div>
            {!potencialSupplier && (
              <div
                onClick={handleChatClick}
                id={`${materialOffer?.user?.company?.name}-chat`}
                className="p-1 rounded-full border font-bold border-gray-700 text-white bg-spectum cursor-pointer"
              >
                <ChatBubbleOvalLeftEllipsisIcon className="h-5 w-5" />
                <TooltipSP
                  id={`${materialOffer?.user?.company?.name}-chat`}
                  message={i18next.t("offer.chat")}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default MaterialOfferItem;
