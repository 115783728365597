import { Fragment } from "react";
import i18next from "../../../i18n";
import {
  OpportunitiesTable,
  Loader,
  JointOrdersTable,
} from "../../../components";
import { ORDER_TYPES } from "../../../types";
import {
  useMultipleOpportunities,
  useOpportunities,
} from "../../../hooks/useOpportunities";

export default function Seller() {
  const opportunities = useOpportunities();

  const multipleOpportunities = useMultipleOpportunities(
    ORDER_TYPES.JOINT,
    ORDER_TYPES.INVERSE_JOINT
  );

  return (
    <Fragment>
      {opportunities?.isLoading ? (
        <div className="py-2">
          <Loader />
        </div>
      ) : (
        <Fragment>
          {opportunities?.data?.length > 0 && (
            <OpportunitiesTable
              title={i18next.t("material.tenders")}
              opportunities={opportunities?.data}
            />
          )}
          {multipleOpportunities.data &&
            multipleOpportunities.data.length > 0 && (
              <JointOrdersTable
                orders={multipleOpportunities.data}
                title={i18next.t("order.joint")}
              />
            )}
        </Fragment>
      )}
    </Fragment>
  );
}
