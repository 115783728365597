import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../utils";

type MobileCardProps = {
  item: any;
  route: string;
};

const MobileCard = ({ item, route }: MobileCardProps) => {
  return (
    <li key={item?.material?.id}>
      <Link
        to={`/${route}/${item?.material?.id}`}
        className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6"
      >
        <span className="flex items-center truncate space-x-3">
          <span
            className={classNames("w-2.5 h-2.5 flex-shrink-0 rounded-full")}
            aria-hidden="true"
          />
          <span className="font-medium truncate text-xs leading-6">
            {item?.material?.globalMaterialName}
          </span>
        </span>
        <ChevronRightIcon
          className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      </Link>
    </li>
  );
};

export default MobileCard;
