export enum CertificationsEnum {
  QUALITY_AND_MANAGEMENT = "QUALITY_AND_MANAGEMENT",
  ENVIRONMENTAL = "ENVIRONMENTAL",
  HEALTH_AND_SAFETY = "HEALTH_AND_SAFETY",
  ENERGY_AND_EFFICIENCY = "ENERGY_AND_EFFICIENCY",
  TECHNOLOGY_AND_ELECTRONICS = "TECHNOLOGY_AND_ELECTRONICS",
  CHEMICALS_AND_HAZARDOUS_MATERIALS = "CHEMICALS_AND_HAZARDOUS_MATERIALS",
  SOCIAL_AND_FAIR_TRADE = "SOCIAL_AND_FAIR_TRADE",
  SPECIFIC_AND_NICHE_PRODUCTS = "SPECIFIC_AND_NICHE_PRODUCTS",
  PRODUCT_SAFETY = "PRODUCT_SAFETY",
}

export interface CertificationResponse {
  createdAt: string;
  id: string;
  name: string;
  required: boolean;
  type: CertificationsEnum;
  updatedAt: string;
}

export interface MaterialCertificationResponse {
  createdAt: string;
  updatedAt: string;
  id: string;
  materialCertification: CertificationResponse;
  valid: boolean;
}

export interface CertificationRequest {
  label: string;
  name: string;
  required: boolean;
  type: CertificationsEnum;
}
