import { connect } from "react-redux";
import { TableTitle, Pagination } from "..";
import i18next from "../../i18n";
import PurchaseRequestsTableRow from "./PurchaseRequestsTableRow/PurchaseRequestsTableRow";
import { mapStateToProps } from "../../utils";
import {
  MultiplePurchaseRequestResponse,
  PurchaseRequestPaginatedResponse,
  MultiplePurchaseRequestPaginatedResponse,
} from "../../types";

type PurchaseRequestsTableProps = {
  title?: string;
  pageNumber: number;
  isRequester: boolean;
  setPageNumber: (pageNumber: number) => void;
  purchaseRequests?: PurchaseRequestPaginatedResponse;
  multiplePurchaseRequests?: MultiplePurchaseRequestPaginatedResponse;
};

const PurchaseRequestsTable = ({
  title,
  pageNumber,
  isRequester,
  setPageNumber,
  purchaseRequests,
  multiplePurchaseRequests,
}: PurchaseRequestsTableProps) => {
  const tableTh = [
    { id: 1, name: i18next.t("tenders.tenderSummary.number") },
    { id: 2, name: i18next.t("tenders.tenderSummary.name") },
    { id: 3, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
    {
      id: 4,
      name: isRequester
        ? i18next.t("tenders.tenderSummary.responsible")
        : i18next.t("userTypes.requester"),
    },
    { id: 5, name: i18next.t("purchaseRequests.createdAt") },
    { id: 6, name: i18next.t("tenders.tenderSummary.status") },
    { id: 7, name: "" },
  ];
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        {title && <TableTitle title={title} />}
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {tableTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xxs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {purchaseRequests &&
                purchaseRequests.content?.map((ord) => (
                  <PurchaseRequestsTableRow
                    key={ord.order.id}
                    order={ord.order}
                    requester={ord.user}
                    purchaseRequestId={ord.id}
                  />
                ))}
              {multiplePurchaseRequests &&
                multiplePurchaseRequests.content?.map(
                  (ord: MultiplePurchaseRequestResponse) => (
                    <PurchaseRequestsTableRow
                      key={ord.purchaseRequest.id}
                      order={ord.purchaseRequest.order}
                      requester={ord.purchaseRequest?.user}
                      multiplePurchaseRequestId={ord.purchaseRequest.id}
                    />
                  )
                )}
            </tbody>
          </table>
          {purchaseRequests && (
            <Pagination
              results={purchaseRequests?.numberOfElements}
              total={purchaseRequests?.totalElements}
              amountOfPages={purchaseRequests?.totalPages}
              currentPage={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
          {multiplePurchaseRequests && (
            <Pagination
              results={multiplePurchaseRequests?.numberOfElements}
              total={multiplePurchaseRequests?.totalElements}
              amountOfPages={multiplePurchaseRequests?.totalPages}
              currentPage={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(PurchaseRequestsTable);
