import { useTranslation } from "react-i18next";
import { OfferItem } from "../../components";
import { OfferResponse, OrderState } from "../../types";

type OffersOnOrdersProps = {
  offOnOrd: any;
  handleItemClick: (e: any, offer: any) => void;
  state: string;
  isCompliant: boolean;
};

export default function OffersOnOrders({
  offOnOrd,
  handleItemClick,
  state,
  isCompliant,
}: OffersOnOrdersProps) {
  const { t } = useTranslation();
  return (
    <div className="pt-2">
      {offOnOrd?.length > 0 ? (
        <>
          <div className="bg-white shadow overflow-hidden sm:rounded">
            <ul className="divide-y divide-gray-200">
              {offOnOrd
                ?.sort(
                  (a: OfferResponse, b: OfferResponse) => a.price - b.price
                )
                .map((offer: OfferResponse) => (
                  <OfferItem
                    key={offer.id}
                    offer={offer}
                    handleItemClick={(e: any) => handleItemClick(e, offer)}
                  />
                ))}
            </ul>
          </div>
          {state === OrderState.OPEN && isCompliant && (
            <p className="text-xs text-spectum uppercase font-bold mt-6">
              {t("tenders.data.visualize")}
            </p>
          )}
        </>
      ) : (
        <p className="text-xs font-bold text-black rounded py-2 mb-4">
          {t("tenders.data.noOffers")}.
        </p>
      )}
    </div>
  );
}
