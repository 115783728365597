import { USER_COMPANY_TYPES, USER_TYPES } from "./enums";

export const mapStateToProps = (state: any) => {
  return {
    // auth
    userId: state.auth?.userId,
    isAuthenticated: state.auth?.isAuthenticated,
    authSignInError: state.auth?.error,
    isLoading: state.auth?.isLoading,

    // user
    user: state.user?.user?.user,
    isLoadingUser: state.user?.isLoading,
    isSeller: state.user?.user?.user?.type === USER_TYPES.SELLER,
    isBuy: state.user?.user?.user?.type === USER_TYPES.BUYER,
    isNew: state.user?.user?.user?.role === USER_TYPES.NEW,
    isResetPassword: state.user?.user?.user?.role === USER_TYPES.RESET_PASSWORD,
    isPending: state.user?.user?.user?.role === USER_TYPES.PENDING,
    isBlocked: state.user?.user?.user?.role === USER_TYPES.BLOCKED,
    isPendingAdminApproval:
      state.user?.user?.user?.role === USER_TYPES.ADMIN_APPROVAL,
    isCompliant: state.user?.user?.user?.compliant,
    isError: state.user?.error,
    isCompanyList: state?.user?.user?.companyList,
    isChangingCompany: state?.user?.isLoading,
    changeCompanyStatus: state?.user?.changeCompany,
    changeUserLanguage: state?.user?.changeUserLanguage,

    // userCompanyType
    isAdmin:
      state.user?.user?.user?.userCompanyType === USER_COMPANY_TYPES.ADMIN,
    isBuyer:
      state.user?.user?.user?.userCompanyType === USER_COMPANY_TYPES.BUYER,
    isManager:
      state.user?.user?.user?.userCompanyType === USER_COMPANY_TYPES.MANAGER,
    isAnalyst:
      state.user?.user?.user?.userCompanyType === USER_COMPANY_TYPES.ANALYST,
    isDefault:
      state.user?.user?.user?.userCompanyType === USER_COMPANY_TYPES.DEFAULT,
    isVisualizer:
      state.user?.user?.user?.userCompanyType === USER_COMPANY_TYPES.VISUALIZER,
    isCommercial:
      state.user?.user?.user?.userCompanyType === USER_COMPANY_TYPES.COMMERCIAL,
    isRequester:
      state.user?.user?.user?.userCompanyType === USER_COMPANY_TYPES.REQUESTER,

    // notifications
    isOpen: state.notification?.isOpen,
    message: state.notification?.message,

    // materialVolume
    volumePrice: state.volumePrice?.items,

    // banner
    isOpenBanner: state.banner?.isOpen,
    bannerMessage: state.banner?.message,

    // invitations
    orderInvitation: state.orderInvitation?.invites,
  };
};
