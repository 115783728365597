import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

type ErrorPillProps = {
  list?: {
    error: boolean;
    email: string;
  }[];
};

export default function ErrorPill({ list = [] }: ErrorPillProps) {
  const { t } = useTranslation();
  return (
    <>
      {list.find((li) => !li.error) ? (
        <div className="rounded-md bg-green-50 p-4 mb-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-xs font-medium text-green-800">
                {t("invite.successInvite")}
              </h3>
              {list.length > 0 ? (
                <div className="mt-2 text-xs text-green-700">
                  <ul className="list-disc space-y-1 pl-5">
                    {list
                      .filter((li) => !li.error)
                      .map((item) => (
                        <li key={item.email}>{item.email}</li>
                      ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {list.find((li) => li.error) ? (
        <div className="rounded-md bg-red-50 p-3 text-xs mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="font-medium text-red-800">{t("invite.error")}</h3>
              {list.length > 0 ? (
                <div className="mt-2 text-xs text-red-700">
                  <ul className="list-disc space-y-1 pl-5">
                    {list
                      .filter((li) => li.error)
                      .map((item) => (
                        <li key={item.email}>{item.email}</li>
                      ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
