const FormattedText = ({ text }: { text: string }) => {
  if (!text) return <div />;
  const formattedText = text
    .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
    .replace(/\n/g, "<br />");

  return (
    <div
      dangerouslySetInnerHTML={{ __html: formattedText }}
      className="text-xs my-2"
    />
  );
};

export default FormattedText;
