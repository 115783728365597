import { Fragment } from "react";
import { Link } from "react-router-dom";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { Pill } from "../..";
import {
  currencyFormatter,
  PILL_LABELS,
  BIDDING_TYPES,
  BIDDING_TYPES_LABELS,
} from "../../../utils";
import { OfferResponse, OrderState } from "../../../types";

type OfferTableRowProps = {
  offer: OfferResponse;
  position: number;
};

const OfferTableRow = ({ offer, position }: OfferTableRowProps) => {
  const currency = currencyFormatter(offer.price, offer.order?.currency);

  return (
    <tr key={offer.id}>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-900 uppercase font-semibold">
        <div className="flex items-center">
          <Link
            to={`/offer/${offer.id}`}
            className="truncate hover:text-gray-600 uppercase"
          >
            <span>{offer.order?.name}</span>
          </Link>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        {offer.order?.user?.company?.name}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        {currency} / {offer?.order?.measurementUnit}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        {offer.order?.type === BIDDING_TYPES.INVERSE
          ? `${position + 1}°`
          : " - "}
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        {offer.order.active ? (
          <Fragment>
            {offer.order.state === OrderState.OPEN && (
              <Pill label={PILL_LABELS.IN_PROGRESS} />
            )}
            {offer.order.state === OrderState.CLOSED && (
              <Pill label={PILL_LABELS.OFFER_PENDING_OF_SELECTION} />
            )}
            {offer.order.state === OrderState.SUCCEED && !offer.selected && (
              <Pill label={PILL_LABELS.OFFER_NOT_SELECTED} />
            )}
            {offer.order.state === OrderState.SUCCEED && offer.selected && (
              <Pill label={PILL_LABELS.OFFER_SELECTED} />
            )}
            {offer.order.state === OrderState.CANCELLED && (
              <Pill label={PILL_LABELS.BIDDING_CANCELED} />
            )}
          </Fragment>
        ) : (
          <Pill label={PILL_LABELS.BIDDING_CANCELED} />
        )}
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          <span
            className={`text-xxs font-bold ${
              offer.order.type === BIDDING_TYPES.INVERSE
                ? "text-spectum"
                : "text-green-600"
            }`}
          >
            {offer.order?.type === BIDDING_TYPES.INVERSE
              ? BIDDING_TYPES_LABELS.INVERSE
              : offer.order.type}
          </span>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        <Link
          to={`/offer/${offer.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default OfferTableRow;
