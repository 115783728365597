import { useState, useEffect } from "react";

export default function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<string>(value);
  const [loadSearch, setLoadSearch] = useState<boolean>(false);

  useEffect(() => {
    setLoadSearch(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setLoadSearch(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return { debouncedSearchTerm: debouncedValue, isSearching: loadSearch };
}
