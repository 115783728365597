import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

type SearchProps = {
  value: string;
  border?: boolean;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
};

const Search = ({
  value,
  border,
  handleSearch,
  label = "cta.search",
}: SearchProps) => {
  const { t } = useTranslation();
  const searchBarStyles = "border rounded";
  return (
    <div className={`w-full flex-1 ${border && searchBarStyles} mt-2 mb-2`}>
      <div className="relative text-gray-400 focus-within:text-gray-500">
        <label htmlFor="desktop-search" className="sr-only">
          {label}
        </label>
        <input
          onChange={handleSearch}
          id="desktop-search"
          type="search"
          placeholder={t(label)}
          value={value}
          className="block text-sm w-full pl-12 placeholder-gray-500 focus:ring-0 border-0 rounded-2xl"
        />
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
          <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
};

export default Search;
