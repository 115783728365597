import { Fragment } from "react";

type PriceVolumeSuppliersProps = {
  event: any;
  month: string;
  isActual?: boolean;
};

export default function PriceVolumeSuppliers({
  event,
  month,
  isActual,
}: PriceVolumeSuppliersProps) {
  return (
    <Fragment>
      <span className="font-bold text-white pt-0.5 bg-gray-100 w-full"></span>
      {event.suppliers?.length > 0 ? (
        <div
          className={`font-bold text-white text-center ${
            isActual ? "bg-spectum" : "bg-spectum-light"
          } w-full p-1`}
        >
          {month}
        </div>
      ) : null}
      {event.suppliers
        ?.sort((a: any, b: any) =>
          a.supplier.company.name.localeCompare(b.supplier.company.name)
        )
        ?.map((supplier: any) => (
          <div
            key={supplier.supplier.id}
            className={`${supplier.closed ? "bg-spectum-green" : "bg-white"}`}
          >
            <div className="w-80 border-b p-1 text-center">
              <span
                className={`text-xs w-full border-none p-0 text-center without-arrows ${
                  supplier.closed ? "bg-spectum-green" : "bg-white"
                }`}
              >
                {supplier.vol?.toFixed(1)}
              </span>
            </div>
            <div className="w-80 border-b p-1 text-center">
              <span
                className={`text-xs w-full border-none p-0 text-center without-arrows ${
                  supplier.closed ? "bg-spectum-green" : "bg-white"
                }`}
              >
                {supplier.price?.toFixed(1)}
              </span>
            </div>
          </div>
        ))
        .sort()}
    </Fragment>
  );
}
