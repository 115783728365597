import { useState } from "react";
import { OrdersTable, Loader } from "../../../components";
import { ORDER_TYPES, OrderState } from "../../../types";
import { useOrdersByTypeAndState } from "../../../hooks/useOrders";

export const Succeed = () => {
  const [succeedPageNumber, setSucceedPageNumber] = useState(0);

  const orders = useOrdersByTypeAndState(
    OrderState.SUCCEED,
    ORDER_TYPES.NORMAL,
    ORDER_TYPES.INVERSE,
    succeedPageNumber
  );

  return (
    <>
      {orders.isLoading ? (
        <Loader />
      ) : (
        <>
          {orders.data && orders.data?.content?.length > 0 && (
            <OrdersTable
              orders={orders.data}
              pageNumber={succeedPageNumber}
              setPageNumber={setSucceedPageNumber}
            />
          )}
        </>
      )}
    </>
  );
};
