import { useParams } from "react-router-dom";

export default function useID() {
  const { id } = useParams();

  if (!id) {
    throw new Error("El ID no existe!");
  }

  return id;
}
