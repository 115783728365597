import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export default function Success({ signUpResponse }: { signUpResponse: any }) {
  const { t } = useTranslation();
  const isRedirect = new URLSearchParams(useLocation().search).get(
    "redirectURL"
  );
  return (
    <div className="w-full bg-white rounded p-10 mt-6">
      <CheckCircleIcon className="block mx-auto h-14 w-14 text-green-400 mb-4" />
      <div className="w-full">
        <p className="font-bold text-center">
          {t("register.form.hi")} {signUpResponse?.data?.fullName}!{" "}
          {t("register.form.welcome")}
        </p>
      </div>
      <p className="mx-auto mt-8 text-center">
        <strong>{t("register.form.apply")}</strong>
      </p>
      <Link
        to={isRedirect ? `/?redirectURL=${isRedirect}` : "/"}
        className="w-max mt-4 mx-auto gap-4 border-transparent flex justify-center rounded-md border py-2 px-4 text-sm font-medium uppercase shadow-sm bg-spectum text-white hover:bg-spectum-light"
      >
        {t("register.form.login")} <span aria-hidden="true">&rarr;</span>
      </Link>
    </div>
  );
}
