import * as actionTypes from "./actionTypes";

export const openBanner = (message: string) => {
  return {
    type: actionTypes.OPEN_BANNER,
    message,
  };
};

export const closeBanner = () => {
  return {
    type: actionTypes.CLOSE_BANNER,
  };
};
