import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";

export const getUserStart = () => {
  return {
    type: actionTypes.GET_USER_START,
  };
};

export const getUserSuccess = (user: any) => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    user,
  };
};

export const getUserFailed = (error: any) => {
  return {
    type: actionTypes.GET_USER_FAILED,
    error,
  };
};

export const changeUserLanguageStart = () => {
  return {
    type: actionTypes.CHANGE_USER_LANGUAGE_START,
  };
};

export const changeUserLanguageSuccess = (user: any) => {
  return {
    type: actionTypes.CHANGE_USER_LANGUAGE_SUCCESS,
    user,
  };
};

export const changeUserLanguageFailed = (error: any) => {
  return {
    type: actionTypes.CHANGE_USER_LANGUAGE_FAILED,
    error,
  };
};

export const changeCompanyStart = () => {
  return {
    type: actionTypes.CHANGE_COMPANY_START,
  };
};

export const changeCompanySuccess = (user: any) => {
  return {
    type: actionTypes.CHANGE_COMPANY_SUCCESS,
    user,
  };
};

export const changeCompanyFailed = (error: any) => {
  return {
    type: actionTypes.CHANGE_COMPANY_FAILED,
    error,
  };
};

export const getUser = () => {
  return (dispatch: any) => {
    dispatch(getUserStart());
    axios
      .get(`/user/me`)
      .then((res) => {
        const resData = res.data;
        if (resData.errors) {
          return dispatch(getUserFailed(resData.errors));
        }
        dispatch(getUserSuccess(resData));
      })
      .catch((err) => dispatch(getUserFailed(err)));
  };
};

export const changeUserLanguage = (userId: string, language: string) => {
  return (dispatch: any) => {
    dispatch(changeUserLanguageStart());
    return axios
      .put(`/user/${userId}/${language}`)
      .then((res) => {
        const resData: any = res;
        if (resData.errors) {
          dispatch(changeUserLanguageFailed(resData.errors));
        } else {
          dispatch(changeUserLanguageSuccess(resData));
        }
        return resData;
      })
      .catch((err) => {
        dispatch(changeUserLanguageFailed(err));
        // Rethrow the error to propagate it to the caller
        throw err;
      });
  };
};

export const changeCompany = (companyId: string) => {
  return (dispatch: any) => {
    dispatch(changeCompanyStart());
    axios
      .get(`/user/company/${companyId}`)
      .then((res) => {
        const resData: any = res;
        if (resData.data.errors) {
          return dispatch(changeCompanyFailed(resData.data.errors));
        }
        dispatch(changeCompanySuccess(resData));
      })
      .catch((err) => dispatch(changeCompanyFailed(err)));
  };
};
