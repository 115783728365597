import i18next from "../../../i18n";
import MaterialOfferItem from "../../MaterialOfferItem/MaterialOfferItem";
import { MaterialCompaniesPaginatedOpportunitiesResponse } from "../../../types";

type PotencialSuppliersProps = {
  setOpen: (value: boolean) => void;
  setPotencialSupplierToShow: (value: any) => void;
  materialCompaniesOpportunities: MaterialCompaniesPaginatedOpportunitiesResponse;
};

const PotencialSuppliers = ({
  setOpen,
  setPotencialSupplierToShow,
  materialCompaniesOpportunities,
}: PotencialSuppliersProps) => {
  return (
    <div>
      {materialCompaniesOpportunities?.content?.length === 0 ? (
        <p className="text-xs">{i18next.t("material.noPotencialSuppliers")}</p>
      ) : (
        <div className="bg-white shadow overflow-hidden rounded border">
          <ul className="divide-y divide-gray-200">
            {materialCompaniesOpportunities?.content?.map((item) => (
              <MaterialOfferItem
                key={item.user.id}
                potencialSupplier={item}
                handleItemClick={() => {
                  setOpen(true);
                  setPotencialSupplierToShow(item);
                }}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PotencialSuppliers;
