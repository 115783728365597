import { Routes, Route } from "react-router-dom";
import {
  Home,
  User,
  Offer,
  Users,
  Profile,
  NotFound,
  IsSellerBid,
  ValidateUser,
  Opportunities,
  MaterialOffer,
  MaterialOffers,
  BiddingOpportunities,
  EditUser,
  IsJointSellerBid,
  IsJointSellerOffer,
  Chat,
} from "../../../containers";
import { Opportunity, Layout, NewUserForm } from "../../../components";

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/validate-user" element={<ValidateUser />} />
      <Route element={<Layout />}>
        <Route path="/opportunities" element={<Opportunities />} />
        <Route path="/opportunities/material/:id" element={<Opportunity />} />
        <Route path="/offer/:id" element={<Offer />} />
        <Route path="/material/offers" element={<MaterialOffers />} />
        <Route path="/material/offer/:id" element={<MaterialOffer />} />
        <Route path="/order/multiple/:id" element={<IsJointSellerBid />} />
        <Route path="/offer/multiple/:id" element={<IsJointSellerOffer />} />
        <Route path="/order/:id" element={<IsSellerBid />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/biddings" element={<BiddingOpportunities />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user/:id" element={<User />} />
        <Route path="/user/edit/:id" element={<EditUser />} />
        <Route path="/users/create" element={<NewUserForm />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
