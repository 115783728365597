import { CheckIcon } from "@heroicons/react/24/solid";

type StepsProps = {
  steps: {
    id: number;
    name: string;
    href?: string;
    status: string;
  }[];
};

const Steps = ({ steps }: StepsProps) => {
  return (
    <nav aria-label="Progress" className="w-full">
      <ol className="border border-gray-300 rounded divide-y divide-gray-300 md:flex md:divide-y-0 w-full">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative flex uppercase w-full">
            {step.status === "complete" ? (
              <a href={step.href} className="group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-xs font-medium">
                  <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-spectum-dark rounded-full group-hover:bg-spectum-light">
                    <CheckIcon
                      className="w-4 h-4 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-xs font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </a>
            ) : step.status === "current" ? (
              <a
                href={step.href}
                className="px-6 py-4 flex items-center text-xs font-medium"
                aria-current="step"
              >
                <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-spectum-dark rounded-full">
                  <span className="text-spectum-dark">{step.id}</span>
                </span>
                <span className="ml-4 text-xs font-medium text-spectum-dark">
                  {step.name}
                </span>
              </a>
            ) : (
              <a href={step.href} className="group flex items-center">
                <span className="px-6 py-4 flex items-center text-xs font-medium">
                  <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-4 text-xs font-medium text-gray-500 group-hover:text-gray-900">
                    {step.name}
                  </span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <div
                className="hidden md:block absolute top-0 right-0 h-full w-5"
                aria-hidden="true"
              >
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Steps;
