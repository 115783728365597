import { Navigate } from "react-router-dom";
import { GoBackArrow, Loader, Material } from "../../components";
import { useID } from "../../hooks";
import { useMaterial } from "../../hooks/useMaterials";

const MaterialCnt = () => {
  const id = useID();

  const { data: material, isLoading, isSuccess } = useMaterial(id);

  if (isLoading) return <Loader isFullScreen />;

  return (
    <div className="min-h-full flex">
      <div className="flex flex-col w-0 flex-1">
        <div className="py-8 xl:py-10 relative">
          <GoBackArrow stylesAdded={"px-8"} />
          {isSuccess ? (
            <Material material={material} />
          ) : (
            <Navigate to="/not-found" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialCnt;
