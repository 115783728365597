import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../axios-orders";
import { resetPasswordValidationSchema } from "../../utils";
import LogoSpectum from "../../assets/images/logo-simbuy.png";
import { InputField, Loader } from "..";

type ResetPasswordFormValues = {
  email: string;
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const formOptions = { resolver: yupResolver(resetPasswordValidationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ResetPasswordFormValues>(formOptions);

  const {
    mutate: resetPassword,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: async (data: ResetPasswordFormValues) =>
      await axios.post("/auth/reset-password", data),
    onSuccess: () => reset(),
  });

  const onSubmit = (data: ResetPasswordFormValues) => {
    resetPassword(data);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center py-12 sm:px-6 lg:px-8 p-4">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-72 mb-4" src={LogoSpectum} alt="Simbuy" />
      </div>

      <div className="mb-4">
        {isSuccess && (
          <p className="text-white bg-green-800 rounded py-2 px-6">
            {t("newPassword.inbox")}
          </p>
        )}

        {isError && (
          <p className="text-white bg-red-600 rounded py-2 px-6">
            {t("newMaterialForm.error")}
          </p>
        )}
      </div>

      {isPending ? (
        <Loader isFullScreen />
      ) : (
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded sm:px-10">
            <h3 className="py-4 font-bold">{t("newPassword.reset")}</h3>
            <p className="pb-4">{t("newPassword.revert")}</p>
            <form
              className="space-y-6"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <div>
                <InputField
                  id="email"
                  name="email"
                  type="email"
                  label={t("login.email")}
                  errorName={errors?.email}
                  errorMessage={errors?.email?.message}
                  required
                  register={register}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-xs font-medium text-white bg-spectum hover:bg-spectum-light"
                >
                  {t("cta.send")}
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-xs">
                  <Link
                    to="/login"
                    className="font-medium text-spectum hover:text-spectum-light"
                  >
                    {t("login.signIn")}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
