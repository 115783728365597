import ClipLoader from "react-spinners/ClipLoader";

const Loader = ({ isFullScreen }: { isFullScreen?: boolean }) => {
  return (
    <div
      className={`grid justify-items-center content-center ${
        isFullScreen ? "h-screen" : null
      }`}
    >
      <ClipLoader loading size={50} />
    </div>
  );
};

export default Loader;
