import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PlusCircleIcon,
  MinusCircleIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import { PickList, Slide } from "../..";
import InviteSupplier from "../../Material/InviteSupplier/InviteSupplier";
import { UserResponse } from "../../../types";

type AddSuppliersProps = {
  materialId: string;
  isMaterialView: boolean;
  setAddSupplier: (value: boolean) => void;
  addSupplier: boolean;
  selected: UserResponse;
  setSelected: (value: UserResponse) => void;
  suppliers: UserResponse[];
  hasSuppliers: boolean;
};

export default function AddSuppliers({
  materialId,
  hasSuppliers,
  isMaterialView,
  setAddSupplier,
  addSupplier,
  selected,
  setSelected,
  suppliers,
}: AddSuppliersProps) {
  const { t } = useTranslation();
  const [openInvite, setOpenInvite] = useState(false);

  const handleInviteSupplier = () => setOpenInvite(true);
  const onCloseInvitation = () => setOpenInvite(false);

  return (
    <>
      {isMaterialView && suppliers?.length > 0 && (
        <button
          className="flex items-center gap-3 mt-2"
          onClick={() => setAddSupplier(!addSupplier)}
        >
          {addSupplier ? (
            <>
              <MinusCircleIcon className="h-6 w-6" />
              <span className="text-xs font-bold uppercase">
                {t("volumePrice.cancel")}
              </span>
            </>
          ) : (
            <>
              <PlusCircleIcon className="h-6 w-6" />
              <span className="text-xs font-bold">
                {t("volumePrice.addSupplier")}
              </span>
            </>
          )}
        </button>
      )}
      {isMaterialView && suppliers?.length === 0 && !hasSuppliers && (
        <div className="pt-4">
          <p className="text-xs font-bold uppercase">
            {t("volumePrice.invite")}
          </p>
          <button
            onClick={handleInviteSupplier}
            className="mt-4 uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
          >
            <UserPlusIcon
              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {t("material.inviteSupplier")}
          </button>
        </div>
      )}
      {addSupplier && (
        <div className="p-4 rounded shadow mt-4">
          <PickList
            selected={selected}
            onChange={setSelected}
            list={suppliers}
            placeholder="Seleccione un Proveedor"
            isSuppliers
          />
        </div>
      )}
      <Slide open={openInvite} setOpen={setOpenInvite}>
        <InviteSupplier
          materialId={materialId}
          onCloseInvitation={onCloseInvitation}
        />
      </Slide>
    </>
  );
}
