import { Routes, Route } from "react-router-dom";
import { Layout } from "../../../components";
import {
  NotFound,
  Profile,
  ValidateUser,
  PurchaseRequest,
  PurchaseRequests,
  PurchaseRequestDetail,
  PurchaseRequestMultipleDetail,
} from "../../../containers";

const RequesterRoutes = () => {
  return (
    <Routes>
      <Route path="/validate-user" element={<ValidateUser />} />
      <Route element={<Layout />}>
        <Route path="/new-purchase-request" element={<PurchaseRequest />} />
        <Route
          path="/purchase-request/:id"
          element={<PurchaseRequestDetail />}
        />
        <Route
          path="/purchase-request/multiple/:id"
          element={<PurchaseRequestMultipleDetail />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/" element={<PurchaseRequests />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default RequesterRoutes;
