import { useTranslation } from "react-i18next";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

type PaginationProps = {
  results: number;
  currentPage: number;
  total: number;
  amountOfPages: number;
  setPageNumber: (page: number) => void;
};

export default function Pagination({
  results,
  currentPage,
  total,
  setPageNumber,
  amountOfPages,
}: PaginationProps) {
  const { t } = useTranslation();
  const interval = 2; // Number of pages before and after the current page to show.
  const pages = [];

  for (let i = currentPage - interval; i <= currentPage + interval; i++) {
    if (i >= 0 && i < amountOfPages) {
      pages.push(i);
    }
  }

  let resOfTotal = 0;
  if (results === 10 && currentPage > 0)
    resOfTotal = results * (currentPage + 1);
  else if (results === 10) resOfTotal = results;
  else if (results <= 10 && total < 10) resOfTotal = results;
  else if (results < 10 && total > 10) resOfTotal = total;

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-2 py-2">
      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-xs text-gray-700">
            <span>
              {resOfTotal} {t("pagination.of")}{" "}
            </span>
            <span className="font-medium">{total}</span>{" "}
            {t("pagination.results")}
          </p>
        </div>
        {total > 10 && (
          <div>
            <nav
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <button className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
                <span className="sr-only">Anterior</span>
                <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
              </button>
              {pages.map((page) => (
                <button
                  key={page}
                  onClick={() => setPageNumber(page)}
                  className={`relative z-10 inline-flex items-center border ${
                    page === currentPage && "text-white bg-spectum"
                  }  px-4 py-1 text-xs font-medium focus:z-20`}
                >
                  {page + 1}
                </button>
              ))}
              <button className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-xs font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
                <span className="sr-only">Siguiente</span>
                <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
}
