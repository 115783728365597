import i18next from "../i18n";

export const PN_TYPE = {
  PI: "PI",
  NPI: "NPI",
  SERVICE: "SERVICE",
};

export const MATERIAL_TYPE = [
  { id: 1, name: i18next.t("materialTypes.pi"), value: PN_TYPE.PI },
  { id: 2, name: i18next.t("materialTypes.npi"), value: PN_TYPE.NPI },
  { id: 3, name: i18next.t("materialTypes.service"), value: PN_TYPE.SERVICE },
];

export const MONTHS = [
  { id: 1, es: "ENERO", en: "JANUARY" },
  { id: 2, es: "FEBRERO", en: "FEBRUARY" },
  { id: 3, es: "MARZO", en: "MARCH" },
  { id: 4, es: "ABRIL", en: "APRIL" },
  { id: 5, es: "MAYO", en: "MAY" },
  { id: 6, es: "JUNIO", en: "JUNE" },
  { id: 7, es: "JULIO", en: "JULY" },
  { id: 8, es: "AGOSTO", en: "AUGUST" },
  { id: 9, es: "SEPTIEMBRE", en: "SEPTEMBER" },
  { id: 10, es: "OCTUBRE", en: "OCTOBER" },
  { id: 11, es: "NOVIEMBRE", en: "NOVEMBER" },
  { id: 12, es: "DICIEMBRE", en: "DECEMBER" },
];

export const CURRENCY_LIST = [
  { id: 1, name: "USD" },
  { id: 2, name: "EUR" },
  { id: 3, name: "MXN" },
  { id: 4, name: "ARS" },
  { id: 5, name: "CLP" },
  { id: 6, name: "BRL" },
  { id: 7, name: "COP" },
  { id: 8, name: "CRC" },
  { id: 9, name: "CUP" },
  { id: 10, name: "DOP" },
  { id: 11, name: "PEN" },
  { id: 12, name: "PYG" },
  { id: 13, name: "UYU" },
];

export const MEASUREMENT_UNIT_LIST = [
  { id: 1, name: "GR" },
  { id: 2, name: "KG" },
  { id: 3, name: "TN" },
  { id: 4, name: "LT" },
  { id: 5, name: "UN" },
  { id: 6, name: "M3" },
  { id: 7, name: i18next.t("measurementUnits.other") },
];

export const MEASUREMENT_UNIT_LIST_SERVICE = [
  {
    id: 1,
    name: i18next.t("tenders.measurementUnits.peopleAmount"),
    value: i18next.t("tenders.measurementUnits.peopleAmount"),
  },
  {
    id: 2,
    name: i18next.t("tenders.measurementUnits.monthlyFee"),
    value: i18next.t("tenders.measurementUnits.monthlyFee"),
  },
  {
    id: 3,
    name: i18next.t("tenders.measurementUnits.day"),
    value: i18next.t("tenders.measurementUnits.day"),
  },
  {
    id: 4,
    name: i18next.t("tenders.measurementUnits.fee"),
    value: i18next.t("tenders.measurementUnits.fee"),
  },
  {
    id: 5,
    name: i18next.t("tenders.measurementUnits.workHours"),
    value: i18next.t("tenders.measurementUnits.workHours"),
  },
  {
    id: 6,
    name: i18next.t("tenders.measurementUnits.workingDay"),
    value: i18next.t("tenders.measurementUnits.workingDay"),
  },
  {
    id: 7,
    name: i18next.t("tenders.measurementUnits.km"),
    value: i18next.t("tenders.measurementUnits.km"),
  },
  {
    id: 8,
    name: i18next.t("tenders.measurementUnits.batch"),
    value: i18next.t("tenders.measurementUnits.batch"),
  },
  {
    id: 9,
    name: i18next.t("tenders.measurementUnits.mile"),
    value: i18next.t("tenders.measurementUnits.mile"),
  },
  {
    id: 10,
    name: i18next.t("tenders.measurementUnits.project"),
    value: i18next.t("tenders.measurementUnits.project"),
  },
  {
    id: 11,
    name: i18next.t("tenders.measurementUnits.resources"),
    value: i18next.t("tenders.measurementUnits.resources"),
  },
  {
    id: 12,
    name: i18next.t("tenders.measurementUnits.fixedRate"),
    value: i18next.t("tenders.measurementUnits.fixedRate"),
  },
  {
    id: 13,
    name: i18next.t("tenders.measurementUnits.task"),
    value: i18next.t("tenders.measurementUnits.task"),
  },
  {
    id: 14,
    name: i18next.t("tenders.measurementUnits.shift"),
    value: i18next.t("tenders.measurementUnits.shift"),
  },
  {
    id: 15,
    name: i18next.t("tenders.measurementUnits.unit"),
    value: i18next.t("tenders.measurementUnits.unit"),
  },
  {
    id: 16,
    name: i18next.t("tenders.measurementUnits.technicalVisit"),
    value: i18next.t("tenders.measurementUnits.technicalVisit"),
  },
];

export const INCOTERM_LIST = [
  { id: 1, name: "EXW" },
  { id: 2, name: "FCA" },
  { id: 3, name: "FAS" },
  { id: 4, name: "FOB" },
  { id: 5, name: "CFR" },
  { id: 6, name: "CIF" },
  { id: 7, name: "CPT" },
  { id: 8, name: "CIP" },
  { id: 9, name: "DAT" },
  { id: 10, name: "DAP" },
  { id: 11, name: "DDP" },
  { id: 12, name: "OTRO (ESPECIFICAR)" },
];

export const PAYMENT_METHOD_LIST = [
  { id: 1, name: i18next.t("paymentMethods.check") },
  { id: 2, name: i18next.t("paymentMethods.e_check") },
  { id: 3, name: i18next.t("paymentMethods.transfer") },
  { id: 4, name: i18next.t("paymentMethods.cash") },
];

export const USER_COMPANY_LIST_FOR_BUYER_ADMIN = [
  { id: 1, name: i18next.t("userTypes.manager"), type: "MANAGER" },
  { id: 2, name: i18next.t("userTypes.buyer"), type: "BUYER" },
  { id: 3, name: i18next.t("userTypes.analyst"), type: "ANALYST" },
  { id: 4, name: i18next.t("userTypes.requester"), type: "REQUESTER" },
];

export const USER_COMPANY_LIST_FOR_BUYER_MANAGER = [
  { id: 1, name: i18next.t("userTypes.buyer"), type: "BUYER" },
  { id: 2, name: i18next.t("userTypes.analyst"), type: "ANALYST" },
  { id: 3, name: i18next.t("userTypes.requester"), type: "REQUESTER" },
];

export const USER_COMPANY_LIST_FOR_BUYER = [
  { id: 1, name: i18next.t("userTypes.analyst"), type: "ANALYST" },
];

export const USER_COMPANY_LIST_FOR_SELLER_ADMIN = [
  { id: 1, name: i18next.t("userTypes.manager"), type: "MANAGER" },
  { id: 2, name: i18next.t("userTypes.commercial"), type: "COMMERCIAL" },
  { id: 3, name: i18next.t("userTypes.analyst"), type: "ANALYST" },
];

export const USER_COMPANY_LIST_FOR_SELLER_MANAGER = [
  { id: 1, name: i18next.t("userTypes.commercial"), type: "COMMERCIAL" },
  { id: 2, name: i18next.t("userTypes.analyst"), type: "ANALYST" },
];

export const USER_COMPANY_LIST_FOR_SELLER = [
  { id: 1, name: i18next.t("userTypes.analyst"), type: "ANALYST" },
];

export const USER_ROLES = [
  { id: 1, name: i18next.t("roles.active"), type: "USER" },
  { id: 2, name: i18next.t("roles.resetPassword"), type: "RESET_PASSWORD" },
  { id: 3, name: i18next.t("roles.blocked"), type: "BLOCKED" },
];

export const YEARS = [
  { id: 1990, year: 1990 },
  { id: 1991, year: 1991 },
  { id: 1992, year: 1992 },
  { id: 1993, year: 1993 },
  { id: 1994, year: 1994 },
  { id: 1995, year: 1995 },
  { id: 1996, year: 1996 },
  { id: 1997, year: 1997 },
  { id: 1998, year: 1998 },
  { id: 1999, year: 1999 },
  { id: 2000, year: 2000 },
  { id: 2001, year: 2001 },
  { id: 2002, year: 2002 },
  { id: 2003, year: 2003 },
  { id: 2004, year: 2004 },
  { id: 2005, year: 2005 },
  { id: 2006, year: 2006 },
  { id: 2007, year: 2007 },
  { id: 2008, year: 2008 },
  { id: 2009, year: 2009 },
  { id: 2010, year: 2010 },
  { id: 2011, year: 2011 },
  { id: 2012, year: 2012 },
  { id: 2013, year: 2013 },
  { id: 2014, year: 2014 },
  { id: 2015, year: 2015 },
  { id: 2016, year: 2016 },
  { id: 2017, year: 2017 },
  { id: 2018, year: 2018 },
  { id: 2019, year: 2019 },
  { id: 2020, year: 2020 },
  { id: 2021, year: 2021 },
  { id: 2022, year: 2022 },
  { id: 2023, year: 2023 },
  { id: 2024, year: 2024 },
  { id: 2025, year: 2025 },
  { id: 2026, year: 2026 },
  { id: 2027, year: 2027 },
  { id: 2028, year: 2028 },
];

export const NO_SPEC =
  "https://spectum-docs.s3.sa-east-1.amazonaws.com/Spectum-no-material.pdf";

export const WHATSAPP = "http://wa.link/y6avwf";

export const TO_BE_AGREED = i18next.t("messages.toAgree");
export const IT_DOESNT_APPLY = i18next.t("messages.notApply");
export const IT_DOESNT_HAVE_SPEC = i18next.t("messages.notAvailable");
export const IS_CONFIDENTIAL = i18next.t("messages.confidential");

export const PILL_LABELS = {
  IN_PROGRESS: i18next.t("tenders.status.inProgress"),
  OFFER_PENDING_OF_SELECTION: i18next.t("tenders.status.pending"),
  OFFER_NOT_SELECTED: i18next.t("tenders.status.notSelected"),
  OFFER_SELECTED: i18next.t("tenders.status.selected"),
  BIDDING_CANCELED: i18next.t("tenders.status.cancelled"),
  DRAFT: i18next.t("tenders.status.draft"),
  REJECTED: i18next.t("tenders.status.rejected"),
  DELIVERED: i18next.t("tenders.status.delivered"),
};

export const BIDDING_LABELS = {
  IN_PROGRESS: i18next.t("tenders.status.inProgress"),
  OFFER_NOW: i18next.t("tenders.status.offerNow"),
  SCHEDULED: i18next.t("tenders.status.scheduled"),
  CLOSED: i18next.t("tenders.status.closed"),
  INACTIVE: i18next.t("cta.inactive"),
  ACTIVE: i18next.t("cta.active"),
  HAS_OFFER: i18next.t("tenders.status.hasOffer"),
  TODAY: i18next.t("tenders.status.today"),
  CANCELLED: i18next.t("tenders.status.cancelled"),
};

export const BIDDING_TYPES = {
  NORMAL: "NORMAL",
  INVERSE: "INVERSE",
  SIMPLE_OR_MULTIPLE: "SIMPLE / MULTIPLE",
  JOINT: "JOINT",
  INVERSE_JOINT: "INVERSE_JOINT",
};

export const SAMPLE_STATE = {
  VERIFIED: "VERIFIED",
  REQUESTED: "REQUESTED",
  REJECTED: "REJECTED",
};

export const BIDDING_TYPES_LABELS = {
  NORMAL: i18next.t("tenderTypes.normal"),
  INVERSE: i18next.t("tenderTypes.inverse"),
  SIMPLE_OR_MULTIPLE: i18next.t("tenderTypes.simpleOrMultiple"),
  JOINT: i18next.t("tenderTypes.joint"),
  INVERSE_JOINT: i18next.t("tenderTypes.inverseJoint"),
};

export const USER_COMPANY_TYPES = {
  BUYER: "BUYER",
  MANAGER: "MANAGER",
  ANALYST: "ANALYST",
  VISUALIZER: "VISUALIZER",
  COMMERCIAL: "COMMERCIAL",
  REQUESTER: "REQUESTER",
  DEFAULT: "DEFAULT",
  ADMIN: "ADMIN",
  ADMIN_APPROVAL: "ADMIN_APPROVAL",
};

export const USER_TYPES = {
  ADMIN: "ADMIN",
  BLOCKED: "BLOCKED",
  BUYER: "BUYER",
  SELLER: "SELLER",
  USER: "USER",
  NEW: "NEW",
  PENDING: "PENDING",
  RESET_PASSWORD: "RESET_PASSWORD",
  ADMIN_APPROVAL: "ADMIN_APPROVAL",
};

export const USER_TYPE = [
  {
    id: 1,
    value: "SELLER",
    label: i18next.t("userTypes.seller"),
  },
  {
    id: 2,
    value: "BUYER",
    label: i18next.t("userTypes.buyer"),
  },
];

export const CATEGORY_TYPE = {
  PI: "PI",
  NPI: "NPI",
  BUY_TEAM: "BUY_TEAM",
  PRODUCT_FAMILY: "PRODUCT_FAMILY",
  PRODUCT_CLASS: "PRODUCT_CLASS",
  MATERIAL_CODE: "MATERIAL_CODE",
};

export const FileType = {
  ATTACHMENT: "ATTACHMENT",
  CERTIFICATION: "CERTIFICATION",
  SHEET: "SHEET",
  BRIEF: "BRIEF",
  SERVICE_PLACE: "SERVICE_PLACE",
};

export const CERTIFICATIONS: {
  [key: string]: { type: string; label: string };
} = {
  QUALITY_AND_MANAGEMENT: {
    type: "QUALITY_AND_MANAGEMENT",
    label: i18next.t("certifications.qualityAndManagement"),
  },
  ENVIRONMENTAL: {
    type: "ENVIRONMENTAL",
    label: i18next.t("certifications.environmental"),
  },
  HEALTH_AND_SAFETY: {
    type: "HEALTH_AND_SAFETY",
    label: i18next.t("certifications.healthAndSafety"),
  },
  ENERGY_AND_EFFICIENCY: {
    type: "ENERGY_AND_EFFICIENCY",
    label: i18next.t("certifications.energyAndEfficiency"),
  },
  TECHNOLOGY_AND_ELECTRONICS: {
    type: "TECHNOLOGY_AND_ELECTRONICS",
    label: i18next.t("certifications.technologyAndElectronics"),
  },
  CHEMICALS_AND_HAZARDOUS_MATERIALS: {
    type: "CHEMICALS_AND_HAZARDOUS_MATERIALS",
    label: i18next.t("certifications.chemicalsAndHazardousMaterials"),
  },
  SOCIAL_AND_FAIR_TRADE: {
    type: "SOCIAL_AND_FAIR_TRADE",
    label: i18next.t("certifications.socialAndFairTrade"),
  },
  SPECIFIC_AND_NICHE_PRODUCTS: {
    type: "SPECIFIC_AND_NICHE_PRODUCTS",
    label: i18next.t("certifications.specificAndNicheProducts"),
  },
  PRODUCT_SAFETY: {
    type: "PRODUCT_SAFETY",
    label: i18next.t("certifications.productSafety"),
  },
};

export const certificationSuggestions = {
  [CERTIFICATIONS.QUALITY_AND_MANAGEMENT.type]: [
    "AS9100",
    "IATF 16949",
    "ISO 9001",
    "ISO 13485",
    "ISO/IEC 17025",
  ],
  [CERTIFICATIONS.ENVIRONMENTAL.type]: [
    "BREEAM",
    "FSC",
    "ISO 14001",
    "LEED",
    "OPDS",
    "Rainforest Alliance",
  ],
  [CERTIFICATIONS.HEALTH_AND_SAFETY.type]: [
    "ATEX",
    "ISO 45001",
    "NFPA",
    "OHSAS 18001",
  ],
  [CERTIFICATIONS.ENERGY_AND_EFFICIENCY.type]: [
    "Energy Star",
    "Green Seal",
    "ISO 50001",
  ],
  [CERTIFICATIONS.TECHNOLOGY_AND_ELECTRONICS.type]: [
    "CE",
    "IEEE Standards",
    "RoHS",
    "WEEE",
  ],
  [CERTIFICATIONS.CHEMICALS_AND_HAZARDOUS_MATERIALS.type]: [
    "CLP Regulation",
    "GHS",
    "REACH",
    "TSCA",
  ],
  [CERTIFICATIONS.SOCIAL_AND_FAIR_TRADE.type]: [
    "B Corp",
    "Fair Trade",
    "SA8000",
    "WRAP",
  ],
  [CERTIFICATIONS.SPECIFIC_AND_NICHE_PRODUCTS.type]: [
    "HALAL",
    "Kosher",
    "Non-GMO Project",
    "USDA Organic",
  ],
  [CERTIFICATIONS.PRODUCT_SAFETY.type]: [
    "CE",
    "CSA Standards",
    "ETL Listed",
    "UL",
  ],
};
