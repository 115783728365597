import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditUserForm, Loader } from "../../../components";
import { useID } from "../../../hooks";
import { mapDispatchToProps } from "../../../utils";
import i18next from "../../../i18n";
import {
  useUpdateUser,
  useUserById,
  useUsersByCompany,
} from "../../../hooks/useUsers";

type EditUserProps = {
  onOpenNotification: (message: string) => void;
};

const EditUser = ({ onOpenNotification }: EditUserProps) => {
  const id = useID();
  const navigate = useNavigate();

  const { data: userToEdit, isLoading: isLoadingUser } = useUserById(id);

  const { data: usersByCompany, isLoading: isLoadingUsers } =
    useUsersByCompany(id);

  const {
    mutate: editUser,
    isPending: isUpdatingUser,
    isError,
  } = useUpdateUser(
    () => {
      onOpenNotification(i18next.t("users.editSuccess"));
      return navigate("/users");
    },
    () => onOpenNotification(i18next.t("newMaterialForm.error"))
  );

  if (isUpdatingUser || isLoadingUser || isLoadingUsers)
    return <Loader isFullScreen />;

  return (
    <>
      <EditUserForm
        error={isError}
        userToEdit={userToEdit}
        onEditUser={editUser}
        usersByCompany={usersByCompany}
      />
    </>
  );
};

export default connect(null, mapDispatchToProps)(EditUser);
