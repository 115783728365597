import { useTranslation } from "react-i18next";
import MaterialOfferItem from "../../MaterialOfferItem/MaterialOfferItem";
import { CombinedBuyerSellerMaterialOfferResponse } from "../../../types";

type OffersReceivedNotValidatedProps = {
  materialOffers: CombinedBuyerSellerMaterialOfferResponse[];
  setOpen: (value: boolean) => void;
  setOpenChat: (value: boolean) => void;
  setMaterialOfferToShow: (value: any) => void;
};

export default function OffersReceivedNotValidated({
  setOpen,
  setOpenChat,
  materialOffers,
  setMaterialOfferToShow,
}: OffersReceivedNotValidatedProps) {
  const { t } = useTranslation();
  return (
    <div className="py-3">
      {materialOffers?.length > 0 &&
      materialOffers.find((item) => !item?.buyerSeller?.validated) ? (
        <div className="bg-white shadow overflow-hidden rounded border">
          <ul className="divide-y divide-gray-200">
            {materialOffers?.map((matOff) => {
              if (!matOff?.buyerSeller?.validated) {
                return (
                  <MaterialOfferItem
                    key={matOff?.materialOffer?.id}
                    materialOffer={matOff?.materialOffer}
                    handleItemClick={() => {
                      setOpen(true);
                      setMaterialOfferToShow(
                        materialOffers?.filter(
                          (el) =>
                            el?.materialOffer?.id === matOff?.materialOffer?.id
                        )[0]
                      );
                    }}
                    handleChatClick={() => {
                      setOpenChat(true);
                      setMaterialOfferToShow(
                        materialOffers?.filter(
                          (el) =>
                            el?.materialOffer?.id === matOff?.materialOffer?.id
                        )[0]
                      );
                    }}
                  />
                );
              }
            })}
          </ul>
        </div>
      ) : (
        <p className="text-xxs">{t("material.noSuppliers")}</p>
      )}
    </div>
  );
}
