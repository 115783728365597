import i18next from "../../i18n";
import { TableTitle } from "..";
import UsersTableRow from "./UsersTableRow/UsersTableRow";
import { UserResponse } from "../../types";

const usersTh = [
  { id: 1, name: i18next.t("users.name") },
  { id: 2, name: i18next.t("users.email") },
  { id: 3, name: i18next.t("users.phone") },
  { id: 4, name: i18next.t("users.responsible") },
  { id: 5, name: i18next.t("users.role") },
  { id: 6, name: "" },
];

type UsersTableProps = {
  usersByCompany: UserResponse[];
  title?: string;
};

const UsersTable = ({ usersByCompany, title }: UsersTableProps) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        {title && <TableTitle title={title} />}
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {usersTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xxs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {usersByCompany?.map((user) => (
                <UsersTableRow key={user.id} user={user} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
