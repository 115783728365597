import { Routes, Route } from "react-router-dom";
import { Approval, NotFound, Profile, ValidateUser } from "../../../containers";
import { Layout } from "../../../components";

const ApprovalRoutes = () => {
  return (
    <Routes>
      <Route path="/validate-user" element={<ValidateUser />} />
      <Route element={<Layout />}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/" element={<Approval />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default ApprovalRoutes;
