import { Fragment } from "react";
import { Dialog, DialogBackdrop, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

type SlideProps = {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Slide = ({ open, setOpen, children }: SlideProps) => {
  return (
    <Dialog
      as="div"
      className="fixed inset-0 overflow-hidden"
      onClose={setOpen}
      open={open}
    >
      <div className="absolute inset-0 overflow-hidden">
        <DialogBackdrop
          className="absolute inset-0 bg-gray-900 opacity-30"
          onClick={() => setOpen(false)}
        />

        <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="w-screen max-w-md">
              <div className="h-full flex flex-col pt-20 bg-white shadow-xl overflow-y-scroll">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between relative">
                    <div className="absolute right-0">
                      <button
                        type="button"
                        className="bg-white rounded text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="h-6 w-6 hover:text-gray-600 text-black"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mt-8 relative flex-1 px-4 sm:px-6">
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  );
};

export default Slide;
