import { useTranslation } from "react-i18next";

type BannerProps = {
  isNew?: boolean;
  isPending?: boolean;
  bannerMessage?: string;
  isResetPassword: boolean;
};

const Banner = ({
  isNew,
  isPending,
  bannerMessage,
  isResetPassword,
}: BannerProps) => {
  const { t } = useTranslation();

  return (
    <div className="relative bg-blue-500">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center">
          <p className="font-medium text-white">
            <span className="hidden md:inline">
              {bannerMessage
                ? bannerMessage
                : (isPending && t("banner.check")) ||
                  (isNew && t("banner.user")) ||
                  (isResetPassword && t("banner.password"))}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
