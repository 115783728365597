import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { calculateMinimumDecrement } from "../../utils";
import { ORDER_TYPES, OrderState } from "../../types";

type EditOfferFormProps = {
  state: OrderState;
  errors: any;
  setOpen: (open: boolean) => void;
  currency: string;
  register: any;
  decrement: number;
  actualOffer: number;
  orderType: ORDER_TYPES;
  measurementUnit: string;
};

const EditOfferForm = ({
  state,
  errors,
  setOpen,
  currency,
  register,
  decrement,
  actualOffer,
  orderType,
  measurementUnit,
}: EditOfferFormProps) => {
  const { t } = useTranslation();
  const minDecrement = calculateMinimumDecrement(actualOffer, decrement);
  return (
    <Fragment>
      {state === OrderState.CLOSED ? (
        <h4 className="text-sm font-bold text-gray-900 uppercase xl:px-6 py-4">
          {t("tenders.closed")}
        </h4>
      ) : (
        <form
          className="space-y-8 divide-y divide-gray-200 mt-8"
          autoComplete="off"
        >
          <div className="mt-10 sm:mt-0">
            <div className="mt-5 md:mt-0">
              <div className="xl:shadow overflow-hidden">
                <h4 className="text-sm font-bold text-gray-900 uppercase xl:px-6 py-4">
                  {t("tenders.title")}
                </h4>
                <div className="grid grid-cols-2 gap-4 bg-white px-6">
                  {orderType === ORDER_TYPES.INVERSE && (
                    <Fragment>
                      <div className="col-span-2 bg-white overflow-hidden">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="uppercase text-sm font-medium text-gray-500">
                              {t("tenders.minimumDecrement")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              {decrement} %
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div className="col-span-2 bg-white overflow-hidden">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="uppercase text-sm font-medium text-gray-500">
                              {t("tenders.maxOffer")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                              {minDecrement} {currency} / {measurementUnit}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </Fragment>
                  )}
                  {orderType === ORDER_TYPES.INVERSE && (
                    <div className="col-span-2 pb-2">
                      <label
                        htmlFor="price"
                        className="uppercase block text-sm font-medium text-gray-700"
                      >
                        {t("tenders.price")} - ({currency} / {measurementUnit}){" "}
                        <i className="text-xs">{t("tenders.withoutTax")}</i>
                      </label>
                      <input
                        {...register("price", { required: true })}
                        type="number"
                        min={0}
                        max={minDecrement}
                        placeholder={minDecrement}
                        name="price"
                        id="price"
                        className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-sm border-gray-300 rounded"
                      />
                      {errors?.price && (
                        <span className="text-xs text-red-500">
                          {errors?.price?.message}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
                  >
                    {t("tenders.modify")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Fragment>
  );
};

export default EditOfferForm;
