import {
  HomeModernIcon,
  QueueListIcon,
  InformationCircleIcon,
  UserIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import {
  MagnifyingGlassIcon,
  CircleStackIcon,
  ScaleIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import { WHATSAPP } from "./enums";
import i18next from "../i18n";

export const buyerNavigation = [
  {
    name: i18next.t("navigation.home"),
    href: "/",
    icon: HomeModernIcon,
    current: true,
  },
  {
    name: i18next.t("navigation.materials"),
    href: "/materials",
    icon: QueueListIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.purchaseRequests"),
    href: "/purchase-requests",
    icon: Square3Stack3DIcon,
    current: true,
  },
  {
    name: i18next.t("navigation.tenders"),
    href: "/orders",
    icon: ScaleIcon,
    current: false,
  },
  // {
  //   name: i18next.t("navigation.marketInfo"),
  //   href: "/market-info",
  //   icon: InformationCircleIcon,
  //   current: false,
  // },
  {
    name: i18next.t("navigation.users"),
    href: "/users",
    icon: UserIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.chat"),
    href: "/chat",
    icon: ChatBubbleOvalLeftEllipsisIcon,
    current: false,
  },
];

export const analystNavigation = [
  {
    name: i18next.t("navigation.home"),
    href: "/",
    icon: HomeModernIcon,
    current: true,
  },
  {
    name: i18next.t("navigation.materials"),
    href: "/materials",
    icon: QueueListIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.purchaseRequests"),
    href: "/purchase-requests",
    icon: Square3Stack3DIcon,
    current: true,
  },
  {
    name: i18next.t("navigation.tenders"),
    href: "/orders",
    icon: ScaleIcon,
    current: false,
  },
  // {
  //   name: i18next.t("navigation.marketInfo"),
  //   href: "/market-info",
  //   icon: InformationCircleIcon,
  //   current: false,
  // },
  {
    name: i18next.t("navigation.chat"),
    href: "/chat",
    icon: ChatBubbleOvalLeftEllipsisIcon,
    current: false,
  },
];

export const sellerNavigation = [
  {
    name: i18next.t("navigation.home"),
    href: "/",
    icon: HomeModernIcon,
    current: true,
  },
  {
    name: i18next.t("navigation.opportunities"),
    href: "/opportunities",
    icon: MagnifyingGlassIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.materialOffers"),
    href: "/material/offers",
    icon: DocumentTextIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.tenders"),
    href: "/biddings",
    icon: ScaleIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.users"),
    href: "/users",
    icon: UserIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.chat"),
    href: "/chat",
    icon: ChatBubbleOvalLeftEllipsisIcon,
    current: false,
  },
];

export const managerNavigation = [
  {
    name: i18next.t("navigation.home"),
    href: "/",
    icon: HomeModernIcon,
    current: true,
  },
  {
    name: i18next.t("navigation.materials"),
    href: "/materials",
    icon: QueueListIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.companyMaterials"),
    href: "/materials/company",
    icon: CircleStackIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.purchaseRequests"),
    href: "/purchase-requests",
    icon: Square3Stack3DIcon,
    current: true,
  },
  {
    name: i18next.t("navigation.tenders"),
    href: "/orders",
    icon: ScaleIcon,
    current: false,
  },
  // {
  //   name: i18next.t("navigation.marketInfo"),
  //   href: "/market-info",
  //   icon: InformationCircleIcon,
  //   current: false,
  // },
  {
    name: i18next.t("navigation.users"),
    href: "/users",
    icon: UserIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.chat"),
    href: "/chat",
    icon: ChatBubbleOvalLeftEllipsisIcon,
    current: false,
  },
];

export const visualizerNavigation = [
  {
    name: i18next.t("navigation.home"),
    href: "/",
    icon: HomeModernIcon,
    current: true,
  },
  {
    name: i18next.t("navigation.companyMaterials"),
    href: "/materials/company",
    icon: CircleStackIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.users"),
    href: "/users",
    icon: UserIcon,
    current: false,
  },
];

export const adminNavigation = [
  {
    name: i18next.t("navigation.home"),
    href: "/",
    icon: HomeModernIcon,
    current: true,
  },
  {
    name: i18next.t("navigation.companyMaterials"),
    href: "/materials/company",
    icon: CircleStackIcon,
    current: false,
  },
  {
    name: i18next.t("navigation.users"),
    href: "/users",
    icon: UserIcon,
    current: false,
  },
];

export const requesterNavigation = [
  {
    name: i18next.t("navigation.purchaseRequests"),
    href: "/",
    icon: Square3Stack3DIcon,
    current: true,
  },
];

export const pendingApprovalNavigation = [
  {
    name: i18next.t("navigation.home"),
    href: "/",
    icon: HomeModernIcon,
    current: true,
  },
];

export const secondaryNavigation = [
  {
    href: WHATSAPP,
    name: i18next.t("navigation.support"),
    icon: InformationCircleIcon,
    current: true,
  },
];
