import { CategoryResponse, CountryResponse } from "../../types";

export const actions = {
  CLEAR_SELECTION: "CLEAR_SELECTION",
  TOGGLE_COUNTRY: "TOGGLE_COUNTRY",
  TOGGLE_BUY_TEAM_CATEGORY: "TOGGLE_BUY_TEAM_CATEGORY",
  TOGGLE_SERVICE_FAMILY_CATEGORY: "TOGGLE_SERVICE_FAMILY_CATEGORY",
  TOGGLE_SERVICE_CLASS_CATEGORY: "TOGGLE_SERVICE_CLASS_CATEGORY",
  TOGGLE_MERCHANT_CODE_CATEGORY: "TOGGLE_MERCHANT_CODE_CATEGORY",
  SELECT_ALL_SERVICE_CLASS_CATEGORIES: "SELECT_ALL_SERVICE_CLASS_CATEGORIES",
  SELECT_ALL_MERCHANT_CODE_CATEGORY: "SELECT_ALL_MERCHANT_CODE_CATEGORY",
};

interface State {
  serviceFamilyCategories: CategoryResponse[];
  serviceClassCategories: CategoryResponse[];
  merchancyCodesCategories: CategoryResponse[];
  buyTeamCategories: CategoryResponse[];
  countries: CountryResponse[];
}

export const categoryFilterHandler = (
  categories: CategoryResponse[] | CountryResponse[],
  action: any
) => {
  return categories.find((cat) => cat.id === action.item.id)
    ? categories.filter((category) => category.id !== action.item.id)
    : [...categories, action.item];
};

export const categoryIdsBuilder = (data: any) => {
  return data.length > 0 ? data.map((item: any) => item.id) : [];
};

export const reducer = (state: State, action: any) => {
  let newCountries: CountryResponse[],
    newBuyTeamCategories: CategoryResponse[],
    newServiceFamilyCategories: CategoryResponse[],
    newServiceClassCategories: CategoryResponse[],
    newMerchancyCodesCategories: CategoryResponse[];

  switch (action.type) {
    case actions.TOGGLE_COUNTRY:
      newCountries = categoryFilterHandler(state.countries, action);
      return {
        ...state,
        countries: newCountries,
      };
    case actions.TOGGLE_BUY_TEAM_CATEGORY:
      newBuyTeamCategories = categoryFilterHandler(
        state.buyTeamCategories,
        action
      );
      if (state.buyTeamCategories.length !== newBuyTeamCategories.length) {
        const removedIds = state.buyTeamCategories
          .filter((cat) => !newBuyTeamCategories.includes(cat))
          .map((cat) => cat.id);
        newServiceFamilyCategories = state.serviceFamilyCategories.filter(
          (cat) => !removedIds.includes(cat.parent.id)
        );
        newServiceClassCategories = state.serviceClassCategories.filter((cat) =>
          newServiceFamilyCategories
            .map((fam) => fam.id)
            .includes(cat.parent.id)
        );
        newMerchancyCodesCategories = state.merchancyCodesCategories.filter(
          (cat) =>
            newServiceClassCategories
              .map((cls) => cls.id)
              .includes(cat.parent.id)
        );
        return {
          ...state,
          buyTeamCategories: newBuyTeamCategories,
          serviceFamilyCategories: newServiceFamilyCategories,
          serviceClassCategories: newServiceClassCategories,
          merchancyCodesCategories: newMerchancyCodesCategories,
        };
      }
      return { ...state, buyTeamCategories: newBuyTeamCategories };

    case actions.TOGGLE_SERVICE_FAMILY_CATEGORY:
      newServiceFamilyCategories = categoryFilterHandler(
        state.serviceFamilyCategories,
        action
      );
      if (
        state.serviceFamilyCategories.length !==
        newServiceFamilyCategories.length
      ) {
        const removedIds = state.serviceFamilyCategories
          .filter((cat) => !newServiceFamilyCategories.includes(cat))
          .map((cat) => cat.id);
        newServiceClassCategories = state.serviceClassCategories.filter(
          (cat) => !removedIds.includes(cat.parent.id)
        );
        newMerchancyCodesCategories = state.merchancyCodesCategories.filter(
          (cat) =>
            newServiceClassCategories
              .map((cls) => cls.id)
              .includes(cat.parent.id)
        );
        return {
          ...state,
          serviceFamilyCategories: newServiceFamilyCategories,
          serviceClassCategories: newServiceClassCategories,
          merchancyCodesCategories: newMerchancyCodesCategories,
        };
      }
      return { ...state, serviceFamilyCategories: newServiceFamilyCategories };

    case actions.TOGGLE_SERVICE_CLASS_CATEGORY:
      newServiceClassCategories = categoryFilterHandler(
        state.serviceClassCategories,
        action
      );
      if (
        state.serviceClassCategories.length !== newServiceClassCategories.length
      ) {
        const removedIds = state.serviceClassCategories
          .filter((cat) => !newServiceClassCategories.includes(cat))
          .map((cat) => cat.id);
        newMerchancyCodesCategories = state.merchancyCodesCategories.filter(
          (cat) => !removedIds.includes(cat.parent.id)
        );
        return {
          ...state,
          serviceClassCategories: newServiceClassCategories,
          merchancyCodesCategories: newMerchancyCodesCategories,
        };
      }
      return { ...state, serviceClassCategories: newServiceClassCategories };

    case actions.TOGGLE_MERCHANT_CODE_CATEGORY:
      return {
        ...state,
        merchancyCodesCategories: categoryFilterHandler(
          state.merchancyCodesCategories,
          action
        ),
      };

    case actions.SELECT_ALL_SERVICE_CLASS_CATEGORIES:
      return {
        ...state,
        serviceClassCategories: action.items,
      };

    case actions.SELECT_ALL_MERCHANT_CODE_CATEGORY:
      return {
        ...state,
        merchancyCodesCategories: action.items,
      };

    case actions.CLEAR_SELECTION:
      return {
        ...state,
        buyTeamCategories: [],
        serviceFamilyCategories: [],
        serviceClassCategories: [],
        merchancyCodesCategories: [],
      };

    default:
      return state;
  }
};

export const extractBuyTeamCategories = (
  category: CategoryResponse,
  buyTeamCategories: CategoryResponse[] = []
) => {
  if (category.type === "BUY_TEAM") {
    buyTeamCategories.push(category);
  }
  if (category.parent) {
    extractBuyTeamCategories(category.parent, buyTeamCategories);
  }
  return buyTeamCategories;
};

export const extractServiceFamilyCategories = (
  category: CategoryResponse,
  serviceFamilyCategories: CategoryResponse[] = []
) => {
  // Check if the current category is of type PRODUCT_FAMILY
  if (category.type === "PRODUCT_FAMILY") {
    serviceFamilyCategories.push(category);
  }
  // Recurse into the parent if it exists
  if (category.parent) {
    extractServiceFamilyCategories(category.parent, serviceFamilyCategories);
  }
  return serviceFamilyCategories;
};

export const extractServiceClassCategories = (
  category: CategoryResponse,
  serviceClassCategories: CategoryResponse[] = []
) => {
  // Check if the current category is of type PRODUCT_CLASS
  if (category.type === "PRODUCT_CLASS") {
    serviceClassCategories.push(category);
  }
  // Recurse into the parent if it exists
  if (category.parent) {
    extractServiceClassCategories(category.parent, serviceClassCategories);
  }
  return serviceClassCategories;
};

export const extractMaterialCodeCategories = (
  category: CategoryResponse,
  materialCodeCategories: CategoryResponse[] = []
) => {
  // Check if the current category is of type PRODUCT_CLASS
  if (category.type === "MATERIAL_CODE") {
    materialCodeCategories.push(category);
  }
  // Recurse into the parent if it exists
  if (category.parent) {
    extractMaterialCodeCategories(category.parent, materialCodeCategories);
  }
  return materialCodeCategories;
};
