import { useState } from "react";
import { OrdersTable, Loader } from "../../../components";
import { ORDER_TYPES, OrderState } from "../../../types";
import { useOrdersByTypeAndState } from "../../../hooks/useOrders";

export const Cancelled = () => {
  const [cancelledPageNumber, setCancelledPageNumber] = useState(0);

  const orders = useOrdersByTypeAndState(
    OrderState.CANCELLED,
    ORDER_TYPES.NORMAL,
    ORDER_TYPES.INVERSE,
    cancelledPageNumber
  );

  return (
    <>
      {orders.isLoading ? (
        <Loader />
      ) : (
        <>
          {orders && orders.data && orders.data?.content?.length > 0 && (
            <OrdersTable
              orders={orders.data}
              pageNumber={cancelledPageNumber}
              setPageNumber={setCancelledPageNumber}
            />
          )}
        </>
      )}
    </>
  );
};
