import { Fragment } from "react";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import { SampleEnum } from "../../types";

type SampleItemProps = {
  stateSample: SampleEnum;
  handleApproveSample: () => void;
  handleRejectSample: () => void;
  userCompanyType: string | null;
};

export default function SampleItem({
  stateSample,
  handleApproveSample,
  handleRejectSample,
  userCompanyType,
}: SampleItemProps) {
  const isRequested = stateSample === SampleEnum.REQUESTED;
  const isRejected = stateSample === SampleEnum.REJECTED;
  const isVerified = stateSample === SampleEnum.VERIFIED;
  return (
    <Fragment>
      {isRequested && (
        <div className="p-2 border rounded text-xs mt-10">
          <p className="pb-6">{i18next.t("offer.sampleMessage")}</p>
          {!userCompanyType ? (
            <div className="w-full flex justify-between items-center">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                onClick={handleApproveSample}
              >
                {i18next.t("offer.approveSample")}
              </button>
              <button
                type="button"
                className="inline-flex justify-center items-center px-4 py-2 border border-red-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
                onClick={handleRejectSample}
              >
                {i18next.t("offer.rejectSample")}
              </button>
            </div>
          ) : null}
        </div>
      )}
      {isVerified && (
        <div className="p-2 flex items-center gap-3 shadow border rounded text-xs mt-10">
          <CheckBadgeIcon
            className="h-7 w-7 text-blue-500"
            aria-hidden="true"
          />
          <p> {i18next.t("offer.verifiedSample")}</p>
        </div>
      )}
      {isRejected && (
        <div className="p-2 flex items-center gap-3 shadow border rounded text-xs mt-10">
          <XCircleIcon className="h-7 w-7 text-red-500" aria-hidden="true" />
          <p>{i18next.t("offer.rejectedSample")}</p>
        </div>
      )}
    </Fragment>
  );
}
