import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { NewMaterialForm } from "../../../components";
import { mapDispatchToProps, mapStateToProps } from "../../../utils";
import { UserResponse } from "../../../types";

type CreateBuyerMaterialProps = {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
};

const CreateBuyerMaterial = ({
  user,
  onOpenNotification,
}: CreateBuyerMaterialProps) => {
  const { t } = useTranslation();
  return (
    <div className="px-4 mt-6">
      <h2 className="uppercase text-xl font-bold leading-7 text-gray-900 sm:truncate">
        {t("materials.add")}
      </h2>
      <NewMaterialForm user={user} onOpenNotification={onOpenNotification} />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBuyerMaterial);
