import { Fragment } from "react";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import i18next from "../../../i18n";
import { InfoItem } from "../../../components";
import {
  BIDDING_TYPES,
  FileType,
  formatDate,
  labelType,
  mapStateToProps,
} from "../../../utils";
import { connect } from "react-redux";
import {
  FileResponse,
  MaterialType,
  ORDER_TYPES,
  OrderState,
} from "../../../types";

const thInverse = [
  { id: 1, name: i18next.t("tenders.tenderSummary.material") },
  { id: 2, name: i18next.t("tenders.tenderSummary.purpose") },
  { id: 6, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

const thNormal = [
  { id: 1, name: i18next.t("tenders.tenderSummary.material") },
  { id: 2, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
];

type PurchaseRequestSummaryDetailProps = {
  type: ORDER_TYPES;
  isBid?: boolean;
  multiplePurchaseRequest?: any;
  purchaseRequest?: any;
  specificationName: string;
  isRequester: boolean;
};

const PurchaseRequestSummaryDetail = ({
  type,
  isBid,
  purchaseRequest,
  specificationName,
  isRequester,
}: PurchaseRequestSummaryDetailProps) => {
  let tableTh = thNormal;
  const isService =
    purchaseRequest?.order?.material?.type === MaterialType.SERVICE;

  if (isService) {
    tableTh = thInverse;
  }

  return (
    <div className="mt-4">
      <div className="sm:flex sm:items-center border-b pb-4 mb-4">
        <div className="sm:flex-auto">
          {!isBid && (
            <h1 className="text-xl font-semibold text-gray-900 mb-2">
              {i18next.t("tenders.summary")}
            </h1>
          )}
          {type && (
            <InfoItem
              name={i18next.t("tenders.form.tenderType")}
              description={labelType(type)}
            />
          )}
          {isRequester ? (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.responsible")}
              description={purchaseRequest?.order?.user?.fullName}
            />
          ) : (
            <InfoItem
              name={i18next.t("userTypes.requester")}
              description={purchaseRequest?.user?.fullName}
            />
          )}
          {purchaseRequest?.order?.startDate && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.initialDate")}
              description={formatDate(purchaseRequest?.order?.startDate)}
            />
          )}
          {purchaseRequest?.order?.finishDate && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.endDate")}
              description={formatDate(purchaseRequest?.order?.finishDate)}
            />
          )}
          {purchaseRequest?.order?.startDateValue && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.initialDate")}
              description={formatDate(purchaseRequest?.order?.startDateValue)}
            />
          )}
          {purchaseRequest?.order?.endDateValue && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.endDate")}
              description={formatDate(purchaseRequest?.order?.endDateValue)}
            />
          )}
          {purchaseRequest?.order?.paymentMethod && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentMethod")}
              description={purchaseRequest?.order?.paymentMethod}
            />
          )}
          {purchaseRequest?.order?.paymentTerm && (
            <InfoItem
              name={i18next.t("tenders.tenderSummary.paymentTerm")}
              description={
                purchaseRequest?.order?.paymentTerm === 1
                  ? ` ${i18next.t("opportunities.table.day")}`
                  : ` ${i18next.t("opportunities.table.days")}`
              }
            />
          )}

          {specificationName && (
            <div className="bg-white overflow-hidden">
              <div className="py-2 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
                    <dt className="uppercase text-xs font-medium text-gray-500">
                      {i18next.t("newMaterialForm.plication")}
                    </dt>
                    <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                      <a
                        href={specificationName}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-flex items-center p-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                      >
                        <ArrowDownTrayIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {i18next.t("cta.plication")}
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          )}

          {purchaseRequest?.order?.files?.length > 0 && (
            <div className="bg-white overflow-hidden">
              {purchaseRequest?.order.files.map((file: FileResponse) => (
                <div key={file.fileURL} className="py-2 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
                      <dt className="uppercase text-xs font-medium text-gray-500">
                        {file.type === FileType.ATTACHMENT &&
                          i18next.t("tenders.form.additionalDocument")}
                        {file.type === FileType.SHEET &&
                          i18next.t("tenders.form.plication")}
                        {file.type === FileType.CERTIFICATION &&
                          i18next.t("tenders.form.certificate")}
                        {file.type === FileType.BRIEF &&
                          i18next.t("tenders.form.brief")}
                        {file.type === FileType.SERVICE_PLACE &&
                          i18next.t("tenders.form.servicePlace")}
                      </dt>
                      <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                        <a
                          href={file.fileURL}
                          target="_blank"
                          rel="noreferrer"
                          className="w-200 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                        >
                          <ArrowDownTrayIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          {file.type === FileType.ATTACHMENT &&
                            i18next.t("tenders.form.additionalDocument")}
                          {file.type === FileType.SHEET &&
                            i18next.t("tenders.form.plication")}
                          {file.type === FileType.CERTIFICATION &&
                            i18next.t("tenders.form.certificate")}
                          {file.type === FileType.BRIEF &&
                            i18next.t("tenders.form.brief")}
                          {file.type === FileType.SERVICE_PLACE &&
                            i18next.t("tenders.form.servicePlace")}
                        </a>
                      </dd>
                    </div>
                  </dl>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {purchaseRequest?.order?.state === OrderState.REJECTED && (
        <div className="col-span-2 p-2 mb-2 rounded border border-red-600 bg-red-200">
          <p className="text-xs font-bold uppercase">
            {i18next.t("purchaseRequests.rejected")}
          </p>
          <p className="text-xs mt-4">{purchaseRequest?.comments}</p>
        </div>
      )}
      <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              {tableTh.map((t) => (
                <th
                  key={t.id}
                  scope="col"
                  className="uppercase py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  {t.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200">
              <td className="py-4 text-xs">
                <div className="font-medium text-gray-900 ">
                  {isBid
                    ? purchaseRequest.material?.globalMaterialName
                    : purchaseRequest.order.material.globalMaterialName}
                </div>
              </td>
              {!isService ? (
                <td className="py-4 text-xs text-gray-500">
                  {purchaseRequest.order.quantity}{" "}
                  {purchaseRequest.order.measurementUnit}
                </td>
              ) : (
                <td className="py-4 text-xs text-gray-500">
                  {purchaseRequest.order.purpose}
                </td>
              )}
              {(type === BIDDING_TYPES.INVERSE ||
                type === BIDDING_TYPES.INVERSE_JOINT) && (
                <Fragment>
                  <td className="py-4 text-xs text-gray-500">
                    {purchaseRequest.order.initialPrice}{" "}
                    {purchaseRequest.order.coin}
                  </td>
                  <td className="py-4 text-xs text-gray-500">
                    {purchaseRequest.order.minimumDecrement} %
                  </td>
                </Fragment>
              )}
              <td className="py-4 text-xs text-gray-500">
                {purchaseRequest.order.startSupplyDate ===
                purchaseRequest.order.endSupplyDate ? (
                  formatDate(purchaseRequest.order.startSupplyDate)
                ) : (
                  <>
                    {formatDate(purchaseRequest.order.startSupplyDate)} -{" "}
                    {formatDate(purchaseRequest.order.endSupplyDate)}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(PurchaseRequestSummaryDetail);
