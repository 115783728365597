import { useMutation } from "@tanstack/react-query";
import axios from "../axios-orders";

const onGetBiddingSuppliers = async (materialIds: string[]) => {
  const { data } = await axios.post(`/buyerSeller/materials`, materialIds);
  return data;
};

export const useBiddingSuppliers = () =>
  useMutation({
    mutationFn: onGetBiddingSuppliers,
  });
