import * as actionTypes from "./actionTypes";

export const openNotification = (message: string) => {
  return {
    type: actionTypes.OPEN_NOTIFICATION,
    message,
  };
};

export const closeNotification = () => {
  return {
    type: actionTypes.CLOSE_NOTIFICATION,
  };
};
