import {
  authCheckState,
  authLogout,
  authSignIn,
} from "../store/actions/authActions";
import {
  getUser,
  changeCompany,
  changeUserLanguage,
} from "../store/actions/userActions";
import {
  openNotification,
  closeNotification,
} from "../store/actions/notificationActions";
import { openBanner, closeBanner } from "../store/actions/bannerActions";
import {
  updateSupplierPriceVolume,
  cleanSupplierPriceVolume,
} from "../store/actions/volumePriceActions";
import {
  addUserToInvites,
  cleanUserInvites,
  orderInvitation,
} from "../store/actions/orderInvitationActions";

export const mapDispatchToProps = (dispatch: any) => {
  return {
    // auth
    onAuthSignIn: (body: any) => dispatch(authSignIn(body)),
    onAuthCheckState: (navigate: any, location: any) =>
      dispatch(authCheckState(navigate, location)),
    onLogout: () => dispatch(authLogout()),

    // user
    onGetUser: () => dispatch(getUser()),
    onChangeCompany: (companyId: string) => dispatch(changeCompany(companyId)),
    onChangeUserLanguage: (userId: string, language: string) =>
      dispatch(changeUserLanguage(userId, language)),

    // notification
    onOpenNotification: (message: string) =>
      dispatch(openNotification(message)),
    onCloseNotification: () => dispatch(closeNotification()),

    // banner
    onOpenBanner: (message: string) => dispatch(openBanner(message)),
    onCloseBanner: () => dispatch(closeBanner()),

    // priceVolume
    onUpdateSupplierPriceVolume: (item: any) =>
      dispatch(updateSupplierPriceVolume(item)),
    onCleanSupplierPriceVolume: () => dispatch(cleanSupplierPriceVolume()),

    // invitations
    onAddUserToInvites: (item: any) => dispatch(addUserToInvites(item)),
    onCreateOrderInvitation: (email: string, orderId: string) =>
      dispatch(orderInvitation(email, orderId)),
    onCleanUserInvites: () => dispatch(cleanUserInvites()),
  };
};
