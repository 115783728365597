import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios-orders";
import { BuyerSellerResponse } from "../types";
import { queryKeys } from "./queryKeys";

const onGetBuyer = async (page = 0) => {
  const { data } = await axios.get(`/buyerSeller/buyer/${page}`);
  return data;
};

export const useBuyer = (page: number, open: boolean, isBuy: boolean) =>
  useQuery({
    queryKey: [queryKeys.BUYER, page, open],
    queryFn: () => onGetBuyer(page),
    enabled: isBuy,
  });

const onSearchBuyers = async (searchTerm: string) => {
  const { data } = await axios.get(`/buyerSeller/buyer/search/${searchTerm}`);
  return data;
};

export const useSearchBuyers = (searchTerm: string) =>
  useQuery({
    queryKey: [queryKeys.FOUND_BUYERS, searchTerm],
    queryFn: () => onSearchBuyers(searchTerm),
    enabled: !!searchTerm,
  });

const onGetSeller = async () => {
  const { data } = await axios.get(`/buyerSeller/seller`);
  return data;
};

export const useSeller = (isBuy?: boolean) =>
  useQuery<BuyerSellerResponse[]>({
    queryKey: [queryKeys.SELLER],
    queryFn: () => onGetSeller(),
    enabled: !isBuy,
  });

const onRejectSeller = async ({
  buyerSellerId,
  buyerSeller,
}: {
  buyerSellerId: string;
  buyerSeller: any;
}) => {
  const { data } = await axios.put(
    `/buyerSeller/reject/${buyerSellerId}`,
    buyerSeller
  );
  return data;
};

export const useRejectSeller = (onSuccess: () => void, onError: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onRejectSeller,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.MATERIAL_OFFERS] });
      onSuccess();
    },
    onError,
  });
};

const onValidateSeller = async (buyerSellerId: string) => {
  const { data } = await axios.get(
    `/buyerSeller/toggleValidated/${buyerSellerId}`
  );
  return data;
};

export const useValidateSeller = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onValidateSeller,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [queryKeys.MATERIAL_OFFERS],
      }),
  });
};
