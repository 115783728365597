import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";

export const addUserToInvites = (invite: any) => {
  return {
    type: actionTypes.ADD_USER_TO_INVITES,
    invite,
  };
};

export const cleanUserInvites = () => {
  return {
    type: actionTypes.CLEAN_USER_INVITES,
  };
};

export const orderInvitationStart = () => {
  return {
    type: actionTypes.ORDER_INVITATION_START,
  };
};

export const orderInvitationSuccess = (order: any) => {
  return {
    type: actionTypes.ORDER_INVITATION_SUCCESS,
    order,
  };
};

export const orderInvitationFailed = (error: any) => {
  return {
    type: actionTypes.ORDER_INVITATION_FAILED,
    error,
  };
};

export const orderInvitation = (email: string, orderId: string) => {
  return (dispatch: any) => {
    dispatch(orderInvitationStart());
    axios
      .get(`/user/invitation/order/${email}/${orderId}`)
      .then((res) => dispatch(orderInvitationSuccess(res.data)))
      .catch((err) => dispatch(orderInvitationFailed(err)));
  };
};
