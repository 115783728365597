import { Routes, Route } from "react-router-dom";
import {
  Home,
  IsBuyerBid,
  MaterialCnt,
  NotFound,
  Profile,
  Orders,
  Order,
  ValidateUser,
  Users,
  User,
  CompanyMaterials,
  IsJointBuyerBid,
} from "../../../containers";
import { Layout } from "../../../components";

const VisualizerRoutes = () => {
  return (
    <Routes>
      <Route path="/validate-user" element={<ValidateUser />} />
      <Route element={<Layout />}>
        <Route path="/material/:id" element={<MaterialCnt />} />
        <Route path="/materials/company" element={<CompanyMaterials />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/order" element={<Order />} />
        <Route path="/order/:id" element={<IsBuyerBid />} />
        <Route path="/order/multiple/:id" element={<IsJointBuyerBid />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/user/material/:id" element={<MaterialCnt />} />
        <Route path="/user/:id" element={<User />} />
        <Route path="/users" element={<Users />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default VisualizerRoutes;
