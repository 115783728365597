import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  USER_COMPANY_LIST_FOR_BUYER_ADMIN,
  USER_COMPANY_LIST_FOR_SELLER,
  USER_COMPANY_LIST_FOR_SELLER_ADMIN,
  USER_COMPANY_LIST_FOR_SELLER_MANAGER,
  editUserValidationSchema,
  mapStateToProps,
} from "../../utils";
import { Modal, InputField, Button } from "..";
import {
  USER_COMPANY_LIST_FOR_BUYER_MANAGER,
  USER_COMPANY_LIST_FOR_BUYER,
  USER_COMPANY_TYPES,
} from "../../utils";
import { UserResponse } from "../../types";

type FormValues = {
  fullName: string;
  phone: string;
  userCompanyType: string;
  parent: {
    id: string;
  };
};

type OptionsProps = {
  list: {
    id: number;
    type: string;
    name: string;
  }[];
};

type EditUserFormProps = {
  user: UserResponse;
  userToEdit?: UserResponse;
  isBuy: boolean;
  isSeller: boolean;
  onEditUser: ({ userId, body }: { userId: string; body: any }) => void;
  error: boolean;
  usersByCompany?: UserResponse[];
};

const Options = ({ list }: OptionsProps) => {
  return list?.map((type) => (
    <option key={type.id} value={type.type}>
      {type.name}
    </option>
  ));
};

const EditUserForm = ({
  user,
  error,
  isBuy,
  isSeller,
  onEditUser,
  userToEdit,
  usersByCompany,
}: EditUserFormProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [parentId, setParentId] = useState(userToEdit?.parent?.id);

  const preloadedValues = {
    fullName: userToEdit?.fullName,
    phone: userToEdit?.phone,
    userCompanyType: userToEdit?.userCompanyType,
  };

  const formOptions = {
    resolver: yupResolver(
      editUserValidationSchema(user?.id === userToEdit?.id)
    ) as any,
    defaultValues: preloadedValues,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>(formOptions);

  const onSubmit = (data: any) => {
    if (!userToEdit) return;
    if (parentId) {
      onEditUser({
        userId: userToEdit?.id,
        body: {
          ...userToEdit,
          ...data,
          company: { id: userToEdit.company.id },
          parent: { id: parentId },
        },
      });
    } else {
      onEditUser({
        userId: userToEdit?.id,
        body: {
          ...userToEdit,
          ...data,
        },
      });
    }
    setOpen(false);
  };

  const onError = () => {
    setOpen(false);
  };

  return (
    <div className="px-4 mt-6">
      <h2 className="uppercase text-xl font-bold leading-7 text-gray-900 sm:truncate">
        {t("users.edit")}
      </h2>
      <form
        className="space-y-8 divide-y divide-gray-200 mt-8"
        autoComplete="off"
      >
        <div className="mt-10 sm:mt-0">
          <div className="mt-5 md:mt-0">
            <div className="shadow border overflow-hidden sm:rounded p-4">
              {error && (
                <p className="text-red-600 text-sm pb-4">
                  {t("users.emailExists")}
                </p>
              )}
              <div className="pb-4">
                <InputField
                  id="fullName"
                  name="fullName"
                  type="text"
                  label={t("users.fullName")}
                  errorName={errors?.fullName}
                  errorMessage={errors?.fullName?.message}
                  required
                  register={register}
                />
              </div>

              <div className="pb-4">
                <InputField
                  id="phone"
                  name="phone"
                  type="text"
                  label={t("users.phone")}
                  errorName={errors?.phone}
                  errorMessage={errors?.phone?.message}
                  required
                  register={register}
                />
              </div>

              {user?.id !== userToEdit?.id && (
                <div className="pb-4">
                  <label
                    htmlFor="userCompanyType"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {t("users.role")}
                    <span className="text-sm text-red-600"> *</span>
                  </label>
                  <select
                    {...register("userCompanyType", { required: true })}
                    id="userCompanyType"
                    name="userCompanyType"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                  >
                    {isBuy && (
                      <>
                        {user?.userCompanyType === USER_COMPANY_TYPES.ADMIN && (
                          <Options list={USER_COMPANY_LIST_FOR_BUYER_ADMIN} />
                        )}
                        {user?.userCompanyType ===
                          USER_COMPANY_TYPES.MANAGER && (
                          <Options list={USER_COMPANY_LIST_FOR_BUYER_MANAGER} />
                        )}
                        {user?.userCompanyType === USER_COMPANY_TYPES.BUYER && (
                          <Options list={USER_COMPANY_LIST_FOR_BUYER} />
                        )}
                      </>
                    )}
                    {isSeller && (
                      <>
                        {user?.userCompanyType === USER_COMPANY_TYPES.ADMIN && (
                          <Options list={USER_COMPANY_LIST_FOR_SELLER_ADMIN} />
                        )}
                        {user?.userCompanyType ===
                          USER_COMPANY_TYPES.MANAGER && (
                          <Options
                            list={USER_COMPANY_LIST_FOR_SELLER_MANAGER}
                          />
                        )}
                        {user?.userCompanyType ===
                          USER_COMPANY_TYPES.COMMERCIAL && (
                          <Options list={USER_COMPANY_LIST_FOR_SELLER} />
                        )}
                      </>
                    )}
                  </select>
                  {errors?.userCompanyType && (
                    <span className="text-xs text-red-500">
                      {errors?.userCompanyType?.message}
                    </span>
                  )}
                </div>
              )}

              {user?.id !== userToEdit?.id && (
                <div className="pb-4">
                  <label
                    htmlFor="parent"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {t("users.responsible")}
                    {" - "}
                    <span className="text-spectum">
                      {userToEdit?.parent?.fullName}
                    </span>
                  </label>
                  <select
                    {...register("parent", { required: true })}
                    id="parent"
                    name="parent"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                    onChange={(e) => setParentId(e.target.value)}
                  >
                    {usersByCompany
                      ?.filter((user) => user.id !== userToEdit?.id)
                      ?.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.fullName}
                        </option>
                      ))}
                    <option value={user?.id}>{user?.fullName}</option>
                  </select>
                </div>
              )}

              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex items-center justify-end gap-2">
                <Button link variant="cancel" to="/users">
                  {t("cta.cancel")}
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                >
                  {t("cta.edit")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={t("users.edit")}
        message={t("users.editQuestion")}
        onClickSuccess={handleSubmit(onSubmit, onError)}
        onClickCancel={() => setOpen(false)}
      />
    </div>
  );
};

export default connect(mapStateToProps)(EditUserForm);
