import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { Button } from "..";
import { useLanguage } from "../../hooks";
import { CountryResponse, MaterialResponse } from "../../types";

type NewOfferMaterialFormProps = {
  errors: any;
  setOpen: (value: boolean) => void;
  material: MaterialResponse;
  register: any;
  countries?: CountryResponse[];
  isLoadingUpload: boolean;
  specificationName: string;
  handleSpecification: (e: any) => void;
  handleAddCertification: (certification: any) => void;
};

const NewOfferMaterialForm = ({
  errors,
  setOpen,
  material,
  register,
  countries,
  isLoadingUpload,
  specificationName,
  handleSpecification,
  handleAddCertification,
}: NewOfferMaterialFormProps) => {
  const { t } = useTranslation();
  const langEs = useLanguage();
  return (
    <form
      className="space-y-8 divide-y divide-gray-200 mt-8"
      autoComplete="off"
    >
      <div className="mt-10 sm:mt-0">
        <div className="mt-5 md:mt-0">
          <div className="xl:shadow overflow-hidden">
            <h4 className="text-sm font-bold text-gray-900 uppercase xl:px-6 py-4">
              {t("materialOffer.title")}
            </h4>
            <div className="grid grid-cols-2 gap-4 bg-white sm:px-6 sm:pb-6">
              <div className="col-span-2">
                <label
                  htmlFor="origin"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {t("materialOffer.origin")}
                  <span className="text-sm text-red-600"> *</span>
                </label>
                <select
                  {...register("origin", { required: true })}
                  id="origin"
                  name="origin"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                  defaultValue=""
                >
                  <option value="">{t("materialOffer.select")}</option>
                  {countries?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {langEs ? country.name : country.nameEn}
                    </option>
                  ))}
                </select>
                {errors?.origin && (
                  <span className="text-xs text-red-500">
                    {errors?.origin?.message}
                  </span>
                )}
              </div>

              {material?.certifications?.length > 0 && (
                <div className="col-span-2">
                  <p className="uppercase block text-xs font-medium text-gray-700">
                    {t("certifications.certifications")}
                    {" - "}
                    <span className="text-red-500 text-xxs">
                      {t("certifications.certificationRequired")} *
                    </span>
                  </p>
                  <div className="flex flex-col gap-2 mt-2">
                    {material.certifications.map((certification) => (
                      <label
                        key={certification.name}
                        className="flex items-center gap-2 text-xs"
                      >
                        <input
                          type="checkbox"
                          className="shadow rounded text-spectum"
                          onClick={() => handleAddCertification(certification)}
                        />
                        {certification.name}
                        {certification.required && (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                    ))}
                  </div>
                </div>
              )}

              <div className="col-span-2">
                <div className="flex gap-4 items-center">
                  <label className="uppercase block text-xs font-medium text-gray-700">
                    {t("newMaterialForm.specification")}
                  </label>
                </div>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {specificationName && <span>{specificationName}</span>}

                    <div className="text-xs text-gray-600">
                      <label
                        htmlFor="specificationFileUrl"
                        className="uppercase relative cursor-pointer bg-white rounded font-medium text-spectum hover:text-spectum-light focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-spectum-light"
                      >
                        {isLoadingUpload ? (
                          <div className="flex w-full items-center justify-center">
                            <ClipLoader loading size={30} />
                          </div>
                        ) : (
                          <span>{t("newMaterialForm.upload")}</span>
                        )}
                        <input
                          {...register("specificationFileUrl")}
                          id="specificationFileUrl"
                          name="specificationFileUrl"
                          type="file"
                          className="sr-only"
                          onChange={(e) => handleSpecification(e)}
                        />
                      </label>
                    </div>

                    <p className="text-xs text-gray-500">PDF 10MB</p>
                  </div>
                </div>

                <div>
                  {errors?.specificationFileUrl && (
                    <span className="text-xs text-red-500">
                      {errors?.specificationFileUrl?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
              >
                {t("materialOffer.cta")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewOfferMaterialForm;
