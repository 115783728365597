import { WHATSAPP } from "../../utils";
import i18next from "../../i18n";

const Blocked = () => {
  return (
    <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main>
          <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl uppercase text-center">
            {i18next.t("notFound.blocked")}
          </p>
          <div className="sm:ml-6">
            <div className="sm:pl-6">
              <p className="mt-4 text-base text-gray-500">
                {i18next.t("notFound.blockedMessage")}{" "}
                <a
                  href={WHATSAPP}
                  target="_blank"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  rel="noreferrer"
                >
                  {i18next.t("notFound.help")}
                </a>{" "}
                {i18next.t("notFound.unblock")}
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Blocked;
