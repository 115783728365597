type SelectCardProps = {
  label: string;
  selected: boolean;
  onClick: () => void;
};

export default function SelectCard({
  label,
  selected,
  onClick,
}: SelectCardProps) {
  return (
    <div
      onClick={onClick}
      className={`flex items-center justify-center border w-full rounded p-2 shadow-md  hover:text-white hover:bg-spectum-dark cursor-pointer ${
        selected ? "text-white bg-spectum-dark" : "text-spectum-dark"
      }`}
    >
      <p className="uppercase font-bold text-sm">{label}</p>
    </div>
  );
}
