import { PILL_LABELS, BIDDING_LABELS } from "../../../utils";

const labelToStyle = {
  [BIDDING_LABELS.IN_PROGRESS]: "bg-purple-100 text-purple-800",
  [BIDDING_LABELS.OFFER_NOW]: "bg-yellow-100 text-yellow-800",
  [PILL_LABELS.OFFER_PENDING_OF_SELECTION]: "bg-yellow-100 text-yellow-800",
  [BIDDING_LABELS.SCHEDULED]: "bg-blue-100 text-blue-800",
  [BIDDING_LABELS.CLOSED]: "bg-red-100 text-red-800",
  [PILL_LABELS.OFFER_NOT_SELECTED]: "bg-red-100 text-red-800",
  [BIDDING_LABELS.INACTIVE]: "bg-red-100 text-red-800",
  [PILL_LABELS.BIDDING_CANCELED]: "bg-red-100 text-red-800",
  [PILL_LABELS.OFFER_SELECTED]: "bg-green-100 text-green-800",
  [BIDDING_LABELS.ACTIVE]: "bg-green-100 text-green-800",
  [BIDDING_LABELS.HAS_OFFER]: "bg-green-100 text-green-800",
  [PILL_LABELS.DRAFT]: "bg-gray-100 text-gray-800",
  [PILL_LABELS.REJECTED]: "bg-red-100 text-red-800",
  [PILL_LABELS.DELIVERED]: "bg-blue-100 text-blue-800",
};

export const Pill = ({ label }: { label: string }) => {
  const styles = labelToStyle[label] || "";

  return (
    <span
      className={`px-2 inline-flex text-xxs leading-5 font-semibold rounded ${styles}`}
    >
      {label}
    </span>
  );
};

export default Pill;
