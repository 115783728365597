import { CompanyResponse } from "./company.type";

export enum UserType {
  BUYER = "BUYER",
  SELLER = "SELLER",
  ADMIN = "ADMIN",
}

export enum UserRole {
  USER = "USER",
  BUYER = "BUYER",
  SELLER = "SELLER",
  ADMIN = "ADMIN",
  RESET_PASSWORD = "RESET_PASSWORD",
  BLOCKED = "BLOCKED",
  ADMIN_APPROVAL = "ADMIN_APPROVAL",
}

export enum UserCompanyType {
  BUYER = "BUYER",
  MANAGER = "MANAGER",
  ANALYST = "ANALYST",
  VISUALIZER = "VISUALIZER",
  COMMERCIAL = "COMMERCIAL",
  REQUESTER = "REQUESTER",
  DEFAULT = "DEFAULT",
  ADMIN = "ADMIN",
}

export interface UserResponse {
  createdAt: string; // ISO 8601 date string
  updatedAt: string; // ISO 8601 date string
  id: string; // UUID
  fullName: string;
  email: string;
  phone: string;
  imageUrl: string | null;
  type: UserType;
  userCompanyType: UserCompanyType;
  role: UserRole;
  localization: string; // Could be a language code like "es" or "en"
  company: CompanyResponse;
  parent?: UserResponse; // Assuming this refers to a parent user or entity
  active: boolean;
  compliant: boolean;
}
