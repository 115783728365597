import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { connect } from "react-redux";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useID } from "../../hooks";
import { Button, GoBackArrow, Loader, Modal } from "../../components";
import i18next from "../../i18n";
import MultiplePurchaseRequestSummaryDetail from "./MultiplePurchaseRequestSummaryDetail/MultiplePurchaseRequestSummaryDetail";
import BidHeader from "../Bid/BidHeader";
import {
  cutIdOnFirstHyphen,
  mapDispatchToProps,
  mapStateToProps,
} from "../../utils";
import { OrderResponse, OrderState } from "../../types";
import {
  useDeletePurchaseRequest,
  useMultiplePurchaseRequest,
  useRejectPurchaseRequest,
  useUpdatePurchaseRequest,
} from "../../hooks/usePurchaseRequests";

const PurchaseRequestMultipleDetail = ({
  isRequester,
  onOpenNotification,
}: {
  isRequester: boolean;
  onOpenNotification: (message: string) => void;
}) => {
  const id = useID();
  const navigate = useNavigate();
  const [openReject, setOpenReject] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");

  const {
    data: multiplePurchaseRequest,
    isLoading: isLoadingPurchaseRequests,
    isError: purchaseRequestError,
  } = useMultiplePurchaseRequest(
    isRequester
      ? `/purchase-request/user/multiple/id/`
      : `/purchase-request/user/multiple/order/id/`,
    id
  );

  const { mutate: deletePurchaseRequest, isPending: isDeleting } =
    useDeletePurchaseRequest(
      () => {
        onOpenNotification(i18next.t("purchaseRequests.deleteMessage"));
        navigate("/");
      },
      () => onOpenNotification(i18next.t("newMaterialForm.error"))
    );

  const { mutate: rejectPurchaseRequest, isPending: isRejecting } =
    useRejectPurchaseRequest(
      () => {
        onOpenNotification(i18next.t("purchaseRequests.rejectedSuccess"));
        return navigate("/purchase-requests");
      },
      () => onOpenNotification(i18next.t("newMaterialForm.error"))
    );

  const { mutate: updatePurchaseRequest } = useUpdatePurchaseRequest(
    () => {
      onOpenNotification(i18next.t("purchaseRequests.sendSuccess"));
      navigate("/");
    },
    () => onOpenNotification(i18next.t("newMaterialForm.error"))
  );

  const onChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) =>
    setRejectMessage(e.target.value);

  const handleReject = () => {
    if (!rejectMessage) return;
    rejectPurchaseRequest({
      purchaseRequestId: id,
      body: { comment: rejectMessage },
    });
    setOpenReject(false);
  };

  const handleDelete = () => {
    deletePurchaseRequest(id);
    setOpenDelete(false);
  };

  const handleSend = () => {
    setOpenSend(true);
  };

  const handleUpdate = () => {
    const purchaseRequests = multiplePurchaseRequest.orders.map(
      (order: OrderResponse) => ({
        ...order,
        state: OrderState.DELIVERED,
      })
    );
    updatePurchaseRequest(purchaseRequests);
    setOpenSend(false);
  };

  if (purchaseRequestError) navigate("/not-found");

  if (
    multiplePurchaseRequest &&
    multiplePurchaseRequest.orders.find(
      (order: OrderResponse) => order.state === OrderState.OPEN
    )
  ) {
    navigate(
      `/order/multiple/${multiplePurchaseRequest.orders[0].id}?multipleOrderId=${id}`
    );
  }

  return (
    <>
      <Modal
        open={openSend}
        setOpen={setOpenSend}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("purchaseRequests.send")}
        message={i18next.t("purchaseRequests.sendMessage")}
        onClickSuccess={handleUpdate}
        onClickCancel={() => setOpenSend(false)}
      />
      <Modal
        open={openReject}
        setOpen={setOpenReject}
        hasMessage
        disabled={!rejectMessage}
        onChangeMessage={onChangeMessage}
        icon={
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("purchaseRequests.reject")}
        message={i18next.t("purchaseRequests.rejectQuestion")}
        isCancel
        onClickSuccess={handleReject}
        onClickCancel={() => setOpenReject(false)}
      />
      <Modal
        open={openDelete}
        setOpen={setOpenDelete}
        hasMessage
        disabled={!rejectMessage}
        onChangeMessage={onChangeMessage}
        icon={
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("purchaseRequests.modalTitleDelete")}
        message={i18next.t("purchaseRequests.modalDelete")}
        isCancel
        onClickSuccess={handleDelete}
        onClickCancel={() => setOpenDelete(false)}
      />
      <div className="min-h-full flex px-6">
        <div className="flex flex-col w-0 flex-1">
          <div className="py-8 xl:py-10 relative">
            <div className="w-full flex justify-between items-center gap-4">
              <GoBackArrow />
              {isRequester ? (
                <div className="flex gap-2 items-center">
                  {(multiplePurchaseRequest?.orders[0]?.state ===
                    OrderState.DRAFT ||
                    multiplePurchaseRequest?.orders[0]?.state ===
                      OrderState.DELIVERED ||
                    multiplePurchaseRequest?.orders[0]?.state ===
                      OrderState.REJECTED) && (
                    <>
                      <Button
                        onClick={() => setOpenDelete(true)}
                        variant="danger"
                      >
                        {i18next.t("purchaseRequests.delete")}
                      </Button>
                      <Button
                        link
                        to={`/new-purchase-request?multiplePurchaseRequestId=${id}`}
                        variant="cancel"
                      >
                        {i18next.t("cta.edit")}
                      </Button>
                      {multiplePurchaseRequest?.orders?.[0]?.state !==
                        OrderState.DELIVERED && (
                        <Button onClick={handleSend}>
                          {i18next.t("cta.send")}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="flex gap-2 items-center">
                  <Button onClick={() => setOpenReject(true)} variant="danger">
                    {i18next.t("purchaseRequests.reject")}
                  </Button>
                  <Button
                    link
                    to={`/order-by-request?multiplePurchaseRequestId=${id}`}
                  >
                    {i18next.t("cta.launch")}
                  </Button>
                </div>
              )}
            </div>
            {isLoadingPurchaseRequests || isRejecting || isDeleting ? (
              <Loader />
            ) : (
              <>
                {multiplePurchaseRequest?.orders?.length > 0 && (
                  <>
                    <h1 className="font-bold text-spectum uppercase mb-4">
                      {i18next.t("tenders.form.internalCode")}
                      {": "}
                      {cutIdOnFirstHyphen(id)}
                    </h1>
                    <BidHeader
                      order={multiplePurchaseRequest?.orders[0]}
                      orderName={multiplePurchaseRequest?.multipleOrder?.name}
                    />
                    <MultiplePurchaseRequestSummaryDetail
                      multiplePurchaseRequest={multiplePurchaseRequest}
                      type={multiplePurchaseRequest?.[0]?.type}
                      isBid
                      specificationName={
                        multiplePurchaseRequest?.[0]?.plicationUrl
                      }
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseRequestMultipleDetail);
