import { connect } from "react-redux";
import { FormattedText, Loader, PoweredByAI } from "../../components";
import { useGlobalMarketInfo } from "../../hooks/useAI";
import i18next from "../../i18n";
import { mapStateToProps } from "../../utils";
import { UserResponse } from "../../types";

const MarketInfo = ({ user }: { user: UserResponse }) => {
  const {
    data: globalMarketInfo,
    isLoading,
    isError,
  } = useGlobalMarketInfo(user?.id);

  return (
    <div className="px-4 mt-6">
      <div className="md:flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {i18next.t("navigation.marketInfo")}
        </h2>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isError ? (
            <p className="text-xs">{i18next.t("messages.noMarketInfo")}</p>
          ) : (
            <div className="shadow border rounded px-2 py-3 relative my-1">
              <PoweredByAI />
              <FormattedText text={globalMarketInfo?.text} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(MarketInfo);
