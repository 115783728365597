import { connect } from "react-redux";
import {
  CheckCircleIcon,
  BuildingOffice2Icon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { mapStateToProps } from "../../utils";
import { useTranslation } from "react-i18next";
import { UserResponse, UserRole } from "../../types";

type UserProps = {
  user: UserResponse;
  isBuyer: boolean;
  isManager: boolean;
  isAnalyst: boolean;
  isAdmin: boolean;
  isVisualizer: boolean;
  isSeller: boolean;
  isCommercial: boolean;
};

const User = ({
  user,
  isAdmin,
  isBuyer,
  isManager,
  isAnalyst,
  isVisualizer,
  isCommercial,
}: UserProps) => {
  const { t } = useTranslation();

  const userType = (() => {
    const userTypeLabels = [
      isBuyer && t("userTypes.buyer"),
      isManager && t("userTypes.manager"),
      isAnalyst && t("userTypes.analyst"),
      isVisualizer && t("userTypes.visualizer"),
      isAdmin && t("userTypes.admin"),
      isCommercial && t("userTypes.commercial"),
    ];
    return userTypeLabels.filter((label) => label).join(", ");
  })();

  return (
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              {user?.imageUrl ? (
                <img
                  className="hidden h-16 w-16 rounded-full sm:block"
                  src={user?.imageUrl}
                  alt={`${user?.fullName} - ${user?.company?.name}`}
                />
              ) : (
                <UserIcon className="hidden h-16 w-16 rounded-full sm:block text-spectum" />
              )}
              <div>
                <h1 className="ml-3 text-xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                  {user?.fullName}
                  {userType && (
                    <span className="text-sm text-gray-500 uppercase">
                      ({userType})
                    </span>
                  )}
                </h1>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt className="sr-only">Company</dt>
                  <dd className="flex items-center text-xs text-gray-500 font-medium capitalize sm:mr-6">
                    <BuildingOffice2Icon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {user?.company?.name}
                  </dd>
                  {user?.role === UserRole.USER && (
                    <dd className="mt-3 flex items-center text-xs text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                      <CheckCircleIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600"
                        aria-hidden="true"
                      />
                      {t("users.verified")}
                    </dd>
                  )}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(User);
