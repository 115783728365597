import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Complete() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="mt-6">
        <h2 className="text-gray-900 text-sm font-semibold">
          {t("register.form.complete")}
        </h2>
        <p className="text-gray-700 mt-2 text-xs">
          {t("register.form.have")}{" "}
          <Link to="/" className="font-medium text-spectum-dark underline">
            {t("register.form.login")}
          </Link>
        </p>
      </div>
    </div>
  );
}
