import { Fragment } from "react";
import { TO_BE_AGREED } from "../../../utils";
import i18next from "../../../i18n";
import {
  MaterialResponse,
  MaterialWithSuppliersPaginatedResponse,
  MaterialWithSuppliersResponse,
} from "../../../types";

type MaterialsCheckboxListProps = {
  materials?: MaterialWithSuppliersPaginatedResponse;
  isChecked: any;
  searchTerm?: string;
  foundMaterials?: any;
  handleCheckboxChange: (e: any, material: MaterialResponse) => void;
};

const MaterialsCheckboxList = ({
  materials,
  isChecked,
  searchTerm,
  foundMaterials,
  handleCheckboxChange,
}: MaterialsCheckboxListProps) => {
  return (
    <fieldset className="space-y-5">
      {materials?.content?.length === 0 && searchTerm !== "" && (
        <p className="text-sm pt-6 text-red-500">
          {i18next.t("tenders.notFound")}
        </p>
      )}
      {isChecked.map((material: MaterialResponse) => (
        <Fragment key={material.id}>
          <legend className="sr-only">{material.globalMaterialName}</legend>
          <div className="flex items-center gap-4">
            <div className="relative flex items-center">
              <div className="flex items-center h-5">
                <input
                  id={material.id}
                  aria-describedby="comments-description"
                  name={material.id}
                  type="checkbox"
                  checked={
                    !!isChecked.find((mat: any) => mat.id === material.id)
                  }
                  className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                  onChange={(e) => handleCheckboxChange(e, material)}
                />
              </div>
              <div className="ml-3 text-xs">
                <label
                  htmlFor={material.id}
                  className="font-medium text-gray-700"
                >
                  <span className="text-gray-500">{material.ean}</span>{" "}
                  <span className="uppercase text-spectum-dark">
                    {material.globalMaterialName}
                  </span>
                </label>
                {" - "}
                <span className="text-gray-700 text-xs">
                  {material.deliveryPlace?.name ?? TO_BE_AGREED} {" - "}
                </span>
                <span className="text-gray-700 text-xs">
                  {material?.deliveryFormat}
                </span>
              </div>
            </div>
          </div>
        </Fragment>
      ))}
      {isChecked.length > 0 && <div className="h-0.5 bg-gray-300 w-full"></div>}
      {!searchTerm
        ? materials?.content
            ?.filter((a: MaterialWithSuppliersResponse) => a.material.active)
            ?.sort(
              (
                a: MaterialWithSuppliersResponse,
                b: MaterialWithSuppliersResponse
              ) =>
                a.material.globalMaterialName.localeCompare(
                  b.material.globalMaterialName
                )
            )
            .map(({ material }: { material: MaterialResponse }) => {
              if (
                !isChecked.find(
                  (mat: MaterialResponse) => mat.id === material.id
                )
              ) {
                return (
                  <Fragment key={material.id}>
                    <legend className="sr-only">
                      {material.globalMaterialName}
                    </legend>
                    <div className="flex items-center gap-4">
                      <div className="relative flex items-center">
                        <div className="flex items-center h-5">
                          <input
                            id={material.id}
                            aria-describedby="comments-description"
                            name={material.id}
                            type="checkbox"
                            className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                            onChange={(e) => handleCheckboxChange(e, material)}
                          />
                        </div>
                        <div className="ml-3 text-xs">
                          <label
                            htmlFor={material.id}
                            className="font-medium text-gray-700"
                          >
                            <span className="text-gray-500">
                              {material.ean}
                            </span>{" "}
                            <span className="uppercase text-spectum-dark">
                              {material.globalMaterialName}
                            </span>
                          </label>
                          {" - "}
                          <span className="text-gray-700 text-xs">
                            {material.deliveryPlace?.name ?? TO_BE_AGREED}{" "}
                            {" - "}
                          </span>
                          <span className="text-gray-700 text-xs">
                            {material?.deliveryFormat}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              }
            })
        : foundMaterials?.content
            ?.filter((a: MaterialResponse) => a.active)
            ?.sort((a: MaterialResponse, b: MaterialResponse) =>
              a.globalMaterialName.localeCompare(b.globalMaterialName)
            )
            .map((material: MaterialResponse) => (
              <Fragment key={material.id}>
                <legend className="sr-only">
                  {material.globalMaterialName}
                </legend>
                <div className="flex items-center gap-4">
                  <div className="relative flex items-center">
                    <div className="flex items-center h-5">
                      <input
                        id={material.id}
                        aria-describedby="comments-description"
                        name={material.id}
                        type="checkbox"
                        className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                        onChange={(e) => handleCheckboxChange(e, material)}
                      />
                    </div>
                    <div className="ml-3 text-xs">
                      <label
                        htmlFor={material.id}
                        className="font-medium text-gray-700"
                      >
                        <span className="text-gray-500">{material.ean}</span>{" "}
                        <span className="uppercase text-spectum-dark">
                          {material.globalMaterialName}
                        </span>
                      </label>{" "}
                      <span className="text-gray-700 text-xs">
                        ({material.deliveryPlace?.name ?? TO_BE_AGREED})
                      </span>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
    </fieldset>
  );
};

export default MaterialsCheckboxList;
