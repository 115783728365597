import { Routes, Route } from "react-router-dom";
import { Layout } from "../../components";
import { Home, Blocked } from "../../containers";

const BlockedRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Blocked />} />
      </Route>
    </Routes>
  );
};

export default BlockedRoutes;
