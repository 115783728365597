import { useReducer } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import i18next from "../../../i18n";
import { TooltipSP } from "../..";

const actions = {
  UPDATE_CODE: "UPDATE_CODE",
  UPDATE_START_SUPPLY_DATE: "UPDATE_START_SUPPLY_DATE",
  UPDATE_END_SUPPLY_DATE: "UPDATE_END_SUPPLY_DATE",
  UPDATE_PRICE: "UPDATE_PRICE",
  UPDATE_VOLUME: "UPDATE_VOLUME",
};

type NegotiationProps = {
  coin: string;
  userId: string;
  companyId: string;
  materialId: string;
  measurementUnit: string;
  cancelNegotiation: boolean;
  onCancelNegotiation: (prevState: boolean) => any;
  onCreateNegotiation: (data: { userId: string; body: any }) => void;
};

export default function Negotiation({
  coin,
  userId,
  companyId,
  materialId,
  measurementUnit,
  cancelNegotiation,
  onCancelNegotiation,
  onCreateNegotiation,
}: NegotiationProps) {
  const [newNegotiation, updateNewNegotiation] = useReducer(
    (state: any, action: any) => {
      let newState = { ...state };
      switch (action.type) {
        case actions.UPDATE_CODE:
          newState = {
            ...newState,
            code: action.code,
            disabled: true,
          };
          break;
        case actions.UPDATE_START_SUPPLY_DATE:
          newState = {
            ...newState,
            startDate: action.startDate,
            finishDate: "",
            disabled: true,
          };
          break;
        case actions.UPDATE_END_SUPPLY_DATE:
          newState = {
            ...newState,
            finishDate: action.finishDate,
            disabled: true,
          };
          break;
        case actions.UPDATE_PRICE:
          newState = {
            ...newState,
            price: action.price,
            disabled: true,
          };
          break;
        case actions.UPDATE_VOLUME:
          newState = {
            ...newState,
            volume: action.volume,
            disabled: true,
          };
          break;
      }

      if (
        newState.startDate &&
        newState.finishDate &&
        newState.price > 0 &&
        newState.volume > 0
      ) {
        newState = {
          ...newState,
          disabled: false,
        };
      }

      return newState;
    },
    {
      code: "",
      startDate: "",
      finishDate: "",
      price: 0,
      volume: 0,
      disabled: true,
      material: { id: materialId },
      company: { id: companyId },
    }
  );

  const handleNegotiation = () => {
    if (!userId) return;
    onCreateNegotiation({
      userId,
      body: {
        ...newNegotiation,
        startDate: new Date(newNegotiation.startDate).toISOString(),
        finishDate: new Date(newNegotiation.finishDate).toISOString(),
        price: +parseFloat(newNegotiation.price).toFixed(1),
        volume: +parseFloat(newNegotiation.volume).toFixed(1),
      },
    });
  };

  return (
    <div className="rounded shadow border px-2 py-6 mt-2">
      <div className="md:flex items-center gap-3 w-full">
        <div className="w-full">
          <label
            htmlFor="code"
            className="uppercase block text-xs font-medium text-gray-700"
          >
            {i18next.t("tenders.form.internalCode")}
          </label>
          <input
            className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
            id="code"
            name="code"
            type="text"
            value={newNegotiation.code}
            onChange={(e) =>
              updateNewNegotiation({
                code: e.target.value,
                type: actions.UPDATE_CODE,
              })
            }
            required
          />
        </div>
        <div className="md:flex items-center gap-3 relative w-full">
          <div className="absolute -top-5 left-0">
            <p className="uppercase text-xs font-medium text-gray-700 pb-2 flex gap-1">
              {i18next.t("tenders.form.tenderPeriod")}
              <QuestionMarkCircleIcon
                className="h-4 w-4"
                id={`tender-period`}
              />
            </p>
            <TooltipSP
              id={`tender-period`}
              message={i18next.t("tenders.form.periodInfo")}
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="startDate"
              className="uppercase block text-xs font-medium text-gray-700"
            >
              {i18next.t("tenders.form.from")}
            </label>
            <input
              className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
              id="startDate"
              name="startDate"
              type="date"
              value={newNegotiation.startDate}
              onChange={(e) =>
                updateNewNegotiation({
                  startDate: e.target.value,
                  type: actions.UPDATE_START_SUPPLY_DATE,
                })
              }
              required
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="finishDate"
              className="uppercase block text-xs font-medium text-gray-700"
            >
              {i18next.t("tenders.form.to")}
            </label>
            <input
              className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
              id="finishDate"
              name="finishDate"
              type="date"
              min={newNegotiation.startDate}
              disabled={!newNegotiation.startDate}
              onChange={(e) =>
                updateNewNegotiation({
                  finishDate: e.target.value,
                  type: actions.UPDATE_END_SUPPLY_DATE,
                })
              }
              required
            />
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="price"
            className="uppercase block text-xs font-medium text-gray-700"
          >
            {i18next.t("award.price")}{" "}
            <span className="text-gray-500 font-bold uppercase">({coin})</span>
          </label>
          <input
            className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
            id="price"
            name="price"
            type="number"
            min={0}
            value={newNegotiation.price}
            onChange={(e) =>
              updateNewNegotiation({
                price: e.target.value,
                type: actions.UPDATE_PRICE,
              })
            }
            required
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="volume"
            className="uppercase block text-xs font-medium text-gray-700"
          >
            {i18next.t("award.volume")}{" "}
            <span className="text-gray-500 font-bold uppercase">
              ({measurementUnit})
            </span>
          </label>
          <input
            className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
            id="volume"
            name="volume"
            type="number"
            min={0}
            value={newNegotiation.volume}
            onChange={(e) =>
              updateNewNegotiation({
                volume: e.target.value,
                type: actions.UPDATE_VOLUME,
              })
            }
            required
          />
        </div>
      </div>
      <div className="flex gap-4 pt-4 justify-end">
        <button
          onClick={handleNegotiation}
          disabled={newNegotiation.disabled}
          className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase ${
            newNegotiation.disabled
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-spectum hover:bg-spectum-light"
          }`}
        >
          {i18next.t("volumePrice.add")}
        </button>
        <button
          onClick={() => onCancelNegotiation(!cancelNegotiation)}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase mr-3"
        >
          {i18next.t("volumePrice.cancel")}
        </button>
      </div>
    </div>
  );
}
