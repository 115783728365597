import { TableTitle } from "..";
import i18next from "../../i18n";
import JointOrdersTableRow from "./JointOrdersTableRow/JointOrdersTableRow";
import { OrderResponse } from "../../types";

const tableTh = [
  { id: 1, name: i18next.t("tenders.form.internalCode") },
  { id: 2, name: i18next.t("tenders.tenderSummary.name") },
  { id: 3, name: i18next.t("tenders.tenderSummary.start") },
  { id: 4, name: i18next.t("tenders.tenderSummary.end") },
  { id: 5, name: i18next.t("tenders.tenderSummary.quantity") },
  { id: 6, name: i18next.t("tenders.tenderSummary.status") },
  { id: 7, name: "" },
];

type JointOrdersTableProps = {
  orders: [OrderResponse[]];
  title: string;
};

const JointOrdersTable = ({ orders, title }: JointOrdersTableProps) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        {title && <TableTitle title={title} />}
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {tableTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xxs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {orders?.map((order) => (
                <JointOrdersTableRow
                  key={order[0].id}
                  order={order[0]}
                  quantity={order.length}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JointOrdersTable;
