import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  PencilIcon,
  ScaleIcon,
  UsersIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import { Toggle } from "../..";
import { mapStateToProps } from "../../../utils";
import i18next from "../../../i18n";
import { MaterialResponse } from "../../../types";

type ActionsProps = {
  isOwner: boolean;
  isBuyer: boolean;
  isManager: boolean;
  isAnalyst: boolean;
  goToBidding: () => void;
  material: MaterialResponse;
  isResponsible: boolean;
  onClick: () => void;
  onClickRegain: () => void;
  onToggleMaterialStatus: (id: string) => void;
};

function Actions({
  isOwner,
  isBuyer,
  onClick,
  material,
  isManager,
  isAnalyst,
  goToBidding,
  isResponsible,
  onClickRegain,
  onToggleMaterialStatus,
}: ActionsProps) {
  return (
    <div className="mx-auto px-4 lg:px-8 grid grid-cols-4 md:items-center md:justify-between pb-4">
      <div className="col-span-4 xl:col-span-1">
        <h1 className="text-xl font-bold text-gray-900 uppercase">
          {material?.globalMaterialName}{" "}
          <span className="text-xs text-gray-500">{material?.ean}</span>
        </h1>
      </div>
      <div className="col-span-4 xl:col-span-3 mt-4 flex space-x-3 md:mt-0 items-center xl:justify-end py-2 xl:p-0">
        {(isBuyer || isManager || isAnalyst) &&
          material?.active &&
          isResponsible && (
            <button
              type="button"
              className="uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
              onClick={goToBidding}
            >
              <ScaleIcon
                className="-ml-1 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              <span>{i18next.t("cta.launch")}</span>
            </button>
          )}
        {isOwner ? (
          <Fragment>
            <button
              type="button"
              onClick={onClick}
              className="uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
            >
              <UsersIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
              <span>{i18next.t("material.delegate")}</span>
            </button>
            {material?.user?.id !== material?.owner?.id ? (
              <button
                type="button"
                onClick={onClickRegain}
                className="uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
              >
                <ArrowPathIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                <span>{i18next.t("material.retrieveMaterial")}</span>
              </button>
            ) : null}
            <Link
              type="button"
              className="uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
              to={`/material/edit/${material?.id}`}
            >
              <PencilIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Link>
            <div className="px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded">
              <div className="flex items-center space-x-2">
                <span
                  className={`${
                    material?.active ? "text-green-700" : "text-red-700"
                  } text-xs font-bold uppercase`}
                >
                  {material?.active
                    ? i18next.t("cta.active")
                    : i18next.t("cta.inactive")}
                </span>
                <Toggle
                  enabled={material?.active}
                  onChange={() => onToggleMaterialStatus(material?.id)}
                />
              </div>
            </div>
          </Fragment>
        ) : null}
        {isResponsible && !isOwner && (
          <Fragment>
            <Link
              type="button"
              className="uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
              to={`/material/edit/${material?.id}`}
            >
              <PencilIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Link>
            <div className="px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded">
              <div className="flex items-center space-x-2">
                <span
                  className={`${
                    material?.active ? "text-green-700" : "text-red-700"
                  } text-xs font-bold uppercase`}
                >
                  {material?.active
                    ? i18next.t("cta.active")
                    : i18next.t("cta.inactive")}
                </span>
                <Toggle
                  enabled={material?.active}
                  onChange={() => onToggleMaterialStatus(material?.id)}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Actions);
