import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils";

const initialState = {
  user: null,
  isLoading: false,
  error: null,
  changeCompany: null,
  changeUserLanguage: null,
};

const getUserStart = (state: any) => {
  return updateObject(state, { isLoading: true, error: null });
};

const getUserSuccess = (state: any, action: any) => {
  return updateObject(state, {
    user: action.user,
    isLoading: false,
    error: null,
  });
};

const getUserFailed = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const changeUserLanguageStart = (state: any) => {
  return updateObject(state, {
    isLoading: true,
    error: null,
  });
};

const changeUserLanguageSuccess = (state: any, action: any) => {
  return updateObject(state, {
    user: {
      ...state.user,
      user: action.user.data,
    },
    changeUserLanguage: action.user,
    isLoading: false,
    error: null,
  });
};

const changeUserLanguageFailed = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const changeCompanyStart = (state: any) => {
  return updateObject(state, { isLoading: true, error: null });
};

const changeCompanySuccess = (state: any, action: any) => {
  return updateObject(state, {
    user: {
      ...state.user,
      user: action.user.data,
    },
    changeCompany: action.user,
    isLoading: false,
    error: null,
  });
};

const changeCompanyFailed = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_USER_START:
      return getUserStart(state);
    case actionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAILED:
      return getUserFailed(state, action);
    case actionTypes.CHANGE_USER_LANGUAGE_START:
      return changeUserLanguageStart(state);
    case actionTypes.CHANGE_USER_LANGUAGE_SUCCESS:
      return changeUserLanguageSuccess(state, action);
    case actionTypes.CHANGE_USER_LANGUAGE_FAILED:
      return changeUserLanguageFailed(state, action);
    case actionTypes.CHANGE_COMPANY_START:
      return changeCompanyStart(state);
    case actionTypes.CHANGE_COMPANY_SUCCESS:
      return changeCompanySuccess(state, action);
    case actionTypes.CHANGE_COMPANY_FAILED:
      return changeCompanyFailed(state, action);
    default:
      return state;
  }
};

export default userReducer;
