import { useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { ORDER_TYPES, OrderResponse } from "../types";
import { queryKeys } from "./queryKeys";

const onGetOpportunities = async () => {
  const { data } = await axios.get(`/order/materials/suppliers`);
  return data;
};

export const useOpportunities = () =>
  useQuery({
    queryKey: [queryKeys.OPPORTUNITIES],
    queryFn: onGetOpportunities,
  });

const onGetMultipleOpportunities = async (
  type: ORDER_TYPES,
  _type: ORDER_TYPES
) => {
  const { data } = await axios.get(`order/multiple/suppliers/${type}/${_type}`);
  return data;
};

export const useMultipleOpportunities = (
  type: ORDER_TYPES,
  _type: ORDER_TYPES
) =>
  useQuery({
    queryKey: [queryKeys.MULTIPLE_OPPORTUNITIES, type, _type],
    queryFn: () => onGetMultipleOpportunities(type, _type),
    select: (data) => {
      const items = Object.keys(data).map((key) => data[key]);
      return items as [OrderResponse[]];
    },
  });
