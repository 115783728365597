import { Fragment, useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import {
  mapDispatchToProps,
  PN_TYPE,
  MATERIAL_TYPE,
  mapStateToProps,
} from "../../utils";
import { Steps, Search, Loader, Modal, Pagination } from "../../components";
import MaterialsCheckboxList from "./MaterialsCheckboxList/MaterialsCheckboxList";
import PriceDecrementPayment from "./PriceDecrementPayment/PriceDecrementPayment";
import MultipleMaterialsBiddingForm from "./MultipleMaterialsBiddingForm/MultipleMaterialsBiddingForm";
import MultipleBiddingSummary from "./MultipleBiddingSummary/MultipleBiddingSummary";
import { useDebounce, useFileInputUploader } from "../../hooks";
import {
  DELIVERY_TYPE,
  MaterialResponse,
  MaterialType,
  ORDER_TYPES,
  OrdersTuSumbitRequest,
  OrderState,
} from "../../types";
import {
  useAllCompanyMaterials,
  useSearchAllMaterials,
} from "../../hooks/useMaterials";
import {
  useCreateMultiplePurchaseRequest,
  useCreatePurchaseRequest,
  useMultiplePurchaseRequest,
  usePurchaseRequest,
  useUpdatePurchaseRequest,
} from "../../hooks/usePurchaseRequests";

const actions = {
  UPDATE_NAME: "UPDATE_NAME",
  ADD_FILE: "ADD_FILE",
  REMOVE_FILE: "REMOVE_FILE",
};

const initalState = {
  files: [],
  name: "",
};

type PurchaseRequestProps = {
  isRequester: boolean;
  onOpenNotification: (message: string) => void;
};

const PurchaseRequest = ({
  isRequester,
  onOpenNotification,
}: PurchaseRequestProps) => {
  const [searchParams] = useSearchParams();

  const purchaseRequestId = searchParams.get("purchaseRequestId");
  const multiplePurchaseRequestId = searchParams.get(
    "multiplePurchaseRequestId"
  );

  const navigate = useNavigate();
  const [searchMaterialsPage, setSearchMaterialsPage] = useState(0);
  const [materialsForBiddingPage, setMaterialsForBiddingPage] = useState(0);
  const [materialType, setMaterialType] = useState<MaterialType | string>("");
  const [from, setFrom] = useState("");
  const [ordersToSubmit, setOrdersToSubmit] = useState<OrdersTuSumbitRequest[]>(
    []
  );
  const [generalBidData, setGeneralBidData] = useReducer(
    (state: any, action: any) => {
      let newEvent = { ...state };
      switch (action.type) {
        case actions.ADD_FILE:
          newEvent = {
            ...newEvent,
            files: [...newEvent.files, ...action.files],
          };
          break;
        case actions.REMOVE_FILE:
          newEvent = {
            ...newEvent,
            files: newEvent.files.filter(
              (_: any, index: number) => index !== action.index
            ),
          };
          break;
        case actions.UPDATE_NAME:
          newEvent = {
            ...newEvent,
            name: action.name,
          };
      }
      return newEvent;
    },
    initalState
  );
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([
    { id: 1, name: i18next.t("tenders.selectMaterials"), status: "current" },
    { id: 2, name: i18next.t("tenders.generalData"), status: "upcoming" },
    { id: 3, name: i18next.t("tenders.summary"), status: "upcoming" },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [activeStep, setActiveStep] = useState(1);
  const [disabled, setDisabled] = useState(true);

  const [specificationName, setSpecificationName] = useState("");

  const { debouncedSearchTerm, isSearching } = useDebounce(searchTerm, 1000);

  const { data: purchaseRequest, isLoading: isLoadingPurchaseRequest } =
    usePurchaseRequest(purchaseRequestId || "");

  const {
    mutate: createPurchaseRequest,
    isPending: isCreatingPurchaseRequest,
  } = useCreatePurchaseRequest(
    () => {
      onOpenNotification(i18next.t("purchaseRequests.success"));
      return navigate("/");
    },
    () => onOpenNotification(i18next.t("newMaterialForm.error"))
  );

  const {
    mutate: createMulitplePurchaseRequest,
    isPending: isCreatingMultiplePurchaseRequest,
  } = useCreateMultiplePurchaseRequest(
    () => {
      onOpenNotification(i18next.t("purchaseRequests.success"));
      return navigate("/");
    },
    () => onOpenNotification(i18next.t("newMaterialForm.error"))
  );

  const { mutate: updatePurchaseRequest } = useUpdatePurchaseRequest(
    () => {
      onOpenNotification(i18next.t("purchaseRequests.editSuccess"));
      return navigate("/");
    },
    () => onOpenNotification(i18next.t("newMaterialForm.error"))
  );

  const {
    data: multiplePurchaseRequest,
    isLoading: isLoadingMultiplePurchaseRequest,
  } = useMultiplePurchaseRequest(
    isRequester
      ? `/purchase-request/user/multiple/id/`
      : `purchase-request/user/multiple/order/id/`,
    multiplePurchaseRequestId || ""
  );

  const {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  } = useFileInputUploader();

  const { data: foundMaterials } = useSearchAllMaterials(
    materialType,
    debouncedSearchTerm,
    searchMaterialsPage
  );

  const { data: allCompanyMaterials, isLoading: isLoadingMaterials } =
    useAllCompanyMaterials(materialType, materialsForBiddingPage);

  const { handleSubmit, reset } = useForm();

  useEffect(() => {
    if (purchaseRequest && ordersToSubmit.length === 0) {
      const { order } = purchaseRequest;
      ordersToSubmit.push({
        ...order,
        orderId: order.id,
        id: order.material.id,
        ean: order.material.ean,
        coin: order.material.coin,
        incoTerm: order.material.incoTerm,
        measurementUnit: order.material.measurementUnit,
        globalMaterialName: order.material.globalMaterialName,
        deliveryPlace: order.material.deliveryPlace,
        fileUrl: order.material.fileURL,
        type: order.material.type,
        quantity:
          order.material.type === MaterialType.SERVICE ? 1 : order.quantity,
        startSupplyDate: order.startSupplyDate,
        files: order.files,
        endSupplyDate: order.endSupplyDate,
        spot: order.spot ? DELIVERY_TYPE.SPOT : DELIVERY_TYPE.PERIOD,
        suppliers: [],
      });
      setMaterialType(order.material.type);
      setGeneralBidData({
        type: actions.ADD_FILE,
        files: order?.files || [],
      });
      setGeneralBidData({
        type: actions.UPDATE_NAME,
        name: order?.name,
      });
    }
  }, [purchaseRequest]);

  useEffect(() => {
    if (multiplePurchaseRequest && ordersToSubmit.length === 0) {
      multiplePurchaseRequest.orders.forEach((order: any) => {
        ordersToSubmit.push({
          ...order,
          orderId: order.id,
          id: order.material.id,
          ean: order.material.ean,
          coin: order.material.coin,
          incoTerm: order.material.incoTerm,
          measurementUnit: order.material.measurementUnit,
          globalMaterialName: order.material.globalMaterialName,
          deliveryPlace: order.material.deliveryPlace,
          fileUrl: order.material.fileURL,
          type: order.material.type,
          quantity:
            order.material.type === MaterialType.SERVICE ? 1 : order.quantity,
          startSupplyDate: order.startSupplyDate,
          files: order.files,
          endSupplyDate: order.endSupplyDate,
          spot: order.spot ? DELIVERY_TYPE.SPOT : DELIVERY_TYPE.PERIOD,
          suppliers: [],
        });
      });
      setMaterialType(multiplePurchaseRequest.orders[0].material.type);
      setGeneralBidData({
        type: actions.ADD_FILE,
        files: multiplePurchaseRequest.orders[0]?.files || [],
      });
      setGeneralBidData({
        type: actions.UPDATE_NAME,
        name: multiplePurchaseRequest.multipleOrder.name,
      });
    }
  }, [multiplePurchaseRequest]);

  const handleNext = (step: any, from: any) => {
    if (step === 1) {
      setActiveStep((prevState) => prevState + 1);
      setDisabled(true);
    }
    if (activeStep === 2) {
      setActiveStep((prevState) => prevState + 1);
      setDisabled(true);
    }
    if (activeStep === 3) setActiveStep((prevState) => prevState + 1);
    if (activeStep === 4) {
      setOpen(true);
      setFrom(from);
    }
  };

  const handleBack = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const handleCheckboxChange = (e: any, material: MaterialResponse) => {
    const indexOfMaterial = ordersToSubmit.findIndex(
      (mat: any) => material.id === mat.id
    );
    if (indexOfMaterial === -1) {
      setOrdersToSubmit([
        ...ordersToSubmit,
        {
          id: material.id,
          ean: material.ean,
          coin: material.coin,
          incoTerm: material.incoTerm,
          measurementUnit: material.measurementUnit,
          globalMaterialName: material.globalMaterialName,
          deliveryPlace: material.deliveryPlace,
          deliveryFormat: material.deliveryFormat,
          fileUrl: material.fileURL,
          quantity: material.type === MaterialType.SERVICE ? 1 : "",
          type: material.type,
          owner: material.owner.id,
          spot: DELIVERY_TYPE.SPOT,
          email: material.owner.email,
          fullName: material.owner.fullName,
          company: material.company.id,
          suppliers: [],
        },
      ]);
      setDisabled(true);
    } else {
      const slicedOrder = [
        ...ordersToSubmit.slice(0, indexOfMaterial),
        ...ordersToSubmit.slice(indexOfMaterial + 1),
      ];

      setOrdersToSubmit(slicedOrder);
      setDisabled(false);
    }
  };

  const handlePriceDecrementChanges = (
    e: any,
    material: MaterialResponse,
    key: any
  ) => {
    setErrorMessage(null);
    setOrdersToSubmit((prevState: any) => {
      const newState = prevState.map((order: any) => {
        if (order.id === material.id) {
          return { ...order, [key]: e.target.value };
        }
        return order;
      });
      return newState;
    });
  };

  const handleSupplyPeriod = (e: any, type: any) => {
    setOrdersToSubmit((prevState: any) => {
      const newState = prevState.map((order: any) => {
        if (type === "start" && order.spot === DELIVERY_TYPE.SPOT) {
          return {
            ...order,
            startSupplyDate: e.target.value,
            endSupplyDate: e.target.value,
          };
        } else if (type === "start") {
          return {
            ...order,
            startSupplyDate: e.target.value,
            endSupplyDate: "",
          };
        } else if (type === "end") {
          return { ...order, endSupplyDate: e.target.value };
        }
        if (type === DELIVERY_TYPE.SPOT) {
          return {
            ...order,
            spot: e.target.value,
            startSupplyDate: order.startSupplyDate,
            endSupplyDate: order.startSupplyDate,
          };
        }
        return order;
      });
      return newState;
    });
  };

  const handleSpecification = (e: any) => {
    const file = e?.target?.files;
    if (file) {
      handleSpecificationUpload(file);
      setSpecificationName(file[0]?.name);
    }
  };

  const onSubmit = () => {
    const amountOfOrders = ordersToSubmit.length;
    if (multiplePurchaseRequestId || purchaseRequestId) {
      const dataToSubmit = ordersToSubmit.map((order: any, index: number) => {
        return {
          ...order,
          id: order.orderId,
          user: { id: order?.material?.owner?.id },
          quantity: parseFloat(order.quantity),
          material: { id: order.id },
          company: { id: order?.company?.id },
          email: order?.email,
          files: index === 0 ? generalBidData.files : null,
          plicationUrl: "",
          deliveryPlace: order?.deliveryPlace
            ? { id: order?.deliveryPlace?.id }
            : null,
          fullname: order?.fullName,
          name: generalBidData.name,
          application: order?.application,
          purpose: order?.purpose,
          fileUrl: order?.fileUrl,
          measurementUnit: order?.measurementUnit,
          incoTerm: order?.incoTerm,
          currency: order?.coin,
          spot: order?.spot === DELIVERY_TYPE.SPOT ? true : false,
          startSupplyDate: new Date(order?.startSupplyDate).toISOString(),
          endSupplyDate: new Date(order?.endSupplyDate).toISOString(),
          internalCode: order?.internalCode,
          type: amountOfOrders > 1 ? "JOINT" : "NORMAL",
          comment: order.state === OrderState.REJECTED && null,
          state:
            from === OrderState.DRAFT ? OrderState.DRAFT : OrderState.DELIVERED,
        };
      });
      updatePurchaseRequest(dataToSubmit);
    } else {
      const dataToSubmit = ordersToSubmit.map(
        (order: OrdersTuSumbitRequest) => {
          return {
            quantity: order.quantity ? parseFloat(String(order.quantity)) : 1,
            material: { id: order.id },
            user: { id: order?.owner },
            company: { id: order?.company },
            email: order?.email,
            files: generalBidData.files,
            plicationUrl: "",
            deliveryPlace: order?.deliveryPlace
              ? { id: order?.deliveryPlace?.id }
              : null,
            fullname: order?.fullName,
            name: generalBidData.name,
            application: order?.application,
            purpose: order?.purpose,
            fileUrl: order?.fileUrl,
            measurementUnit: order?.measurementUnit,
            incoTerm: order?.incoTerm,
            currency: order?.coin,
            spot: order?.spot === DELIVERY_TYPE.SPOT ? true : false,
            startSupplyDate:
              order?.startSupplyDate &&
              new Date(order?.startSupplyDate).toISOString(),
            endSupplyDate:
              order?.endSupplyDate &&
              new Date(order?.endSupplyDate).toISOString(),
            internalCode: order?.internalCode,
            type: amountOfOrders > 1 ? ORDER_TYPES.JOINT : ORDER_TYPES.NORMAL,
            state:
              from === OrderState.DRAFT
                ? OrderState.DRAFT
                : OrderState.DELIVERED,
          };
        }
      );
      ordersToSubmit.length > 1
        ? createMulitplePurchaseRequest(dataToSubmit)
        : createPurchaseRequest(dataToSubmit[0]);
    }
    setOpen(false);
    reset();
  };
  const onError = () => setOpen(false);

  const updateStepsStatus = () => {
    let objIndex = steps.findIndex((obj) => obj.id === activeStep);
    setSteps((prevState) => {
      const newState = prevState.map((step) => {
        if (step.id === objIndex + 1) {
          return { ...step, status: "current" };
        }
        if (step.id > objIndex + 1) {
          return { ...step, status: "upcoming" };
        }
        if (activeStep === 4) {
          return { ...step, status: "complete" };
        }
        return { ...step, status: "complete" };
      });
      return newState;
    });
    if (activeStep === 4) {
      setSteps((prevState) => {
        const newState = prevState.map((step) => ({
          ...step,
          status: "complete",
        }));
        return newState;
      });
    }
  };

  useEffect(() => {
    updateStepsStatus();
  }, [activeStep]);

  const verifySecondStep = () => {
    const quantity = ordersToSubmit?.every((el: any) => el.quantity > 0);
    const purpose = ordersToSubmit?.every((el: any) => el.purpose);
    const measurementUnit = ordersToSubmit?.every(
      (el: any) => el.measurementUnit
    );
    if (materialType === PN_TYPE.PI || materialType === PN_TYPE.NPI) {
      quantity ? setDisabled(false) : setDisabled(true);
    } else {
      purpose && measurementUnit ? setDisabled(false) : setDisabled(true);
    }
  };

  useEffect(() => {
    const startSupplyDate = ordersToSubmit?.every(
      (el: any) => el.startSupplyDate
    );
    const endSupplyDate = ordersToSubmit?.every((el: any) => el.endSupplyDate);
    if (activeStep === 1)
      ordersToSubmit?.length > 0 ? setDisabled(false) : setDisabled(true);
    if (activeStep === 2) verifySecondStep();
    if (activeStep === 3)
      startSupplyDate && endSupplyDate && generalBidData.name
        ? setDisabled(false)
        : setDisabled(true);
  });

  return (
    <div className="px-4 mt-6">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {i18next.t("purchaseRequests.add")}
      </h2>
      {isLoadingMultiplePurchaseRequest ||
      isLoadingPurchaseRequest ||
      isCreatingMultiplePurchaseRequest ||
      isCreatingPurchaseRequest ? (
        <div className="pt-8">
          <Loader />
        </div>
      ) : (
        <div className="mt-4 border p-4 rounded">
          <Steps steps={steps} />
          {activeStep === 1 && (
            <Fragment>
              {errorMessage && (
                <span className="uppercase text-sm text-red-700 font-bold">
                  {errorMessage}
                </span>
              )}
              <div className="col-span-4 md:col-span-1 mt-4">
                <label
                  htmlFor="type"
                  className="uppercase block text-xs font-medium text-gray-700"
                >
                  {i18next.t("newMaterialForm.type")}
                </label>
                <select
                  id="type"
                  name="type"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                  defaultValue=""
                  value={materialType}
                  onChange={(e) => {
                    setMaterialType(e.target.value);
                    setOrdersToSubmit([]);
                  }}
                >
                  <option value="">
                    {i18next.t("validations.selectedRequest")}
                  </option>
                  {MATERIAL_TYPE?.map((type) => (
                    <option key={type.id} value={type.value}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              {materialType && (
                <Search
                  value={searchTerm}
                  handleSearch={(e) => setSearchTerm(e.target.value)}
                  border
                />
              )}
              {isLoadingMaterials || isSearching ? (
                <div className="pt-8">
                  <Loader />
                </div>
              ) : (
                <>
                  {allCompanyMaterials?.content?.length > 0 &&
                    searchTerm === "" && (
                      <>
                        <MaterialsCheckboxList
                          isChecked={ordersToSubmit}
                          materials={allCompanyMaterials}
                          handleCheckboxChange={handleCheckboxChange}
                        />
                        <div className="w-full mt-5">
                          <Pagination
                            currentPage={materialsForBiddingPage}
                            setPageNumber={setMaterialsForBiddingPage}
                            results={allCompanyMaterials.numberOfElements}
                            total={allCompanyMaterials.totalElements}
                            amountOfPages={allCompanyMaterials.totalPages}
                          />
                        </div>
                      </>
                    )}
                  {foundMaterials?.content && searchTerm !== "" && (
                    <>
                      <MaterialsCheckboxList
                        searchTerm={searchTerm}
                        isChecked={ordersToSubmit}
                        foundMaterials={foundMaterials}
                        handleCheckboxChange={handleCheckboxChange}
                      />
                      <div className="w-full mt-5">
                        <Pagination
                          currentPage={searchMaterialsPage}
                          setPageNumber={setSearchMaterialsPage}
                          results={foundMaterials.numberOfElements}
                          total={foundMaterials.totalElements}
                          amountOfPages={foundMaterials.totalPages}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </Fragment>
          )}
          {activeStep === 2 && (
            <PriceDecrementPayment
              materialsToBid={ordersToSubmit}
              handlePriceDecrementChanges={handlePriceDecrementChanges}
            />
          )}
          {activeStep === 3 && (
            <MultipleMaterialsBiddingForm
              actions={actions}
              materialsToBid={ordersToSubmit}
              isLoadingUpload={isLoadingUpload}
              generalBidData={generalBidData}
              setGeneralBidData={setGeneralBidData}
              specificationName={specificationName}
              setSpecificationName={setSpecificationName}
              handleSpecification={handleSpecification}
              uploadedFileUrl={uploadedSpecificationFileUrl}
              handleSupplyPeriod={handleSupplyPeriod}
            />
          )}
          {activeStep === 4 && (
            <MultipleBiddingSummary
              orderSummary={ordersToSubmit}
              generalBidData={generalBidData}
              specificationName={specificationName}
            />
          )}
          <div className="flex justify-end items-center gap-2 mt-8">
            <button
              className={`uppercase flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-xs font-medium ${
                activeStep === 1 ? "text-gray-400" : "text-gray-900"
              } bg-white`}
              onClick={handleBack}
              disabled={activeStep === 1}
            >
              {i18next.t("cta.back")}
            </button>
            {activeStep === 4 && (
              <button
                className="uppercase flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-xs font-medium text-gray-900 bg-white"
                onClick={() => handleNext(activeStep, OrderState.DRAFT)}
                disabled={disabled}
              >
                {i18next.t("cta.saveDraft")}
              </button>
            )}
            <button
              className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded ${
                disabled
                  ? "bg-white text-gray-400"
                  : "bg-spectum hover:bg-spectum-light text-white"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase`}
              onClick={() => handleNext(activeStep, OrderState.DELIVERED)}
              disabled={disabled}
            >
              {activeStep === 4 ? i18next.t("cta.send") : i18next.t("cta.next")}
            </button>
          </div>
        </div>
      )}
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={
          from === OrderState.DRAFT
            ? i18next.t("purchaseRequests.modalTitleDraft")
            : i18next.t("purchaseRequests.modalTitleDeliver")
        }
        message={
          from === OrderState.DRAFT
            ? i18next.t("purchaseRequests.modalDraft")
            : i18next.t("purchaseRequests.modalDeliver")
        }
        onClickSuccess={handleSubmit(onSubmit, onError)}
        onClickCancel={() => setOpen(false)}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseRequest);
