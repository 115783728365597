import { Switch } from "@headlessui/react";
import { classNames } from "../../../utils";

type ToggleProps = {
  label?: string;
  enabled: boolean;
  onChange: (e: boolean) => void;
};

const Toggle = ({ enabled, onChange, label }: ToggleProps) => {
  return (
    <div className="flex items-center">
      <Switch
        checked={enabled}
        onChange={onChange}
        className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className="pointer-events-none absolute bg-white w-full h-full rounded"
        />
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "bg-spectum-dark" : "bg-gray-200",
            "pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            enabled
              ? "translate-x-5 border-spectum bg-spectum-dark"
              : "translate-x-0 border-gray-200 bg-white",
            "pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full shadow transform ring-0 transition-transform ease-in-out duration-200"
          )}
        />
      </Switch>
      {label && (
        <span className="ml-4 text-xs font-medium text-gray-500 uppercase">
          {label}
        </span>
      )}
    </div>
  );
};

export default Toggle;
