import i18next from "../../../i18n";
import { JointOrdersTable } from "../../../components";
import { OrderState } from "../../../types";
import { useJointOrders } from "../../../hooks/useOrders";

export const Joint = ({ state }: { state: OrderState }) => {
  const { data: jointOrders } = useJointOrders(state);

  return (
    <>
      {jointOrders && jointOrders?.length > 0 && (
        <JointOrdersTable
          title={i18next.t("order.joint")}
          orders={jointOrders}
        />
      )}
    </>
  );
};
