import i18next from "../../i18n";
import { OfferResponse } from "../../types";

const IsBuyerMultipleOffer = ({ offers }: { offers: OfferResponse[] }) => {
  return (
    <div className="relative h-full">
      <h4 className="text-xs font-bold text-gray-600 uppercase">
        {i18next.t("offer.from")}
      </h4>
      <h1 className="text-sm font-bold text-gray-900">
        {offers[0]?.user?.company?.name} -{" "}
        <span className="text-gray-500">{offers[0]?.user?.company?.taxId}</span>
      </h1>
      {offers?.map((offer) => (
        <div
          key={offer.id}
          className={`${
            offer.selected
              ? "border-green-600 border-2"
              : "border-spectum border"
          } rounded p-2 mt-3`}
        >
          <h1 className="text-sm font-bold text-gray-900 uppercase pb-1">
            {offer?.order?.material?.globalMaterialName}
          </h1>
          <hr />
          <div className="bg-white overflow-hidden">
            <div className="py-2">
              <div className="uppercase text-xs font-medium text-gray-600 pb-1">
                {i18next.t("offer.table.price")}
              </div>
              <div className="text-xs text-gray-900 font-bold">
                {offer?.price} {offer?.order?.currency} /{" "}
                {offer?.order?.measurementUnit}{" "}
              </div>
            </div>
            <div className="sm:divide-y sm:divide-gray-200">
              <div className="py-2">
                <div className="uppercase text-xs font-medium text-gray-600 pb-1">
                  {i18next.t("offer.table.quantity")}
                </div>
                <div className="text-xs text-gray-900 font-bold">
                  {offer?.order?.quantity} {offer?.order?.measurementUnit}
                </div>
              </div>
            </div>
            {offer?.selected && (
              <div className="sm:divide-y sm:divide-gray-200">
                <div className="py-2">
                  <div className="uppercase text-xs font-medium text-gray-600 pb-1">
                    {i18next.t("offer.table.quantitySelected")}
                  </div>
                  <div className="text-xs text-gray-900 font-bold">
                    {offer?.price} {offer?.order?.currency} /{" "}
                    {offer?.order?.measurementUnit}
                    <br /> {offer?.quantity} {offer?.order?.measurementUnit}
                  </div>
                </div>
              </div>
            )}
            {offer?.comment && (
              <div className="sm:divide-y sm:divide-gray-200">
                <div className="py-2">
                  <div className="uppercase text-xs font-medium text-gray-500 pb-1">
                    {i18next.t("offer.comments")}
                  </div>
                  <div className="text-xs text-gray-900 font-bold">
                    {offer?.comment}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default IsBuyerMultipleOffer;
