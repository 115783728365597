import { useState } from "react";
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";

interface UseFileInputUploaderReturn {
  uploadedSpecificationFileUrl: string | null;
  handleSpecificationUpload: (file: any) => Promise<void>;
  isLoadingUpload: boolean;
}

export default function useFileInputUploader(): UseFileInputUploaderReturn {
  const [uploadedSpecificationFileUrl, setUploadedSpecificationFileUrl] =
    useState<string | null>(null);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);

  const handleSpecificationUpload = async (files: any) => {
    if (!files) return;
    const file = files[0];
    setIsLoadingUpload(true);

    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
    const REGION = process.env.REACT_APP_REGION;
    const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
    const SECRET_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

    if (!S3_BUCKET || !REGION || !ACCESS_KEY || !SECRET_KEY) {
      throw new Error("S3 environment variables are not properly defined");
    }

    const s3Client = new S3Client({
      region: REGION,
      credentials: {
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_KEY,
      },
    });

    try {
      const upload = new Upload({
        client: s3Client,
        params: {
          Bucket: S3_BUCKET,
          Key: file.name,
          Body: file,
        },
      });

      upload.on("httpUploadProgress", (evt) => {
        console.log(`Uploading: ${evt.loaded} / ${evt.total}`);
      });

      await upload.done();
      const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`;
      setUploadedSpecificationFileUrl(fileUrl);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setIsLoadingUpload(false);
    }
  };

  return {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  };
}
