import { useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { queryKeys } from "./queryKeys";

const onGetHistoric = async (materialId: string, page = 0) => {
  const { data } = await axios.get(`/order/material/${materialId}/${page}`);
  return data;
};

export const useHistoric = (materialId: string, page: number) =>
  useQuery({
    queryKey: [queryKeys.HISTORIC, materialId, page],
    queryFn: () => onGetHistoric(materialId, page),
    enabled: !!materialId,
  });
