import PriceVolumeColumn from "./PriceVolumeColumn/PriceVolumeColumn";
import TableHead from "./TableHead/TableHead";
import { useLanguage } from "../../../hooks";

type PriceVolumeTableProps = {
  year: number;
  months: any;
  data: any;
  isActual?: boolean;
  isFullYear?: boolean;
  isPreviousYear?: boolean;
  isNextYear?: boolean;
  isMaterialView?: boolean;
  isFirst?: boolean;
  hasTableHead: boolean;
};

export default function PriceVolumeTable({
  year,
  months,
  data,
  isActual,
  isFullYear,
  isPreviousYear,
  isNextYear,
  isMaterialView,
  isFirst,
  hasTableHead,
}: PriceVolumeTableProps) {
  const langEs = useLanguage();
  const table = data?.map((y: any, index: number) => (
    <PriceVolumeColumn
      key={y?.actual?.id}
      id={y?.actual?.id}
      actualId={y?.actual?.id}
      forecastId={y?.forecast?.id}
      isPreviousYear={isPreviousYear}
      isNextYear={isNextYear}
      month={
        langEs
          ? months[index].es.substring(0, 3)
          : months[index].en.substring(0, 3)
      }
      isActual={isFullYear}
      volActual={parseFloat(y?.actual?.vol).toFixed(1)}
      priceActual={parseFloat(y?.actual?.price).toFixed(1)}
      volForecast={parseFloat(y?.forecast?.vol).toFixed(1)}
      priceForecast={parseFloat(y?.forecast?.price).toFixed(1)}
      suppliers={y?.suppliers}
      isMaterialView={isMaterialView}
      isFirst={isFirst}
      hasTableHead={hasTableHead}
    />
  ));

  return (
    <div className="flex relative">
      {isFirst || hasTableHead ? (
        <TableHead
          isActual={isActual}
          isFullYear={isFullYear}
          isPreviousYear={isPreviousYear}
          isNextYear={isNextYear}
          year={year}
        />
      ) : null}
      {isPreviousYear ? table.slice(-2) : null}
      {isFullYear ? table : null}
      {isNextYear ? table.slice(0, 2) : null}
    </div>
  );
}
