import { useMemo, useState } from "react";
import i18next from "../../i18n";
import { currencyFormatter } from "../../utils";
import { OfferResponse, OffersToAccept } from "../../types";

type EventState = {
  offersToAccept: OffersToAccept[];
  errors: any[];
  invalid: any[];
};

type AwardItemProps = {
  event: EventState;
  offer: OfferResponse;
  actions: any;
  updateEvent: (arg0: any) => void;
};

const AwardItem = ({ event, offer, actions, updateEvent }: AwardItemProps) => {
  const [selectedOffers, setSelectedOffers] = useState<string[]>([]);

  const handleCheckboxChange = (offer: OfferResponse) => {
    const isSelected = selectedOffers.includes(offer?.id);
    setSelectedOffers((prevSelectedOffers) =>
      isSelected
        ? prevSelectedOffers.filter((id) => id !== offer.id)
        : [...prevSelectedOffers, offer.id]
    );

    updateEvent({
      type: actions.UPDATE_ON_CHECKBOX_CHANGE,
      id: offer?.id,
      price: offer?.price,
      volume: offer?.order?.quantity,
      orderId: offer?.order?.id,
      material: offer?.order?.material?.globalMaterialName,
      offer,
    });

    if (!isSelected) return;

    updateEvent({ type: actions.DELETE_OFFER, id: offer?.id });
  };

  const currentOffer = event.offersToAccept.find((o) => o?.id === offer?.id);
  const hasError = event.errors.find(
    (error) => error.orderId === offer?.order?.id
  );

  const totalPriceQuantity = useMemo(() => {
    const off = event.offersToAccept.find((o) => o.id === offer?.id);
    return off
      ? off.price * off?.volume
      : offer?.price * offer?.order?.quantity;
  }, [offer, event]);

  const currency = currencyFormatter(
    totalPriceQuantity,
    offer?.order?.currency
  );

  return (
    <div className="border flex">
      <div>
        <p className="text-xs text-center p-2 border-b uppercase font-bold text-spectum truncate">
          {offer?.user?.company?.name}
        </p>
        <div className="text-center flex w-full">
          <p className="text-xs w-full border-r border-b p-2 uppercase font-bold text-gray-600">
            {i18next.t("award.finalVolume")} -{" "}
            <span>({offer?.order?.measurementUnit})</span>
          </p>
          <p className="text-xs w-full border-b p-2 uppercase font-bold text-gray-600">
            {i18next.t("award.finalPrice")} -{" "}
            <span>({offer?.order?.currency})</span>
          </p>
        </div>

        <div
          className={`flex border-b ${
            offer?.isBest ? "bg-spectum-success" : "bg-white"
          }`}
        >
          <div className="p-2 border-r w-full">
            <input
              type="number"
              className={`rounded text-xs shadow w-120 focus:border-transparent border-none ${
                hasError ? "text-red-500 font-bold" : ""
              } `}
              disabled={!selectedOffers.includes(offer?.id)}
              value={currentOffer?.volume ?? offer?.order?.quantity}
              min={0}
              onChange={(e) =>
                updateEvent({
                  type: actions.UPDATE_VOLUME,
                  id: offer.id,
                  volume: e.target.value,
                  orderId: offer.order.id,
                })
              }
            />
          </div>
          <div className="p-2 w-full">
            <input
              type="number"
              className="rounded border-gray-200 text-xs shadow w-120 border-none"
              disabled={!selectedOffers.includes(offer?.id)}
              value={currentOffer?.price ?? offer?.price}
              min={0}
              onChange={(e) =>
                updateEvent({
                  type: actions.UPDATE_PRICE,
                  id: offer.id,
                  price: e.target.value,
                  orderId: offer.order.id,
                })
              }
            />
          </div>
        </div>
        <div
          className={`focus:border-transparent border-none flex ${
            offer?.isBest ? "bg-spectum-success font-bold" : "bg-white"
          }`}
        >
          <div className="font-bold text-xs p-3 border-r w-full">TOTAL</div>
          <div className="text-xs w-full p-3 border-r">
            {offer?.order.currency !== "ARS" ? offer?.order.currency : ""}{" "}
            {totalPriceQuantity && currency}
          </div>
        </div>
      </div>
      <div>
        <div className="text-center flex w-full p-4 border-b"></div>
        <div className="text-center flex w-full p-2 border-b border-l">
          <p className="text-xs uppercase font-bold text-gray-600">
            {i18next.t("tenders.form.select")}
          </p>
        </div>
        <div
          className={`p-4 border-l border-b w-full flex items-center justify-center ${
            offer?.isBest ? "bg-spectum-success" : "bg-white"
          }`}
        >
          <input
            type="checkbox"
            className="rounded shadow text-xs"
            checked={selectedOffers.includes(offer?.id)}
            onChange={() => handleCheckboxChange(offer)}
          />
        </div>
      </div>
    </div>
  );
};

export default AwardItem;
