import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function TooltipSP({
  id,
  message,
}: {
  id: string;
  message: string;
}) {
  return (
    <ReactTooltip
      id={id}
      place="bottom"
      content={message}
      className="bg-black z-50"
    />
  );
}
