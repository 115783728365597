import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios-orders";
import { UserResponse } from "../types";
import { queryKeys } from "./queryKeys";

const onGetUserById = async (userId: string): Promise<UserResponse> => {
  const { data } = await axios.get(`/user/company/user/${userId}`);
  return data;
};

export const useUserById = (userId: string) =>
  useQuery<UserResponse>({
    queryKey: [queryKeys.USER, userId],
    queryFn: () => onGetUserById(userId),
    enabled: !!userId,
  });

const onGetUsersByCompany = async (): Promise<UserResponse[]> => {
  const { data } = await axios.get(`/user/company`);
  return data;
};

export const useUsersByCompany = (userId: string) =>
  useQuery({
    queryKey: [queryKeys.USERS_BY_COMPANY, userId],
    queryFn: onGetUsersByCompany,
    select: (data) => data?.filter((u: any) => u.id !== userId),
  });

const onCreateNewUser = async (body: any) => {
  const { data } = await axios.post(`/user/new`, body);
  return data;
};

export const useCreateNewUser = (onSuccess: () => void, onError: () => void) =>
  useMutation({
    mutationFn: onCreateNewUser,
    onSuccess,
    onError,
  });

const onEditUser = async ({ userId, body }: { userId: string; body: any }) => {
  const { data } = await axios.put(`/user/${userId}`, body);
  return data;
};

export const useUpdateUser = (onSuccess: () => void, onError: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onEditUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.USER, queryKeys.USER_MATERIALS_BY_COMPANY],
      });
      onSuccess();
    },
    onError,
  });
};

const onChangePassword = async (body: { password: string }) => {
  const { data } = await axios.post(`/auth/change-password`, body);
  return data;
};

export const useChangePassword = (onSuccess: () => void, onError: () => void) =>
  useMutation({
    mutationFn: onChangePassword,
    onSuccess,
    onError,
  });
