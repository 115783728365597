import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../axios-orders";
import { queryKeys } from "./queryKeys";

const onGetMaterialVolumePrice = async (materialId: string) => {
  const { data } = await axios.get(`/materialVolume/material/${materialId}`);
  return data;
};

export const useMaterialVolumePrice = (materialId: string) =>
  useQuery({
    queryKey: [queryKeys.MATERIAL_VOLUME, materialId],
    queryFn: () => onGetMaterialVolumePrice(materialId),
    enabled: !!materialId,
  });

const onUpdateMaterialVolume = async (materialVolume: any) => {
  const data = await axios.put("/materialVolume", materialVolume);
  return data;
};

export const useUpdateMaterialVolume = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onUpdateMaterialVolume,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.MATERIAL_VOLUME] });
      onSuccess();
    },
    onError,
  });
};

const onGetAllMaterialsVolumesPrices = async () => {
  const { data } = await axios.get("/materialVolume/user");
  return data;
};

export const useAllMaterialVolumesPrices = () =>
  useQuery({
    queryKey: [queryKeys.MATERIAL_VOLUME],
    queryFn: () => onGetAllMaterialsVolumesPrices(),
  });

const onCreateNegotiation = async ({
  userId,
  body,
}: {
  userId: string;
  body: any;
}) => {
  const { data } = await axios.post(`/negotiation/${userId}`, body);
  return data;
};

export const useCreateNegotiation = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onCreateNegotiation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.MATERIAL_VOLUME] });
      onSuccess();
    },
    onError,
  });
};
