import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/solid";
import ClipLoader from "react-spinners/ClipLoader";
import {
  mapStateToProps,
  mapDispatchToProps,
  editMaterialValidationSchema,
  MEASUREMENT_UNIT_LIST,
  CURRENCY_LIST,
  capitalize,
  TO_BE_AGREED,
  INCOTERM_LIST,
  NO_SPEC,
  IT_DOESNT_HAVE_SPEC,
  PN_TYPE,
  certificationSuggestions,
  CERTIFICATIONS,
} from "../../utils";
import { useFileInputUploader, useID } from "../../hooks";
import { Loader, Modal, InputField, Toggle, TooltipSP, Button } from "..";
import {
  CertificationResponse,
  MaterialResponse,
  PN_TYPES,
  SiteResponse,
  UserResponse,
} from "../../types";
import { useUpdateMaterial } from "../../hooks/useMaterials";

type EditMaterialFormProps = {
  user: UserResponse;
  sites: SiteResponse[];
  material: MaterialResponse;
  deliveryPlace: string;
  onOpenNotification: (message: string) => void;
  pnType: PN_TYPES;
  confidentSpec: boolean;
};

type EditFormValues = {
  globalMaterialName?: string;
  deliveryFormat?: string;
  deliveryPlace?: string;
  annualVolume?: number;
  fileURL?: string;
  measurementUnit?: string;
  coin?: string;
  comments?: string;
  application?: string;
  incoTerm?: string;
  confidentSpec?: boolean;
  isMaterialConfidential?: boolean;
  certifications?: CertificationResponse[];
};

const EditMaterialForm = ({
  pnType,
  user,
  material,
  sites,
  deliveryPlace,
  confidentSpec,
  onOpenNotification,
}: EditMaterialFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useID();
  const {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  } = useFileInputUploader();
  const [fileName, setFileName] = useState(null);
  const [open, setOpen] = useState(false);
  const [changeFile, setChangeFile] = useState(false);
  const [measurementUnitValue, setMeasurementUnitValue] = useState("");
  const [deliveryPlaceId, setDeliveryPlaceId] = useState(deliveryPlace);
  const [isSpecConfidential, setIsSpecConfidential] = useState(confidentSpec);
  const [isMaterialConfidential, setIsMaterialConfidential] = useState(
    material?.confident
  );
  const [incotermValue, setIncotermValue] = useState(material?.incoTerm);
  const [certifications, setCertifications] = useState<any[]>(
    material?.certifications || []
  );
  const [certificationType, setCertificationType] = useState<any>();
  const [certificationName, setCertificationName] = useState("");
  const [certificationRequired, setCertificationRequired] = useState(false);
  const [addCertification, setAddCertification] = useState(false);

  const { mutate: editMaterial, isPending: isLoadingMaterials } =
    useUpdateMaterial(
      () => {
        onOpenNotification(t("material.success"));
        return navigate(`/material/${id}`);
      },
      () => onOpenNotification(t("newMaterialForm.error"))
    );

  const preloadData = {
    globalMaterialName: material?.globalMaterialName,
    deliveryFormat: material?.deliveryFormat,
    deliveryPlace,
    annualVolume: material?.annualVolume,
    fileURL: material?.fileURL,
    measurementUnit: material?.measurementUnit,
    coin: material?.coin,
    comments: material?.comments,
    application: material?.application,
    incoTerm: material?.incoTerm,
    confidentSpec,
    isMaterialConfidential,
    certifications: material?.certifications,
  };

  const formOptions = {
    resolver: yupResolver(editMaterialValidationSchema(pnType)),
    defaultValues: preloadData,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditFormValues>(formOptions);

  const handleSpecification = (e: any) => {
    const file = e?.target?.files;
    if (file) {
      handleSpecificationUpload(file);
      setFileName(file[0]?.name);
    }
  };

  const onAddCertification = () => {
    if (certificationType && certificationName) {
      setCertifications((prevCertifications) => [
        ...prevCertifications,
        {
          name: certificationName,
          type: certificationType.type,
          label: certificationType.label,
          required: certificationRequired,
        },
      ]);
      setCertificationType(null);
      setCertificationName("");
      setCertificationRequired(false);
    }
  };

  const onRemoveCertification = (index: number) => {
    setCertifications((prevCertifications) =>
      prevCertifications.filter((_, i) => i !== index)
    );
  };

  const onSubmit = (data: EditFormValues) => {
    const name = data.globalMaterialName && capitalize(data.globalMaterialName);
    const format = data.deliveryFormat && capitalize(data.deliveryFormat);
    const comments = data.comments ? capitalize(data.comments, true) : "";
    const application = data.application
      ? capitalize(data.application, true)
      : "";
    editMaterial({
      materialId: material?.id,
      body: {
        ...material,
        user: { id: user?.id },
        globalMaterialName: name,
        deliveryFormat: format,
        deliveryPlace: !deliveryPlaceId ? null : { id: deliveryPlaceId },
        annualVolume: parseInt(String(data.annualVolume)),
        measurementUnit: data.measurementUnit,
        coin: data.coin,
        incoTerm: incotermValue,
        comments,
        application,
        confident: isMaterialConfidential,
        confidentSpec: isSpecConfidential,
        fileURL: uploadedSpecificationFileUrl
          ? uploadedSpecificationFileUrl
          : material?.fileURL,
        certifications,
      },
    });
    setOpen(false);
    setFileName(null);
  };
  const onError = () => setOpen(false);

  const handleChangeFile = (e: any) => {
    e.preventDefault();
    setChangeFile((prevState) => !prevState);
  };

  if (isLoadingMaterials || !material || !preloadData)
    return <Loader isFullScreen />;

  return (
    <Fragment>
      <form
        className="space-y-8 divide-y divide-gray-200 mt-8"
        autoComplete="off"
      >
        <div className="mt-10 sm:mt-0">
          <div className="mt-5 md:mt-0">
            <div className="shadow border overflow-hidden rounded">
              <div className="grid grid-cols-4 px-4 py-5 bg-white sm:p-6 gap-4">
                <div className="col-span-2 md:col-span-1 pb-4">
                  <InputField
                    id="globalMaterialName"
                    name="globalMaterialName"
                    type="text"
                    label={t("material.name")}
                    errorName={errors?.globalMaterialName}
                    errorMessage={errors?.globalMaterialName?.message}
                    required
                    register={register}
                  />
                </div>

                {pnType === PN_TYPE.PI && (
                  <div className="col-span-2 md:col-span-1 pb-4">
                    <InputField
                      id="deliveryFormat"
                      name="deliveryFormat"
                      type="text"
                      label={t("material.deliveryFormat")}
                      errorName={errors?.deliveryFormat}
                      errorMessage={errors?.deliveryFormat?.message}
                      required
                      register={register}
                    />
                  </div>
                )}

                <div className="col-span-2 md:col-span-1 pb-4">
                  <label
                    htmlFor="deliveryPlace"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {t("material.deliveryPlace")}
                  </label>
                  <select
                    {...register("deliveryPlace", { required: true })}
                    id="deliveryPlace"
                    name="deliveryPlace"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                    onChange={(e) => setDeliveryPlaceId(e.target.value)}
                  >
                    <option value="">{TO_BE_AGREED}</option>
                    {sites?.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.name}
                      </option>
                    ))}
                  </select>

                  {errors?.deliveryPlace && (
                    <span className="text-xs text-red-500">
                      {errors?.deliveryPlace?.message}
                    </span>
                  )}
                </div>

                {pnType === PN_TYPE.PI && (
                  <div className="col-span-4 md:col-span-1">
                    <label
                      htmlFor="incoTerm"
                      className="uppercase block text-xs font-medium text-gray-700"
                    >
                      {t("material.incoTerm")}
                    </label>
                    <select
                      {...register("incoTerm", { required: true })}
                      id="incoTerm"
                      name="incoTerm"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                      defaultValue=""
                      onChange={(e) => setIncotermValue(e.target.value)}
                    >
                      <option value="">Seleccione un Incoterm</option>
                      {INCOTERM_LIST?.map((inco) => (
                        <option key={inco.id} value={inco.name}>
                          {inco.name}
                        </option>
                      ))}
                    </select>
                    {errors?.incoTerm && !incotermValue && (
                      <span className="text-xs text-red-500">
                        {errors?.incoTerm?.message}
                      </span>
                    )}
                  </div>
                )}

                {incotermValue === "OTRO (ESPECIFICAR)" &&
                  pnType === PN_TYPE.PI && (
                    <div className="col-span-4 md:col-span-1">
                      <InputField
                        id="incoTerm"
                        name="incoTerm"
                        type="text"
                        label={t("material.incoTerm")}
                        errorName={errors?.incoTerm}
                        errorMessage={errors?.incoTerm?.message}
                        register={register}
                        required
                      />
                    </div>
                  )}

                <div className="col-span-2 md:col-span-1 pb-4">
                  <InputField
                    id="annualVolume"
                    name="annualVolume"
                    type="number"
                    label={t("material.annualVolume")}
                    min={0}
                    defaultValue={0}
                    errorName={errors?.annualVolume}
                    errorMessage={errors?.annualVolume?.message}
                    register={register}
                  />
                </div>

                {pnType === PN_TYPE.PI && (
                  <div className="col-span-2 md:col-span-1">
                    <label
                      htmlFor="measurementUnit"
                      className="uppercase block text-xs font-medium text-gray-700"
                    >
                      {t("material.measurementUnit")}
                    </label>
                    <select
                      {...register("measurementUnit", {
                        required: true,
                      })}
                      id="measurementUnit"
                      name="measurementUnit"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                      onChange={(e) => setMeasurementUnitValue(e.target.value)}
                    >
                      {MEASUREMENT_UNIT_LIST?.map((unit) => (
                        <option key={unit.id} value={unit.name}>
                          {unit.name}
                        </option>
                      ))}
                    </select>
                    {errors?.measurementUnit && (
                      <span className="text-xs text-red-500">
                        {errors?.measurementUnit?.message}
                      </span>
                    )}
                  </div>
                )}

                {measurementUnitValue === "OTRO (ESPECIFICAR)" && (
                  <div className="col-span-2 md:col-span-1">
                    <InputField
                      id="measurementUnit"
                      name="measurementUnit"
                      type="text"
                      label={t("material.measurementUnit")}
                      errorName={errors?.measurementUnit}
                      errorMessage={errors?.measurementUnit?.message}
                      required
                      register={register}
                    />
                  </div>
                )}

                <div className="col-span-2 md:col-span-1">
                  <label
                    htmlFor="coin"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {t("material.currency")}
                  </label>
                  <select
                    {...register("coin", {
                      required: true,
                    })}
                    id="coin"
                    name="coin"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                  >
                    {CURRENCY_LIST?.map((coin) => (
                      <option key={coin.id} value={coin.name}>
                        {coin.name}
                      </option>
                    ))}
                  </select>
                  {errors?.coin && (
                    <span className="text-xs text-red-500">
                      {errors?.coin?.message}
                    </span>
                  )}
                </div>

                <div className="col-span-4">
                  <hr />
                  <div className="w-full uppercase block text-xs font-bold text-gray-900 py-2">
                    {t("certifications.certifications")}
                  </div>
                  {!addCertification && (
                    <Button
                      variant="add"
                      onClick={() => {
                        setAddCertification(!addCertification);
                      }}
                    >
                      <PlusIcon className="h-4 w-4" />
                      {t("certifications.addCertifications")}
                    </Button>
                  )}

                  {addCertification && (
                    <>
                      <div className="grid grid-cols-4 gap-2 items-end">
                        <div className="col-span-4 md:col-span-1">
                          <label
                            htmlFor="certificationType"
                            className="uppercase block text-xs font-medium text-gray-700"
                          >
                            {t("certifications.certificationType")}
                          </label>
                          <select
                            id="certificationType"
                            name="certificationType"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                            value={certificationType?.type}
                            onChange={(e) =>
                              setCertificationType(
                                CERTIFICATIONS[e.target.value]
                              )
                            }
                          >
                            <option value="">
                              {t("certifications.selectCertificationType")}
                            </option>
                            {Object.keys(CERTIFICATIONS).map((key) => (
                              <option
                                key={key}
                                value={CERTIFICATIONS[key].type}
                              >
                                {CERTIFICATIONS[key].label}
                              </option>
                            ))}
                          </select>
                        </div>

                        {certificationType && (
                          <div className="col-span-4 md:col-span-1">
                            <div>
                              <label
                                htmlFor="certificationType"
                                className="uppercase block text-xs font-medium text-gray-700"
                              >
                                {t("certifications.certificationName")}
                              </label>
                              <input
                                type="text"
                                value={certificationName}
                                onChange={(e) =>
                                  setCertificationName(e.target.value)
                                }
                                list="suggestions"
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                                placeholder={t(
                                  "certifications.insertCertificationName"
                                )}
                              />
                              <datalist id="suggestions">
                                {certificationSuggestions[
                                  certificationType?.type
                                ].map((suggestion) => (
                                  <option key={suggestion} value={suggestion} />
                                ))}
                              </datalist>
                            </div>
                          </div>
                        )}

                        {certificationType && (
                          <div className="col-span-4 md:col-span-1 flex items-center">
                            <Toggle
                              enabled={certificationRequired}
                              onChange={() =>
                                setCertificationRequired(
                                  (prevState) => !prevState
                                )
                              }
                              label={t("certifications.certificationRequired")}
                            />
                            <Button
                              onClick={() => {
                                onAddCertification();
                                setAddCertification(false);
                              }}
                            >
                              <PlusIcon className="h-5 w-5" />
                              {t("cta.add")}
                            </Button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {certifications.length > 0 && (
                    <div className="mt-4">
                      <ul className="grid grid-cols-4 gap-2">
                        {certifications.map((certification, index) => (
                          <li
                            key={index}
                            className="col-span-1 shadow-sm sm:text-xs border-gray-300 flex text-xs border rounded items-center justify-between p-2"
                          >
                            <span>
                              {certification.name}{" "}
                              {certification.label && `(${certification.label}`}{" "}
                              {certification.required && (
                                <span className="text-red-500 text-md">*</span>
                              )}
                            </span>
                            <XMarkIcon
                              className="h-5 w-5 text-red-600 cursor-pointer border rounded-full border-red-500"
                              onClick={() => onRemoveCertification(index)}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="col-span-4">
                  <hr />
                </div>

                <div className="col-span-4 mt-3">
                  <label className="uppercase block text-xs font-medium text-gray-700 mb-2">
                    {t("newMaterialForm.specification")}
                  </label>
                  <div className="flex items-center">
                    <Button onClick={handleChangeFile} variant="cancel">
                      {!changeFile ? t("cta.edit") : t("cta.cancel")}
                    </Button>
                    {!changeFile ? (
                      <div>
                        {material?.fileURL === NO_SPEC ? (
                          <p className="text-xs uppercase font-bold">
                            {IT_DOESNT_HAVE_SPEC}
                          </p>
                        ) : (
                          <dd className="text-xs text-gray-900">
                            <a
                              href={material?.fileURL}
                              target="_blank"
                              rel="noreferrer"
                              className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                            >
                              <ArrowDownTrayIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              {t("cta.download")}
                            </a>
                          </dd>
                        )}
                      </div>
                    ) : (
                      <Fragment>
                        <div className="w-full mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {fileName && <span>{fileName}</span>}

                            <div className="text-xs text-gray-600">
                              <label
                                htmlFor="fileURL"
                                className="uppercase relative cursor-pointer bg-white rounded font-medium text-spectum hover:text-spectum-light focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-spectum-light"
                              >
                                {isLoadingUpload ? (
                                  <div className="flex w-full items-center justify-center">
                                    <ClipLoader loading size={30} />
                                  </div>
                                ) : (
                                  <span>
                                    {t("newMaterialForm.changeUpload")}
                                  </span>
                                )}
                                <input
                                  {...register("fileURL", {
                                    required: true,
                                  })}
                                  id="fileURL"
                                  name="fileURL"
                                  type="file"
                                  className="sr-only"
                                  onChange={(e) => handleSpecification(e)}
                                />
                              </label>
                            </div>

                            <p className="text-xs text-gray-500">PDF 10MB</p>
                          </div>
                        </div>
                        {errors?.fileURL && (
                          <span className="text-xs text-red-500">
                            {errors?.fileURL?.message}
                          </span>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>

                <div className="relative col-span-2 mt-3">
                  <Toggle
                    enabled={isSpecConfidential}
                    onChange={() =>
                      setIsSpecConfidential((prevState) => !prevState)
                    }
                    label={t("newMaterialForm.confidential")}
                  />
                </div>

                <div className="relative col-span-4 mt-3">
                  <div
                    className="flex items-center gap-4"
                    id="material_confident"
                  >
                    <Toggle
                      enabled={isMaterialConfidential}
                      onChange={() => {
                        setIsMaterialConfidential((prevState) => !prevState);
                        if (!isMaterialConfidential)
                          setIsSpecConfidential(true);
                      }}
                    />
                    <span className="text-xs uppercase text-gray-700">
                      {t("cta.isConfidential")}
                    </span>
                    <QuestionMarkCircleIcon
                      className="h-4 w-4 text-gray-400"
                      id="material_confident"
                    />
                  </div>
                  <TooltipSP
                    id="material_confident"
                    message={t("messages.confidentialMessage")}
                  />
                </div>

                <div className="col-span-4 mt-2">
                  <InputField
                    id="application"
                    name="application"
                    type="text"
                    label={
                      pnType === PN_TYPE.PI
                        ? t("newMaterialForm.application")
                        : t("newMaterialForm.brand")
                    }
                    isOptional
                    register={register}
                  />
                </div>

                <div className="col-span-4 pb-2 mt-2">
                  <InputField
                    id="comments"
                    name="comments"
                    type="text"
                    label={t("newMaterialForm.comments")}
                    isOptional
                    register={register}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end items-center gap-2">
              <Button link to={`/material/${material?.id}`} variant="cancel">
                {t("cta.cancel")}
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
                disabled={isLoadingUpload}
              >
                {t("cta.saveChanges")}
              </Button>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          setOpen={setOpen}
          icon={
            <CheckCircleIcon
              className="h-6 w-6 text-green-600"
              aria-hidden="true"
            />
          }
          title={t("newMaterialForm.edit")}
          message={t("newMaterialForm.editQuestion")}
          onClickSuccess={handleSubmit(onSubmit, onError)}
          onClickCancel={() => setOpen(false)}
        />
      </form>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMaterialForm);
