import { useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { KpiResponse } from "../types";
import { queryKeys } from "./queryKeys";

const fetchKpis = async (): Promise<KpiResponse> => {
  const response = await axios.get("/user/kpi");
  return response.data;
};

export const useKpis = () =>
  useQuery<KpiResponse, Error>({
    queryKey: [queryKeys.KPIS],
    queryFn: fetchKpis,
  });
