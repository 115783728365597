import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { getAccessToken } from "../axios-orders";
import {
  ORDER_TYPES,
  OrderResponse,
  OrderState,
  SimpleOrderPaginatedResponse,
} from "../types";
import { queryKeys } from "./queryKeys";

const onGetJointOrders = async (state: OrderState) => {
  const { data } = await axios.get(`/order/multiple/state/${state}`);
  return data;
};

export const useJointOrders = (state: OrderState) =>
  useQuery({
    queryKey: [queryKeys.JOINT_ORDERS, state],
    queryFn: () => onGetJointOrders(state),
    select: (data) => {
      const items = Object.keys(data).map((key) => data[key]);
      return items as [OrderResponse[]];
    },
  });

const onGetOrders = async (
  state: OrderState,
  type: ORDER_TYPES,
  _type: ORDER_TYPES,
  page = 0
): Promise<SimpleOrderPaginatedResponse> => {
  const { data } = await axios.get(`/order/${state}/${type}/${_type}/${page}`);
  return data;
};

export const useOrdersByTypeAndState = (
  state: OrderState,
  type: ORDER_TYPES,
  _type: ORDER_TYPES,
  page = 0
) =>
  useQuery<SimpleOrderPaginatedResponse>({
    queryKey: [queryKeys.ORDERS, page],
    queryFn: () => onGetOrders(state, type, _type, page),
  });

const onGetJointOrder = async (orderId: string) => {
  const { data } = await axios.get(`/order/multiple/${orderId}`);
  return data;
};

export const useJointOrder = (orderId: string) =>
  useQuery({
    queryKey: [queryKeys.JOINT_ORDER, orderId],
    queryFn: () => onGetJointOrder(orderId),
    enabled: !!orderId,
  });

const onGetOrder = async (orderId: string) => {
  const { data } = await axios.get(`/order/${orderId}`);
  return data;
};

export const useOrder = (orderId: string) =>
  useQuery<OrderResponse>({
    queryKey: [queryKeys.ORDER, orderId],
    queryFn: () => onGetOrder(orderId),
  });

export const onGetOrderComparison = (orderId: string) => {
  fetch(process.env.REACT_APP_API + `/utils/comparison-report/${orderId}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + getAccessToken(), // Include your authentication token
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob(); // Convert the response to a Blob
    })
    .then((blob) => {
      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "comparison-report.xls"; // Set the desired filename
      document.body.appendChild(a);
      a.click();

      // Clean up the URL and remove the link element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => {
      console.error("Error downloading file: ", error);
    });
};

const onGetSelectedOrder = async (orderId: string) => {
  const { data } = await axios.get(`/offer/order/selected/${orderId}`);
  return data;
};

export const useSelectedOrder = (orderId: string) =>
  useQuery({
    queryKey: [queryKeys.TENDER, orderId],
    queryFn: () => onGetSelectedOrder(orderId),
  });

const onCreateOrder = async (body: any) => {
  const { data } = await axios.post("/order", body);
  return data;
};

export const useCreateOrder = (
  onSuccess: (res: any) => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onCreateOrder,
    onSuccess,
    onError,
  });

const onCreateMultipleOrder = async (body: any) => {
  const { data } = await axios.post("/order/multiple", body);
  return data;
};

export const useCreateMultipleOrder = (
  onSuccess: (res: any) => void,
  onError: () => void
) =>
  useMutation({
    mutationFn: onCreateMultipleOrder,
    onSuccess,
    onError,
  });

const onDeleteOrder = async ({
  orderId,
  order,
}: {
  orderId: string;
  order: any;
}) => {
  const { data } = await axios.put(`/order/${orderId}`, order);
  return data;
};

export const useDeleteOrder = (onSuccess: () => void, onError: () => void) =>
  useMutation({
    mutationFn: onDeleteOrder,
    onSuccess,
    onError,
  });

const onDeleteMultipleOrders = async (orders: any) => {
  const { data } = await axios.put(`/order/list`, orders);
  return data;
};

export const useDeleteMultipleOrders = (
  onSuccess: () => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: onDeleteMultipleOrders,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.MULTIPLE_ORDER_CHAT],
      });
      onSuccess();
    },
    onError,
  });
};

const onGetOrdersCount = async () => {
  const { data } = await axios.get("/order/count");
  return data;
};

export const useOrdersCount = () =>
  useQuery({
    queryKey: [queryKeys.ORDERS_COUNT],
    queryFn: () => onGetOrdersCount(),
  });
