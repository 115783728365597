import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import i18next from "../../../i18n";
import { classNames } from "../../../utils";

type BiddingTypeSelectorProps = {
  types: any[];
  typeValue: any;
  onChangeType: (value: any) => void;
};

const BiddingTypeSelector = ({
  types,
  typeValue,
  onChangeType,
}: BiddingTypeSelectorProps) => {
  return (
    <RadioGroup value={typeValue} onChange={onChangeType}>
      <p className="pt-3 font-bold uppercase text-xs text-spectum">
        {i18next.t("validations.tender.select")}
      </p>
      <div className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {types.map((type) => (
          <RadioGroup.Option
            key={type.id}
            value={type}
            className={({ checked, active }) =>
              classNames(
                checked
                  ? "border-indigo-500 ring-2 ring-indigo-500"
                  : "border-gray-300",
                active ? "border-indigo-500 ring-2 ring-indigo-500" : "",
                "relative bg-white border rounded p-4 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex-1 flex">
                  <span className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-gray-900"
                    >
                      {type.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex items-center text-xs text-gray-500"
                    >
                      {type.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description
                      as="span"
                      className="mt-6 text-sm font-medium text-gray-900"
                    >
                      {type.users}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-indigo-500" : "border-transparent",
                    "absolute rounded pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default BiddingTypeSelector;
