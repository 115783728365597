import * as actionTypes from "./actionTypes";

export const updateSupplierPriceVolume = (item: any) => {
  return {
    type: actionTypes.UPDATE_SUPPLIER_PRICE_VOLUME,
    item,
  };
};

export const cleanSupplierPriceVolume = () => {
  return {
    type: actionTypes.CLEAN_SUPPLIER_PRICE_VOLUME,
  };
};
