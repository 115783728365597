import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import {
  BIDDING_TYPES,
  BIDDING_TYPES_LABELS,
  FileType,
  formatDate,
  IT_DOESNT_HAVE_SPEC,
  NO_SPEC,
  PILL_LABELS,
  TO_BE_AGREED,
} from "../../utils";
import {
  GoBackArrow,
  Loader,
  Offer,
  TenderSucceeded,
  Pill,
  InfoItem,
  Slide,
  Chat,
} from "../../components";
import { useID } from "../../hooks";
import { FileResponse, OrderState } from "../../types";
import {
  useCreateChat,
  useCreateMessage,
  useOrderChat,
} from "../../hooks/useChat";
import { useOffer } from "../../hooks/useOffers";
import { queryKeys } from "../../hooks/queryKeys";

const Bid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = useID();
  const [openChat, setOpenChat] = useState(false);

  const { data: offer, isLoading: isLoadingOffers, isError } = useOffer(id);

  const { data: chat, isLoading: isLoadingChat } = useOrderChat(
    offer?.order?.id || ""
  );

  const { mutate: mutateChat, isPending: isLoadingCreateChat } = useCreateChat([
    queryKeys.CHAT,
  ]);

  const { mutate: mutateMessage, isPending: isLoadingMessage } =
    useCreateMessage([queryKeys.CHAT]);

  const isSpec = NO_SPEC === offer?.order?.fileUrl;

  if (isLoadingOffers) return <Loader isFullScreen />;

  if (isError) navigate("/not-found");

  return (
    <Fragment>
      <div className="min-h-full flex">
        <div className="flex flex-col w-0 flex-1">
          <div className="py-8 xl:py-10">
            <div className="flex justify-between items-center mb-4 pr-4 lg:pr-8">
              <GoBackArrow stylesAdded={"px-8"} isOffer />
              <button
                onClick={() => setOpenChat(true)}
                className="uppercase flex items-center gap-2 px-4 py-2 shadow-sm text-xs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                {t("chat.title")}
                {chat?.messages?.length > 0 && (
                  <span className="text-white border border-blue-600 bg-blue-600 px-1 rounded-full">
                    {chat?.messages?.length}
                  </span>
                )}
              </button>
            </div>
            <div className="mx-auto px-4 lg:px-8 grid grid-cols-4">
              <div className="col-span-2">
                <div>
                  <div>
                    <div className="relative md:flex md:items-center md:justify-between md:space-x-4 border-b pb-4">
                      <div className="w-full">
                        {offer?.order?.active && (
                          <div className="mb-2">
                            {(offer?.order.state === OrderState.OPEN ||
                              offer?.order.state === OrderState.CLOSED) && (
                              <Pill
                                label={PILL_LABELS.OFFER_PENDING_OF_SELECTION}
                              />
                            )}
                            {offer?.order.state === OrderState.SUCCEED &&
                              !offer?.selected && (
                                <Pill label={PILL_LABELS.OFFER_NOT_SELECTED} />
                              )}
                            {offer?.order.state === OrderState.SUCCEED &&
                              offer?.selected && (
                                <Pill label={PILL_LABELS.OFFER_SELECTED} />
                              )}
                            {offer?.order.state === OrderState.CANCELLED &&
                              !offer?.selected && (
                                <Pill label={PILL_LABELS.BIDDING_CANCELED} />
                              )}
                          </div>
                        )}
                        <div className="flex items-center justify-between">
                          <h1 className="text-xl font-bold text-gray-900 uppercase">
                            {offer?.order?.material?.globalMaterialName}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <InfoItem
                      name={t("tenders.form.company")}
                      description={
                        <span className="text-spectum font-bold uppercase">
                          {offer?.order?.user?.company?.name}
                        </span>
                      }
                    />
                    <InfoItem
                      name={t("tenders.form.fullName")}
                      description={offer?.order?.user?.fullName}
                    />
                    <InfoItem
                      name={t("tenders.form.email")}
                      description={offer?.order?.user?.email}
                    />
                    <InfoItem
                      name={t("tenders.form.phone")}
                      description={offer?.order?.user?.phone}
                    />
                    <InfoItem
                      name={t("tenders.form.tenderType")}
                      description={
                        offer?.order?.type === BIDDING_TYPES.INVERSE
                          ? BIDDING_TYPES_LABELS.INVERSE
                          : offer?.order?.type
                      }
                    />
                    {offer && (
                      <InfoItem
                        name={t("tenders.form.end")}
                        description={formatDate(offer?.order?.finishDate)}
                      />
                    )}
                    {offer?.order?.type === BIDDING_TYPES.INVERSE && (
                      <Fragment>
                        <InfoItem
                          name={t("tenders.form.maximumInitialPrice")}
                          description={`${offer?.order?.initialPrice} ${offer?.order?.currency}`}
                        />
                        <InfoItem
                          name={t("tenders.form.minimumDecrement")}
                          description={`${offer?.order?.minimumDecrement} %`}
                        />
                      </Fragment>
                    )}
                    <InfoItem
                      name={t("tenders.form.volume")}
                      description={offer?.order?.quantity}
                    />
                    <InfoItem
                      name={t("tenders.form.measurementUnit")}
                      description={offer?.order?.measurementUnit}
                    />
                    {offer?.order?.material?.incoTerm && (
                      <InfoItem
                        name={t("tenders.form.incoTerm")}
                        description={offer?.order?.material?.incoTerm}
                      />
                    )}
                    <InfoItem
                      name={t("tenders.form.deliveryPlace")}
                      description={
                        offer?.order?.deliveryPlace
                          ? offer?.order?.deliveryPlace?.address
                          : TO_BE_AGREED
                      }
                    />
                    {offer && (
                      <>
                        {offer?.order?.startSupplyDate ===
                        offer?.order?.endSupplyDate ? (
                          <InfoItem
                            name={t("tenders.form.tenderPeriod")}
                            description={formatDate(
                              offer?.order?.startSupplyDate
                            )}
                          />
                        ) : (
                          <InfoItem
                            name={t("tenders.form.tenderPeriod")}
                            description={`${formatDate(offer?.order?.startSupplyDate)} ${t(
                              "tenders.to"
                            )} ${formatDate(offer?.order?.endSupplyDate)}`}
                          />
                        )}
                      </>
                    )}
                    <InfoItem
                      name={t("tenders.form.paymentMethod")}
                      description={offer?.order?.paymentMethod}
                    />
                    <InfoItem
                      name={t("tenders.form.paymentTerm")}
                      description={`${offer?.order?.paymentTerm} ${
                        offer?.order?.paymentTerm === 1
                          ? ` ${t("opportunities.table.day")}`
                          : ` ${t("opportunities.table.days")}`
                      }
                      `}
                    />
                    <div className="bg-white overflow-hidden">
                      <div className="py-2 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-2 sm:grid sm:grid-cols-2 sm:gap-4">
                            <dt className="uppercase text-xs font-medium text-gray-500">
                              {t("newMaterialForm.specification")}
                            </dt>
                            <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                              {isSpec ? (
                                <p>{IT_DOESNT_HAVE_SPEC}</p>
                              ) : (
                                <a
                                  href={offer?.order?.fileUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="w-200 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                                >
                                  <ArrowDownTrayIcon
                                    className="-ml-0.5 mr-2 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  {t("cta.download")}
                                </a>
                              )}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>

                    {offer && offer?.order?.files?.length > 0 && (
                      <div className="bg-white overflow-hidden">
                        {offer.order.files.map((file: FileResponse) => (
                          <div key={file.fileURL} className="py-2 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                              <div className="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
                                <dt className="uppercase text-xs font-medium text-gray-500">
                                  {file.type === FileType.ATTACHMENT &&
                                    t("tenders.form.additionalDocument")}
                                  {file.type === FileType.SHEET &&
                                    t("tenders.form.plication")}
                                  {file.type === FileType.CERTIFICATION &&
                                    t("tenders.form.certificate")}
                                </dt>
                                <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                                  <a
                                    href={file.fileURL}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="w-200 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                                  >
                                    <ArrowDownTrayIcon
                                      className="-ml-0.5 mr-2 h-4 w-4"
                                      aria-hidden="true"
                                    />
                                    {file.type === FileType.ATTACHMENT &&
                                      t("tenders.form.additionalDocument")}
                                    {file.type === FileType.SHEET &&
                                      t("tenders.form.plication")}
                                    {file.type === FileType.CERTIFICATION &&
                                      t("tenders.form.certificate")}
                                  </a>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                {offer?.order?.active ? (
                  <>
                    <Offer offer={offer} />
                    {offer?.selected && (
                      <TenderSucceeded tender={offer?.order} />
                    )}
                  </>
                ) : (
                  <div>
                    <p className="text-center uppercase text-md font-bold bg-red-100 text-red-800 rounded px-4 py-2 mb-4">
                      {t("tenders.form.canceled")}
                    </p>
                  </div>
                )}
                <div className="mt-4 p-2 rounded bg-gray-200">
                  <p className="font-bold text-xxs text-center uppercase">
                    {t("tenders.disclaimer")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slide open={openChat} setOpen={setOpenChat}>
        {offer && (
          <Chat
            isOrder
            chat={chat}
            order={offer?.order}
            onCreateChat={mutateChat}
            isLoadingChat={
              isLoadingChat || isLoadingMessage || isLoadingCreateChat
            }
            onCreateMessage={mutateMessage}
          />
        )}
      </Slide>
    </Fragment>
  );
};

export default Bid;
