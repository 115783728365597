export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const CHANGE_USER_LANGUAGE_START = "CHANGE_USER_LANGUAGE_START";
export const CHANGE_USER_LANGUAGE_SUCCESS = "CHANGE_USER_LANGUAGE_SUCCESS";
export const CHANGE_USER_LANGUAGE_FAILED = "CHANGE_USER_LANGUAGE_FAILED";

export const CHANGE_COMPANY_START = "CHANGE_COMPANY_START";
export const CHANGE_COMPANY_SUCCESS = "CHANGE_COMPANY_SUCCESS";
export const CHANGE_COMPANY_FAILED = "CHANGE_COMPANY_FAILED";

export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export const UPDATE_SUPPLIER_PRICE_VOLUME = "UPDATE_SUPPLIER_PRICE_VOLUME";
export const CLEAN_SUPPLIER_PRICE_VOLUME = "CLEAN_SUPPLIER_PRICE_VOLUME";

export const OPEN_BANNER = "OPEN_BANNER";
export const CLOSE_BANNER = "CLOSE_BANNER";

export const ADD_USER_TO_INVITES = "ADD_USER_TO_INVITES";
export const CLEAN_USER_INVITES = "CLEAN_USER_INVITES";
export const ORDER_INVITATION_START = "ORDER_INVITATION_START";
export const ORDER_INVITATION_SUCCESS = "ORDER_INVITATION_SUCCESS";
export const ORDER_INVITATION_FAILED = "ORDER_INVITATION_FAILED";
