import { Fragment, useState, useReducer } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import InfoItem from "../UI/InfoItem/InfoItem";
import Modal from "../UI/Modal/Modal";
import {
  BIDDING_TYPES,
  labelType,
  calculateMinimumDecrement,
  FileType,
  formatDate,
} from "../../utils";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { OfferResponse, ORDER_TYPES, OrderState } from "../../types";

const actions = {
  UPDATE_OFFER_PRICE: "UPDATE_OFFER_PRICE",
};

type JointOfferSummaryProps = {
  offerSummary: any;
  type: ORDER_TYPES;
  currency: string;
  measurementUnit: string;
  onUpdateMultipleInverseOffer: (offers: any) => void;
};

const JointOfferSummary = ({
  type,
  currency,
  offerSummary,
  measurementUnit,
  onUpdateMultipleInverseOffer,
}: JointOfferSummaryProps) => {
  const [open, setOpen] = useState(false);
  const [event, updateEvent] = useReducer(
    (state: any, action: any) => {
      const newState = { ...state };
      switch (action.type) {
        case actions.UPDATE_OFFER_PRICE: {
          const price = parseFloat(action.value);
          const offerIndex = newState.offersToUpdate.findIndex(
            (offer: OfferResponse) => offer.id === action.offerId
          );
          if (isNaN(price) || price === 0) {
            if (offerIndex !== -1) {
              newState.offersToUpdate.splice(offerIndex, 1);
            }
            delete newState.errors[action.offerId];
            return newState;
          }

          if (price > parseFloat(action.minDecrement) || price < 0) {
            newState.errors[action.offerId] = newState.errors[action.offerId] =
              `${i18next.t("validations.lowerThanX")} ${action.minDecrement}.`;
          } else {
            delete newState.errors[action.offerId];

            if (offerIndex !== -1) {
              newState.offersToUpdate[offerIndex].price = parseFloat(
                action.value
              );
            } else {
              newState.offersToUpdate.push({
                id: action.offerId,
                price: price,
              });
            }
          }
          return newState;
        }
      }
    },
    { offersToUpdate: [], errors: {} }
  );

  let thIsBid = [
    { id: 1, name: i18next.t("tenders.tenderSummary.material") },
    { id: 2, name: i18next.t("tenders.tenderSummary.volume") },
    { id: 3, name: i18next.t("tenders.tenderSummary.incoTerm") },
    { id: 4, name: i18next.t("tenders.tenderSummary.paymentTerm") },
    { id: 5, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
    {
      id: 6,
      name: `${i18next.t(
        "offer.table.price"
      )} - (${currency} / ${measurementUnit}) ${i18next.t(
        "tenders.seller.noTax"
      )}`,
    },
    { id: 7, name: i18next.t("offer.comments") },
  ];

  if (type === BIDDING_TYPES.INVERSE_JOINT) {
    thIsBid = [
      { id: 1, name: i18next.t("tenders.tenderSummary.material") },
      { id: 2, name: i18next.t("tenders.tenderSummary.volume") },
      { id: 3, name: i18next.t("tenders.tenderSummary.incoTerm") },
      { id: 4, name: i18next.t("tenders.tenderSummary.paymentTerm") },
      { id: 5, name: i18next.t("tenders.tenderSummary.tenderPeriod") },
      { id: 6, name: i18next.t("tenders.tenderSummary.initialPrice") },
      { id: 7, name: i18next.t("tenders.tenderSummary.percentageDecrement") },
      {
        id: 8,
        name: `${i18next.t(
          "offer.table.price"
        )} - (${currency} / ${measurementUnit}) ${i18next.t(
          "tenders.seller.noTax"
        )}`,
      },
      { id: 9, name: i18next.t("offer.comments") },
    ];
  }

  const onSubmit = () => {
    if (event.offersToUpdate.length > 0) {
      const offersToSubmit = event.offersToUpdate.map(
        (offer: OfferResponse) => ({
          ...offer,
          offer: { id: offer.id },
        })
      );
      onUpdateMultipleInverseOffer(offersToSubmit);
    }
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("tenders.seller.offer")}
        message={i18next.t("tenders.seller.question")}
        onClickSuccess={onSubmit}
        onClickCancel={() => setOpen(false)}
      />
      <div className="mt-4">
        <div className="sm:flex sm:items-center">
          {offerSummary[0] && (
            <div className="sm:flex-auto">
              <InfoItem
                name={i18next.t("tenders.form.tenderType")}
                description={labelType(type)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.initialDate")}
                description={formatDate(offerSummary[0][0]?.order?.startDate)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.endDate")}
                description={formatDate(offerSummary[0][0]?.order?.finishDate)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.paymentMethod")}
                description={offerSummary[0][0]?.order?.paymentMethod}
              />
              {offerSummary[0][0]?.order?.files?.length > 0 && (
                <div className="bg-white overflow-hidden">
                  {offerSummary[0][0].order.files.map((file: any) => (
                    <div key={file.fileURL} className="py-2 sm:p-0">
                      <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-1 sm:grid sm:grid-cols-2 sm:gap-4">
                          <dt className="uppercase text-xs font-medium text-gray-500">
                            {file.type === FileType.ATTACHMENT &&
                              i18next.t("tenders.form.additionalDocument")}
                            {file.type === FileType.SHEET &&
                              i18next.t("tenders.form.plication")}
                            {file.type === FileType.CERTIFICATION &&
                              i18next.t("tenders.form.certificate")}
                            {file.type === FileType.BRIEF &&
                              i18next.t("tenders.form.brief")}
                            {file.type === FileType.SERVICE_PLACE &&
                              i18next.t("tenders.form.servicePlace")}
                          </dt>
                          <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-1">
                            <a
                              href={file.fileURL}
                              target="_blank"
                              rel="noreferrer"
                              className="w-200 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-xs leading-4 font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum"
                            >
                              <ArrowDownTrayIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              {file.type === FileType.ATTACHMENT &&
                                i18next.t("tenders.form.additionalDocument")}
                              {file.type === FileType.SHEET &&
                                i18next.t("tenders.form.plication")}
                              {file.type === FileType.CERTIFICATION &&
                                i18next.t("tenders.form.certificate")}
                              {file.type === FileType.BRIEF &&
                                i18next.t("tenders.form.brief")}
                              {file.type === FileType.SERVICE_PLACE &&
                                i18next.t("tenders.form.servicePlace")}
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                {thIsBid.map((t) => (
                  <th
                    key={t.id}
                    scope="col"
                    className="uppercase py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                  >
                    {t.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {offerSummary[0]?.map((offer: any) => {
                const minDecrement = calculateMinimumDecrement(
                  offer?.price,
                  offer?.order?.minimumDecrement
                );

                const errorMessage = event.errors[offer?.id];

                return (
                  <tr
                    key={offer?.order.id}
                    className="border-b border-gray-200"
                  >
                    <td className="py-5 text-xs">
                      <div className="font-medium text-gray-900 ">
                        {offer?.order?.material?.globalMaterialName}
                      </div>
                    </td>
                    <td className="py-5 text-xs text-gray-500">
                      {offer?.order.quantity} {offer?.order.measurementUnit}
                    </td>
                    <td className="py-5 text-xs text-gray-500">
                      {offer?.order?.material?.incoTerm ?? " - "}
                    </td>
                    <td className="py-5 text-xs text-gray-500">
                      {offer?.order.paymentTerm}{" "}
                      {offer?.order.aymentTerm === 1
                        ? ` ${i18next.t("opportunities.table.day")}`
                        : ` ${i18next.t("opportunities.table.days")}`}
                    </td>
                    <td className="py-5 text-xs text-gray-500">
                      {offer?.order.startSupplyDate ===
                      offer?.order.endSupplyDate ? (
                        formatDate(offer?.order.startSupplyDate)
                      ) : (
                        <>
                          {formatDate(offer?.order.startSupplyDate)} -{" "}
                          {formatDate(offer?.order.endSupplyDate)}
                        </>
                      )}
                    </td>
                    {type === BIDDING_TYPES.INVERSE_JOINT && (
                      <Fragment>
                        <td className="py-5 text-xs text-gray-900">
                          {offer?.order.initialPrice} {offer?.order.currency}
                        </td>
                        <td className="py-5 text-xs text-gray-500">
                          {offer?.order.minimumDecrement} %
                        </td>
                      </Fragment>
                    )}
                    <td className="py-5 text-xs text-gray-900 font-bold">
                      <div className="flex items-center gap-3 justify-between pr-4 relative">
                        <span className="w-full">
                          $ {offer?.price} {currency} / {measurementUnit}
                        </span>
                        {type === BIDDING_TYPES.INVERSE_JOINT &&
                          offerSummary[0][0]?.order?.state ===
                            OrderState.OPEN && (
                            <>
                              <div className="flex gap-2 items-center relative w-full">
                                <input
                                  type="number"
                                  className={`mt-1 shadow-sm sm:text-xs rounded ${
                                    errorMessage
                                      ? "border-red-500"
                                      : "border-gray-300"
                                  }`}
                                  max={minDecrement}
                                  placeholder={minDecrement}
                                  onChange={(e) =>
                                    updateEvent({
                                      type: actions.UPDATE_OFFER_PRICE,
                                      value: e.target.value,
                                      offerId: offer?.id,
                                      minDecrement,
                                    })
                                  }
                                />
                                <div className="flex">
                                  <span>{currency}</span> /
                                  <span>{measurementUnit}</span>
                                </div>
                              </div>
                              {errorMessage && (
                                <span className="absolute right-0 text-xs text-red-600 -bottom-5">
                                  {errorMessage}
                                </span>
                              )}
                            </>
                          )}
                      </div>
                    </td>
                    <td className="py-5 text-xs text-gray-500">
                      {offer?.comment ? offer?.comment : " - "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {type === BIDDING_TYPES.INVERSE_JOINT &&
            offerSummary[0][0]?.order?.state === OrderState.OPEN && (
              <div className="px-2 py-3 bg-gray-50 text-right">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                  disabled={
                    Object.keys(event.errors).length > 0 ||
                    event.offersToUpdate.length === 0
                  }
                  className={`${
                    Object.keys(event.errors).length ||
                    event.offersToUpdate.length === 0
                      ? "bg-gray-200 text-gray-600 cursor-not-allowed"
                      : "bg-spectum hover:bg-spectum-light text-white"
                  } inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase`}
                >
                  {i18next.t("offer.offerNow")}
                </button>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default JointOfferSummary;
