import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader, OrdersTable } from "../..";
import { useHistoric } from "../../../hooks/useHistoric";

export default function BiddingList({ materialId }: { materialId: string }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const { data: historic, isLoading: isLoadingHistoric } = useHistoric(
    materialId,
    page
  );

  return (
    <section
      aria-labelledby="activity-title"
      className="mx-auto px-4 lg:px-8 pb-8"
    >
      {isLoadingHistoric ? (
        <Loader />
      ) : (
        <div className="divide-y divide-gray-200">
          <div className="pb-4">
            <h2
              id="activity-title"
              className="text-xs font-medium text-gray-900 uppercase"
            >
              {t("order.succeed")}
            </h2>
          </div>
          <div className="mt-2">
            {historic?.content?.length > 0 ? (
              <OrdersTable
                orders={historic}
                pageNumber={page}
                setPageNumber={setPage}
              />
            ) : (
              <p className="text-xs mt-2">{t("order.noTender")}</p>
            )}
          </div>
        </div>
      )}
    </section>
  );
}
