import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils";

const initialState = {
  invites: [],
  isLoading: false,
  error: null,
  order: null,
};

const addUserToInvites = (state: any, action: any) => {
  const newState = [...state.invites];
  const index = newState.findIndex((item) => {
    return item.materialId === action.invite.materialId;
  });

  if (index === -1) {
    newState.push(action.invite);
  } else {
    newState[index] = action.invite;
  }

  return updateObject(state, {
    invites: newState,
    isLoading: false,
  });
};

const orderInvitationStart = (state: any) => {
  return updateObject(state, { error: null, isLoading: true });
};

const orderInvitationSuccess = (state: any, action: any) => {
  return updateObject(state, {
    order: action.order,
    error: null,
    isLoading: false,
  });
};

const orderInvitationFailed = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    isLoading: false,
  });
};

const cleanUserInvites = (state: any) => {
  return updateObject(state, {
    invites: [],
  });
};

const orderInvitesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.ADD_USER_TO_INVITES:
      return addUserToInvites(state, action);
    case actionTypes.ORDER_INVITATION_START:
      return orderInvitationStart(state);
    case actionTypes.ORDER_INVITATION_SUCCESS:
      return orderInvitationSuccess(state, action);
    case actionTypes.ORDER_INVITATION_FAILED:
      return orderInvitationFailed(state, action);
    case actionTypes.CLEAN_USER_INVITES:
      return cleanUserInvites(state);
    default:
      return state;
  }
};

export default orderInvitesReducer;
