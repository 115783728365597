import React from "react";

const MainArea = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return <main className="lg:pl-64 flex flex-col pb-10">{children}</main>;
};

export default MainArea;
