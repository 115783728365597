import { useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { queryKeys } from "./queryKeys";

const onGetUserMaterialCategories = async () => {
  const { data } = await axios.get(`/userMaterialCategory/material/category`);
  return data;
};

export const useUserMaterialCategories = () =>
  useQuery({
    queryKey: [queryKeys.USER_MATERIAL_CATEGORIES],
    queryFn: onGetUserMaterialCategories,
  });
