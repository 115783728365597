import { combineReducers } from "redux";
import authReducer from "./authReducer";
import bannerReducer from "./bannerReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import orderInvitationReducer from "./orderInvitationReducer";
import volumePriceReducer from "./volumePriceReducer";

const initialState = {};

const mainReducer = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};

const appReducer = combineReducers({
  main: mainReducer,
  auth: authReducer,
  banner: bannerReducer,
  user: userReducer,
  notification: notificationReducer,
  volumePrice: volumePriceReducer,
  orderInvitation: orderInvitationReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
