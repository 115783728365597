import { TableTitle, Pagination } from "..";
import i18next from "../../i18n";
import OrdersTableRow from "./OrdersTableRow/OrdersTableRow";
import { SimpleOrderPaginatedResponse } from "../../types";

const tableTh = [
  { id: 1, name: i18next.t("tenders.form.internalCode") },
  { id: 2, name: i18next.t("tenders.form.name") },
  { id: 3, name: i18next.t("tenders.tenderSummary.volume") },
  { id: 4, name: i18next.t("tenders.tenderSummary.start") },
  { id: 5, name: i18next.t("tenders.tenderSummary.end") },
  { id: 6, name: i18next.t("tenders.tenderSummary.status") },
  { id: 7, name: "" },
];

type OrdersTableProps = {
  orders: SimpleOrderPaginatedResponse;
  title?: string;
  pageNumber: number;
  setPageNumber: any;
};

const OrdersTable = ({
  orders,
  title,
  pageNumber,
  setPageNumber,
}: OrdersTableProps) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col mt-2">
        {title && <TableTitle title={title} />}
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {tableTh?.map((th) => (
                  <th
                    key={th.id}
                    scope="col"
                    className="px-2 py-2 text-left text-xxs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {th.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {orders?.content
                ?.sort((a, b) =>
                  a.material?.globalMaterialName.localeCompare(
                    b.material?.globalMaterialName
                  )
                )
                .map((ord) => <OrdersTableRow key={ord.id} order={ord} />)}
            </tbody>
          </table>
          <Pagination
            results={orders?.numberOfElements}
            total={orders?.totalElements}
            amountOfPages={orders?.totalPages}
            currentPage={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default OrdersTable;
