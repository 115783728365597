type TableHeadProps = {
  year: number;
  isActual?: boolean;
  isFullYear?: boolean;
  isPreviousYear?: boolean;
  isNextYear?: boolean;
};

export default function TableHead({
  isActual,
  isFullYear,
  isPreviousYear,
  isNextYear,
  year,
}: TableHeadProps) {
  return (
    <>
      {!isActual ? (
        <div
          className={`absolute -top-8 left-0 w-full text-center p-2 text-xs ${
            isFullYear ? "bg-spectum" : "bg-spectum-light"
          } text-white font-bold`}
        >
          {isPreviousYear ? year - 1 : null}
          {isFullYear ? year : null}
          {isNextYear ? year + 1 : null}
        </div>
      ) : null}
    </>
  );
}
