import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  mapDispatchToProps,
  mapStateToProps,
  validationSchema,
} from "../../utils";
import { useErrors } from "../../hooks";
import LogoSpectum from "../../assets/images/logo-simbuy.png";
import Icon from "../../assets/images/icon-spectum.png";
import { Button, InputField, Loader } from "..";

type FormValues = {
  email: string;
  password: string;
};

type FormProps = {
  authSignInError: {
    timestamp: string;
    status: number;
    error: string;
  };
  onAuthSignIn: (data: FormValues) => void;
  isLoading: boolean;
  userId: string;
};

const Form = ({
  authSignInError,
  onAuthSignIn,
  isLoading,
  userId,
}: FormProps) => {
  const { t } = useTranslation();
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>(formOptions);
  const { errorMessage } = useErrors(authSignInError);

  const onSubmit = (data: FormValues) => {
    onAuthSignIn({
      email: data.email,
      password: data.password,
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 grid grid-cols-2">
      <div className="col-span-2 md:col-span-1 flex flex-col items-center justify-center">
        {isLoading && !userId ? (
          <Loader isFullScreen />
        ) : (
          <>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto w-72 mb-4"
                src={LogoSpectum}
                alt="Simbuy"
              />
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow rounded sm:px-10">
                <form
                  className="space-y-6"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <div>
                    <InputField
                      id="email"
                      name="email"
                      type="email"
                      label={t("login.email")}
                      errorName={errors?.email}
                      errorMessage={errors?.email?.message}
                      required
                      register={register}
                    />
                  </div>

                  <div>
                    <InputField
                      id="password"
                      name="password"
                      type="password"
                      label={t("login.password")}
                      errorName={errors?.password}
                      errorMessage={errors?.password?.message}
                      required
                      register={register}
                    />
                  </div>

                  {authSignInError && (
                    <span className="text-xs text-red-500">{errorMessage}</span>
                  )}

                  <div className="flex items-center justify-end">
                    <div className="text-xs">
                      <Link
                        to="/reset-password"
                        className="font-medium text-spectum hover:text-spectum-light"
                      >
                        {t("login.forgot")}
                      </Link>
                    </div>
                  </div>

                  <div>
                    <Button type="submit" style="w-full">
                      {t("login.login")}
                    </Button>
                  </div>
                </form>
                <div>
                  <p className="text-xs pt-4">
                    {t("login.noAccount")}{" "}
                    <Link
                      to="/register"
                      className="underline text-spectum-dark"
                    >
                      {t("login.create")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="hidden md:col-span-1 md:block relative">
        <div className="h-full w-full bg-spectum-ultraDark">
          <img className="mx-auto w-full" src={Icon} alt="Simbuy" />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
