import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

type NotificationProps = {
  handleShow: () => void;
  message: string;
  show: boolean;
};

const Notification = ({ handleShow, message, show }: NotificationProps) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div
        aria-live="assertive"
        className="z-50 fixed top-14 inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`max-w-sm md:max-w-md w-full ${
                message === t("newMaterialForm.error") ||
                message === t("newMaterialForm.materialFamilyExists")
                  ? "bg-red-700"
                  : "bg-green-700"
              } shadow-lg rounded pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {message === t("newMaterialForm.error") ||
                    message === t("newMaterialForm.materialFamilyExists") ? (
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-200"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-200"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    {message && (
                      <p className="text-sm font-medium text-white">
                        {message}
                      </p>
                    )}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className={`${
                        message === t("newMaterialForm.error") ||
                        message === t("newMaterialForm.materialFamilyExists")
                          ? "bg-red-700"
                          : "bg-green-700"
                      } rounded inline-flex text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      onClick={handleShow}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Fragment>
  );
};

export default Notification;
