import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { queryKeys } from "./queryKeys";

const onGetSite = async (siteId: string) => {
  const { data } = await axios.get(`/site/${siteId}`);
  return data;
};

export const useSite = (siteId: string) =>
  useQuery({
    queryKey: [queryKeys.SITE, siteId],
    queryFn: () => onGetSite(siteId),
  });

const onCreateSite = async (body: any) => {
  const { data } = await axios.post("/site", body);
  return data;
};

export const useCreateSite = (onSuccess: () => void, onError: () => void) =>
  useMutation({
    mutationFn: (body: any) => onCreateSite(body),
    onSuccess,
    onError,
  });

const onEditSite = async ({ siteId, body }: { siteId: string; body: any }) => {
  const { data } = await axios.put(`/site/${siteId}`, body);
  return data;
};

export const useUpdateSite = (onSuccess: () => void, onError: () => void) =>
  useMutation({
    mutationFn: onEditSite,
    onSuccess,
    onError,
  });

const onGetSites = async (companyId: string) => {
  const { data } = await axios.get(`/site/company/${companyId}`);
  return data;
};

export const useSites = (companyId: string) =>
  useQuery({
    queryKey: [queryKeys.SITES, companyId],
    queryFn: () => onGetSites(companyId),
    enabled: !!companyId,
  });
