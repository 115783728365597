import { Fragment, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import {
  GoBackArrow,
  Loader,
  JointOfferSummary,
  TenderSucceeded,
  Slide,
  Chat,
} from "../../components";
import { useID } from "../../hooks";
import BidHeader from "./BidHeader";
import { mapDispatchToProps } from "../../utils";
import i18next from "../../i18n";
import BidResultTable from "./BidResultTable";
import { OfferResponse } from "../../types";
import {
  useCreateChat,
  useCreateMessage,
  useMultipleOrderChat,
} from "../../hooks/useChat";
import {
  useMultipleOffer,
  useUpdateMultipleInverseOffer,
} from "../../hooks/useOffers";
import { queryKeys } from "../../hooks/queryKeys";

const IsJointSellerOffer = ({
  onOpenNotification,
}: {
  onOpenNotification: (message: string) => void;
}) => {
  const id = useID();
  const navigate = useNavigate();
  const [openChat, setOpenChat] = useState(false);
  const [searchParams] = useSearchParams();

  const multipleOrderId = searchParams.get("multipleOrderId");

  const { data: multipleOrderChat, isLoading: isLoadingChat } =
    useMultipleOrderChat(multipleOrderId || "");

  const { mutate: mutateChat, isPending: isLoadingCreateChat } = useCreateChat([
    queryKeys.MULTIPLE_ORDER_CHAT,
  ]);

  const { mutate: mutateMessage, isPending: isLoadingMessage } =
    useCreateMessage([queryKeys.MULTIPLE_ORDER_CHAT]);

  const {
    data: multipleOffer,
    isLoading: isLoadingOffers,
    isError: offerError,
  } = useMultipleOffer(id);

  const { mutate: mutateUpdateInverse } = useUpdateMultipleInverseOffer(
    () => {
      onOpenNotification(i18next.t("tenders.seller.offerSent"));
    },
    () => onOpenNotification(i18next.t("newMaterialForm.error"))
  );

  const selectedOffers = useMemo(() => {
    if (multipleOffer)
      return (
        multipleOffer[0]?.filter((offer: OfferResponse) => offer.selected) || []
      );
  }, [multipleOffer]);

  if (offerError) navigate("/not-found");

  return (
    <Fragment>
      {isLoadingOffers ? (
        <Loader isFullScreen />
      ) : (
        <div className="min-h-full flex">
          <div className="flex flex-col w-0 flex-1">
            <div className="py-8 xl:py-10 relative">
              <div className="w-full px-8 flex justify-between items-center gap-4">
                <GoBackArrow isOffer />
                <button
                  onClick={() => setOpenChat(true)}
                  className="uppercase flex items-center gap-2 px-4 py-2 shadow-sm text-xxs font-medium rounded border border-black bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                  {i18next.t("chat.title")}
                  {multipleOrderChat?.messages?.length > 0 && (
                    <span className="text-white border border-blue-600 bg-blue-600 px-1 rounded-full">
                      {multipleOrderChat?.messages?.length}
                    </span>
                  )}
                </button>
              </div>
              {multipleOffer &&
                multipleOffer.length &&
                multipleOffer[0].length && (
                  <div className="mx-auto px-4 lg:px-8 gap-1">
                    <div>
                      <BidHeader
                        order={multipleOffer[0][0]}
                        orderName={multipleOffer[0][0]?.order?.name}
                        selected={multipleOffer[0].some(
                          (offer: OfferResponse) => offer.selected
                        )}
                      />
                      {selectedOffers.length > 0 && (
                        <>
                          <BidResultTable
                            selectedOffers={selectedOffers}
                            title={i18next.t("tenders.seller.accepted")}
                          />
                          <div className="grid grid-cols-3 gap-2">
                            <TenderSucceeded tenderSeller={selectedOffers[0]} />
                          </div>
                          <hr />
                        </>
                      )}
                      <JointOfferSummary
                        offerSummary={multipleOffer}
                        type={multipleOffer[0][0]?.order?.type}
                        currency={multipleOffer[0][0]?.order?.currency}
                        measurementUnit={
                          multipleOffer[0][0]?.order?.measurementUnit
                        }
                        onUpdateMultipleInverseOffer={mutateUpdateInverse}
                      />
                      <div className="mt-4 p-2 rounded bg-gray-200">
                        <p className="font-bold text-xxs text-center uppercase">
                          {i18next.t("tenders.disclaimer")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      <Slide open={openChat} setOpen={setOpenChat}>
        {multipleOffer && multipleOffer.length && multipleOffer[0].length && (
          <Chat
            isOrder
            isMultiple
            chat={multipleOrderChat}
            onCreateChat={mutateChat}
            isLoadingChat={
              isLoadingChat || isLoadingCreateChat || isLoadingMessage
            }
            onCreateMessage={mutateMessage}
            order={multipleOffer[0][0]?.order}
          />
        )}
      </Slide>
    </Fragment>
  );
};

export default connect(null, mapDispatchToProps)(IsJointSellerOffer);
