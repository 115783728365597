import { Fragment } from "react";
import PriceVolumeActual from "../PriceVolumeActual/PriceVolumeActual";

type PriceVolumeItemProps = {
  id: string;
  event: any;
  actions: any;
  volActual: number | string;
  priceActual: number | string;
  updateEvent: (arg: { type: string; vol?: string; price?: string }) => void;
  prevForecastVol: number | string;
  prevForecastPrice: number | string;
  setPrevForecastVol: (arg: number | string) => void;
  isForecastDisabled: () => any;
  setPrevForecastPrice: (arg: number | string) => void;
  onUpdateSupplierPriceVolume: (arg: {
    id: string;
    forecast: { vol: string; price: string };
  }) => void;
};

export default function PriceVolumeItem({
  id,
  event,
  actions,
  volActual,
  priceActual,
  updateEvent,
  prevForecastVol,
  prevForecastPrice,
  setPrevForecastVol,
  isForecastDisabled,
  setPrevForecastPrice,
  onUpdateSupplierPriceVolume,
}: PriceVolumeItemProps) {
  return (
    <Fragment>
      <div
        className={`w-80 border-b p-1 text-center ${
          isForecastDisabled() ? "bg-gray-200" : "bg-white"
        }`}
      >
        <input
          className={`text-xs w-full border-none p-0 text-center without-arrows ${
            isForecastDisabled() ? "bg-gray-200" : "bg-white"
          }`}
          type="number"
          value={event.forecast.vol}
          onChange={(e) =>
            updateEvent({
              type: actions.UPDATE_FORECAST_VOLUME,
              vol: e.target.value,
            })
          }
          onBlur={(e) => {
            if (e.target.value !== prevForecastVol) {
              onUpdateSupplierPriceVolume({ ...event, id });
            }
          }}
          onFocus={(e) => setPrevForecastVol(e.target.value)}
          disabled={isForecastDisabled()}
        />
      </div>
      <div
        className={`w-80 border-b p-1 text-center ${
          isForecastDisabled() ? "bg-gray-200" : "bg-white"
        }`}
      >
        <input
          className={`text-xs w-full border-none p-0 text-center without-arrows ${
            isForecastDisabled() ? "bg-gray-200" : "bg-white"
          }`}
          type="number"
          value={event.forecast.price}
          onChange={(e) =>
            updateEvent({
              type: actions.UPDATE_FORECAST_PRICE,
              price: e.target.value,
            })
          }
          onBlur={(e) => {
            if (e.target.value !== prevForecastPrice) {
              onUpdateSupplierPriceVolume({ ...event, id });
            }
          }}
          onFocus={(e) => setPrevForecastPrice(e.target.value)}
          disabled={isForecastDisabled()}
        />
      </div>
      <span className="font-bold text-white pt-0.5 bg-gray-100 w-full"></span>
      <PriceVolumeActual
        event={event}
        volActual={volActual}
        priceActual={priceActual}
      />
    </Fragment>
  );
}
