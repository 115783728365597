import { connect } from "react-redux";
import { Loader, PriceVolume } from "../..";
import i18next from "../../../i18n";
import { mapDispatchToProps } from "../../../utils";
import { MaterialResponse } from "../../../types";
import {
  useCreateNegotiation,
  useMaterialVolumePrice,
  useUpdateMaterialVolume,
} from "../../../hooks/useMaterialVolume";

type PriceVolumeProps = {
  material: MaterialResponse;
  isOwner: boolean;
  isResponsible: boolean;
  onOpenNotification: (message: string) => void;
};

const PricingVolume = ({
  material,
  isOwner,
  isResponsible,
  onOpenNotification,
}: PriceVolumeProps) => {
  const { data: materialVolumePrice, isLoading: isLoadingMaterialVolume } =
    useMaterialVolumePrice(material?.id);

  const { mutate: mutateCreateNegotiation, isPending: isLoadingNegotiation } =
    useCreateNegotiation(
      () => onOpenNotification(i18next.t("volumePrice.addNegotiationSuccess")),
      () => onOpenNotification(i18next.t("offer.error"))
    );

  const { mutate: mutateUpdateVolumePrice, isPending: isUpdating } =
    useUpdateMaterialVolume(
      () => onOpenNotification(i18next.t("volumePrice.update")),
      () => onOpenNotification(i18next.t("offer.error"))
    );

  return (
    <section
      aria-labelledby="activity-title"
      className="mx-auto px-4 lg:px-8 pb-8"
    >
      {isLoadingMaterialVolume || isLoadingNegotiation || isUpdating ? (
        <Loader />
      ) : materialVolumePrice ? (
        <PriceVolume
          isOwner={isOwner}
          isResponsible={isResponsible}
          priceVolume={materialVolumePrice}
          materialId={material?.id}
          measurementUnit={material?.measurementUnit}
          coin={material?.coin}
          onUpdateMaterialVolume={mutateUpdateVolumePrice}
          onCreateNegotiation={mutateCreateNegotiation}
          isMaterialView
          hasTableHead
        />
      ) : null}
    </section>
  );
};

export default connect(null, mapDispatchToProps)(PricingVolume);
