import { useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { CategoryResponse } from "../types";
import { queryKeys } from "./queryKeys";

const onGetCategoriesByParent = async (type: string) => {
  const { data } = await axios.get(`/materialCategory/parent/${type}`);
  return data;
};

export const useCategoriesByParent = (queryKey: string, pnType: string) =>
  useQuery({
    queryKey: [queryKey, pnType],
    queryFn: () => onGetCategoriesByParent(pnType),
    enabled: !!pnType,
  });

const onGetCategoriesById = async (categoryId: string) => {
  const { data } = await axios.get(`/materialCategory/parent/id/${categoryId}`);
  return data;
};

export const useCategoriesById = (queryKey: string, type: string) =>
  useQuery({
    queryKey: [queryKey, type],
    queryFn: () => onGetCategoriesById(type),
    enabled: !!type,
  });

const onSearchMaterialCategoryByName = async (name: string) => {
  const { data } = await axios.get(`/materialCategory/material/${name}`);
  return data;
};

export const useSearchMaterialCategoryByName = (
  debouncedSearchTerm: string,
  langEs: boolean
) =>
  useQuery({
    queryKey: [queryKeys.MATERIAL_CATEGORY_BY_NAME, debouncedSearchTerm],
    queryFn: () => {
      if (langEs) {
        return onSearchMaterialCategoryByName(debouncedSearchTerm as string);
      }
      return onSearchMaterialCategoryByName(`/en/${debouncedSearchTerm}`);
    },
    enabled: !!debouncedSearchTerm,
  });

const onGetAvailableCountries = async () => {
  const { data } = await axios.get(`/country/material`);
  return data;
};

export const useAvailableCountries = () =>
  useQuery({
    queryKey: [queryKeys.AVAILABLE_COUNTRIES],
    queryFn: onGetAvailableCountries,
  });

const onGetCategories = async (categoryIds: string[]) => {
  const { data } = await axios.get(
    `materialCategory/parent/ids?ids=${categoryIds.join(",")}`
  );
  return data;
};

export const useCategories = (
  queryKey: string,
  categories: CategoryResponse[]
) =>
  useQuery({
    queryKey: [queryKey, categories],
    queryFn: () => {
      const cats = categories.map((item: any) => item.id);
      return onGetCategories(cats);
    },
    enabled: categories.length > 0,
  });
