import { CSSProperties } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function SkeletonLoad({
  count,
  style,
}: {
  count: number;
  style: CSSProperties;
}) {
  return <Skeleton count={count} style={style} highlightColor="#aaaaaa" />;
}
