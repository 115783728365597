import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hooks";
import { CategoryResponse } from "../../../types";
import { classNames } from "../../../utils";

type SearchListProps = {
  filteredCategories: CategoryResponse[];
  searchTerm: string;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSearchList: (value: string) => void;
  isSearching: boolean;
};

export default function SearchList({
  filteredCategories,
  searchTerm,
  onChangeSearch,
  onChangeSearchList,
  isSearching,
}: SearchListProps) {
  const { t } = useTranslation();
  const langEs = useLanguage();
  return (
    <Combobox
      as="div"
      value={searchTerm}
      onChange={onChangeSearchList}
      className="col-span-4 md:col-span-3"
    >
      <div className="relative">
        <ComboboxInput
          value={searchTerm}
          onChange={onChangeSearch}
          placeholder={t("newMaterialForm.insert")}
          className="w-full rounded border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>
        {filteredCategories?.length > 0 && !isSearching && (
          <ComboboxOptions className="relative z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredCategories.map((cat) => (
              <ComboboxOption
                key={cat.id}
                value={cat}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-8 pr-4",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => {
                  return (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected ? "font-semibold" : ""
                        )}
                      >
                        {langEs ? cat.name : cat.nameEn}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 left-0 flex items-center pl-1.5",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  );
                }}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}
