import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useDebounce, useLanguage } from "../../hooks";
import { mapStateToProps } from "../../utils";
import i18next from "../../i18n";
import ActiveChat from "./ActiveChat/ActiveChat";
import { Slide, Loader, Pagination, Search } from "../../components";
import { ChatResponse } from "../../types";
import { useChats, useCreateChat } from "../../hooks/useChat";
import {
  useBuyer,
  useSearchBuyers,
  useSeller,
} from "../../hooks/useValidateSeller";
import { queryKeys } from "../../hooks/queryKeys";

export const formatDate = (dateInput: string, langEs?: boolean) => {
  if (!dateInput) return "";

  const date = new Date(dateInput);

  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  const options = {
    day: "numeric",
    month: "long",
    hour: "2-digit",
    minute: "2-digit",
  };

  return new Intl.DateTimeFormat(
    langEs ? "es-AR" : "en-US",
    options as any
  ).format(date);
};

const Chat = ({ isBuy }: { isBuy: boolean }) => {
  const langEs = useLanguage();
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { debouncedSearchTerm, isSearching } = useDebounce(searchTerm, 1000);
  const [selectedChat, setSelectedChat] = useState({
    materialId: "",
    userId: "",
  });
  const [open, setOpen] = useState(false);

  const { data: buyer } = useBuyer(page, open, isBuy);

  const { data: foundBuyers } = useSearchBuyers(debouncedSearchTerm);

  const { data: seller } = useSeller(isBuy);

  const { data: chats, isLoading: isLoadingChat } = useChats(isBuy);

  const { mutate: mutateChat } = useCreateChat([queryKeys.CHATS]);

  const userIdParams = new URLSearchParams(useLocation().search).get("userId");
  const materialIdParams = new URLSearchParams(useLocation().search).get(
    "materialId"
  );

  useEffect(() => {
    if (userIdParams && materialIdParams) {
      setSelectedChat({
        userId: userIdParams,
        materialId: materialIdParams,
      });
    }
  }, []);

  const handleSelectedChat = (userId: string, materialId: string) =>
    setSelectedChat({ userId, materialId });

  const handleStartNewChat = (data: any) => {
    if (!data) return;
    mutateChat({
      material: { id: data.material.id },
      supplier: { id: data.seller.id },
    });
    setOpen(false);
  };

  return (
    <div className="px-4 mt-6">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {i18next.t("chat.title")}
      </h2>
      <div className="grid grid-cols-4 mt-4 min-h-eighty">
        <div className="col-span-1 max-h-eightyFive border-t border-l border-b rounded-tl rounded-bl shadow">
          {isBuy && (
            <div className="border-b cursor-pointer py-4 flex w-full justify-center">
              <button
                onClick={() => setOpen(true)}
                className="flex items-center justify-between gap-2 p-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
              >
                <PlusCircleIcon className="h-6 w-6" />
                <span>{i18next.t("chat.start")}</span>
              </button>
            </div>
          )}
          {isLoadingChat ? (
            <Loader isFullScreen />
          ) : (
            <>
              {chats?.map((chat: ChatResponse) => (
                <div
                  key={chat.id}
                  className={`${
                    selectedChat.materialId === chat?.material?.id &&
                    ((isBuy && chat?.supplier?.id === selectedChat.userId) ||
                      (!isBuy &&
                        chat.material.owner.id === selectedChat.userId))
                      ? "border-blue-600 border-b-2 bg-blue-100"
                      : "border-b"
                  } cursor-pointer`}
                  onClick={() => {
                    const userId = isBuy
                      ? chat?.supplier?.id
                      : chat?.material?.owner.id;
                    userId &&
                      chat?.material?.id &&
                      handleSelectedChat(userId, chat.material.id);
                  }}
                >
                  <div className="text-xs px-2 py-4">
                    <p className="uppercase font-bold text-spectum">
                      {chat?.material?.globalMaterialName}
                    </p>
                    <p className="uppercase font-bold">
                      {isBuy ? (
                        <>
                          {chat?.supplier?.fullName} -{" "}
                          {chat?.supplier?.company.name}
                        </>
                      ) : (
                        <>
                          {chat?.material?.owner.fullName} -{" "}
                          {chat?.material?.owner.company.name}
                        </>
                      )}
                    </p>
                    {chat.messages.length > 0 && (
                      <span className="text-gray-600">
                        {formatDate(
                          chat.messages[chat.messages.length - 1].updatedAt,
                          langEs
                        )}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <ActiveChat
          isBuy={isBuy}
          userId={selectedChat.userId}
          materialId={selectedChat.materialId}
          userIdParams={userIdParams}
          materialIdParams={materialIdParams}
        />
      </div>
      <Slide open={open} setOpen={setOpen}>
        <div>
          <h2 className="text-sm font-bold pb-2 text-spectum border-b uppercase">
            {i18next.t("chat.startWith")}
          </h2>
          <Search
            value={searchTerm}
            handleSearch={(e) => setSearchTerm(e.target.value)}
            border
          />
          {isSearching ? (
            <Loader isFullScreen />
          ) : (
            <>
              {isBuy ? (
                <>
                  {searchTerm === "" && buyer?.content?.length > 0 ? (
                    <>
                      {buyer?.content?.map((buy: any) => (
                        <div
                          key={buy.id}
                          className="border-b cursor-pointer flex justify-between items-center"
                        >
                          <div className="text-xs px-2 py-2">
                            <p className="uppercase text-xs font-bold text-spectum">
                              {buy.material.globalMaterialName}
                            </p>
                            <p className="uppercase font-bold text-gray-500">
                              {buy.seller.fullName} - {buy.seller.company.name}
                            </p>
                          </div>
                          <div>
                            <button
                              onClick={() => handleStartNewChat(buy)}
                              className="p-2 border border-transparent shadow-sm text-xs font-medium rounded-full text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
                            >
                              <PlusCircleIcon className="h-4 w-4" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <Pagination
                        results={buyer?.numberOfElements}
                        total={buyer?.totalElements}
                        amountOfPages={buyer?.totalPages}
                        currentPage={page}
                        setPageNumber={setPage}
                      />
                    </>
                  ) : (
                    <>
                      {foundBuyers?.length === 0 ? (
                        <p className="pt-4 text-xs">
                          {i18next.t("chat.notFound")}
                        </p>
                      ) : (
                        <>
                          {foundBuyers?.map((buy: any) => (
                            <div
                              key={buy.id}
                              className="border-b cursor-pointer flex justify-between items-center"
                            >
                              <div className="text-xs px-2 py-4">
                                <p className="uppercase text-xs font-bold text-spectum">
                                  {buy.material.globalMaterialName}
                                </p>
                                <p className="uppercase font-bold text-gray-500">
                                  {buy.seller.fullName} -{" "}
                                  {buy.seller.company.name}
                                </p>
                              </div>
                              <div>
                                <button
                                  onClick={() => handleStartNewChat(buy)}
                                  className="p-2 border border-transparent shadow-sm text-xs font-medium rounded-full text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
                                >
                                  <PlusCircleIcon className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {seller?.map((s: any) => (
                    <div
                      key={s.id}
                      className="border-b cursor-pointer flex justify-between items-center"
                    >
                      <div className="text-xs px-2 py-4">
                        <p className="uppercase text-xs font-bold text-spectum">
                          {s.material.globalMaterialName}
                        </p>
                        <p className="uppercase font-bold text-gray-500">
                          {s.seller.fullName} - {s.seller.company.name}
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={() => handleStartNewChat(s)}
                          className="p-2 border border-transparent shadow-sm text-xs font-medium rounded-full text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
                        >
                          <PlusCircleIcon className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </Slide>
    </div>
  );
};

export default connect(mapStateToProps)(Chat);
