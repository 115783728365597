import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils";

const initialState = {
  isOpen: false,
  message: null,
};

const openNotification = (state: any, action: any) => {
  return updateObject(state, {
    isOpen: true,
    message: action.message,
  });
};

const closeNotification = (state: any) => {
  return updateObject(state, {
    isOpen: false,
    message: null,
  });
};

const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.OPEN_NOTIFICATION:
      return openNotification(state, action);
    case actionTypes.CLOSE_NOTIFICATION:
      return closeNotification(state);
    default:
      return state;
  }
};

export default notificationReducer;
