import { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Loader, PurchaseRequestsTable, Tabs } from "../../components";
import i18next from "../../i18n";
import { mapStateToProps } from "../../utils";
import {
  useCountPurchaseRequests,
  useMultiplePurchaseRequests,
  useMultiplePurchaseRequestsByState,
  useUserPurchaseRequests,
  useUserPurchaseRequestsByState,
} from "../../hooks/usePurchaseRequests";

const PurchaseRequests = ({ isRequester }: { isRequester: boolean }) => {
  const [page, setPage] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [multiplePage, setMultiplePage] = useState(0);

  const { data: multiplePurchaseRequests, isLoading: isLoadingMultiple } =
    useMultiplePurchaseRequests(multiplePage, isRequester);

  const { data: userPurchaseRequests, isLoading: isLoadingUser } =
    useUserPurchaseRequests(page, isRequester);

  const { data: countPurchaseRequests, isLoading: isLoadingCount } =
    useCountPurchaseRequests(isRequester);

  const {
    data: userPurchaseRequestsByState,
    isLoading: isLoadingUserPurchase,
  } = useUserPurchaseRequestsByState(selectedTab, isRequester);

  const { data: multiplePurchaseRequestsByState, isLoading: isLoadingByState } =
    useMultiplePurchaseRequestsByState(selectedTab, isRequester);

  const tabs = [
    {
      name: i18next.t("purchaseRequests.tabs.sent"),
      current: false,
      bgColor: "bg-blue-100 text-blue-800",
      count: countPurchaseRequests?.delivered || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.draft"),
      current: true,
      bgColor: "bg-gray-100 text-gray-800",
      count: countPurchaseRequests?.draft || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.inProgress"),
      current: true,
      bgColor: "bg-purple-100 text-purple-800",
      count: countPurchaseRequests?.draft || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.rejected"),
      current: false,
      bgColor: "bg-red-100 text-red-800",
      count: countPurchaseRequests?.rejected || 0,
    },
    {
      name: i18next.t("purchaseRequests.tabs.closed"),
      current: false,
      bgColor: "bg-green-100 text-green-800",
      count: countPurchaseRequests?.succeed || 0,
    },
  ];

  return (
    <div className="px-4 mt-8">
      <div className="md:flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
          {i18next.t("purchaseRequests.title")}
        </h2>
        {isRequester && (
          <Link
            to="/new-purchase-request"
            type="button"
            className="px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum sm:order-1 uppercase mt-4"
          >
            {i18next.t("purchaseRequests.add")}
          </Link>
        )}
      </div>
      {isRequester && (
        <Tabs
          tabs={tabs}
          handleTabClick={setSelectedTab}
          selectedTab={selectedTab}
        />
      )}
      {isLoadingByState ||
      isLoadingCount ||
      isLoadingMultiple ||
      isLoadingUser ||
      isLoadingUserPurchase ? (
        <Loader />
      ) : (
        <div>
          {isRequester && (
            <>
              {userPurchaseRequestsByState?.content.length > 0 && (
                <PurchaseRequestsTable
                  pageNumber={page}
                  setPageNumber={setPage}
                  purchaseRequests={userPurchaseRequestsByState}
                />
              )}

              {multiplePurchaseRequestsByState?.content?.length > 0 && (
                <PurchaseRequestsTable
                  title={`${i18next.t("purchaseRequests.joint")}`}
                  pageNumber={multiplePage}
                  setPageNumber={setMultiplePage}
                  multiplePurchaseRequests={multiplePurchaseRequestsByState}
                />
              )}

              {userPurchaseRequestsByState?.content.length === 0 &&
                multiplePurchaseRequestsByState?.content.length === 0 && (
                  <p className="text-xs mt-10">
                    {i18next.t("purchaseRequests.noPurchaseRequests")}
                  </p>
                )}
            </>
          )}

          {!isRequester && (
            <>
              {userPurchaseRequests?.content.length > 0 && (
                <PurchaseRequestsTable
                  pageNumber={page}
                  setPageNumber={setPage}
                  purchaseRequests={userPurchaseRequests}
                />
              )}

              {multiplePurchaseRequests?.content.length > 0 && (
                <PurchaseRequestsTable
                  title={`${i18next.t("purchaseRequests.joint")}`}
                  pageNumber={multiplePage}
                  setPageNumber={setMultiplePage}
                  multiplePurchaseRequests={multiplePurchaseRequests}
                />
              )}

              {userPurchaseRequests?.content.length === 0 &&
                multiplePurchaseRequests?.content.length === 0 && (
                  <p className="text-xs mt-10">
                    {i18next.t("purchaseRequests.noPurchaseRequests")}
                  </p>
                )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(PurchaseRequests);
