import { Routes, Route } from "react-router-dom";
import { Form, NewPassword, ResetPassword } from "../../components";
import { ValidateUser, Register } from "../../containers";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/validate-user" element={<ValidateUser />} />
      <Route path="/" element={<Form />} />
    </Routes>
  );
};

export default AuthRoutes;
