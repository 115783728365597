import { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { Button, InputField } from "..";
import { BIDDING_TYPES, currencyFormatter } from "../../utils";
import i18next from "../../i18n";
import { ClipLoader } from "react-spinners";
import { OrderState } from "../../types";

type NewOfferFormProps = {
  state: string;
  errors: any;
  setOpen: (value: boolean) => void;
  currency: string;
  initialPrice?: number;
  decrement: number;
  orderType: string;
  register: any;
  quantity: number;
  measurementUnit: string;
  handleSpecification: (e: any) => void;
  isLoadingUpload: boolean;
  fileName?: string;
  control: any;
};

const NewOfferForm = ({
  state,
  errors,
  setOpen,
  currency,
  quantity,
  register,
  control,
  fileName,
  decrement,
  orderType,
  initialPrice,
  isLoadingUpload,
  measurementUnit,
  handleSpecification,
}: NewOfferFormProps) => {
  const [value, setValue] = useState(0);
  return (
    <Fragment>
      {state === OrderState.CLOSED ? (
        <h4 className="text-xs font-bold text-gray-900 uppercase xl:px-6 py-4">
          {i18next.t("offer.closed")}
        </h4>
      ) : (
        <form
          className="space-y-8 divide-y divide-gray-200 mt-8"
          autoComplete="off"
        >
          <div className="mt-10 sm:mt-0">
            <div className="mt-5 md:mt-0">
              <div className="shadow overflow-hidden">
                <h4 className="text-xs font-bold text-gray-900 uppercase p-4">
                  {i18next.t("offer.info")}
                </h4>
                <div className="bg-white px-6">
                  {orderType === BIDDING_TYPES.INVERSE && (
                    <Fragment>
                      <div className=" bg-white overflow-hidden mt-2">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="uppercase text-xs font-medium text-gray-500">
                              {i18next.t("tenders.form.maximumInitialPrice")}
                            </dt>
                            <dd className="mt-1 text-xs text-gray-900 sm">
                              {initialPrice} {currency} / {measurementUnit}{" "}
                              <i className="text-xs">
                                {i18next.t("tenders.seller.noTax")}
                              </i>
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div className="bg-white overflow-hidden mt-2">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="uppercase text-xs font-medium text-gray-500">
                              {i18next.t("tenders.form.minimumDecrement")}
                            </dt>
                            <dd className="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                              {decrement} %
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </Fragment>
                  )}
                  <div className="pb-2 mt-2">
                    <label
                      htmlFor="price"
                      className="uppercase block text-xs font-medium text-gray-700"
                    >
                      {i18next.t("offer.table.price")} - ({currency} /{" "}
                      {measurementUnit}) {i18next.t("tenders.seller.noTax")}
                      <span className="text-sm text-red-600">*</span>
                    </label>
                    <Controller
                      name="price"
                      control={control}
                      defaultValue="0"
                      render={({ field }) => (
                        <NumericFormat
                          {...field}
                          id="price"
                          thousandSeparator="."
                          decimalSeparator=","
                          defaultValue={0}
                          max={
                            orderType === BIDDING_TYPES.INVERSE
                              ? initialPrice?.toString()
                              : undefined
                          }
                          onValueChange={(values) => {
                            field.onChange(values.value);
                            setValue(Number(values.value));
                          }}
                          decimalScale={2}
                          allowNegative={false}
                          prefix={"$ "}
                          className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                        />
                      )}
                    />
                    {errors?.price && (
                      <span className="text-xs text-red-500">
                        {errors?.price?.message}
                      </span>
                    )}
                  </div>
                  <div className="w-full text-xs">
                    <p className="font-bold uppercase">
                      {i18next.t("offer.table.total")}:{" "}
                      {currencyFormatter(value * quantity, currency) || 0}
                    </p>
                  </div>
                  <div className="w-full mt-2">
                    <div className="flex gap-4 items-center">
                      <label className="uppercase block text-xs font-medium text-gray-700">
                        {i18next.t("newMaterialForm.specification")}
                      </label>
                    </div>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {fileName && <span>{fileName}</span>}

                        <div className="text-xs text-gray-600">
                          <label
                            htmlFor="specifications"
                            className="uppercase relative cursor-pointer bg-white rounded font-medium text-spectum hover:text-spectum-light focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-spectum-light"
                          >
                            {isLoadingUpload ? (
                              <div className="flex w-full items-center justify-center">
                                <ClipLoader loading size={30} />
                              </div>
                            ) : (
                              <span>{i18next.t("newMaterialForm.upload")}</span>
                            )}
                            <input
                              {...register("specifications")}
                              id="specifications"
                              name="specifications"
                              type="file"
                              className="sr-only"
                              onChange={(e) => handleSpecification(e)}
                            />
                          </label>
                        </div>

                        <p className="text-xs text-gray-500">PDF 10MB</p>
                      </div>
                    </div>

                    {errors?.specifications && (
                      <span className="text-xs text-red-500">
                        {errors?.specifications?.message}
                      </span>
                    )}
                  </div>
                  <div className="col-span-2 pb-2 mt-2">
                    <InputField
                      id="comment"
                      name="comment"
                      type="text"
                      label={`${i18next.t("offer.comments")}`}
                      isOptional
                      register={register}
                    />
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                    disabled={isLoadingUpload}
                  >
                    {i18next.t("offer.offerNow")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <div className="mt-4 p-2 rounded bg-gray-200">
        <p className="font-bold text-xxs text-center uppercase">
          {i18next.t("tenders.disclaimer")}
        </p>
      </div>
    </Fragment>
  );
};

export default NewOfferForm;
