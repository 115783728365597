import { Fragment, useRef } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import i18next from "../../../i18n";

type ModalProps = {
  icon: any;
  open: boolean;
  title: string;
  message: string;
  setOpen: any;
  isCancel?: boolean;
  hasMessage?: boolean;
  disabled?: boolean;
  onClickCancel: any;
  onClickSuccess: any;
  onChangeMessage?: any;
};

const Modal = ({
  icon,
  open,
  title,
  message,
  setOpen,
  disabled = false,
  isCancel,
  hasMessage,
  onClickCancel,
  onClickSuccess,
  onChangeMessage,
}: ModalProps) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div
                  className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${
                    isCancel ? "bg-red-100" : "bg-green-100"
                  } sm:mx-0 sm:h-10 sm:w-10`}
                >
                  {icon}
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <h3 className="text-md leading-6 font-medium text-gray-900">
                    {title}
                  </h3>
                  <div className="mt-2">
                    <p className="text-xs text-gray-700">{message}</p>
                  </div>
                  {hasMessage && (
                    <div className="flex flex-col gap-1 pt-6">
                      <label
                        htmlFor="comment"
                        className="text-xs text-gray-700"
                      >
                        {i18next.t("tenders.data.cancelBrief")}{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="comment"
                        onChange={onChangeMessage}
                        className="mt-1 focus:ring-spectum-light focus:border-spectum-light block w-full shadow-sm sm:text-xs border-gray-300 rounded"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  onClick={onClickSuccess}
                  className={`uppercase w-full inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 ${
                    isCancel && !disabled
                      ? "bg-spectum-red text-white hover:bg-red-700 focus:ring-red-500"
                      : disabled
                        ? "bg-gray-600 focus:ring-gray-600 cursor-not-allowed"
                        : "bg-spectum hover:bg-spectum-light focus:ring-spectum-light"
                  } text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-xs`}
                  disabled={disabled}
                >
                  {title}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-xs"
                  onClick={onClickCancel}
                  ref={cancelButtonRef}
                >
                  No
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
