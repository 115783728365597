import { useQuery } from "@tanstack/react-query";
import axios from "../axios-orders";
import { CountryResponse, MaterialType } from "../types";
import { queryKeys } from "./queryKeys";

const onGetPublicCountries = async (): Promise<CountryResponse[]> => {
  const { data } = await axios.get("/country/public");
  return data;
};

export const usePublicCountries = () =>
  useQuery<CountryResponse[]>({
    queryKey: [queryKeys.COUNTRIES],
    queryFn: onGetPublicCountries,
  });

const onGetCountriesByMaterialId = async (
  materialId: string
): Promise<CountryResponse[]> => {
  const { data } = await axios.get(`/country/filtered/${materialId}`);
  return data;
};

export const useCountriesByMaterialId = (id: string, type: MaterialType) =>
  useQuery<CountryResponse[]>({
    queryKey: [queryKeys.COUNTRIES_BY_MATERIAL, id],
    queryFn: () => onGetCountriesByMaterialId(id),
    enabled: type === MaterialType.PI,
  });
