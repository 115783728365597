import i18next from "../../i18n";

const Approval = () => {
  return (
    <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main>
          <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl uppercase text-center">
            {i18next.t("notFound.approvalPending")}
          </p>
          <div className="sm:ml-6">
            <div className="sm:pl-6">
              <p className="mt-4 text-base text-gray-500">
                {i18next.t("notFound.approvalMessage")}
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Approval;
