import { useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { connect } from "react-redux";
import i18next from "../../../i18n";
import { Loader, Modal } from "../..";
import { mapDispatchToProps } from "../../../utils";
import { UserResponse } from "../../../types";

type PotencialSupplierProps = {
  materialId: string;
  potencialSupplier: {
    user: UserResponse;
    invited: boolean;
  };
  setOpenSupplier: (value: boolean) => void;
  isLoadingMaterialOffer: boolean;
  onCreateMaterialOfferInvitation: (data: any) => void;
};

const PotencialSupplier = ({
  materialId,
  potencialSupplier: { user, invited },
  setOpenSupplier,
  isLoadingMaterialOffer,
  onCreateMaterialOfferInvitation,
}: PotencialSupplierProps) => {
  const [open, setOpen] = useState(false);

  const handleSupplierInvitation = () => {
    if (invited) return;
    onCreateMaterialOfferInvitation({
      user: { id: user?.id },
      material: { id: materialId },
    });
    setOpen(false);
    setOpenSupplier(false);
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={`${i18next.t("invite.supplier")} ${user?.company?.name}`}
        message={i18next.t("invite.supplierQuestion")}
        onClickSuccess={handleSupplierInvitation}
        onClickCancel={() => setOpen(false)}
      />
      <div className="relative h-full">
        {isLoadingMaterialOffer ? (
          <Loader isFullScreen />
        ) : (
          <>
            <div className="shadow p-4 rounded border border-spectum">
              <h1 className="text-xl font-bold text-gray-900">
                {user?.company?.name}
              </h1>
              <div className="bg-white overflow-hidden">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-3">
                    <dt className="uppercase text-xs text-gray-500 pb-1 font-bold">
                      {i18next.t("offer.information")}
                    </dt>
                    <dd className="text-xs text-gray-900">
                      <span className="uppercase text-xs font-medium text-gray-500">
                        {i18next.t("offer.taxId")}:{" "}
                      </span>
                      {user?.company?.taxId} <br /> <br />
                      <span className="uppercase text-xs font-medium text-gray-500">
                        {i18next.t("offer.seller")}:{" "}
                      </span>
                      <span className="font-bold">{user?.fullName}</span>
                      <br />
                      <span className="uppercase text-xs font-medium text-gray-500">
                        {i18next.t("offer.email")}:{" "}
                      </span>
                      {user?.email} <br />
                      <span className="uppercase text-xs font-medium text-gray-500">
                        {i18next.t("offer.phone")}:{" "}
                      </span>
                      {user?.phone} <br />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            {!invited && (
              <button
                onClick={() => setOpen(true)}
                className="px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum sm:order-1 uppercase mt-4"
              >
                {i18next.t("material.inviteSupplier")}
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default connect(null, mapDispatchToProps)(PotencialSupplier);
