import { useMutation } from "@tanstack/react-query";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../axios-orders";
import { newPasswordValidationSchema } from "../../utils";
import LogoSpectum from "../../assets/images/logo-simbuy.png";
import { Button, InputField, Loader } from "..";

type FormValues = {
  email: string;
  password: string;
  confirmPassword?: string;
};

const NewPassword = () => {
  const { t } = useTranslation();
  const searchParams = useSearchParams()[0];
  const formOptions = { resolver: yupResolver(newPasswordValidationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>(formOptions);

  const {
    mutate: newPassword,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: async (data: FormValues) =>
      await axios.post("/auth/new-password", data),
    onSuccess: () => reset(),
  });

  const onSubmit = (data: FormValues) => {
    const token = searchParams.get("token");
    const newPasswordData = { ...data, token };
    newPassword(newPasswordData);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 p-4">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-72 mb-4" src={LogoSpectum} alt="Simbuy" />
      </div>
      {!isSuccess && (
        <>
          {isPending ? (
            <Loader isFullScreen />
          ) : (
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow rounded sm:px-10">
                <h3 className="py-4 font-bold">{t("newPassword.reset")}</h3>
                <p className="pb-4">{t("newPassword.insert")}</p>
                <form
                  className="space-y-6"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <div>
                    <InputField
                      id="email"
                      name="email"
                      type="email"
                      label={t("login.email")}
                      errorName={errors?.email}
                      errorMessage={errors?.email?.message}
                      required
                      register={register}
                    />
                  </div>

                  <div>
                    <InputField
                      id="password"
                      name="password"
                      type="password"
                      label={t("login.password")}
                      errorName={errors?.password}
                      errorMessage={errors?.password?.message}
                      required
                      register={register}
                    />
                  </div>

                  <div>
                    <InputField
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      label={t("login.confirmPassword")}
                      errorName={errors?.confirmPassword}
                      errorMessage={errors?.confirmPassword?.message}
                      required
                      register={register}
                    />
                  </div>

                  <div className="border rounded shadow p-2">
                    <p className="text-xs text-gray-600">
                      {t("changePassword.title")}
                    </p>
                    <ul className="text-xs text-gray-600 list-disc">
                      <li className="ml-4">{t("changePassword.characters")}</li>
                      <li className="ml-4">{t("changePassword.uppercase")}</li>
                      <li className="ml-4">{t("changePassword.lowercase")}</li>
                      <li className="ml-4">{t("changePassword.number")}</li>
                      <li className="ml-4">{t("changePassword.special")}</li>
                    </ul>
                  </div>

                  <div>
                    <Button type="submit">{t("cta.send")}</Button>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-xs">
                      <Link
                        to="/"
                        className="font-medium text-spectum hover:text-spectum-light"
                      >
                        {t("login.signIn")}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}
      <div className="sm:max-w-md m-auto mt-4">
        {isSuccess && (
          <>
            <p className="text-white bg-green-800 rounded py-2 px-6">
              {t("login.change")}
            </p>
            <Link
              to="/"
              className="block text-center mt-6 font-medium text-spectum hover:text-spectum-light"
            >
              {t("login.signIn")}
            </Link>
          </>
        )}

        {isError && (
          <p className="text-white bg-red-600 rounded py-2 px-6">
            {t("newMaterialForm.error")}
          </p>
        )}
      </div>
    </div>
  );
};

export default NewPassword;
