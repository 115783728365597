import { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/24/solid";
import {
  USER_COMPANY_TYPES,
  USER_TYPES,
  mapDispatchToProps,
  mapStateToProps,
} from "../../utils";
import { useID } from "../../hooks";
import {
  GoBackArrow,
  InfoItem,
  Loader,
  MaterialsTable,
} from "../../components";
import { useUpdateUser, useUserById } from "../../hooks/useUsers";
import { useAllMaterialsByCompanyAndUser } from "../../hooks/useMaterials";

const User = ({
  isBuy,
  onOpenNotification,
}: {
  isBuy: boolean;
  onOpenNotification: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const id = useID();

  const { data: user, isLoading: isLoadingUser } = useUserById(id);

  const userMaterialsByCompany = useAllMaterialsByCompanyAndUser(
    id,
    page,
    isBuy
  );

  const { mutate: editUser, isPending: isUpdatingUser } = useUpdateUser(
    () => {
      onOpenNotification(t("users.approveSuccess"));
      return navigate("/users");
    },
    () => onOpenNotification(t("newMaterialForm.error"))
  );

  const handleApprove = () => {
    editUser({
      userId: id,
      body: {
        ...user,
        role: USER_TYPES.USER,
        userCompanyType: USER_COMPANY_TYPES.COMMERCIAL,
        parent: { id: user?.parent?.id },
      },
    });
  };

  return (
    <main className="py-10">
      <div className="px-4">
        <GoBackArrow />
      </div>
      {userMaterialsByCompany.isLoading || isUpdatingUser || isLoadingUser ? (
        <Loader />
      ) : (
        <>
          <div className="mx-auto mt-8 px-4">
            <div className="space-y-3">
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow rounded w-full">
                  <div className="px-4 py-5 flex justify-between items-center w-full">
                    <h2
                      id="applicant-information-title"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {user?.fullName}
                    </h2>
                    <div className="flex gap-2 items-center">
                      <Link
                        type="button"
                        to={`/user/edit/${id}`}
                        className="uppercase inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light"
                      >
                        <PencilIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </Link>
                      {user?.role === USER_COMPANY_TYPES.ADMIN_APPROVAL && (
                        <button
                          className="py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded text-white bg-spectum hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase"
                          onClick={handleApprove}
                        >
                          {t("users.approve")}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-2">
                    <InfoItem
                      name={t("profile.email")}
                      description={user?.email}
                    />
                    <InfoItem
                      name={t("profile.phone")}
                      description={user?.phone}
                    />
                    <InfoItem
                      name="Rol"
                      description={
                        <>
                          {user?.userCompanyType === USER_COMPANY_TYPES.BUYER
                            ? t("userTypes.buyer")
                            : null}
                          {user?.userCompanyType === USER_COMPANY_TYPES.MANAGER
                            ? t("userTypes.manager")
                            : null}
                          {user?.userCompanyType === USER_COMPANY_TYPES.ANALYST
                            ? t("userTypes.analyst")
                            : null}
                          {user?.userCompanyType ===
                          USER_COMPANY_TYPES.VISUALIZER
                            ? t("userTypes.visualizer")
                            : null}
                          {user?.userCompanyType ===
                          USER_COMPANY_TYPES.COMMERCIAL
                            ? t("userTypes.commercial")
                            : null}
                          {user?.role === USER_COMPANY_TYPES.ADMIN_APPROVAL ? (
                            <span className="text-xs font-semibold uppercase p-1 rounded bg-yellow-100 text-yellow-800">
                              {t("userTypes.pendingApproval")}
                            </span>
                          ) : null}
                        </>
                      }
                    />
                  </div>
                </div>
                {isBuy && (
                  <div className="mt-1">
                    {userMaterialsByCompany.data?.content?.length > 0 ? (
                      <MaterialsTable
                        pageUserMaterial={page}
                        setPageUserMaterial={setPage}
                        userMaterials={userMaterialsByCompany.data}
                      />
                    ) : (
                      <p className="text-xs mt-2">{t("users.noMaterials")}</p>
                    )}
                  </div>
                )}
              </section>
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
