import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

const TenderSucceeded = ({
  tender,
  seller,
  tenderSeller,
}: {
  tender?: any;
  seller?: any;
  tenderSeller?: any;
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-span-1 bg-white rounded shadow divide-y divide-gray-200 mt-4 mb-4 grid">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          {seller && (
            <p className="text-spectum uppercase text-lg truncate">
              {t("tenders.tenderSucceed.sample")}
            </p>
          )}
          <p className="my-1 text-gray-500 text-xs truncate uppercase">
            {t("tenders.tenderSucceed.contactData")}
          </p>
          <div>
            {tender && (
              <h3 className="my-1 text-gray-900 text-xs font-medium uppercase">
                {tender?.user?.company?.name} - {tender?.user?.fullName}
              </h3>
            )}
            {tenderSeller && (
              <h3 className="my-1 text-gray-900 text-xs font-medium uppercase">
                {tenderSeller?.order?.user?.company?.name} -{" "}
                {tenderSeller?.order?.user?.fullName}
              </h3>
            )}
            {seller && (
              <h3 className="text-gray-900 text-xs font-medium truncate">
                {seller?.material?.owner?.fullName} -{" "}
                {seller?.material?.owner?.company?.name}
              </h3>
            )}
            {tender && (
              <p className="text-gray-900 text-xs font-medium truncate uppercase">
                <span className="text-gray-500">{t("profile.tax")}:</span>{" "}
                {tender?.user?.company?.taxId}
              </p>
            )}
            {tenderSeller && (
              <p className="text-gray-900 text-xs font-medium truncate uppercase">
                <span className="text-gray-500">{t("profile.tax")}:</span>{" "}
                {tenderSeller?.order?.user?.company?.taxId}
              </p>
            )}
            {seller && (
              <p className="text-gray-900 text-xs font-medium truncate">
                {t("profile.tax")}: {seller?.material?.owner?.company?.taxId}
              </p>
            )}
          </div>
        </div>
        {tender?.user?.imageUrl && (
          <img
            className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
            src={tender?.user?.imageUrl}
            alt=""
          />
        )}
        {tenderSeller?.order?.user?.imageUrl && (
          <img
            className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
            src={tender?.user?.imageUrl}
            alt=""
          />
        )}
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="w-0 flex-1 flex">
            {tender && (
              <a
                href={`mailto:${tender?.user?.email}`}
                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
              >
                <EnvelopeIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">{tender?.user?.email}</span>
              </a>
            )}
            {tenderSeller && (
              <a
                href={`mailto:${tenderSeller?.order?.user?.email}`}
                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
              >
                <EnvelopeIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">{tenderSeller?.order?.user?.email}</span>
              </a>
            )}
            {seller && (
              <a
                href={`mailto:${seller?.material?.owner?.email}`}
                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
              >
                <EnvelopeIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">{seller?.material?.owner?.email}</span>
              </a>
            )}
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            {tender && (
              <a
                href={`tel:${tender?.user?.phone}`}
                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              >
                <PhoneIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">{tender?.user?.phone}</span>
              </a>
            )}
            {tenderSeller && (
              <a
                href={`tel:${tenderSeller?.order?.user?.phone}`}
                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              >
                <PhoneIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">{tenderSeller?.order?.user?.phone}</span>
              </a>
            )}
            {seller && (
              <a
                href={`tel:${seller?.phone}`}
                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-xs text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              >
                <PhoneIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">{seller?.material?.owner?.phone}</span>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenderSucceeded;
