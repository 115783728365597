import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import {
  BIDDING_LABELS,
  BIDDING_TYPES,
  getNumberOfDays,
  BIDDING_TYPES_LABELS,
} from "../../../utils";
import { OrderResponse } from "../../../types";

const OpportunitiesTableRow = ({
  opportunity,
}: {
  opportunity: OrderResponse;
}) => {
  const { t } = useTranslation();
  const daysTillClose = getNumberOfDays(opportunity.finishDate);
  const daysResolver = `${
    daysTillClose < 0
      ? BIDDING_LABELS.CLOSED
      : daysTillClose === 0
        ? BIDDING_LABELS.TODAY
        : daysTillClose === 1
          ? `${daysTillClose} ${t("opportunities.table.day")}`
          : `${daysTillClose} ${t("opportunities.table.days")}`
  }`;

  return (
    <tr key={opportunity.id}>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="flex text-xxs items-center">
          <Link
            to={`/order/${opportunity.id}`}
            className="truncate text-gray-900 uppercase font-semibold text-xxs"
          >
            <span>{opportunity.name}</span>
          </Link>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {opportunity.user?.company?.name}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          {opportunity.quantity} {opportunity.measurementUnit}
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">{daysResolver}</div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <div className="text-xxs text-gray-500">
          <span
            className={`text-xxs font-bold ${
              opportunity.type === BIDDING_TYPES.INVERSE
                ? "text-spectum"
                : opportunity.type === BIDDING_TYPES.NORMAL
                  ? "text-green-600"
                  : "text-yellow-600"
            }`}
          >
            {opportunity.type === BIDDING_TYPES.INVERSE &&
              BIDDING_TYPES_LABELS.INVERSE}
            {opportunity.type === BIDDING_TYPES.NORMAL &&
              BIDDING_TYPES_LABELS.NORMAL}
            {opportunity.type === BIDDING_TYPES.JOINT &&
              BIDDING_TYPES_LABELS.JOINT}
          </span>
        </div>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-xxs text-gray-500">
        <Link
          to={`/order/${opportunity.id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <ChevronDoubleRightIcon className="h-6 w-6" />
        </Link>
      </td>
    </tr>
  );
};

export default OpportunitiesTableRow;
