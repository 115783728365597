import UserMaterialOpportunitiesNPITable from "../UserMaterialOpportunitiesNPITable/UserMaterialOpportunitiesNPITable";
import { OpportunityByFamilyPaginatedResponse } from "../../types";

type UserMaterialOpportunityBoxProps = {
  materialOpportunitiesByFamily: OpportunityByFamilyPaginatedResponse;
  familyPage: number;
  setFamilyPage: any;
};

export default function UserMaterialOpportunityBox({
  materialOpportunitiesByFamily,
  familyPage,
  setFamilyPage,
}: UserMaterialOpportunityBoxProps) {
  const uniqueOpportunities = new Set();

  const uniqueArray = materialOpportunitiesByFamily.content.filter(
    (mainElement) => {
      const parentId = mainElement.category.parent.id;
      if (uniqueOpportunities.has(parentId)) {
        return false;
      }
      uniqueOpportunities.add(parentId);
      return true;
    }
  );

  return (
    <div>
      <UserMaterialOpportunitiesNPITable
        npiOpportunities={uniqueArray}
        familyPage={familyPage}
        setFamilyPage={setFamilyPage}
        pagination={materialOpportunitiesByFamily}
      />
    </div>
  );
}
